import ProbabilityImg1 from '../../Images/ProbabilityImg1.PNG'
import ProbabilityImg2 from '../../Images/ProbabilityImg2.PNG'
import ProbabilityImg3 from '../../Images/ProbabilityImg3.PNG'
import ProbabilityImg4 from '../../Images/ProbabilityImg4.PNG'

export default function Tendencies(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Measures of Central Tendencies</h1>
               <p>
                                
           
                         
                        <h2>Mean or Average;</h2>
Finding the mean of a dataset will allow you to find the average amount of all input numbers combined by calculating the sum of all the input values and dividing it by the amount values input. 
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ProbabilityImg1} alt="mean" width="15%"/>
                                </div>
<h2>Sample Standard Deviation;</h2>
The standard deviation of a dataset will give you information of the variability of the data and can be used to determine how far an individual data point is from the average.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ProbabilityImg2} alt="standard deviation" width="20%"/>
                                </div>
<h2>Mode;</h2>
The mode of a dataset is the value which occurs the most frequently.
<br />
                        <br />
<h2>Intersection;</h2>
The intersection of two variables or datasets is the numbers which show up in both sets.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ProbabilityImg3} alt="intersection" width="10%"/>
                                </div>
<h2>Union;</h2>
The union is the numbers which show up in either datasets.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ProbabilityImg4} alt="union" width="10%"/>
                                </div>
                        </p>
            </div>
    )
}