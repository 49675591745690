import GrinderImg from '../../Images/GrinderImg.jpg'
import T13 from '../../Images/T13.JPG'

export default function Grinder(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Grinder</h1>
               <p>
                              
     
          
           <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GrinderImg} alt="Grinder" width="45%"/>
                                </div>
                                <span style={{ fontWeight: 'bold' }}>Description:</span> A grinder/belt sander is a two-in-one mechanism used to refine the surface of a material using rotating abrasive surfaces.
                                <br />
                        <br /> 
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={T13} alt="Grinder" width="45%"/>
                                </div>
                                <br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Wheel Speed:</span> The rotating speed of the abrasive wheel.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Belt Speed:</span> The rotating speed of the belt sander.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Motor Horsepower (HP):</span> The unit of measurement for how much mechanical work can be done by the motor within this assembly.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Motor Amperage (AMP):</span> The total amount of amps which the motor may pull when running on full capacity.
                        </p>
            </div>
    )
}