import GeometryImg5 from '../../Images/GeometryImg5.PNG'
import ParallelogramImg from '../../Images/ParallelogramsImg.jpg'

export default function Parallelograms(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Parallelograms</h1>
               <p>
                                
           
                     
                        <h2>Intro to Parallelograms;</h2>
If a=b, then the parallelogram is a rhombus.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GeometryImg5} alt="parallelogram" width="30%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ParallelogramImg} alt="parallelogram" width="45%"/>
                                </div>
                        </p>
            </div>
    )
}