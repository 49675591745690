import ColdWorkImg from '../../Images/ColdWorkImg.jpg'

export default function Coldforming(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Cold Forming</h1>
               <p>
                                
           
                   <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                   <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ColdWorkImg} alt="Cold Work" width="100%"/>
                                </div>
                                <div style={{width:'60%'}}>
                                <h2>Intro to Cold Forming;</h2>
Also known as cold roll forming, cold forming is a forging technique used to recrystallize and shape metals at near room temperature. Forming materials at cooler temperatures retains and even enhances the tensile strength. Unlike hot working, cold working causes the material grains to distort following the flow of metal which causes work hardening and anisotropic (non-uniform-directional) material properties. This process can make metals harder and stiffer at the cost of plasticity.

                                </div>
                   </div>
                 
                   <br /> 
                   <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                    <div style={{width:'50%'}}>
                    <h2>Advantages vs. Hot Working;</h2>
No heat required
<br /> 
Better surface finish
<br /> 
Greater dimensional control
<br /> 
Excellent reproducibility and interchangeability
<br /> 
Directional properties can be worked into material
<br /> 
Minimal contamination issues
                    </div>
                    <div style={{width:'50%'}}>
                    <h2>Disadvantages vs. Hot Working;</h2>
Harder metals require stronger/harder tooling and equipment
<br /> 
Materials become less ductile, limiting deformation
<br /> 
Surfaces requires cleaning
<br /> 
May leave undesirable directional characteristics
<br /> 
May leave unwanted residual stresses
                    </div>
                   </div>


<br /> 
<h2>Materials Suitable for Cold Forming;</h2>
Alloy Steel (1045, 1117, 1215, 4140, 4150, 4340, 8620, H-11)
<br /> 
Stainless Steel (316, 416, 17-4)
<br /> 
Aluminum (2024, 6061)
<br /> 
Naval Brass
<br /> 
Most Materials up to 44 HRC

                        </p>
            </div>
    )
}