import Materials from '../../MEComponents/MaterialsImg.jpg'

export default function Stock(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Stock Material</h1>
               <p>
<h2>Intro to Stock Materials;</h2>
                            If you plan on using metals, and you should, then you will quickly discover the variety of shapes and sizes that these materials can come in. From the thinnest sheet of titanium to a rectangular aluminum extrusion to a round tube of high-grade steel, knowing what shapes material comes in proves an invaluable advantage. When designing any system for manufacturing, the least machining needed the better.
                            <br />
                            <br /> 
                            The most common metals you’ll find readily available are steel, aluminum, brass and titanium.You’ll also find copper, bronze, tungsten, nickel and platinum though the shapes available might be very limited. Aluminum is reasonably strong, light, easy to use and doesn't rust since it contains no iron. Steel on the hand comes in dozens of alloys and is excellent for most robust applications, but it is extremely heavy.
                            <br />
                            <br /> 
                            2020 <span style={{ fontWeight: 'bold' }}>extrusion</span>, named for being 20mm x 20mm, allows sliding fasteners into its grooves and be used for a wide variety of structural applications. The inside cylindrical tube can also be tapped to allow bolts to directly screw into it. 
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={Materials} alt="Common Stock Materials" width="55%"/>
                            </div>                      
                            </p>
            </div>
    )
}