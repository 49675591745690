import LightSourceImg from '../../Images/LightSourceImg.jpg'

export default function LightSources(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Light Sources</h1>
               <p>
                                
           

                        <h2>Intro to Light Sources;</h2>
The requirements for a spectrometer light source include brightness across a wide range of wavelengths, stability over time, long service life
 and reasonable cost. Many types of light sources meet some of these criteria but few if any sources meet them all. It is common in industry
  to use a halogen light for the visible range and a deuterium light source for the ultraviolet range according to the wavelength setting.
   Switching light sources using a rotating reflector is also a possibility and is often used with halogen and deuterium sources.
<br />
                        <br /> 
<h2>Halogen Lights;</h2>
Halogen lights work by running a current through a tungsten filament. The tungsten which “wants” to evaporate is prevented from doing so 
by having the bulb filled with an inert gas. The bulb is also filled with a halide to form a halogen cycle which returns any evaporated tungsten
 back to the filament. This also prevents blackening of the tube walls which in turn allows for a long service life and high brightness. Halogen
  lights are stable over time, have a long service life (typically 2000hours) and are relatively low cost.
<br />
                        <br /> 
<h2>Deuterium Lights;</h2>
Deuterium light is a discharge light source which uses a hot cathode to achieve a stable arc discharge. These light sources require a large 
and complex power supply which leads to higher cost. However, they are one of the few continuous spectrum light sources that is stable in the
 ultraviolet range. These lamps have a short emission wavelength of 400 nm or less. The use at the long-wavelength end is limited to around 
 400 nm though the low degree of attenuation allows the use of wavelengths above that. Multiple emission spectra exist above the 400 nm range. 
 Of these the ones at 486.0 nm and 656.1 nm are particularly strong and can be used for spectrometer calibration.
 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={LightSourceImg} alt="light sources" width="45%"/>
                                </div>
<h2>Xenon Lights;</h2>
A xenon lamp is a discharge lamp with xenon gas sealed within a bulb. These bulbs are categorized by whether they use direct or alternating 
current. These light sources have an issue that if the electrodes become too hot, they can evaporate and adhere to the tube walls which results
 in blackening and a loss of brightness. The xenon lamps provide a similar spectral distribution to sunlight with ranges from ultraviolet to
  infrared. Although the xenon lamp is inferior to the halogen and deuterium lamps in regards to cost and output fluctuations, they do provide
   a high brightness.
<br />
                        <br /> 
<h2>Mercury Lights;</h2>
The low-pressure (100 Pa max) mercury lamp is another discharge lamp that when lit will emit the mercury resonance lines of 254 nm and 185 nm. 
These lamps are available in versions that emit the uv light directly or as those which use fluorescent material to convert the wavelength to 
a different wavelength. The mercury emission lines are useful for calibrating spectrometers though care is required when using as some of the
 wavelengths are very close together, in these cases a slit of no more than 0.5 nm is required.

                        </p>
            </div>
    )
}