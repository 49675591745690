import MicrocontrollersImg from '../../Images/MicrocontrollersImg.jpg'

export default function Microcontrollers(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Microcontrollers</h1>
               <p>
                                
           
                       
                        <h2>Intro to Microcontrollers;</h2>
The microcontroller is a small computer printed onto a PCB which contains its own processing unit, ROM, RAM, serial communication ports and
 often an array of digital and analogue IO pins. These micro computers can be written using a stand computer and cable connection and then 
 trusted to run the programs on their own. 
<br />
                        <br />
One of the most common types of microcontrollers include one-time-programmable (OTP) which once a program has been written one the board no 
changes can be made to the program. 
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={MicrocontrollersImg} alt="microcontroller layout" width="45%"/>
                                </div>
                
                        </p>
            </div>
    )
}