import React from "react";
import {
//   BrowserRouter as Router,
  useParams,
} from "react-router-dom";


import IntegerFunctions from "./IntegerFunctions";
import VariableFunctions from './VariableFunctions';
import GraphicEquations from './GraphicEquations';
import Polynomials from './Polynomials';
import Exponential from './Exponential';
import Matrixes from './Matrixes';
import Linear from "./Linear";
import Quadratic from "./Quadratic";

const ChangeComponents = {
    integerfunctions: IntegerFunctions,
    variablefunctions: VariableFunctions,   
    graphicequations: GraphicEquations,
    polynomials: Polynomials,
    exponential: Exponential,
    matrixes: Matrixes,
    linear: Linear,
    quadratic: Quadratic,
  };

export default function Subtopics(props) {
    // The <Route> that rendered this component has a
    // path of `/topics/:topicId`. The `:topicId` portion
    // of the URL indicates a placeholder that we can
    // get from `useParams()`.
    let { topicId } = useParams();
    
    let MyComponent = ChangeComponents[topicId];
    return (
        <div>
            {/* {topicId} is for testing route names */}
            {/* <h3>{topicId}</h3> */}
            <MyComponent topicId={topicId}/>
      </div>
    );
  }