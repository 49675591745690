import Hydrophilicity from '../../Images/Hydrophilic.jpg'

export default function Hydro(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Hydrophilicity & Phobicity</h1>
               <p>
                             
           
          <h2>Intro to Hydrophilicity;</h2>
          The measurement of static and dynamic contact angles and commonly used to study wetting, dewetting and adhesion of contact
           surfaces between fluids and solids. The specific fields of hydrophilicity and hydrophobicity measure the affinity of solids
            in contact with water. The scientific community distinguishes between these two terms based on the angle formed between a
             drop of water and the horizontal surface of a solid. If the angle is less than 90 degrees, the surface is said to be 
             hydrophilic while an angle greater than 90 degrees is said to be hydrophobic. Materials with an angle greater than 145 
             degrees are considered super-hydrophobic and have almost no affinity with water.               
             <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={Hydrophilicity} alt="linkages" width="75%"/>
                                </div>
                        </p>
            </div>
    )
}