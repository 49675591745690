import StaticFluidsPic from '../../Images/StaticFluids.jpg'

export default function StaticFluids(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Static Fluids</h1>
               <p>
      <h2>Characteristics;</h2>
      In a static liquid, the difference in pressure between two points is related directly to their height both between each other and to the relative bottom. 
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={StaticFluidsPic} alt="basic dimensions" width="40%"/>
                                </div>
                        </p>
            </div>
    )
}