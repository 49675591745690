import Diaphragm from '../../MEComponents/DiaphragmPumps.jpg'
import PumpPower from '../../MEComponents/PumpPower.PNG'
import RotaryPumpsImg from '../../MEComponents/RotaryPumpsImg.jpg'
import PeristalticPumpImg from '../../MEComponents/PeristalticPumpsImg.jpg'

export default function Pumps(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Pumps</h1>
               <p>
                            
<h2>Intro to Pumps and Compressors;</h2>
Pumps are machines which move a fluid from one place to another. Compressors are machines which compress a gas into a smaller volume and often 
pump it somewhere else. Compressors are used almost exclusively for gases since liquids have such a low compression rate.  
<br />
<br /> 
There are two main types of pump mechanisms; reciprocating pumps which operate by moving alternatively back and forth, and rotary pumps which
 spin. Reciprocating pumps commonly intake fluid on one stroke and compress the fluid on the other. One or more valves ensures that the air
  drawn in does not leave it between strokes. Bicycle pumps are a common example of these. 
  <br />
<br /> 
Pumps can be further categorized by their method of displacement; positive displacement pumps, impulse pumps, velocity pumps, gravity pumps,
 steam pumps and valveless pumps. Pumps are commonly rated by their horsepower, volumetric flow rate, outlet pressure of head and inlet suction.
  The head can be simplified as the number of feet or meters which the pump can raise or lower a column of water at atmospheric pressure. A 
  quality known as specific speed is often used by engineers to categorize the pump's relationship between flow rate and head.
  <br />
<br /> 
<h2>Pumping Power and Efficiency;</h2>
The power relationship between a pump and the fluid is characterized by the mechanical work being performed by the pump and the existing energy
 within the moving fluid. This is governed by a set of instantaneous differential equations known as the Navier-Stokes Equations and Bernoulli’s
  fluid dynamic equations. The required power necessary for a pump to move a fluid is defined as the change in pressure between the inlet and
   outlet multiplied by the flow rate, all divided by the pump efficiency. The pressures are in terms of Pa while the flow rate Q is in terms 
   of m^3/s.
   <br />
<br /> 
Pump efficiency is determined by the ratio between the input electrical power into the pump and the output mechanical power performed by
 the pump. This efficiency is not a constant number as it is a function of the discharge and operating head. Pump performance data supplied
  from the manufacturer is often used to determine which flow rate operating ranges will yield the best results.
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={PumpPower} alt="Pump Equation" width="15%"/>
                            </div>
<h2>Positive Displacement Pumps;</h2>
Positive displacement pumps work by trapping a fixed amount of fluid into an area and then forcing it into a discharge pipe. Some of these 
pumps use an expanding cavity on the intake side and a decreasing cavity on the outlet side. Liquid flows into the pump as the cavity on the
 suction side expands and then discharges as the cavity on the discharge charge shrinks.
 <br />
<br /> 
Unlike centrifugal pumps, positive displacement pumps can produce the same flow rate at a given speed (rpm) no matter the discharge pressure. 
A minor loss due to internal leakage prevents them from being a perfect constant flow machine. It is important for these pumps to not operate
 with a closed outlet valve as the pressure will continue climbing until a burst in the discharge line occurs.
 <br />
<br /> 
<h2>Diaphragm Pumps;</h2>
A diaphragm pump (also known as a membrane pump) is a positive displacement pump which uses a rubber, thermoplastic or teflon diaphragm and 
suitable valves to pump a fluid. When the volume of the chamber increases, fluid rushes into the inlet side only to be channeled into the 
outlet side from the change in pressure. These pumps provide a hermetic (airtight) seal between the drive mechanism and compression chamber
 allowing the pump to operate without a lubricant. These pumps never leak, have little friction and depending on the material can be used
  on a wide range of pressures and temperatures.
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={Diaphragm} alt="Diaphragm Pumps" width="40%"/>
                            </div>
<h2>Rotary Pumps;</h2>
Rotary pumps utilize a spinning wheel to move fluid from the inlet to the outlet. Devices such as these are often called centrifugal pumps
 because they force the fluid outwards from the centrifugal force of the spinning wheel. Vane pumps, a subclass of rotary pumps work by using 
 flat blades that slide in and out as they rotate, moving the fluid and flinging it out at high speeds. Impeller pumps, another subclass, use
  a wheel with curved blades called an impeller to move the fluid to the sides and out the outlet from its rotation.
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={RotaryPumpsImg} alt="Rotary Pumps Cycle" width="60%"/>
                            </div>
                            <h2>Peristaltic Pumps;</h2>
Also called roller pumps, peristaltic pumps are positive displacement pumps which can work by channeling a liquid exclusively through a flexible tube and then pressing rollers about the tube to create flow. These pumps are ideal for pumping a variety of fluids and only require changing the tube to switch from fluids. The internal rotor has a number of rollers which usually rotate about a center point which  move fluid by compressing the tube as it rolls. As the rollers rotate, more fluid enters the tubes which is trapped and transported through the flow path.
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={PeristalticPumpImg} alt="Peristaltic ump" width="60%"/>
                            </div>                   
                       </p>
            </div>
    )
}