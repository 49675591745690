import CircularSawImg from '../../Images/CircularSawImg.jpg'
import T44 from '../../Images/T44.JPG'

export default function CircularSaw(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Circular Saw</h1>
               <p>
 
          
           <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={CircularSawImg} alt="Circular Saw" width="45%"/>
                                </div>
                                <span style={{ fontWeight: 'bold' }}>Description:</span> A circular saw is a handheld wood cutting machine which can often be arranged at a certain bevel angle for diagonal cuts.
                                <br />
                        <br /> 
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={T44} alt="Circular Saw" width="60%"/>
                                </div>
                                <br />    
                        <span style={{ fontWeight: 'bold' }}>Blade Length:</span> The diameter of the circular blade. Important to keep track of when replacing dull blades.
                        </p>
            </div>
    )
}