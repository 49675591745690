import TorqueImg from '../../Images/TorqueImg.jpg'


export default function Torque(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Torque</h1>
               <p>
<h2>Intro to Torque;</h2>
                            <span style={{ fontWeight: 'bold' }}>Torque</span> is caused by applying a force along a line of action which causes a rotational force. The fundamental concept to consider with torsion is that torque is equal to force multiplied by the length of what is known as the <span style={{ fontWeight: 'bold' }}>moment arm</span>. 
                            <br />
                            <br /> 
                            You can experience this concept if you have ever used a wrench. The longer the wrench or the further back you apply the force, the greater the torque. If you ever find yourself trying to turn a really tight nut, remember that you can either just push harder at the end of your wrench or get a longer wrench.
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={TorqueImg} alt="Torque" width="40%"/>
                            </div>
                            </p>
            </div>
    )
}