import React from 'react';
import Subtopics from './Subtopics';
import { Container } from "react-bootstrap";
import {
    Switch,
    Route,
    Link,
    useRouteMatch
  } from "react-router-dom";

function Discrete(props) {
    let { path, url } = useRouteMatch();
    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', maxWidth: "100vw"}}>
                {/* <SectionTitle title={props.topicId}/> */}
            <div style={{display: 'flex', flexDirection: 'row'}}> 
            <div style={{marginTop: '10px', marginRight: '0px', marginLeft: '5px',border: '3px', padding:'40px', paddingRight: '0px', borderStyle:'solid', borderColor:'grey', paddingLeft: '20px', whiteSpace:'nowrap'}}>
                <h2 style={{whiteSpace:'normal'}}>Discrete Components</h2>
                <li>
                    <Link to={`${url}/microcontrollers`}>Microcontrollers</Link>
                </li>
                <li>
                    <Link to={`${url}/displays`}>Displays</Link>
                </li>
                <li>
                    <Link to={`${url}/op`}>Operational Amplifiers</Link>
                </li>
                <li>
                    <Link to={`${url}/oscillators`}>Oscillators and Timers</Link>
                </li>
                <li>
                    <Link to={`${url}/powersupply`}>Power Supply</Link>
                </li>
                <li>
                    <Link to={`${url}/regulators`}>Voltage Regulators</Link>
                </li>
                <li>
                    <Link to={`${url}/audio`}>Audio Electronics</Link>
                </li>
                <li>
                    <Link to={`${url}/lightsources`}>Light Sources</Link>
                </li>  
                <li>
                    <Link to={`${url}/plcs`}>PLCs</Link>
                </li>  
                <li>
                    <Link to={`${url}/potentiometers`}>Potentiometers</Link>
                </li> 
                <li>
                    <Link to={`${url}/encoders`}>Encoders</Link>
                </li>   
                <li>
                    <Link to={`${url}/cameras`}>Cameras</Link>
                </li>   
                <li>
                    <Link to={`${url}/network`}>Network Communication</Link>
                </li>   
                <li>
                    <Link to={`${url}/transformers`}>Transformers</Link>
                </li>     
                <li>
                    <Link to={`${url}/motors`}>Motors</Link>
                </li>             
                </div>
                
            <Container>
                <Switch>
                    <Route exact path={path}>
                    <h3>Please select a sub-topic.</h3>
                    </Route>
                    <Route path={`${path}/:topicId`}>
                        <Subtopics />
                    </Route>
                </Switch>
            </Container>
         
                    </div>
            </div>
        </div>
    );
};

export default Discrete; 