import RecursionImg from '../../Images/RecursionImg.jpg'

export default function Recursion(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Recursion</h1>
               <p>
                                
           
                    
                        <h2>Intro to Recursion;</h2>
The recursive algorithm family operates by creating a smaller instance of a problem until the problem is so small that it can be solved directly..
<br />
                        <br /> 
<h2>Intro to Divide and Conquer;</h2>
When the size of an input array is large, algorithms such as selection sort or insertion sort can take quite a long time. The divide and conquer approach separates a problem into subproblems that are similar to the original and recursively solves the subproblems. This routine operates in three steps; divide the problem into subproblems, solve the individual subproblems and finally, recombine the solutions of the subproblems into the solution of the original problem.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={RecursionImg} alt="Recursion" width="35%"/>
                                </div>
                        </p>
            </div>
    )
}