import MomentImg from '../../Images/MomentImg.jpg'
import CouplesImg from '../../Images/CouplesImg.jpg'

export default function Moments(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Moments and Couples</h1>
               <p>
<h2>Intro to Moments and Couples;</h2>
                            The term <span style={{ fontWeight: 'bold' }}>moment</span>, if often used interchangeably with the term torque, they even have the same equation. Moment is often used when the object as a whole is not made to rotate despite the force being a rotational force. For example, placing a heavy box on a table will have just this effect, the gravity multiplied by mass of the box is our force, the distance between the box and table legs is our length and the resultant force is known as the moment. You should note that in this example, there would be a moment acting on each corner.
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={MomentImg} alt="Moment" width="35%"/>
                            </div>
                            A <span style={{ fontWeight: 'bold' }}>couple</span>, consists of two forces of equal magnitude with parallel lines of action and opposite facing force directions. They cannot be reduced any further than what they are as forces but can easily be converted into a single rotation moment force. An important property of couples and moments is they can be applied anywhere on a rigid body and still have the same rotational effect.
                            <br />
                            <br /> 
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={CouplesImg} alt="couples" width="55%"/>
                            </div>
                            </p>
            </div>
    )
}