import MillImg from '../../Images/MillImg.jpg'
import T32 from '../../Images/T32.JPG'

export default function Mill(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Mill</h1>
               <p>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={MillImg} alt="Mill" width="45%"/>
                                </div>
           
                      
           <span style={{ fontWeight: 'bold' }}>Description:</span> Mills are industrial machines which use rotary cutters to remove material. 
                        <br />
                        <br /> 
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={T32} alt="Mill" width="100%"/>
                                </div>
                                <br /> 
                        <span style={{ fontWeight: 'bold' }}>Motor HP:</span> The maximum output of mechanical work performed by the spindle onto a workpiece.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Spindle Speed:</span> The max rotating speed of the spindle element.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Nose to Table:</span> The maximum vertical distance between the lowest part of the chuck and the work surface table.
                        </p>
            </div>
    )
}