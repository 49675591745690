import React from "react";
import {
//   BrowserRouter as Router,
  useParams,
} from "react-router-dom";

import HTML from "./HTML";
import CSS from "./CSS";
import CPlusPlus from "./CPlusPlus";
import Javascript from "./Javascript";
import Reactjs from "./Reactjs";
import ROS from "./ROS";


const ChangeComponents = {
 
    html: HTML,
    css: CSS,
    cplusplus: CPlusPlus,
    javascript: Javascript,
    reactjs: Reactjs,
    ros: ROS,
    
   
  };

export default function Subtopics(props) {
    // The <Route> that rendered this component has a
    // path of `/topics/:topicId`. The `:topicId` portion
    // of the URL indicates a placeholder that we can
    // get from `useParams()`.
    let { topicId } = useParams();
    
    let MyComponent = ChangeComponents[topicId];
    return (
        <div>
            {/* {topicId} is for testing route names */}
            {/* <h3>{topicId}</h3> */}
            <MyComponent topicId={topicId}/>
      </div>
    );
  }