import ChromatographyImg from '../../Images/ChromatographyImg.jpg'

export default function Chromatography(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Chromatography</h1>
               <p>

                        <h2>Intro to Chromatography;</h2>
Chromatography is a scientific process used for the separation or purification of mixtures. This process works by dissolving the mixture in a
 fluid called the mobile phase which then carries it through a system (can be a column, a capillary tube, a sheet, a plate, ect) called the
  stationary phase. Once the fluid mixture runs through the separation system, molecular size and shape differences cause the molecules in
   the mixture to flow through the system at different velocities which in turn separates the mixture. Differences in the ratio of compounds
    in the mixture will affect the separation and chemical retention within the stationary phase.
<br />
                        <br /> 
<h2>Column Chromatography;</h2>
Column chromatography is a separation process which uses a tube to house the stationary phase (often a resin, silica, alumina, cellulose). The 
stationary phase can both be packed entirely filling the tube (packed column) or packed along the tube while leaving the center open for 
unrestricted mobile phase flow (open tubular column). During operation, the mobile phase is continuously added to the column and can be
 further influenced by adding a pressure source within the system such as a pump or gas pressure. Differences in velocities of the mobile 
 phase will impact the retention of the sample through the stationary phase. 
 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ChromatographyImg} alt="column chromatography" width="65%"/>
                                </div>
                        </p>
            </div>
    )
}