import ResistorsImg from '../../Images/ResistorsImg.jpg'

export default function Resistors(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Resistors</h1>
               <p>
                                
           
                  
                        <h2>Intro to Resistors;</h2>
Resistors serve two major purposes; to limit current flow and to set voltage levels within a circuit. As a general rule, always select a
 resistor with a power rating at least twice the maximum value anticipated. External factors can also have an affect on the resistors power
  rating. These include ambient temperatures, enclosures, resistor groupings, pulsed operation and additional air cooling. 
<br />
                        <br /> 
<h2>Types of Resistors;</h2>

Fixed Resistors
<br /> 
Variable Resistors
<br /> 
Digitally Adjustable Resistors
<br /> 
Fusible Resistors
<br /> 
Photoresistors
<br /> 
Resistor Arrays
<br />
<br /> 
<h2>Resistor Noise;</h2>
Resistors produce electrical noise proportional to a function of its applied voltage, physical characteristics and materials. Although
 noise doesn’t affect the value of the resistor it can have a devastating impact on signals and amplifiers. There are three main types
  of resistor noise, thermal, contact and shot noise. 
  <br />
<br />  
  <h2>Resistors in Parallel and Series;</h2>
When two or more resistors are placed in parallel, the voltage across the resistors will remain the same but the current through each resistor 
will vary based on the resistances. The total resistance of the combination will always be lower than that of the lowest resistance value 
present. 
<br />
<br /> 
When a circuit has resistors in series, the total resistance of the series becomes the summation of all of the resistors in the series. The 
amount of current flowing through the series will be the same while the voltage between each resistance will depend on the individual 
resistor’s resistance value.
<br />
<br /> 
<h2>Resistor Values;</h2>
Axial lead resistors which are resistors whose leads go outwards along the axis use color bands to indicate their resistance levels. 
Although some manufacturers use their own color coding system, most follow the following procedure for 4-band resistors. Regarding 4-band
 resistors, the first band represents the first digit, the second band represents the second digit, the third band the multiplier (using an
  exponent of 10) and the fourth band represents the tolerance. Some resistors omit the fourth band, in which case you can assume a tolerance
   of 20%. 
   <br />
<br /> 
Precision resistors utilize a 5-band system in which the first three bands represent the significant figures, the fourth band represents
 the multiplier while a space between the fourth and fifth band represents the tolerance. Military-grade resistors often require the fifth 
 band to demonstrate the reliability rating of the resistor which shows the change in resistance over a time interval.
 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ResistorsImg} alt="basic resistor" width="75%"/>
                                </div>
                        </p>
            </div>
    )
}