import DHTable1Img from '../../Images/DHTable1Img.PNG'
import DHTable2Img from '../../Images/DHTable2Img.PNG'
import DHTable3Img from '../../Images/DHTable3Img.PNG'

export default function Homogeneity(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Dimensional Homogeneity</h1>
               <p>
          
               <h2>Basic Dimensions;</h2>
                        The process of describing natural phenomena requires engineers to establish standards of units that represent both
                         the quantitative and qualitative properties of nature. Qualitative analysis provides the type of qualities present
                          such as length, time, velocity, or stress while quantitative analysis provides a numerical solution. Basic dimensions
                           represent the conceptual foundation for empirical units and are independent of metric/imperial differences. For 
                           example, the basic dimension for area is length squared, while the units for area could be feet squared or meters 
                           squared. In either case for which units you use, the basic dimensions will stay the same.
                           <br />
                        <br /> 
These basic dimensions are extremely important when writing balanced equations because every equation must have equal basic dimensions on both
 sides of the equation. Below is a chart with common basic dimensions for physical quantities separated by both force, length, time and mass, 
 length, time. Note that anything to zero power is equal to one.
 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={DHTable1Img} alt="basic dimensions" width="60%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={DHTable2Img} alt="basic dimensions" width="60%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={DHTable3Img} alt="basic dimensions" width="60%"/>
                                </div>
               
                
                        </p>
            </div>
    )
}