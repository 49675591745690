import React from "react";
import {
//   BrowserRouter as Router,
  useParams,
} from "react-router-dom";
import Freebody from './Freebody';
import Forceflow from './Forceflow';
import Centroids from './Centroids';
import Torque from "./Torque";
import Forcedecomp from "./Forcedecomp";
import Rigidbodies from "./Rigidbodies";
import Moments from "./Moments";
import Supports from "./Supports";
import Struts from "./Struts";
import Trusses from "./Trusses";

const ChangeComponents = {
    freebody: Freebody,
    forceflow: Forceflow,
    centroids: Centroids,
    torque: Torque,
    forcedecomp: Forcedecomp,
    rigidbodies: Rigidbodies,
    moments: Moments,
    supports: Supports,
    struts: Struts,
    trusses: Trusses,
  };

export default function Subtopics(props) {
    // The <Route> that rendered this component has a
    // path of `/topics/:topicId`. The `:topicId` portion
    // of the URL indicates a placeholder that we can
    // get from `useParams()`.
    let { topicId } = useParams();
    
    let MyComponent = ChangeComponents[topicId];
    return (
        <div>
            {/* {topicId} is for testing route names */}
            {/* <h3>{topicId}</h3> */}
            <MyComponent topicId={topicId}/>
      </div>
    );
  }