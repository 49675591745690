import TransformersImg from '../../Images/TransformersImg.jpg'

export default function Transformers(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Transformers</h1>
               <p>
                                
      
                        <h2>Intro to Transformers;</h2>
The basic transformer is a two-port, (4 terminal) component capable of transforming an AC input voltage into a higher or lower ac output 
voltage. Transformers are not designed to work with DC voltages. These are used with power lines and high current applications. 
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={TransformersImg} alt="transformer" width="55%"/>
                                </div>
                        </p>
            </div>
    )
}