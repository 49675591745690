import ShrinkFittingImg from '../../Images/ShrinkFittingImg.jpg'
import InductionHeatingImg from '../../Images/InductionHeatingImg.jpg'

export default function ShrinkFitting(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>ShrinkFitting</h1>
               <p>
                                
           
                       
                        <h2>Intro to Shrink Fitting;</h2>
The heating or cooling of a material has it expand or contrast based on the underlying geometry of the object. Heat shrinking uses this concept to enlarge what is usually a circular hole within one object and then insert another object within. Once the cooling begins, the boundaries of the two objects begin to press against each other and a pressure seal is formed. 
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ShrinkFittingImg} alt="Shrink Fitting" width="75%"/>
                                </div> 

<h2>Advantages;</h2>
Minimal material distortion
<br />
Prevents distortion induced adhesion (galling)
<br />
Minimal surface preparation
<br />
Maintains concentricity
<br />
Reduced processing time and energy requirements
<br />
                        <br /> 
                                
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={InductionHeatingImg} alt="Shrink Fitting" width="100%"/>
                                </div>  
                                <div style={{width:'70%'}}>
                                <h2>Induction Heating;</h2>
Induction heating is a manufacturing technique which can be used to join, treat, heat and test materials. The process involves using electrical currents within a material to produce heat and is a fast and reliable way to generate an even distribution of heat within materials.
<br />
<br />
The basic components include an AC power supply, an induction coil and the workpiece. The power supply sends alternating waves of current through the coil which generates a magnetic field. Once the workpiece is placed within the induction coil, the magnetic field induced eddy currents produce localized heat without any physical contact between the workpiece and the machenry.
<br />
<br />
There is a relationship between the frequency of the alternating current and the depth at which the workpiece will experience the localized heat. Low frequencies are better at penetrating thick materials which require deeper penetration while higher frequencies are more effective for smaller parts and shallow penetration. The design of the induction coil will dictate the required power levels and heating time. 
 
                                    </div>        
                                    </div>                 
           
                        </p>
            </div>
    )
}