import ForceDecompImg from '../../Images/ForceDecompImg.jpg'


export default function Forcedecomp(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Force Decomposition</h1>
               <p>
<h2>Intro to Force Decomposition;</h2>
                            Force is a vector quantity which is defined by its magnitude, direction and point of application. When dealing with any three-dimensional force, it is often helpful to reduce the force to its’ x, y and z components. Physically, these three component forces have an equal result to the initial 3D force but having the forces individually within one dimension can make them much more manageable. 
                            <br />
                            <br /> 
                            Often engineers will mention a six degree of freedom system and by that they mean three linear directions (x,y,z) and then three rotational directions. The <span style={{ fontWeight: 'bold' }}>right hand rule</span> is used throughout the world and is an agreed upon rule which engineers use to decide which direction is positive and which is negative when it comes to rotation. If you point your thumb in the direction of any of the positive linear axes and rotate your hand in the clockwise direction, then that rotational direction is considered positive.
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={ForceDecompImg} alt="Force Decomposition" width="55%"/>
                            </div>
                            </p>
            </div>
    )
}