import React from 'react';
import Subtopics from './Subtopics';
import { Container } from "react-bootstrap";
import {
    Switch,
    Route,
    Link,
    useRouteMatch
  } from "react-router-dom";

function Arduino(props) {
    let { path, url } = useRouteMatch();
    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', maxWidth: "100vw"}}>
                {/* <SectionTitle title={props.topicId}/> */}
            <div style={{display: 'flex', flexDirection: 'row'}}> 
            <div style={{marginTop: '10px', marginRight: '0px', marginLeft: '5px',border: '3px', padding:'40px', paddingRight: '50px', borderStyle:'solid', borderColor:'grey', paddingLeft: '20px', whiteSpace:'nowrap'}}>
                <h2>Arduino C++</h2>
              
                <li>
                <Link to={`${url}/cplusplus`}>Arduino Basics</Link> 
                </li>
                <li>
                <Link to={`${url}/variables`}>Variables</Link> 
                </li>
                <li>
                <Link to={`${url}/setupfunctions`}>Setup Functions</Link> 
                </li>
                <li>
                <Link to={`${url}/basicfunctions`}>Basic Functions</Link> 
                </li>
                <li>
                <Link to={`${url}/loopfunctions`}>Loop Functions</Link> 
                </li>
                {/* <li>
                <Link to={`${url}/examples`}>Examples</Link>    
                </li> */}
                <li>
                <Link to={`${url}/libraries`}>Libraries</Link>  
                </li>
                </div>
                
            <Container>
                <Switch>
                    <Route exact path={path}>
                    <h3>Please select a sub-topic.</h3>
                    </Route>
                    <Route path={`${path}/:topicId`}>
                        <Subtopics />
                    </Route>
                </Switch>
            </Container>
         
                    </div>
            </div>
        </div>
    );
};

export default Arduino; 