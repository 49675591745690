import Conductivity from '../../Images/ConductivitySensors.jpg'

export default function ConductivitySensors(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Conductivity Sensors</h1>
               <p>

                        <h2>Intro to Conductivity Sensors;</h2>
Conductivity sensors measure the ability of a fluid to conduct electricity. This type of sensor is also useful for measuring the purity or 
impurity of a liquid or for measuring the concentration of an acid or a base. If the fluid being measured is water, the concentration of 
ions will determine the conductivity, with increases in ion count leading to higher conductivity. 
<br />
                        <br /> 
 <h2>2-Pole Sensors</h2>                       
Conductivity sensors generally work by aligning two probes within a medium and running an alternating current between them. The intensity of 
the current will depend on the medium’s free anions and cations which will move between the two electrodes. The more anions and cations within
 the medium, the higher the conductivity. The unit of measuring conductivity is in Siemens per meter, Siemens being the repricrical of an ohm.
<br />
                        <br /> 
                        <h2>4-Pole Sensors</h2>
The polarization effect is the case where high concentrations of ions begin to repel each other which then causes a reduction in current. This 
can affect the accuracy of conduction readings which led to the development of the 4-pole conductivity probe. In this case, two extra poles are
 currentless and measure the potential difference between the two poles. A connected transmitter can then use the current and potential 
 difference readings to provide a conductivity value free from polarization effects.
<br />
                        <br /> 
                        <h2>Inductive Sensors;</h2>
Inductive sensors contain a transmission and reception coil which measures conductivity by following a series of instructions. First, the
 transmission coil generates a magnetic field which in turn creates a voltage difference within the medium. This causes the ions within the
  medium to begin to move and generate a current which then induces an alternating current which reaches the reception coil which is read for
   conductivity.
   <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={Conductivity} alt="linkages" width="75%"/>
                                </div>
                        </p>
            </div>
    )
}