import POT from '../../Images/POTImg.jpg'
import Parallel from '../../Images/ParallelImg.jpg'
import MomentReduc from '../../Images/MomentReducImg.jpg'

export default function Rigidbodies(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Rigid Body Forces</h1>
               <p>
    <h2>Intro to Rigid Body Forces;</h2>
                            The term <span style={{ fontWeight: 'bold' }}>rigid body</span> is used in engineering and physics to describe any object which when applied forces, does not deform from their original shape. Rigid bodies are the precondition for many of the following laws of physics. The <span style={{ fontWeight: 'bold' }}>principle of transmissibility</span> states that a single force can be applied anywhere on an object along its <span style={{ fontWeight: 'bold' }}>line of action</span> without changing the effect of the force.
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={POT} alt="principle of transmissibility" width="45%"/>
                            </div>
                            The <span style={{ fontWeight: 'bold' }}>parallel law of forces</span> states that a body subject to two or more forces whose lines intersect at any point can have their forces replaced by an equivalent force resultant force R. Since rigid bodies can have their forces moved along their lines of actions, as long as the lines of forces intersect between multiple forces, you can lump all of them together and reduce it to one resultant force. This is massive when determining how an object will react when subject to a large number of forces. 
                           
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={Parallel} alt="parallel law of forces" width="45%"/>
                            </div>
                         
                            Forces also have the unique property that any force acting on a body can be translated to another location so long as the appropriate rotational force is also added. When combining this rule with the parallel law of forces and the principle of transmissibility it means that all forces acting on a body can be moved to one location and then reduced to a single equivalent force and moment. This combination of rules allows an endless amount of systems to be controlled by adding the appropriate force vectors in the right locations. When dealing with multiple forces, traditionally engineers will determine the resultant using the center of mass (centroid) as the origin. 
                         
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={MomentReduc} alt="moment reduction" width="45%"/>
                            </div>	
                            </p>
            </div>
    )
}