import BeltsImg from '../../Images/BeltsImg.jpg'
import BeltLengthImg from '../../Images/BeltLengthImg.png'
import BeltTorqueImg from '../../Images/BeltTorqueImg.png'
import BeltVelocityImg from '../../Images/BeltVelocityImg.png'
import MultipleBeltsImg from '../../Images/MultipleBeltsImg.png'

export default function Belts(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Belt and Chain Drives</h1>
               <p>
                                
     
                  
                                
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>                    
                            <img src={BeltsImg} alt="belts" width="100%"/>
                        </div>   
                        <div style={{width:'100%'}}>
                        <h2>Intro to Belt Drives;</h2>
                    
             
                        Belt and chain assemblies both use a turning mechanism such as a pulley or sprocket to transfer power to another 
                        rotating piece of machinery. Both of these assemblies are designed as continuous and will endlessly keep turning so 
                        long as the pulley or sprocket continues to operate. 
                        <br />
                        <br /> 
                        The chain drive is made up of chain links that have special shaping to allow it to mesh with the pointed edges of a 
                        sprocket. The sprocket is similar to a gear in its form and function, but has triangular shaped patterns along its 
                        perimeter. These points are what mate within the gaps of the chain and convert power by pulling the chain as it rotates.                      
                        <br />
                        <br />
                        One the hand, the belt drive is continuous and along the inside of the material will have small notched grooves. Very much
                         like a sprocket and chain drive, these grooves mate with the ridges around a pulley and transfer rotational motion by turning and pulling.                        
                         <br />
                        <br />
                        The most important consideration when using either of these assemblies is placing a tensioner in the right location so that the 
                        belt or chain stays tight against the sprocket or pulley. You will have to place a tensioner towards the direction of rotation. 
                        If your system is being designed to rotate in two directions, you will need a tensioner on each side. 
                     </div>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={BeltLengthImg} alt="BeltLength" width="80%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={BeltVelocityImg} alt="BeltVelocity" width="80%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={BeltTorqueImg} alt="BeltTorque" width="80%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={MultipleBeltsImg} alt="MultipleBelts" width="80%"/>
                                </div>
                        </p>
            </div>
    )
}