import ImpulseMomentumImg from '../../Images/ImpulseMomentumImg.jpg'

export default function Impulse(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Impulse Momentum</h1>
               <p>
                                
           
          <h2>The Impulse Momentum Principle</h2>
                        The impulse momentum principle was derived from Newton’s second law f=ma and relates force and rate of change
                         of momentum. It states that the resultant force acting in a direction on the fluid will equal to the rate of change
                          in momentum of the fluid.                 
                          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ImpulseMomentumImg} alt="Impulse Momentum" width="55%"/>
                                </div>
                        </p>
            </div>
    )
}