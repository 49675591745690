import pHSensorImg from '../../Images/pHSensorsImg.jpg'

export default function PHSensors(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>PH Sensors</h1>
               <p>
                                
           <h2>Intro to PH Sensors</h2>
                               
A pH sensor is used to measure the acidity or alkalinity of a fluid with a range between 0-14. Values below 7 are considered acidic while values 
above 7 are referred to as basic. Each unit of change represents a tenfold increase in acidic or alkalinity. The pH value named from “power of 
hydrogen” is also equal to the negative logarithm of the hydrogen ion concentration. 
<br />
                        <br />  
<h2>Combination pH Sensors;</h2>
The most common type of pH sensor is the combinational sensor which is an electro-chemical sensor. This type of sensor utilizes both a reference
 electrode and a measuring electrode. The measuring electrode detects changes in the pH levels which is then compared to the reference line. 
 Since the pH of a fluid is the measure of ions, these electrodes measure the small electrical differences. A high-impedance device known as a
  pH meter is then used to display the millivolt signal as pH units. 
<br />
                        <br />  
<h2>Differential pH Sensors;</h2>
The main difference between a combinational and differential pH sensor is that the differential sensor has three electrodes. In this case,
 two electrodes measure pH differentially from each other and in relation to the third reference electrode which prevents reference fouling. 
 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={pHSensorImg} alt="pH Sensor" width="75%"/>
                                </div>
                        </p>
            </div>
    )
}