import FiberCablesImg from '../../Images/FiberCablesImg.jpg'

export default function Cables(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Cables</h1>
               <p>
                                
      
                        <h2>Intro to Cables;</h2>
                        Computer cables come in an enormous variety and have been steadily improving for a number of decades. Their 
                        identification usually comes from one of an international organization which oversees standard codes. These 
                        organizations include Institute of Electrical and Electronics Engineers (IEEE), USB Implementers Frontier (USB IF),
                         Registered Jack(RJ) and the International Electrotechnical Commision (IEC).
                         <br />
<br /> 
Computer cables are “gendered” for ease of understanding with “male” connectors being the side with pins and the “female” connector being
 the receptacle side.
 <br />
<br /> 
<h2>Fibre-Optic Cables;</h2>
The fiber-optic cable, also known as the optical fiber cable is a component similar to an electrical cable but with the difference that
 internally, transparent flexible fibres made of drawn silica or plastic are used to transmit light. The optical fiber elements are
  typically coated with protective plastic layers and are placed with a protective material capable of offering protection based on
   where the cable is to be used. 
   <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={FiberCablesImg} alt="fiber optic cable" width="50%"/>
                                </div>
<h2>Display Cables;</h2>
Video Graphics Array (VGA)
<br /> 
Digital Visual Interface (DVI)
<br /> 
High Definition Multimedia Interface (HDMI)
<br /> 
Displayport
<br />
<br /> 
<h2>Device Cables;</h2>
Parallel
<br /> 
Serial
<br /> 
Personal Systems/2 (PS/2)
<br /> 
Universal Serial Bus (USB)
<br /> 
Firewire (IEEE 1394)
<br /> 
Thunderbolt
<br /> 
Lightning
<br />
<br /> 
<h2>Audio Cables;</h2>
3.5mm Audio Jack
<br /> 
Optical Fiber Audio Cable
<br />
<br /> 
<h2>Networking Cables;</h2>
Ethernet
<br /> 
Telephone
<br /> 
Fiber Optic Cable
<br />
<br /> 
<h2>Power Cables;</h2>
Power Cords
<br /> 
DC Barrel Jacks

                        </p>
            </div>
    )
}