import OscilloscopesImg from '../../Images/OscilloscopesImg.jpg'

export default function Oscilloscopes(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Oscilloscopes</h1>
               <p>
                                
           
                
                        <h2>Intro to Oscilloscopes;</h2>
                        Oscilloscopes are devices which exclusively measure voltage and have a response rate fast enough to plot real-time 
                        voltage data. Although oscilloscopes can reverse their axis, traditionally the screen displays voltage over time with 
                        voltage plotted on the y-axis and time moving along the x-axis. If the voltage is varying as a wave, the oscilloscope
                         may fit the data to look like a non-moving waveform.
                         <br />
                        <br /> 
                        These machines can also be configured to indirectly measure current, strain, acceleration and pressure. 
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={OscilloscopesImg} alt="Oscilloscopes" width="55%"/>
                                </div>
                                <br />     
                        <span style={{ fontWeight: 'bold' }}>Analog Bandwidth:</span> Generally, bandwidth is the measure of how well an oscilloscope is able to measure a signal. Specifically, bandwidth is the frequency at which the oscilloscope attenuates a sinusoidal input signal to 70.7% of the signal’s true amplitude.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Sample Rate:</span> The rate at which the machine is capable of generating and storing data.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Record Length:</span> Given an amount of data input, record length is the amount of time which the machine will spend recording the incoming data.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Analog Channels:</span> The amount of input signals the machine can process at once.

                        </p>
            </div>
    )
}