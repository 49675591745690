import GreekTableImg from '../../Images/GreekTableImg.jpg'

export default function Greek(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>The Greek Alphabet</h1>
               <p>
                                
 <h2>Intro to the Greek Alphabet;</h2>                  
 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GreekTableImg} alt="linkages" width="65%"/>
                                </div>
                        </p>
            </div>
    )
}