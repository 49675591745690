import TubeMechanicsImg from '../../Images/TubeMechanicsImg.jpg'
import BendGeometryImg from '../../Images/BendGeometryImg.jpg'
import TubeBendingImg from '../../Images/TubeBendingImg.jpg'

export default function TubeBending(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Tube Bending</h1>
               <p>
               <br />
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight:'10px'}}>
                                    <img src={TubeBendingImg} alt="Tube Bending Types" width="100%"/>
                                </div>
<div style={{width:'60%'}}>
<h2>Intro to Tube Fabrication;</h2>
                      
                      Cutting tools are frequently mounted using a conical shaped spindle because the cone shaped geometry provides a rotationally symmetric and self-centering tool. The toolholder, sometimes called the adapter, allows for tool retention and transmits torque and thrust to the cutting tool as it rotates.
                      <br />
                   
                      <span style={{ fontWeight: 'bold' }}>1. Compression Bending</span>
                      <br />
                      <span style={{ fontWeight: 'bold' }}>2. Draw Bending</span>
                      <br />
                      <span style={{ fontWeight: 'bold' }}>3. Roll Bending </span> 
                      <br />
                      <span style={{ fontWeight: 'bold' }}>4. Ram Bending</span>
                     <br />
                     <br /> 
                     The process itself always fixes the tube at two points and then a roller, die or press performs the bend using a combination of tensile and compressive forces. The tubing material, tooling, the amount of external pressure applied, lubrication and the bending geometry will determine the outcome of the bend. Tube bending is just one of the processes for making components out of straight tubes. Other steps in the tubing fabrication process can include cutting, deburring, slotting, notching and welding.            
</div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={BendGeometryImg} alt="Geometry of Bending" width="100%"/>
                                </div>
<div style={{width:'70%'}}>
<h2>Tube Bending Terminologies;</h2>
<span style={{ fontWeight: 'bold' }}>Center Line Radius:</span> CLR refers to the distance between the center of the curve to the central axis of the tube. This term is often referred to as the bend radius.
<br /> 
<span style={{ fontWeight: 'bold' }}>Outside Diameter:</span> The outer distance of the tube.
<br /> 
<span style={{ fontWeight: 'bold' }}>Inside Diameter:</span> The inner distance within the opening of a hollow tube.
<br /> 
<span style={{ fontWeight: 'bold' }}>Wall Thickness:</span> The thickness of the tube walls.
<br /> 
<span style={{ fontWeight: 'bold' }}>Degree of Bend:</span> The angle formed from bending the tube in degrees.</div>
                        </div>
                       <h2>Intro to Compression Bending;</h2> 
The most common tool for compression bending is a manual pipe bending tool which holds one end of a tube in place and forms the shape of the bend by compressing the pipe around a former. These tools are great for copper and aluminum tools since they utilize the strength of the user as the power source. 
<br />
                     <br /> 
<h2>Intro to Draw Bending;</h2>
With larger diameter tubing as well as steel tubing it may be required to turn to machine powered bending machines and the draw bending approach.
<br /> 
<br /> 
<div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={TubeMechanicsImg} alt="Tube Mechanics" width="100%"/>
                                </div>
<div style={{width:'70%'}}>
<h2>Potential Issues of Tube Bending;</h2>
The outer side of a bent tube will experience tension while the inner wall experiences compression. To comply with the bend, the outer wall will often elongate resulting in thinning of the wall. The inner wall may undergo wrinkling and thickening of the wall. The imbalance of forces may distort the tube’s cross section from a rounded shape into an oval (ovality). 

</div>
                        </div>                     
                        </p>
            </div>
    )
}