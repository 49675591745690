import GeometryImg1 from '../../Images/GeometryImg1.PNG'
import GeometryImg2 from '../../Images/GeometryImg2.PNG'

export default function Trigonometry(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Trigonometry</h1>
               <p>
                                
        <h2>Intro to Trigonometry;</h2>                 
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GeometryImg1} alt="right triangles" width="65%"/>
                                </div>  
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GeometryImg2} alt="right triangles" width="35%"/>
                                </div>                            
                        </p>
            </div>
    )
}