import StrutsAndTiesImg from '../../Images/StrutsAndTiesImg.jpg'
import TiesImg from '../../Images/TiesImg.jpg'

export default function Struts(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Struts & Ties</h1>
               <p>
<h2>Intro to Struts and Ties;</h2>
                           
                            
All structures, machines and objects have forces acting upon them and internally those forces can be separated into the various force categories such as tension and compression. Regarding structures, the part(s) which has tensile forces acting upon it is called a <span style={{ fontWeight: 'bold' }}>tie.</span> The part(s) which has compressive forces acting upon it is called a <span style={{ fontWeight: 'bold' }}>strut.</span>
<br />
                            <br /> 
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={TiesImg} alt="Torque" width="100%"/>
                            </div>
                            <div style={{width:'60%'}}>
                            An example which can help visualize these concepts is a beam protruding from a wall which has a cable tie attached to its’ far side which connects to a higher point along the wall. The tie is undergoing forces pulling in opposite directions (tension). 

                            </div>
                            </div>

<div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={StrutsAndTiesImg} alt="Torque" width="100%"/>
                            </div>
                            <div style={{width:'60%'}}>
                            Another example is a triangular shaped roof which is supporting its own weight. The two leaning struts are internally pushing towards each other (compression) while the horizontal beam tasked with keeping the leaning struts stable is being pulled in opposite directions.
 
                            </div>
</div>
 
                           
                            </p>
            </div>
    )
}