import FDMBasicImg from '../../Images/FDMBasicImg.jpg'
import FDMPrintingProcessImg from '../../Images/FDMPrintingProcessImg.png'

export default function Extrusion(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Introduction to Extrusion Printing</h1>
               <p>
                                
           
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={FDMBasicImg} alt="extrusion printing" width="100%"/>
                                </div>
                                <div style={{width:'70%'}}>
                                <h2>Intro to Fused Filament Fabrication (FFF);</h2>
                                Fused Filament Fabrication (FFF) or Fused Deposition Modeling (FDM) is the most common type of 3D printing due to its affordable cost. The 3D printer works by extruding spools of 3D printer material (usually thermoplastics) through a heated nozzle which traces the cross section of the part, layer by layer onto a heated bed. 
<br />
                        <br /> 
<h2>Intro to Continuous Fiber Reinforcement;</h2>
CFR is a complementary process to FFF which utilizes a secondary nozzle to deposit continuous strands of composite fibers inside of the conventional FFF thermoplastic layers. The reinforcing fibers can greatly increase the strength and stiffness of a part.
<br />
                        <br /> 
<h2>Intro to Atomic Diffusion Additive Manufacturing (ADAM);</h2>
Also known as bound powder deposition, the ADAM process is nearly identical to the fused filament fabrication method with the exception that the extruded material is metal. The ADAM process utilizes a filament composed of metal powder embedded within a plastic binder. After printing concludes, the binder is dissolved away and the metal powder is sintered into a fully metallic object.
                   
                                </div>
                      </div>
                      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={FDMPrintingProcessImg} alt="extrusion printing" width="90%"/>
                                </div>
                        
                        </p>
            </div>
    )
}