import React from "react";
import {
//   BrowserRouter as Router,
  useParams,
} from "react-router-dom";
import Microcontrollers from './Microcontrollers';
import Displays from './Displays';
import OP from './OP';
import Oscillators from "./Oscillators";
import Regulators from './Regulators';
import Audio from './Audio';
import LightSources from "./LightSources";
import PLCs from "./PLCs";
import Potentiometers from "./Potentiometers";
import Encoders from "./Encoders";
import Cameras from "./Cameras";
import Network from "./Network";
import PowerSupply from "./PowerSupply";
import Transformers from "./Transformers";
import Motors from "./Motors";

const ChangeComponents = {
    microcontrollers: Microcontrollers,
    displays: Displays,
    op: OP,
    oscillators: Oscillators,
    regulators: Regulators,
    audio: Audio,
    lightsources: LightSources,
    plcs: PLCs,
    potentiometers: Potentiometers,
    encoders: Encoders,
    cameras: Cameras,
    network: Network,
    powersupply: PowerSupply,
    transformers: Transformers,
    motors: Motors,
  
  };

export default function Subtopics(props) {
    // The <Route> that rendered this component has a
    // path of `/topics/:topicId`. The `:topicId` portion
    // of the URL indicates a placeholder that we can
    // get from `useParams()`.
    let { topicId } = useParams();
    
    let MyComponent = ChangeComponents[topicId];
    return (
        <div>
            {/* {topicId} is for testing route names */}
            {/* <h3>{topicId}</h3> */}
            <MyComponent topicId={topicId}/>
      </div>
    );
  }