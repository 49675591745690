import ElectroStaticImg from '../../Images/ElectroStaticImg.jpg'

export default function Safety(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Safety</h1>
               <p>
                                
           <h2>Basic Safety Precautions;</h2>
              
                        In all likelihood, the most potentially dangerous electrical conduit in your local environment is your 
                        holdhousehold’s main power line. If you live in the United States, the AC voltage is set to 120 volts, oscillating 
                        at 60Hz while other countries use 240 volts at 50Hz. In both these cases, if a person accidentally connects themselves 
                        into the circuit, they will quickly discover that the human body is an excellent conductor and electricity will flow 
                        through you and into the ground.                    
                        <br />
                        <br /> 
                        It just so happens that the human muscular system often contracts from violent current which prevents you from letting
                         go of whatever object formed a bridge between you and the circuit. The longer a person gets electrocuted, the more
                          internal tissue damage they take and the greater the chance of cardiac or respiratory arrest. 
                        <br />
                        <br />
                        Thankfully, higher frequency power lines are less likely to cause muscle contractions but have the potential to arc 
                        and cause a jolt even without direct contact. Aside from the voltage, the most important factor to note is the current
                         that flows through the system. Since power is equal to voltage times current, the amount of electrical power that goes
                          through you and for how long will be important variables in determining if you survive the accident.
                          <br />
                          <br />
                          To avoid such pesky things as death and electrocution please we have compiled the following safety guidelines.
                          <br />
                         1. Do not operate on any electronic that have the power on. If the electronic utilizes power from a wall socket, always unplug the electronic first.
                         <br />
                        2. A human’s internal resistance to electricity can change from 1 M-ohm (1 million ohms) to only 100 ohms if the person is wet. Never operate on an electrical system if your body is wet or if you are standing in a liquid.
                        <br />
                        3. Avoid working from a location where a fall could kill you.
                        <br />
                        4. All high voltage instruments (such as 120 or 240 V) should use a 3-wire line cable.
                        <br />
                        5. Prop up circuit boards plastic or wood insulation sticks. If you need to move them, place an insulated material between your hand and the circuit board.
                        <br />
                        6. Make sure all components meet their intended power ratings.
                        <br />
                        7. Wear eye protection. This should be a given.
                        <br />
                          <br />
                          <h2>Electrostatic Discharge;</h2>
                         
                          If you ever walked on carpet while wearing socks and shocked yourself by touching something, then you have experienced
                           electrostatic discharge. Certain actions like walking on carpet, combing your hair, or handling polyethylene (a common 
                           plastic) can all result in electrons accumulating in your body and raising your potential voltage to 250, 500 or even
                            1000 volts. The drier the air (lower the humidity) the more this process is prone to happen.  
                            <br />
                          <br />
                            The amount of electrostatic discharge (ESD) that can result from a charged human connecting to a grounded object is not high
                             in terms of damage to humans but can be destructive to your electrical components. Specifically semiconductors are vulnerable
                              to this type of damage. MOSFETs, JFETs and field effect transistors with their delicate gate systems can be easily destroyed
                               in this manner. 
                               <br />
                          <br />
                          These types of components, or any component marked with an ESD caution notice should be kept in their original
                           packaging or wrapped in some kind of conductive material like aluminum foil or metal sheets. When handling these
                            components, always discharge your potential voltage first by touching a grounded metal surface. Antistatic gloves 
                            are also commonly used as an extra safety procedure.     
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ElectroStaticImg} alt="electrostatic discharge" width="55%"/>
                                </div>                      
                        </p>
            </div>
    )
}