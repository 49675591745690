import T60 from '../../Images/T60.JPG'

export default function Overview(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Overview of Hand Tools</h1>
               <p>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={T60} alt="overview" width="100%"/>
                                </div>
                                <br />
                     
                                <span style={{ fontWeight: 'bold' }}>Power Drill:</span> A power drill is a hand tool used to drill round holes into material. 
                        <br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Handheld Band Saw:</span> The handheld band saw is a continuous band of sharp metal which is stretched between two or more wheels that rotate to allow cutting of a material. Because the cutting teeth on the band are small, this machine is often used for cutting metal and other hard materials.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Hand Saw:</span> A handsaw is a device used to cut wood and other materials.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Hammer:</span> A tool with a long handle and a weight used to drive nails through material upon impact.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Angle Grinder:</span> Also known as a disk grinder, this device rotates discs at high speeds for cutting and polishing.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Screw Driver Set:</span> A tool set used for driving screws.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Mallet:</span> Similar to a hammer, this is used to cause impact upon striking.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Dremel Kit:</span> A rotary tool removing small areas of material and carving.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Allen Key Set:</span> Also known as hex keys, these hexagonal tools are used for turning driving hex screws.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Drill Bit Set:</span> A set of bits for a hand drill and drill press with a variety of diameters.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Hand Tap:</span> A tool used to create threads within a cylindrical opening.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Digital Calipers:</span> A device used to precisely measure the dimensions of an object.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Tape Measure:</span> A device used to rapidly measure the dimensions of an object.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Digital Tiltmeter:</span> A device used to measure the tilt angle of an object’s surface.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>File Set:</span> A tool used to remove material from an object using highly abrasive surfaces.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Handheld Band Saw:</span> A handheld variation of the bandsaw, used for cutting.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Hex T Driver Set:</span> An alternative hex key tool, easier to turn but takes up more space, used for driving hex head screws.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Hot Melt Glue Gun:</span> A device used to melt cylindrical glue sticks to join surfaces together.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Utility Knife:</span> A retractable knife used to open boxes.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Stud Finder:</span> A handheld device used to locate framing studs and other noteworthy objects in wood buildings.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Wrench Set:</span> A handheld tool used to grip and increase torsion when turning bolts or fasteners.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Plier Set:</span> A set of hand tools used for gripping and bending a wide range of materials.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Clamps:</span> A device used to temporarily fix something to a workbench.                 
                
                        </p>
            </div>
    )
}