import LubricantsImg from '../../Images/LubricantsImg.jpg'

export default function Lubricants(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Lubricants</h1>
               <p>
         

<h2>Intro to Lubricants;</h2>
When dealing with moving parts, it is often desirable and even necessary to minimize friction and wear between component surfaces. A lubricant is any substance introduced into the system for the purpose of reducing friction and wear. Lubricants are often liquid but can be solids or even gases as well.
<br />
                        <br /> 
Liquid lubricants that are oil-based are traditionally characterised by their viscosity and often contain additional additives to allow the oil to flow at low temperatures. Greases are another type of liquid lubricant that have been thickened to achieve additional properties. Unlike oils, greases are used when a lubricant is designed to stay in a position such as hard to reach areas or when machine servicing is difficult or expensive.
<br />
                        <br /> 
Penetrating lubricants are traditionally not long-lasting and are instead used for penetrating tiny cracks in a material surface and breaking up rust. These can be used when loosening up nuts and bolts that have years of rust buildup or for removing adhesive, stickers or chewing gum. 
<br />
                        <br /> 
Dry lubricants are a great alternative to oils and grease that attract dust and dirt. These often come in a spray form that has been mixed with water, alcohol or another solvent. When the secondary mixtures evaporate, it leaves behind a thin film of lubricant that helps reduce friction. These can be used when parts need to stay clean or for tiny parts and threaded rods. A disadvantage of these is that liquids or solvents can wash these away from a surface upon contact.
<br />
                        <br /> 
<h2>Families of Lubricants;</h2>
Pour-Point Depressants: these have less variation of viscosity within a wide range of temperatures.
<br />
Viscosity Index Improvers: this category resists foaming from high-speed machinery.
<br />
Defoamants: these resist oxidation at high temperatures.
<br />
Oxidation Inhibitors: these prevent corrosion on metal surfaces.
<br />
Detergents and Dispersants: these reduce friction and wear when traditional lubricants are incapable of doing so to a suitable degree.
<br />
                        <br /> 
<h2>Types of Lubricants;</h2>
Lubrication is also classified by the degree/distance with which they separate two sliding surfaces. Hydrodynamic lubrication provides support entirely from fluid pressure and causes the relative motion of the sliding surfaces to not come into contact. This allows the friction losses to occur entirely within the lubricant film. Mixed film lubrication provides partial contact separation while boundary lubrication continuously smears itself against nearly touching surfaces.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={LubricantsImg} alt="Lubricants" width="75%"/>
                                </div>

                        </p>
            </div>
    )
}