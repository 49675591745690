import StrainCurve from '../../Images/StrainCurve2Img.png';


export default function Strain(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
                     {/* <SectionTitle title={props.topicId}/> */}
                     <h1>Strain</h1>
                     <p>
                            
<h2>Intro to Strain;</h2>
                            <span style={{ fontWeight: 'bold' }}>Strain</span> is the measure of deformation that an object undergoes from forces acting on it, specifically stress. It follows a very simple equation known as the Stress-Strain Equation.
                            <br />
                            <br /> 
                            <span style={{ fontWeight: 'bold' }}>Modulus of Elasticity</span> is the measure of an object's willingness to deform elastically. Elastically, meaning that once the stress is removed, the material returns to its original shape and dimensions. The term stiffness refers to this measurement. The higher a material’s modulus of elasticity, the stiffer the object and thus harder to deform. On the opposite end, the lower an object's modulus of elasticity, the more flexible it is.
                            <br />
                            <br /> 
                            When engineers began to document the strengths of materials, they quickly discovered that internal stress is very difficult to measure. Strain however, which can then give you stress using the stress-strain equation is much easier to measure. Every material now has curves which shows you how a material will react when undergoing a certain amount of stress. 
                            <br />
                            <br /> 
                            These curves are extremely helpful at understanding if your material will undergo a process known as plastic deformation. <span style={{ fontWeight: 'bold' }}>Plastic deformation</span> is when a material deforms permanently as a result of some type of stress. The yield limit of a material is the point on a stress-strain curve where if the stress goes any higher, the material will not return to its original shape after the force has been removed.  
                            <br />
                            <br /> 
                            For these reasons, it should be clear that when designing a system, the need to keep the stresses below the material’s yield limit cannot be overstated. 
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={StrainCurve} alt="Strain" width="75%"/>
                            </div>
                            </p>
            </div>
    )
}