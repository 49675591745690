import React from "react";
import {
//   BrowserRouter as Router,
  useParams,
} from "react-router-dom";
import Systems from './Systems';
import CPU from './CPU';
import GPU from './GPU';
import Drives from "./Drives";
import Motherboards from './Motherboards';
import Power from "./Power";
import Expansion from "./Expansion";

const ChangeComponents = {
    systems: Systems,
    cpu: CPU,
    gpu: GPU,
    drives: Drives,
    motherboards: Motherboards,
    power: Power,
    expansion: Expansion,
  };

export default function Subtopics(props) {
    // The <Route> that rendered this component has a
    // path of `/topics/:topicId`. The `:topicId` portion
    // of the URL indicates a placeholder that we can
    // get from `useParams()`.
    let { topicId } = useParams();
    
    let MyComponent = ChangeComponents[topicId];
    return (
        <div>
            {/* {topicId} is for testing route names */}
            {/* <h3>{topicId}</h3> */}
            <MyComponent topicId={topicId}/>
      </div>
    );
  }