import ElectromagneticSpectrumImg from '../../Images/ElectromagneticSpectrumImg.jpg'

export default function Spectrum(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Electromagnetic Spectrum</h1>
               <p>
                                
           <h2>Intro to the Electromagnetic Spectrum;</h2>
                    
                        The electromagnetic spectrum is the range of all types of electromagnetic radiation. Electromagnetic radiation can be
                         described as a stream of photons, traveling in a wavelike pattern at the speed of light. The different types of
                          radiation are defined by the amount of energy found in the photons. Gamma rays are some of the fastest/ highest
                           energy waves with the shortest wavelength. Radio waves on the other hand have much less energy and far longer
                            wavelengths. Visible light exists within the relative center of this spectrum between the range of 400 to 700 
                            nm in wavelength. 
                        <br />
                        <br /> 
Electromagnetic radiation can be described in terms of energy, wavelength or frequency where frequency is measured in cycles per second (Hertz).
 Wavelength is often measured in meters while energy is measured in electron volts.
 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ElectromagneticSpectrumImg} alt="Electromagnetic Spectrum" width="75%"/>
                                </div>
                        </p>
            </div>
    )
}