import DiodesImg from '../../Images/DiodesImg.jpg'

export default function Diodes(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Diodes</h1>
               <p>
                                
      
                        <h2>Intro to Diodes;</h2>
                        The diode is a two-terminal electrical component which primarily is used to allow current in one direction only. Although real-world applications are imperfect, diodes are designed to have a resistance in one direction as close to zero as possible and to have resistance as close to infinite as possible in the other. 
  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={DiodesImg} alt="diodes" width="55%"/>
                                </div>
                        </p>
            </div>
    )
}