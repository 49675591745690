import JointerImg from '../../Images/JointerImg.jpg'
import T45 from '../../Images/T45.JPG'

export default function LaserCutter(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Laser Cutter</h1>
               <p>
         
             <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={JointerImg} alt="Jointer" width="45%"/>
                                </div>
                                <span style={{ fontWeight: 'bold' }}>Description:</span> A jointer is a woodworking machine used to produce a flat surface onto a board’s side.
                                <br />
                        <br /> 
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={T45} alt="Jointer" width="100%"/>
                                </div>
                                <br />    
                        <span style={{ fontWeight: 'bold' }}>Table Length/Depth:</span> The dimensions of the work surface of the machine.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Cutter Head:</span> The amount of blades which the rotating element contains.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Cuts/Min:</span> The amount of cuts which the rotating blades will make given a unit of time.

                        </p>
            </div>
    )
}