import BluetoothImg from '../../Images/BluetoothImg.jpg'

export default function Expansion(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Expansion Devices</h1>
               <p>
                               
           
                      
                        <h2>Intro to Expansion Devices;</h2>
Most computing systems have additional slots in the motherboards which allows external devices to be connected and added to the functionality. 
These are officially called PCI (peripheral component interconnect) cards. Below are some of the most common types of expansion cards.
<br /> 
Video Card
<br /> 
Sound Card
<br /> 
Network Card
<br /> 
Bluetooth Card
<br /> 
Input/ Output Devices
<br />
                        <br /> 
The sound or video cards can be connected and selected to improve a computer's graphics or video quality. Bluetooth and network cards can also
 be added to provide additional connectivity options to your device. External input and output devices include add-ons such as mich, keyboards,
  speakers, webcams and removable media such as flash drives and SD cards.
  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={BluetoothImg} alt="Bluetooth" width="35%"/>
                                </div>

                        </p>
            </div>
    )
}