import WaterjetCutterImg from '../../Images/WaterjetCutterImg.jpg'
import T35 from '../../Images/T35.JPG'

export default function WaterjetCutter(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Waterjet Cutter</h1>
               <p>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={WaterjetCutterImg} alt="Waterjet Cutter" width="45%"/>
                                </div>
           
                      
                                <span style={{ fontWeight: 'bold' }}>Description:</span> Waterjet cutters work by rapidly discharging water infused with abrasives onto a material surface.
                        <br />
                        <br /> 
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={T35} alt="Waterjet Cutter" width="100%"/>
                                </div>
                                <br /> 
                        <span style={{ fontWeight: 'bold' }}>Feed Rate:</span> The max travel speed of the machine element being used.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Resolution:</span> Resolution is the smallest change that the machine is capable of measuring.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Accuracy:</span> Accuracy is how close the machine element manages to be from the desired location.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Travel Distance:</span> The max distance which the waterjet cutting machine is capable of traveling.

                        </p>
            </div>
    )
}