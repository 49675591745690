import Friction8Img from '../../Images/Friction8Img.jpg';
import Friction7Img from '../../Images/Friction7Img.jpg';
import Friction1Img from '../../Images/Friction1AImg.jpg';
import Friction2Img from '../../Images/Friction2AImg.jpg';
import Friction3Img from '../../Images/Friction3AImg.jpg';
import FrictionTableImg from '../../Images/FrictionTable2Img.jpg';

export default function Friction(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Friction</h1>
            <p>
                        
           
                      
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>                    
                            <img src={Friction8Img} alt="friction" width="100%"/>
                        </div>   
                        <div style={{width:'70%'}}>
                        <h2>Intro to Friction;</h2>
                        Friction is a force which opposes relative motion between surfaces in contact. Frictional force, which acts in the opposite direction of motion changes once a system begins to move. The term <span style={{ fontWeight: 'bold' }}>static friction μₛ</span> describes the condition when two objects are currently not moving and the term <span style={{ fontWeight: 'bold' }}>dynamic or kinetic friction μₖ</span> is used after objects begin to move.
                        <br />    
                        <br />
                        For every pair of contact surfaces, there exists a force limit where if the force exceeds the limit, the two objects will break apart and start moving. Static friction allows humans to move throughout the world. As our feet make contact with the earth, the frictional force between the soles of our shoes and the ground is greater than the force of slip known as the limiting friction.                        
                        </div>
                        </div>
                      <br />
               
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>                    
                            <img src={Friction7Img} alt="friction" width="100%"/>
                        </div>  
                        <div style={{width:'48%'}}>
                        What's conceptually important is that if gravity is the force pushing the two objects against each other, then the greater the mass of an object, the greater the limiting friction will be, since the force of gravity is equal to an object's mass multiplied by the gravitational constant.
                        <br />
                        <br />
                        The <span style={{ fontWeight: 'bold' }}>Coulomb Theories of Friction</span> gives an approximation for each of the three cases of friction.
                            </div> 
                        </div>
                       
                        <br />
                     <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                     <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>                    
                            <img src={Friction1Img} alt="friction" width="100%"/>
                        </div>  
                        <div style={{width:'70%'}}>
                        <span style={{ fontWeight: 'bold' }}>Case 1; Static Friction:</span> The bodies stay at rest because the force F attempting to move the two objects against each other is lower than the limiting friction amount.  uₒ is the coefficient of static friction and N is the magnitude of the normal (reaction) force between the two objects.                       
                        </div>
                     </div>
                        <br />
            <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>                    
                            <img src={Friction2Img} alt="friction" width="100%"/>
                        </div>   
                        <div style={{width:'70%'}}>
                        <span style={{ fontWeight: 'bold' }}>Case 2; Limiting Friction:</span> The objects are at rest but are on the verge of moving. Once the force Fₛ reaches a large enough magnitude, the two bodies will be set in motion. Note that this equation gives you the force limit between two static objects irrespective of their size or the areas between the two objects. The term Fₒ is the limit force of a system.
                        
                        </div>
            </div>
                        <br />
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>                    
                            <img src={Friction3Img} alt="friction" width="100%"/>
                        </div>  
                        <div style={{width:'70%'}}>
                        <span style={{ fontWeight: 'bold' }}>Case 3; Kinetic Friction:</span> Once two objects cross the limiting friction threshold, the kinetic friction force R begins to act as an active force and the static friction coefficient changes to the dynamic friction coefficient which is always smaller.
                       
                            </div>   
                      </div>
                        <br />
                        <br />
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>                    
                            <img src={FrictionTableImg} alt="friction" width="70%"/>
                        </div>  
                       
            </p>
            </div>
    )
}