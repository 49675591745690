import PowerDrillImg from '../../Images/PowerDrillImg2.jpg'
import T61 from '../../Images/T61.JPG'

export default function PowerDrill(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Power Drill</h1>
               <p>
  
     <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={PowerDrillImg} alt="Power Drill" width="25%"/>
              
                                </div>
                                <span style={{ fontWeight: 'bold' }}>Description:</span> A power drill is a hand tool used to drill round holes into material. 
                                    <br />
                        <br /> 
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={T61} alt="Power Drill" width="90%"/>
              
                                </div>
                                <br />       
                        <span style={{ fontWeight: 'bold' }}>Max Torque:</span> The max torque produced by the drill is the highest amount of rotational force which the machine is capable of generating. If fasteners require more torque to turn than the machine can generate, then the machine will stall and often lock up.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Chuck Max Diameter:</span> The drill chuck can be outfitted with a variety of drill bits which allows different diameter holes to be drilled. The max diameter is the largest bit size which the chuck is capable of fitting inside its opening.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Speed:</span> The range at which the spindle is capable of rotating. Different materials require different drilling speeds so it is important to set your machine at the correct units.
                  
                        </p>
            </div>
    )
}