import MetalCastingImg from '../../Images/MetalCastingImg.jpg'
import TypesMetalCastingImg from '../../Images/TypesMetalCastingImg.png'

export default function Casting(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Metal Casting</h1>
               <p>
                               
           
                        
                        <h2>Intro to Metal Casting;</h2>
Casting is the process of flowing molten metal into a mold where it solidifies into shape as it cools within the mold cavity. The process can be divided by the type of mold it uses which can be expendable molds made from sand or other materials of permanent mold casting. Some of the advantages of metal casting is the ability to create complex part geometries with both external and internal shapes. Some of the disadvantages include poor dimensional accuracy and surface finishes along with limitations of part mechanical properties.
<br />
                        <br /> 
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={TypesMetalCastingImg} alt="Metal Casting Defects" width="65%"/>
                                </div>  
<h2>Defects in Casting;</h2>
Misrun: the casting has solidified before completely filling the mold cavity.
<br />
Cold Shut: two or more boundaries have failed to fuse together despite touching.
<br />
Shrinkage: the part solidified and shrunk leaving a gap where there should be material.
<br />
Sand Blow: released gases from pouring have formed balloon-shaped cavities within the material.
<br />
Pin Holes: multiple small gas cavities formed at the boundaries of the material.
<br />
Penetration: highly fluid metal penetrated the molding walls and casted into a wrong shape.
<br />
Mold Shift: part alignment between sections has caused a shift and failed to align.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={MetalCastingImg} alt="Metal Casting Defects" width="55%"/>
                                </div>                 
                
                        </p>
            </div>
    )
}