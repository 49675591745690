import LCDImg from '../../Images/LCDImg.jpg'

export default function Displays(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Displays</h1>
               <p>
                                
           
                        
                        <h2>Liquid Crystal Displays;</h2>
All liquid crystal displays utilize a white backlight that when shone through layers of color and polarizing filters can change the coloration 
of individual pixels. Polarization is the direction in which the light wave is oscillating. The polarization filter ensures that all the light 
is oscillating in the same way.
<br />
                        <br /> 
Most cell phone screens consist of the liquid crystal display which generates colors and brightness, a set of wires where you touch the screen 
and a protective glass cover. Similar to LCD screens in monitors and televisions, phone LCDs use an electric current to adjust the color of each
 pixel. 
 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={LCDImg} alt="lcd layers" width="55%"/>
                                </div>
<h2>OLED;</h2>
The organic light emitting diode (OLED) panels are different from leds as they do not employ any polarization. Instead, each pixel lights up
 according to the voltage being applied to it by the giant light emitting diode. The brightness is dependent on the voltage applied while the
  color emitted is dependent on the molecule in question. 

                        </p>
            </div>
    )
}