import PLCImg from '../../Images/PLCImg.jpg'

export default function PLCs(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>PLCs</h1>
               <p>
                                
           
                     
                        <h2>Intro to Programmable Logic Controllers;</h2>
A programmable logic controller is an industrial solid-state computer that can monitor inputs and outputs and make logic based decisions 
from the readings. PLCs are robust and capable of withstanding dust, extreme heat & cold, vibrations and even extreme moisture. PLCs are also
 modular and can usually be connected to digital and analog inputs and outputs. Due to their simple array of programming languages, they are 
 the ideal choice when selecting robust machine controllers. Some of their common disadvantages include inability to handle complex data and 
 difficulty displaying data. 
 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={PLCImg} alt="plc system" width="40%"/>
                                </div>
<h2>Intro to PLC Hardware;</h2>
A 16 or 32 bit microprocessor CPU serves as the brain of the PLC. The CPUs include a memory chip and integrated circuits for controlling 
logic, monitoring and communicating. The CPUs’ responsibilities also include executing control operations, communicating with other devices,
 performing arithmetic and internal diagnostics. 
 <br />
                        <br /> 
The CPU’s Read-Only Memory (ROM) is used to store data permanently while the Random Access Memory (RAM) is used to store status information 
for the system’s input and output devices. The CPU’s operating cycle includes the following steps: start scan, internal checks, scan inputs,
 execute program logic, and update outputs. PLCs require external programming devices such as a computer or console to upload data onto the CPU. 
 <br />
                        <br /> 
Sinking and sourcing are two important terms used when discussing PLCs. Sinking refers to the common ground line (-) and sourcing refers to
 the voltage common collector (VCC) line (+). These inputs and outputs only refer to DC circuit connections with the sinking and sourcing 
 lines only capable of conducting electricity in one direction. 
 <br />
                        <br /> 
<h2>Intro to PLC Languages;</h2>
Programmable Logic Controllers almost always use one of five programming languages with Ladder Logic being the most common. In ladder logic,
 symbols represent functions such as mathematical operators, opening and closing relays, counters, timers and shift registry. Rules in ladder
  logic are known as “rung” with each rung having a single output. Rung inputs can be used for multiple rungs. Ladder logic can be organized 
  both vertically and horizontally.
  <br />
                        <br /> 
Another programming language used in PLCs are Function Block Diagrams (FBDs) which describe functions between input and output variable blocks.
 These are helpful for representing algorithms and logic from interconnected control systems. 
 <br />
                        <br /> 
Structured Text (ST) is a high-level language used by some PLCs that uses sentence commands as its structure. Programmers can use if/then/else 
statements or repeat/until commands to execute operations. 
<br />
                        <br /> 
Instruction Lists (IL) is a much lower level language which organizes functions and variables into a simple defined list. Program control is 
done by jump instruction where the program memory is fetched and executed as it runs. 
<br />
                        <br /> 
When dealing with complex control systems Sequential Functions Charts (SFC) may be the preferred language. This language uses basic building 
blocks that can run their own subroutines with program files written in other languages. This approach is useful for dividing large and 
complex systems into smaller, more manageable modules.  
<br />
                        <br /> 
<h2>Intro to PLC Communication;</h2>
PLCs use built-in ports such as ethernet, USB, RS-232, RS-485 or RS-422to communicate to external devices and systems. Larger I/O operations may
 have peer-to-peer (P2P) communication between processors though many protocols are vendor specific. RS-232 is the most common mode of
  communication with PLCs and uses binary code to transmit data in ASCII format. One of the advantages of RS-232 is that it is virtually
   immune to electrostatic interference.

                        </p>
            </div>
    )
}