import CeramicsImg from '../../Images/CeramicsImg.jpg'

export default function Ceramics(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Ceramics</h1>
               <p>

                  <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={CeramicsImg} alt="Ceramics" width="100%"/>
                                </div> 
                                <div style={{width:'70%'}}>
                                <h2>Intro to Ceramics</h2>
           Ceramics are inorganic compounds formed from a metal (or semimetal) and one or more nonmetals. These materials are known for their high hardness, high electrical and thermal insulation, chemical stability and high melting temperature. They are also generally very brittle with little to no flexibility/ductility. The low tensile strength is often mitigated when dealing with compressive stress.
           <br />
                        <br /> 
Some ceramics like glass can be translucent.

                                </div>
                    </div>              
           
<h2>Classifications</h2>                  
Traditional Ceramics: clay products, pottery, bricks, common abrasives, cement.
<br /> 
New Ceramics: More recent compounds made from using carbides or oxides with much stronger physical and mechanical properties.
<br /> 
Glass: Based on silica and known for their non-crystalline structure.
<br />
<br />   
<h2>Physical Properties</h2> 
Density: Ceramics are heavier than polymers and lighter than metals.
<br />   
Melting Point: Usually higher than metals with some ceramics decomposing instead of melting.
<br />   
Thermal and Electrical Conductivity: Wide range of characteristics, some are used as conductors and others as insulators.
<br />   
Thermal Expansion: Less than metals but with more severe effects due to brittleness.

                        </p>
            </div>
    )
}