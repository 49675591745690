import React from "react";
import {
//   BrowserRouter as Router,
  useParams,
} from "react-router-dom";



import BasicFunctions from "./BasicFunctions";
import Examples from "./Examples";
import Libraries from "./Libraries";
import LoopFunctions from "./LoopFunctions";
import Variables from "./Variables";
import SetupFunctions from "./SetupFunctions";

const ChangeComponents = {
  
    basicfunctions: BasicFunctions,
    examples: Examples,
    libraries: Libraries,
    loopfunctions: LoopFunctions,
    variables: Variables,
    setupfunctions: SetupFunctions,
 

  };

export default function Subtopics(props) {
    // The <Route> that rendered this component has a
    // path of `/topics/:topicId`. The `:topicId` portion
    // of the URL indicates a placeholder that we can
    // get from `useParams()`.
    let { topicId } = useParams();
    
    let MyComponent = ChangeComponents[topicId];
    return (
        <div>
            {/* {topicId} is for testing route names */}
            {/* <h3>{topicId}</h3> */}
            <MyComponent topicId={topicId}/>
      </div>
    );
  }