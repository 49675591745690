import MagnetometerImg from '../../Images/MagnetometerImg.jpg'

export default function Magnetometer(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>UV Sensors</h1>
               <p>


<h2>Intro to Magnetometers;</h2>
Magnetometers are devices which measure magnetic flux density (units of Tesla or As/ m2). Since magnetic flux density in air is directly
 proportional to magnetic field strength, a magnetometer is capable of detecting fluctuations in the Earth's magnetic field. As magnetic
  objects create disturbances in the fields surrounding them, magnetometers are also capable of detecting magnetic objects. 
<br /> 
<br /> 
The magnetometer measures the magnetic field flux in accordance with the location in space of the sensor. Magnetic field properties show that
 the magnetic field drops in intensity with the cube of the distance from the object. This means that the maximum distance a magnetometer can 
 detect an object is directly proportional to the cube root of the magnetometer's sensitivity.

  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={MagnetometerImg} alt="uv sensor" width="70%"/>
                                </div>
                        </p>
            </div>
    )
}