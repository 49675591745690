import GearTrainsImg from '../../Images/GearTrainsImg.jpg'


export default function Geartrains(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Gear Trains</h1>
               <p>
 <h2>Intro to Gear Trains;</h2>
                        Gear trains are a system of gears engaged together to transform and move energy from one place to another. 
                        The ratio between the pitch circles of mating gears will determine the trade between rotational speed and 
                        torsion-based, mechanical advantage. If you connect two gears, one with twice the number of teeth then the 
                        larger gear will spin at half the speed, but have twice the torsional force.                      
                        <br />
                        <br /> 
                        Although the amount of teeth around two mating gears can vary, the teeth themselves between two mating gears
                         must be identical. There are some gear assemblies which use other mechanisms instead of teeth but we will focus
                          on the traditional spur gear for the moment. 
                        <br />
                        <br /> 
                        <h1>Two Gear Relationships</h1>
                        <br />
                        <br /> 
                        The gear ratio is the relationship between the number of teeth of two interlocking gears and will determine the 
                        amount that the driven gear is turned in relation to one full revolution of the driver gear. In most cases, 
                        the gear attached to the power source which is known as the driver gear is the smaller of the two because the modern
                         motor can afford to trade rotational speed for greater torsion.
                        <br />
                        <br />
                        The following equations incorporate a negative symbol to convey the direction of rotation between these gears since 
                        each gear turns in the opposite direction of its counterpart. In some cases, the an idler gear can be added to an
                         assembly to provide the opposite turning direction of the final resultant shaft. Note that the following equation 
                         uses N for the number of teeth on a gear, d for diameter, n for rotating speed, and w for angular velocity.  
                        <br />
                        <br />
                        <h1>Compound Gears</h1>
                        <br />
                        <br />
                        In a compound gear setup, at least one shaft will carry more than one gear. Gears that share a shaft 
                        will turn at the same rotational speed and have the same torsion. Compound gears can be used to create multiple
                         stages of gear reductions and thus further and further increases in speed or torsion. 
                         <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GearTrainsImg} alt="gear train" width="55%"/>
                                </div>  
                        </p>
            </div>
    )
}