// import GT from '../../Images/GT.jpg'

export default function Overview(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Arduino C++ Loop Functions</h1>
               <p>
                                {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GT} alt="linkages" width="75%"/>
                                </div> */}
           
           <br /> 
           
           <span style={{ fontWeight: 'bold' }}>If Statement:</span>
           <br />   
The if statement compares if two things are true or false.
<br /> 
<code>{`if (variableName == LOW) {`}</code>
<br /> 
<code>{`//insert function here`}</code>
<br /> 
<code>{`}`}</code>
<br /> 
<code>{`if(varName < #){`}</code>
<br /> 
<code>{`//insert function here`}</code>
<br /> 
<code>{`}`}</code>
<br /> 
<code>{`if(varName < # && varName > #){`}</code>
<br /> 
<code>{`//insert function here`}</code>
<br /> 
<code>{`}`}</code>
<br /> 
<br /> 
<span style={{ fontWeight: 'bold' }}>Else Statement:</span>
<br /> 
The else statement allows you to tell the program what to do if the if condition is not met.
<br /> 
<code>{`else { `}</code>
<br /> 
<code>{`//insert function here`}</code>
<br /> 
<code>{`}`}</code>
<br /> 
<br /> 
<span style={{ fontWeight: 'bold' }}>Else If Statement:</span>
<br /> 
The else if statement allows for multiple lines of different possibilities.
<br /> 
<code>{`else if(varName >= varName2 && varName <varName3){`}</code>
    <br /> 
<code>{`//insert function`}</code>
<br /> 
<code>{`}`}</code>
<br /> 
<br /> 
<span style={{ fontWeight: 'bold' }}>For Loop:</span>
<br /> 
The for loop allows you to quickly iterate through a large number of things, in our example we are telling the loop to run sequentially from our starting number to the middle number and to do something for all of them.
<br /> 
<code>{`for(int varName = #; varName<#: varName++){`}</code>
    <br /> 
<code>{`//insert function`}</code>
<br /> 
<code>{`}`}</code>
<br />
<br />
<span style={{ fontWeight: 'bold' }}>While Loops:</span>
<br />
While Loops run until a certain condition is met. Millis function for example.
<br />
<code>{`while (millis() <5000){`}</code>
    <br />
<code>{`//insert function`}</code>
<br /> 
<code>{`}`}</code>
<br /> 
<br /> 
<span style={{ fontWeight: 'bold' }}>Switch Case Function:</span>
<br /> 
A switch function executes a different command depending on the value you give it, the different commands are called cases. The switch() function checks the value of the inside parenthesis variable and acts accordingly. The break command tells the code where the end of the command is.
<br /> 
<code>{`switch(varName){`}</code>
    <br /> 
<code>{`case 0:`}</code>
<br /> 
<code>{`lcd.print(“text”);`}</code>
<br /> 
<code>{`break;`}</code>
<br /> 
<code>{`case 1:`}</code>
<br /> 
<code>{`lcd.print(“text”);`}</code>
<br /> 
<code>{`break;`}</code>
<br /> 
<code>{`}`}</code>

                        </p>
            </div>
    )
}