import PowersTableImg from '../../Images/PowersTableImg.PNG'

export default function Powers(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Powers of 10</h1>
               <p>
                                
           
            <h2>Intro to Powers of 10;</h2>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={PowersTableImg} alt="Powers Table" width="55%"/>
                                </div>
                        </p>
            </div>
    )
}