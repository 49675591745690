import ThreadingImg from '../../Images/ThreadingImg.jpg'
import TappingImg from '../../Images/TappingImg.jpg'
import GroovingImg from '../../Images/GroovingImg.jpg'
import KnurlingImg from '../../Images/KnurlingImg.jpg'
import PartingImg from '../../Images/PartingImg.jpg'
import ReamingImg from '../../Images/ReamingImg.jpg'
import StepTurningImg from '../../Images/StepTurningImg.jpg'
import TaperTurningImg from '../../Images/TaperTurningImg.jpg'
import FormingImg from '../../Images/FormingImg.jpg'
import FacingImg from '../../Images/FacingImg.jpg'
import DrillingImg from '../../Images/DrillingImg.jpg'
import ContourTurningImg from '../../Images/ContourTurningImg.jpg'
import ChamferTurningImg from '../../Images/ChamferTurningImg.jpg'
import BoringImg from '../../Images/BoringImg.jpg'

export default function Turning(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Turning Operations</h1>
               <p>
                                
               <h2>Turning Operations</h2>       
<div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={StepTurningImg} alt="StepTurningImg" width="100%"/>
                                </div>
                                <div style={{width:'60%'}}>
                                <span style={{ fontWeight: 'bold' }}>Step Turning:</span> The most common cutting operation, step turning creates a distinct difference in the diameter of a workpiece.

                                </div>
</div>

                                <br />
 <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={TaperTurningImg} alt="TaperTurningImg" width="100%"/>
                                </div>   
                                <div style={{width:'60%'}}>
                                <span style={{ fontWeight: 'bold' }}>Taper Turning:</span> Using angled motion between the workpiece and the cutting tool, a turning taper can be used to create a slope, either straight or curved between the two surfaces of a workpiece.

                                </div>
    </div>                     

                                <br />
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ChamferTurningImg} alt="ChamferTurningImg" width="100%"/>
                                </div>  
                                <div style={{width:'60%'}}>
                                <span style={{ fontWeight: 'bold' }}>Chamfer Turning:</span> This cutting operation adds a chamfer to the workpiece at the specific slope at which the cutting tool is angled.
 
                                </div>
                                </div>
  
                                <br />
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ContourTurningImg} alt="ContourTurningImg" width="45%"/>
                                    <div style={{width:'60%'}}>
                                    <span style={{ fontWeight: 'bold' }}>Contour Turning:</span> By moving the cutting tool in a curved path, intricate rounded shapes can be created in a material. Multiple passes are required when using a standard cutting tool though form tools exist which can produce the same contour shape repeatedly.
  
                                    </div>
                                </div>   
                                </div>

                                <br /> 
<div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={FormingImg} alt="FormingImg" width="100%"/>
                                </div>   
                                <div style={{width:'60%'}}>
                                <span style={{ fontWeight: 'bold' }}>Forming:</span> Forming creates a rounded groove within a workpiece by the use of a circular cutting tool. 
  
                                </div>
</div>

                                <br /> 
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={FacingImg} alt="FacingImg" width="100%"/>
                                </div>  
                                <div style={{width:'60%'}}>
                                <span style={{ fontWeight: 'bold' }}>Facing:</span> To ensure a workpiece is at the exact required length it is common to use a piece that is slightly longer than desired and shave away the excess incrementally until the part is at the correct length and the surface smooth and perpendicular to the central axis. 
   
                                </div>
                                </div>

                                <br />  
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GroovingImg} alt="GroovingImg" width="100%"/>
                                </div>  
                                <div style={{width:'60%'}}>
                                <span style={{ fontWeight: 'bold' }}>Grooving:</span> Grooving is a cutting operation that occurs on the “body” of a workpiece and creates a narrow groove based on the width of the cutting tool.
   
                                </div>
                                </div>

                                <br />  
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={PartingImg} alt="PartingImg" width="100%"/>
                                </div>     
<div style={{width:'60%'}}>
<span style={{ fontWeight: 'bold' }}>Parting:</span> Parting is an operation which cuts off a part of the workpiece at the end of an operation. This requires a specific cutting tool which vertically moves inward until it reaches the center of a workpiece and separates it from the whole.

</div>
                                </div>

                                <br /> 
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ThreadingImg} alt="ThreadingImg" width="100%"/>
                                </div>   
                                <div style={{width:'60%'}}>
                                <span style={{ fontWeight: 'bold' }}>Threading:</span> Threading is a cutting operation used to create threads on the surface of a workpiece. The depth of the threads will be determined from the amount of passes with the cutting tool while the pitch of the thread will be determined from the speed of the pass.
 
                                </div>
                                    </div>   
 
                                <br /> 
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={KnurlingImg} alt="KnurlingImg" width="100%"/>
                                </div>     
                                <div style={{width:'60%'}}>
                                <span style={{ fontWeight: 'bold' }}>Knurling:</span>Knurling uses a rolling tool with a special serrated pattern to create small diagonal grooves in the surface of a workpiece which increases gripping friction and visual appeal. 

                                </div>
                                </div>

                                <br />  
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={DrillingImg} alt="DrillingImg" width="100%"/>
                                </div> 
                                <div style={{width:'60%'}}>
                                <span style={{ fontWeight: 'bold' }}>Drilling:</span> Drilling into a piece can be performed by securing a drill bit into the tailstock of a lathe and slowly rotating a workpiece into it.
    
                                    </div>    
                                    </div>   

                                <br /> 
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ReamingImg} alt="ReamingImg" width="100%"/>
                                </div>
                                <div style={{width:'60%'}}>
                                <span style={{ fontWeight: 'bold' }}>Reaming:</span> Reaming is very similar to drilling with the exception that it enters a pre-drilled hole and expands it to the diameter of the reaming tool. It is often used after drilling to achieve a smoother finish on the inside of the hole.
     
                                </div>
                                    </div> 

                                <br />  
<div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={BoringImg} alt="BoringImg" width="100%"/>
                                </div>  
                                <div style={{width:'60%'}}>
                                <span style={{ fontWeight: 'bold' }}>Boring:</span> Unlike drilling and reaming, boring is an internal material removal process where a cutting tool is inserted into an existing hole and moved outward to enlarge the hole.
   
                                </div>
</div>

                                <br /> 
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={TappingImg} alt="TappingImg" width="100%"/>
                                </div>  
                                <div style={{width:'60%'}}>
                                <span style={{ fontWeight: 'bold' }}>Tapping:</span> Tapping is used to create internal threads within a pre-drilled hole in the workpiece. This is accomplished by inserting the tap and turning either the workpiece or tool to cut grooves within the material.
  
                                </div>
                                </div>
 
                                <br /> 
                        </p>
            </div>
    )
}