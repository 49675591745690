import GrommetsImg from '../../Images/GrommetsImg.jpg'

export default function Grommets(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Grommets</h1>
               <p>
                                
      
                        <h2>Intro to Grommets;</h2>
                        A grommet is a flexible ring which is inserted into a thin flat plane to help facilitate wires passing through 
                        the material. They come in a variety of shapes and sizes, some of which are resistant to liquids and dust. These
                         are especially helpful for the protection of wires from being cut from the edges of sheet metal. Grommets are
                          generally collared on each side to keep them from moving. Smaller grommets are sometimes called eyelets, as is 
                          the case with footwear. In electrical applications, they may also be called insulating bushings.
  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GrommetsImg} alt="grommets" width="55%"/>
                                </div>
                        </p>
            </div>
    )
}