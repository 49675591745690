import FinsImg from '../../Images/FinsImg.jpg'
import FinOverviewImg from '../../Images/FinOverviewImg.png'

export default function Fins(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Extended Surfaces</h1>
               <p>
                                
  <h2>Intro to Extended Surfaces;</h2>                              
  With many designs, a goal is to transfer excess heat rapidly away from components. An extended surface (often called fins) is a design method of extending components with high surface area features to help in transferring heat to a fluid acting by convection on the surface of the solid.
  <br />
                        <br /> 
There are multiple ways of increasing the energy transfer out of the component without changing the material type. By increasing the velocity of the convection, you will attain a higher convective coefficient value and thus more energy will be transferred per second. Decreasing the temperature of the moving fluid will also increase the rate of heat transfer. Maximizing the surface area of the solid component and/or changing the shape of the extended surface one will also result in a greater transfer amount.
           
 
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={FinOverviewImg} alt="Fins" width="100%"/>
                                </div>
                                <div style={{width:'60%'}}>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={FinsImg} alt="RadiationImg" width="100%"/>
                                </div>
           </div>
           </div>

           

                        </p>
            </div>
            
    )
}