import TaperImg from '../../Images/TapersImg.jpg'

export default function MachineTaper(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Machine Tool Tapers</h1>
               <p>
                              
           
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={TaperImg} alt="Taper" width="100%"/>
                                </div>
<div style={{width:'70%'}}>
<h2>Intro to Machine Tool Tapers;</h2>
                      
                      Cutting tools are frequently mounted using a conical shaped spindle because the cone shaped geometry provides a rotationally symmetric and self-centering tool. The toolholder, sometimes called the adapter, allows for tool retention and transmits torque and thrust to the cutting tool as it rotates.
                      <br />
                      <br />
                      Cone-type tool holders have two basic designs: self-locking and free-releasing. For the self-locking design, the angle of the cone is small (the cone seems pointed). A cutting tool can be directly inserted into these holders and the friction within is enough to transmit torque as the spindle rotates. 
                      <br />
                      <br />
                      A special tool is often required to remove the holder from the spindle. The Morse taper is one of the most common self-locking tapers and has an angle of ⅝’’ per axial foot. One of its’ key features is a groove at its end which allows the holder to be removed using a thin wedge-shaped tool called a drift which can be inserted into a hole in the side of the spindle.
                      <br />
                      <br />
                      With the rise of cnc machines and automated tool changes, self-locking tools became less desirable which led to tool holders that do not self-lock. The 7/24 tapers have roughly 3.5’’ per axial foot of taper and are among the most common. 
                      
</div>
                        </div>
                    
                        </p>
            </div>
    )
}