import ArticulatedArmImg from '../../Images/ArticulatedArmImg.png';
import CylindricalArmImg from '../../Images/CylindricalArmImg.png';
import ParallelRobotArmImg from '../../Images/ParallelRobotArmImg.png';
import RectangularArmImg from '../../Images/RectangularArmImg.png';
import SCARAArmImg from '../../Images/SCARAArmImg.png';
import SphericalArmImg from '../../Images/SphericalArmImg.png';

export default function RobotArms(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Robot Arms</h1>
            <p>
               <h2> Design of a Robot Arm; </h2>
The application of a robot arm will dictate which of the general types of robot arms will best accomplish your goal. The following is an overview of important robot arm design criteria.
<br />
                        <br />
                        <span style={{ fontWeight: 'bold' }}>1. Degrees of Freedom (DOF):</span> Each axis of freedom increases the capability of a robot arm although it will also increase complexity, cost and weight.
<br />
<span style={{ fontWeight: 'bold' }}>2. End Effector:</span> The tool at the end of the robot arm will determine what the arm is capable of interacting with. Modern robot arms often have interchangeable grippers to allow interaction with a wide range of objects. 
<br />
<span style={{ fontWeight: 'bold' }}>3. Materials and Construction:</span> Materials should be selected for strength, weight, durability and cost.
<br />
<span style={{ fontWeight: 'bold' }}>4. Actuators:</span> Electric motors, hydraulics, pneumatics or spring force and be used to power each joint. Each has advantages in terms of precision, speed, power consumption and cost.
<br />
<span style={{ fontWeight: 'bold' }}>5. Control System:</span> To ensure accuracy and repeatability, robot arms should be equipped with control mechanisms, sensors, feedback systems and software.
<br />
<span style={{ fontWeight: 'bold' }}>6. Safety Features:</span> Robot arms can behave in unpredictable ways from hardware, electrical or software issues. A good design incorporates both active and passive safety features.
<br />
<span style={{ fontWeight: 'bold' }}>7. Power Supply:</span> Ensure your power supply can support all your electronics running simultaneously at peak consumption and add an appropriate safety factor. Ensure your cables and wiring are suitable for these peak spikes in power demand.
<br />
<span style={{ fontWeight: 'bold' }}>8. Programming and Interface:</span> Modern robot arms strive to have the most user friendly interface that allows for the minimum amount of operator learning.
<br />
<span style={{ fontWeight: 'bold' }}>9. Cost Analysis:</span> Consider the design, construction, maintenance and operation of the arm.
<br />
<span style={{ fontWeight: 'bold' }}>10. Testing and Iteration:</span> If possible, iterate the design of your robot arm both physically and virtually as much as possible and optimize based on findings.
<br />
<br />
     <h2>Types of End Effectors;</h2>
     
     <span style={{ fontWeight: 'bold' }}>Grippers:</span> Grippers are mechanical assemblies used to grasp and manipulate objects. The most common types of grippers are parallel, three finger and suction cup designs.
<br />
<span style={{ fontWeight: 'bold' }}>Welding Tools:</span> These include welding torches and mechanisms used to fuse/join metal.
<br />
<span style={{ fontWeight: 'bold' }}>Cutting Tools:</span> Cutting tools can include plasma or waterjet cutters.
<br />
<span style={{ fontWeight: 'bold' }}>Sanders & Polishers:</span> Tools used for refining the surface of materials.
<br />
<span style={{ fontWeight: 'bold' }}>Sprayers & Dispensers:</span> This category includes painting, adhesives and sealants.
<br />
<span style={{ fontWeight: 'bold' }}>Screwdrivers & Fastening Tools:</span> Tools for driving bolts, screws or other fasteners.
<br />
<span style={{ fontWeight: 'bold' }}>Sensors & Cameras:</span> Detection devices for feedback, inspecting parts or data gathering.
<br />
<span style={{ fontWeight: 'bold' }}>Laser Tools:</span> Laser engraving, cutting or other laser precision tasks.
<br />
<span style={{ fontWeight: 'bold' }}>Vacuum Cups:</span> Tools used for lifting items with a smooth surface.
           
                      
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>                    
                            <img src={RectangularArmImg} alt="Rectangular Arm" width="100%"/>
                        </div>   
                        <div style={{width:'70%'}}>
                        <h2>Types of Robot Arms;</h2>
                        <span style={{ fontWeight: 'bold' }}>Rectangular Arms (3P):</span>
                        <br />
Rectangular robot arms can only move in a linear direction and can be best demonstrated by a gantry crane setup. Typically an overhead crane capable of vertical movement will be mounted on a slide which is itself mounted on two more linear slides. This type of setup has a large space requirement but is one of the best solutions to lifting heavy objects.
 <br />    
                        <br />
                     </div>
                        </div>
                      <br />
               
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>                    
                            <img src={CylindricalArmImg} alt="CylindricalArm" width="100%"/>
                        </div>  
                        <div style={{width:'48%'}}>
                        <span style={{ fontWeight: 'bold' }}>Cylindrical Arms (R2P):</span>
                        <br />
A cylindrical robot arm is often configured to have two axes of linear motion and one axis of rotational motion. The vertical linear motion is called stroke, the horizontal linear motion is called reach and the rotational motion is known as swing. This type of setup creates a cylindrical work envelope. These robots are often seen in industrial applications that require repeatability and robustness.
                            </div> 
                        </div>
                        <br />
               
               <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
               <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>                    
                   <img src={SphericalArmImg} alt="Spherical Arm" width="100%"/>
               </div>  
               <div style={{width:'48%'}}>
               <span style={{ fontWeight: 'bold' }}>Spherical Arms (2RP):</span>
<br />
This type of robot arm consists of two axes of rotation and one linear axis. These robot arms are well suited for material handling and manufacturing tasks such as welding. Once the arm is fully extended, it attains a work envelope that is spherical on the outer edge. The inner working area will be determined by the limitations of the design.

                
                   </div> 
               </div>
               <br />
               
               <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
               <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>                    
                   <img src={ArticulatedArmImg} alt="Articulated Arm" width="100%"/>
               </div>  
               <div style={{width:'48%'}}>
               <span style={{ fontWeight: 'bold' }}>Articulated/ Jointed Spherical Arms (3R):</span>
<br />
The jointed spherical arm most closely resembles the human arm and is often called an articulated arm. These consist of a minimum of three axes of rotation and will have a working area that is almost entirely spherical. These arms have a minimal floor space requirement and a deep reach.
                
                   </div> 
               </div>
               <br />
               
               <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
               <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>                    
                   <img src={SCARAArmImg} alt="SCARA Arm" width="100%"/>
               </div>  
               <div style={{width:'48%'}}>
               <span style={{ fontWeight: 'bold' }}>SCARA Robots (2RP):</span>
<br />
SCARA robot arms (Selective Compliance Assembly Robot Arm) are commonly used for pick and place assembly operations. Similar to the spherical arm design, SCARA arms predominantly consist of two axes of rotation with a final linear axis. The main difference is the planes of rotation of the rotary axes are parallel to each other. This design difference leads to a cylindrical envelope instead of a spherical envelope.             
                   </div> 
               </div>     
               <br />
               
               <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
               <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>                    
                   <img src={ParallelRobotArmImg} alt="Parallel Robot Arm" width="100%"/>
               </div>  
               <div style={{width:'48%'}}>
               <span style={{ fontWeight: 'bold' }}>Parallel Robot Arms:</span>
<br />
A parallel robot arm consists of a fixed base, a parallel platform and a number (usually greater than three) linear actuators. This configuration has the advantage of having high stiffness/ rigidity, high payload capacity, fast response and great accuracy. These arms are commonly used in flight simulators, medical surgery, packaging and assembly lines. The actuators of a parallel arm can be hydraulic, electric or pneumatic.                  </div> 
               </div>     


            </p>
            </div>
    )
}