import MiterSawImg from '../../Images/MiterSawImg.jpg'
import T15 from '../../Images/T15.JPG'

export default function MiterSaw(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Miter Saw</h1>
               <p>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={MiterSawImg} alt="Miter Saw" width="45%"/>
                                </div>
           
                       
                                <span style={{ fontWeight: 'bold' }}>Description:</span> The miter saw is a rotating cutting saw which can be positioned to produce angle cutting paths. The cutting operation is done by lowering the blade onto the workpiece which has been placed on top of the lower cutting bed.
                        <br />
                        <br /> 
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={T15} alt="Miter Saw" width="65%"/>
                                </div>
                                <br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Blade Length:</span> The diameter of the cutting blade. This is important when you want to replace or upgrade the blade from for instance, wood cutting to metal cutting.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Bevel Tilt:</span> The maximum tilt angle which the blade can be rotated to which allows for diagonal cuts.
                        </p>
            </div>
    )
}