import React from "react";
import {
//   BrowserRouter as Router,
  useParams,
} from "react-router-dom";
import Structures from './Structures';
import Recursion from './Recursion';
import Arrays from './Arrays';
import BigO from "./BigO";
import Sorting from "./Sorting";
import BinarySearch from "./BinarySearch";

const ChangeComponents = {
    structures: Structures,
    recursion: Recursion,
    arrays: Arrays,
    bigo: BigO,
    sorting: Sorting,
    binarysearch: BinarySearch,
  };

export default function Subtopics(props) {
    // The <Route> that rendered this component has a
    // path of `/topics/:topicId`. The `:topicId` portion
    // of the URL indicates a placeholder that we can
    // get from `useParams()`.
    let { topicId } = useParams();
    
    let MyComponent = ChangeComponents[topicId];
    return (
        <div>
            {/* {topicId} is for testing route names */}
            {/* <h3>{topicId}</h3> */}
            <MyComponent topicId={topicId}/>
      </div>
    );
  }