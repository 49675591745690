import PolymersImg from '../../Images/PolymersImg.jpg'

export default function Polymers(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Polymers</h1>
               <p>

                <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={PolymersImg} alt="Polymers" width="100%"/>
                                </div> 
                                <div style={{width:'80%'}}>
                                <h2>Intro to Polymers</h2>
Polymers, often known as plastics, are carbon-based chemicals made up of long, string-like molecules whose repeating units connect together to form chains and structures within the materials. They have a relatively low density when compared to metals or ceramics with good strength to weight ratios for certain types. Polymers also have low electrical conductivity and high corrosion resistance. 
    <br />
                        <br /> 
Some of their limitations include low stiffness, low strength in comparison to metals, low melting point, and may degrade when subject to sunlight or other elements. Polymers can be supplemented with additives to alter their molecular structure to create stronger composites.
                        
                                    </div> 
                    </div>                
 <br />
                        <br /> 
<h2>Types of Polymers</h2>                                    
Thermoplastics(TP)
<br /> 
Thermosets(TS)
<br /> 
Elastomers
        
                        </p>
            </div>
    )
}