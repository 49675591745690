import IfStatementsImg from '../../Images/IfStatementsImg.jpg'

export default function Conditionals(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Conditionals</h1>
               <p>
                                
           
           <h2>Intro to Logic and If Statements;</h2>
The if statement provides the program with an executable given that a condition is true. An if statement works by creating a template where if the program knows a thing occurs, then it should do x. The function also commonly provides an alternative where if the thing does not occur, then the program should do y instead. 
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={IfStatementsImg} alt="If Statements" width="45%"/>
                                </div>
                        </p>
            </div>
    )
}