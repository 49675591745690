import React from "react";
import {
//   BrowserRouter as Router,
  useParams,
} from "react-router-dom";
import Structures from './Structures';
import Imperfections from './Imperfections';
import Diffusion from "./Diffusion";
import Dislocations from "./Dislocations";
import Failure from './Failure';
import Strengthening from "./Strengthening";
import Crystalline from "./Crystalline";
import PhaseChange from "./PhaseChange";
import PolymerStructures from "./PolymerStructures";

const ChangeComponents = {
    structures: Structures,
    imperfections: Imperfections,
    diffusion: Diffusion,
    dislocations: Dislocations,
    failure: Failure,
    strengthening: Strengthening,
    crystalline: Crystalline,
    phasechange: PhaseChange,
    polymerstructures: PolymerStructures,
  };

export default function Subtopics(props) {
    // The <Route> that rendered this component has a
    // path of `/topics/:topicId`. The `:topicId` portion
    // of the URL indicates a placeholder that we can
    // get from `useParams()`.
    let { topicId } = useParams();
    
    let MyComponent = ChangeComponents[topicId];
    return (
        <div>
            {/* {topicId} is for testing route names */}
            {/* <h3>{topicId}</h3> */}
            <MyComponent topicId={topicId}/>
      </div>
    );
  }