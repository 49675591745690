import DigitalMicroscopeImg from '../../Images/DigitalMicroscopeImg.jpg'
import CircularSawImg from '../../Images/CircularSawImg.png'
import PowerDrillImg from '../../Images/PowerDrillImg.png'
import HandheldBandSawImg from '../../Images/HandheldBandSawImg.png'

export default function HandTools(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Hand Tools</h1>
               <p>
                           
          
                         

                        <span style={{ fontWeight: 'bold' }}>Power Drill:</span> A power drill is a hand tool used to drill round holes into material. 
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Handheld Band Saw:</span> The handheld band saw is a continuous band of sharp metal which is stretched between two or more wheels that rotate to allow cutting of a material. Because the cutting teeth on the band are small, this machine is often used for cutting metal and other hard materials.
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Hand Saw:</span> A device used to cut wood and other materials.
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Hammer:</span> A tool with a long handle and a weight at the end used to drive nails through material upon impact.
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Angle Grinder:</span> Also known as a disk grinder, this device rotates discs at high speeds for cutting and polishing.
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Screw Driver Set:</span> A tool set used for driving screws.
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Mallet:</span> Similar to a hammer, this is used to cause impact upon striking.
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Dremel Kit:</span> A rotary tool removing small areas of material and carving.
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Allen Key Set:</span> Also known as hex keys, these hexagonal tools are used for turning driving hex screws.
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Drill Bit Set:</span> A set of bits for a hand drill and drill press with a variety of diameters.
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Hand Tap:</span> A tool used to create threads within a cylindrical opening.
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>igital Calipers:</span>D A device used to precisely measure the dimensions of an object.
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Tape Measure:</span> A device used to rapidly measure the dimensions of an object.
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Digital Tiltmeter:</span> A device used to measure the tilt angle of an object’s surface.
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>File Set:</span> A tool used to remove material from an object using highly abrasive surfaces.
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Handheld Band Saw:</span> A handheld variation of the bandsaw, used for cutting.
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Hex T Driver Set:</span> An alternative hex key tool, easier to turn but takes up more space, used for driving hex head screws.
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Hot Melt Glue Gun:</span> A device used to melt cylindrical glue sticks to join surfaces together.
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Utility Knife:</span> A retractable knife used to open boxes.
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Stud Finder:</span> A handheld device used to locate framing studs and other noteworthy objects in wood buildings.
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Wrench Set:</span> A handheld tool used to grip and increase torsion when turning bolts or fasteners.
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Plier Set:</span> A set of hand tools used for gripping and bending a wide range of materials.
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Clamps:</span> A device used to temporarily fix something to a workbench.
                        <br /> 
                        <br /> 
<h2>Circular Saw;</h2>

<span style={{ fontWeight: 'bold' }}>Description:</span> A circular saw is a handheld wood cutting machine which can often be arranged at a certain bevel angle for diagonal cuts.
<br /> 
<span style={{ fontWeight: 'bold' }}>Blade Length:</span> The diameter of the circular blade. Important to keep track of when replacing dull blades.
<br /> 
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={CircularSawImg} alt="Mill" width="40%"/>
                                </div>
                                <br /> 
                                <br /> 
<h2>Power Drill;</h2>

<span style={{ fontWeight: 'bold' }}>Description:</span> A power drill is a hand tool used to drill round holes into material. 
<br /> 
<span style={{ fontWeight: 'bold' }}>Max Torque:</span> The max torque produced by the drill is the highest amount of rotational force which the machine is capable of generating. If fasteners require more torque to turn than the machine can generate, then the machine will stall and often lock up.
<br /> 
<span style={{ fontWeight: 'bold' }}>Chuck Max Diameter:</span> The drill chuck can be outfitted with a variety of drill bits which allows different diameter holes to be drilled. The max diameter is the largest bit size which the chuck is capable of fitting inside its opening.
<br /> 
<span style={{ fontWeight: 'bold' }}>Speed:</span> The range at which the spindle is capable of rotating. Different materials require different drilling speeds so it is important to set your machine at the correct units.
<br /> 
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={PowerDrillImg} alt="Mill" width="40%"/>
                                </div>
                                <br /> 
                                <br /> 
<h2>Handheld Band Saw;</h2>

<span style={{ fontWeight: 'bold' }}>Description:</span> The handheld band saw is a continuous band of sharp metal which is stretched between two or more wheels that rotate to allow cutting of a material. Because the cutting teeth on the band are small, this machine is often used for cutting metal and other hard materials.
<br /> 
<span style={{ fontWeight: 'bold' }}>Cutting Depth Capacity:</span> The maximum distance which the blade can be brought down which determines the maximum cut depth of the machine.
<br /> 
<span style={{ fontWeight: 'bold' }}>Blade Length:</span> The total length of the band which is rapidly reciprocated or circulated to cut material. This is important to note for when replacing or upgrading the blade will inevitably occur due to wear.
<br /> 
<span style={{ fontWeight: 'bold' }}>Blade Speed:</span> The speed at which the blade will reciprocate or rotate depending on the style of the machine.
<br /> 
     <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={HandheldBandSawImg} alt="Mill" width="40%"/>
                                </div>
                                <br /> 
                                <br /> 
<h2>Digital Microscope;</h2>

<span style={{ fontWeight: 'bold' }}>Description:</span> Handheld electric microscopes can magnify images, snap high resolution images and record videos and pass all the information through a usb cable.
<br /> 
<span style={{ fontWeight: 'bold' }}>Magnification:</span> The amount of times the camera is capable of enlarging an image in relation to the actual size of the material or surface.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={DigitalMicroscopeImg} alt="Mill" width="40%"/>
                                </div>      
                     
                     
                        </p>
            </div>
    )
}