import RivetsImg from '../../Images/RivetsImg.jpg'
import RivetDiagramImg from '../../Images/RivetDiagramImg.jpg'

export default function Rivets(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Rivets</h1>
               <p>
                                
           
                   
                        <h2>Intro to Rivets;</h2>
                        Rivets are permanent mechanical fasteners which consist of a smooth cylindrical shaft with a head at one end. Rivets are designed to deform into a larger diameter hole which creates a pressure seal between the rivet and the mating material. Rivets are excellent at withstanding shear and tensile loads and can offer excellent joining for watertight applications.
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={RivetsImg} alt="Rivets" width="75%"/>
                                </div>
                                <h2>Pop/Blind Rivets;</h2>
Pop Rivets are a two part permanent fastener which consists of an internal part called a mandrel and an external shell called the rivet body. This type of rivet is used primarily when you can only access one side of a workpiece. Unlike other rivets, blind rivets need a riveting tool to pull the mandrel head into the body of the rivet causing it to expand into the blind side of the workpiece. Once the mandrel is squished into the rivet body, the stem snaps, leaving a flat surface on both sides of what was once the rivet hole.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={RivetDiagramImg} alt="RivetDiagramImg" width="50%"/>
                                </div>
                                <br />
<br />
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
    <div style={{width:'50%'}}>

    <h2>Advantages of Using Rivets;</h2>
1. Rivets are very cost effective
<br />
2. Capacity for joining materials where access is limited to only one side of the workpiece
<br />
3. Durable
<br />
4. Lightweight
<br />
5. Can create watertight joints
<br />
<br />
    </div>
    <div style={{width:'50%'}}>
    <h2>Disadvantages of Rivets;</h2>
1. Difficult to remove after being deformed into place
<br />
2. Does not sit flush with surface, slight protrusion depending on geometry of the head
    </div>
</div>
                        </p>
            </div>
    )
}