import SolderingImg from '../../Images/SolderingImg.jpg'
import DesolderingImg from '../../Images/DesolderingImg.jpg'

export default function Soldering(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Constructing Circuits</h1>
               <p>
                               
           <h2>Basics of Soldering;</h2>
                  
                        Solder is an alloy typically made from a combination of tin, lead, silver and/or copper which is used to join electronics
                         or wiring together. A large majority of the modern world has outlawed the use of lead in consumer products, so you will 
                         often find modern solder without it. Be aware though that some mass-production sources outside of the US and Europe may
                          still use it so think twice before soldering or desoldering with it. Smoke absorbing devices are readily on the market 
                          for a low price so be sure to place a smoke absorbing fan within your workstation to protect yourself.
                          <br />
                        <br /> 
                      A soldering iron is a metal pen-like device which when turned on, heats the tip so that solder can be melted and spread on
                       the desired surfaces. Before solder can be applied to a metal surface, be sure to cleanse the metal of all oils, dirt and 
                       grease using steel wool, fine sandpaper, or solvent. 
                       <br />
                        <br /> 
                      Before melting any solder onto your tool, use the hot tip to heat up both surfaces of the metal you intend to solder by 
                      pressing it onto the surface for a few seconds. Solder likes to flow to warm areas which greatly helps the process. If you 
                      skip this step, the solder you are depositing may not bind to the part effectively enough to pass a stable current. 
                      <br />
                        <br /> 
                      Many soldering irons benefit from a tin coating of solder at the tip to prevent breaking and wear. In the case that the 
                      soldering iron no longer grips to hot solder, you can dip the tip into a substance known as flux which will help the 
                      situation.
                      <br />
                        <br /> 
                    Be wary of small drips of solder as you use a soldering iron as they may cause burns or damage electrical equipment. If you
                     are worried about the excessive heat conducted into sensitive components, consider temporarily attaching a heat sink onto 
                     the material surface. 
                     <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={SolderingImg} alt="Soldering" width="35%"/>
                                </div>
                        <h2>Desoldering;</h2>
                     
                        In the event that a connection was formed incorrectly, it may be necessary to remove a soldered joint. This can be 
                        a difficult process as the solder when molten will favor staying on the part instead of returning to the tip of 
                        the soldering iron. The most effective method is to first melt the solder and once the solder is molten, suck it up
                         using a solder sucker. This cheap tool is often spring loaded and should be placed right on the edge of the hot metal. 
                         When activated, a burst of suction will pull the solder right off. The solder, being rapidly exposed to air, will 
                         solidify in seconds.
                         <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={DesolderingImg} alt="Desoldering" width="35%"/>
                                </div>
                        </p>
            </div>
    )
}