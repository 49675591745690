import PlasmaSystemImg from '../../Images/PlasmaSystemImg.jpg'
import PlasmaGasTableImg from '../../Images/PlasmaGasTableImg.jpg'
import PlasmaTemplateImg from '../../Images/PlasmaTemplateImg.jpg'
import PlasmaTorchImg from '../../Images/PlasmaTorchImg.jpg'

export default function PlasmaCutting(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Plasma Cutting</h1>
               <p>
                              
           
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={PlasmaSystemImg} alt="Plasma Cutting" width="100%"/>
                                </div>
<div style={{width:'70%'}}>
<h2>Intro to Plasma Cutting;</h2>

                      Plasma cutting is one of the fastest sheet metal cutting technologies due to its ability to concentrate a jet of hot plasma through electrically conductive materials. These materials include steel, stainless steel, aluminum, brass and copper. 
                      <br />
                      <br />
Plasma cutting works by channeling a mixture of electrically charged gas (usually compressed argon and hydrogen) through a circuit. The electrical current forms between the electrode in the gas nozzle to the metal being cut and then out through the grounding clamp. This current can reach temperatures of up to 22000 degrees centigrade which can cut metal sheets as the CNC table moves the torch around.

</div>
                        </div>
                        <br />
                        <br />
        <br />
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
    <div style={{width:'50%'}}>
    
    <h2>Advantages of Plasma Cutting;</h2>
1. Cost effective
<br />
2. Rapid
<br />
3. Variety of metals it can cut
<br />
4. Localized heat leads to low metal distortion
<br />
<br />

    </div>
    <div style={{width:'50%'}}>
    <h2>Disadvantages of Plasma Cutting;</h2>
1. Edge left after cutting requires grinding
<br />
2. Fumes extraction & operator safety precautions must be met
    </div>
</div>
 <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={PlasmaTorchImg} alt="Plasma Cutting" width="100%"/>
                                </div>
                                <div style={{width:'70%'}}>
                               

<h2>Pilot Arc;</h2>
A pilot arc is included in most plasma cutters and allows you to make a cut without touching the tip of the torch to the metal. This greatly increases the life of the consumables on your cutting torch and allows for smoother cuts. 
<br />
<br />
<h2>Consumables;</h2>
Consumables are the part of the plasma cutting torch which will wear out over time. Many of the parts in the cutting torch; electrode, swirl ring, nozzle, retaining cap and shield will have to be changed over time, although at different rates. All plasma torches are designed to be pulled apart and have the consumables easily replaced. Users which use their plasma cutters on a regular, weekly basis may have to do these changes once a year though the cutting speed, cutting material, design of the torch, ect will have an effect on this duration.

                                </div>
    </div>    
    <br />
        <br />       
    <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={PlasmaTemplateImg} alt="Plasma Cutting Template" width="100%"/>
                                </div>

<div style={{width:'70%'}}>
<h2>Templates & Cutting Tip Diameter;</h2>
If you are cutting a workpiece by hand and using a template, ensure that you account for the diameter of the cutting tip. For example, if you require a piece that is the diameter of the solid circle, then your cutting circle would have to be the desired diameter plus the diameter of the cutting tip.
</div>
        </div>  
        
        <h2>Operating a Plasma Cutter;</h2>
1. Perform the basic setup procedure for the machine you are using as detailed in the user manual of your particular model. 
<br />
2. Ensure all user safety equipment is equipped and your working area is prepared.
<br />
3. Secure the workpiece & template (if using a template) on metal blocks to the work table using reliable clamps. 
<br />
4. Connect the ground clamp to the work table. The connection from the ground clamp to the workpiece should be free of paint or other contaminants.
<br />
5. Rehearse the cutting pattern without squeezing the trigger while using your non-cutting hand as a brace to steady yourself as you move.
<br />
6. Ensure your gas/air valve is open and turn on your machine.
<br />
7. If working with a handheld torch, begin cuts by piercing outside of cutting design while holding the torch at an angle. Squeeze the trigger and roll the gun until it is perpendicular to the metal. Gradually move to the side of the cutting path.
<br />
8. Once you reach the cutting path, always keep the torch perpendicular to the metal or a beveled edge will form. 
<br />
9. After cutting the part, there will be an accumulation of molten metal on the lower edge of your part (dross), it can easily be removed by using an angle grinder or a piece of scrap metal.
<br />
        <br /> 
        <h2>Plasma Gas Table;</h2>
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={PlasmaGasTableImg} alt="Plasma Gas Table" width="80%"/>
                                </div>                 
                        </p>
            </div>
    )
}