import WoodwarpImg from '../../Images/WoodwarpImg.png'
import HardwoodImg from '../../Images/HardwoodImg.png'

export default function Wood(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Wood</h1>
               <p>

               <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    <img src={WoodwarpImg} alt="Wood warp" width="100%"/>
                                </div> 
                                <div style={{width:'60%'}}> <h2>Intro to Wood;</h2>             
Wood is generally divided into two categories: softwood and hardwood. Softwood is harvested from coniferous trees which produce cones and needles such as fir, redwood, cedar and spruce. Hardwoods are harvested from deciduous trees such as walnut, cherry, oak, mahogany and maple. A common misconception is the notion that softwoods are softer than hardwoods, this is generally the case but there are in fact some softer hardwoods and vice versa.
<br />
<br />
<h2>Moisture Content;</h2>   
Moisture content is defined as the percentage of water inside a piece of wood in relation to the woods weight. In most environments, wood will reach an <span style={{ fontWeight: 'bold' }}>equilibrium moisture content (EMC)</span> where it will no longer gain or lose water. Although it differs based on the wood, kiln-dried wood for woodworking generally has a 6-8% moisture content.

</div>                       
</div>

              
<br />
<br />
 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={HardwoodImg} alt="Hardwoods" width="100%"/>
                                </div> 
                                                 
                                <h2>Softwoods;</h2> 
                                <span style={{ fontWeight: 'bold' }}>Cedar:</span> There is a variety of cedar but the western red cedar is one of the most popular softwoods for outdoor applications due to its rot-resistance, natural oils and aromatic properties. It has a reddish color, has a straight grain pattern and is one of the softest woods to work with. For the same reasons that make this wood good for outdoor fencing, furniture and storage, cedar can be a mild irritant to some and should not be used for anything which has food or prolonged skin contact.
<br />
<br /> 
<span style={{ fontWeight: 'bold' }}>Pine:</span> Pine is one of the most common softwoods used around the world due to its low cost and durability. It is traditionally used as indoor furniture material since it is soft and easy to carve though it can be treated for outdoor use. Pine has high amounts of knots per area and is prone to warping and twisting.
<br />
<br /> 
<span style={{ fontWeight: 'bold' }}>Redwood:</span> Redwood trees have a straight grain and a reddish tint and are considered fairly soft. This wood has natural moisture resistance making it ideal for outdoor use though it is commonly used for indoor furniture as well.
<br />
<br /> 
<span style={{ fontWeight: 'bold' }}>Fir:</span> The Douglas fir is an inexpensive, reddish-brown wood with relatively high strength. This wood is often painted since it has an unattractive grain pattern and can be hard to stain. This wood is more commonly found in utility and construction projects.
<br />
<br /> 
<span style={{ fontWeight: 'bold' }}>Spruce:</span> Spruce is a straight grain wood with a light color and a low softness, similar to pine. Unless treated, this wood is not very weather resistant and will absorb moisture and swell. This wood has excellent acoustic properties which causes it to be commonly used for musical instruments.
<br />
<br /> 
 <h2>Hardwoods;</h2>
 <span style={{ fontWeight: 'bold' }}>Mahogany:</span> Mahogany comes in a range of color from reddish-brown to deep red and is soft, highly workable and has a straight grain. Due to the difficulty in growing and harvesting this wood, it is often expensive. 
<br />
<br /> 
<span style={{ fontWeight: 'bold' }}>Cherry:</span> Cherry sapwood has a whitish color while its heartwood has a reddish brown tint. It is a popular woodworking material since it is easily finished and is relatively soft. 
<br />
<br /> 
<span style={{ fontWeight: 'bold' }}>Oak:</span> Oak has a harder workability than cherry or mahogany and comes in a range of white and red colors. It has excellent rot resistance and can be used for outdoor applications if finished properly. However, oak has high moisture absorbance and can swell and shrink regularly. Oak is also particularly heavy.
<br />
<br /> 
<span style={{ fontWeight: 'bold' }}>Maple:</span> Maple comes in two varieties: hard maple and soft maple. Both varieties are hard to work with due to their high hardness though the straight grain and strength makes them one of the most used woods for furniture making. 
<br />
<br /> 
<span style={{ fontWeight: 'bold' }}>Poplar:</span> Poplar is an extremely soft wood which can easily be dented and does not have a particularly attractive grain and color pattern. It is more commonly used for cheap furniture and painted parts.
<br />
<br /> 
<span style={{ fontWeight: 'bold' }}>Birch:</span> Birch comes in a variety of yellow and whites and is one of the hardest hardwoods. Most types of birch are very stable and robust but can be difficult to stain. This wood is more commonly used for parts that will be painted.
<br />
<br /> 
<span style={{ fontWeight: 'bold' }}>Ash:</span> Ash has a pale brown tint, a straight grain and is very hard. This material is often used in hockey sticks or baseball bats.
<br />
<br /> 
<span style={{ fontWeight: 'bold' }}>Walnut:</span> Walnut has a deep brown color, a fine grain, stains easily and looks great when polished. It is considered on the higher range of hardness but retains its machinability.
<br />
<br /> 
<span style={{ fontWeight: 'bold' }}>Teak:</span> Teak is one of the rarest hardwoods and comes in a golden brown color. It has mid-range hardness and is very temperature resistant. The wood’s natural oil finish can make it harder to glue or stain. The wood internal oils can be irritating to some and this material should not be used in parts that have regular skin or food contact.
<br />
<br /> 
<span style={{ fontWeight: 'bold' }}>Meranti:</span> Meranti is a wood from southeast Asia which has a straight grain, good workability and is often used for furniture. Meranti has no natural protection from inserts and needs a surface finish. A disadvantage of this wood is that it requires more regular maintenance than other woods.
                                 
                        </p>
            </div>
    )
}