import ForceSensorsImg from '../../Images/ForceSensorsImg.jpg'

export default function ForceSensors(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Force Sensors</h1>
               <p>

 
<h2>Simple Pressure Sensor;</h2>
The most basic type of force sensor is the force sensitive resistor which is largely a mechanical pressure sensor. Because pressure is equal to force over area, this type of sensor can measure the pressure in a single direction and convert it to a corresponding force. These types of sensors are often inaccurate but are also very inexpensive. One of their best functions is to determine whether a force or contact has been applied or not.
<br /> 
<br />
<h2>Strain Gauge Force Sensor;</h2>
Strain gauge sensors are one of the most common types of force sensors used in industry and work by the principle that the resistance of a flexible conductive foil changes when placed under strain. A highly accurate analog circuit known as a Wheatstone Bridge measures the changes in resistance and produces a corresponding output voltage. Strain gauge force sensors generally come in two different forms:
Single-axis load cells - These use a single strain gauge to measure forces in one axis only.
Multi-axis force sensors - These 6-axis force torque sensors use multiple strain gauges to detect all forces applied to the sensor in all six axes.
<br /> 
<br />
<h2>Capacitance Force-Torque Sensors;</h2>
This type of sensor works on the principle that the capacitance of two conductive elements is dependent on the distance between them. An alternating electric current passes through the elements which varies on their distance. Because capacitive sensors use an alternating signal, their output signal is digital which increases the resistance to noise significantly.

  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ForceSensorsImg} alt="force sensor" width="80%"/>
                                </div>
                        </p>
            </div>
    )
}