import ExtrusionPrinterImg from '../../Images/ExtrusionPrinterImg.jpg'
import T21 from '../../Images/T21.JPG'

export default function PolynomialEquations(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Extrusion Printer</h1>
               <p>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ExtrusionPrinterImg} alt="Extrusion Printer" width="45%"/>
                                </div>
           
                       
           <span style={{ fontWeight: 'bold' }}>Description:</span> A 3D printer works by extruding spools of 3D printer material (usually thermoplastics) through a heated nozzle which traces the cross section of the part, layer by layer onto a heated bed.
                        <br />
                        <br />
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={T21} alt="Extrusion Printer" width="100%"/>
                                </div>
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Type:</span> The type of extrusion process performed by the printer. The most common is FDM (fused deposition modeling.
    <br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Layer Thickness:</span> The minimum height of a layer of filament deposited by the machine.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Resolution:</span> Resolution is the accuracy which the machine operates within in comparison to the commands it receives from the computer’s instructions. 
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Build Volume:</span> The total size of the area which the laser table is able to accommodate.

                        </p>
            </div>
    )
}