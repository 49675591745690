import PlasmaCutterImg from '../../Images/PlasmaCutterImg.jpg'
import T34 from '../../Images/T34.JPG'

export default function PlasmaCutter(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Plasma Cutter</h1>
               <p>
                              
         
            
           <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={PlasmaCutterImg} alt="Plasma Cutter" width="45%"/>
                                </div>
                                <span style={{ fontWeight: 'bold' }}>Description:</span> Plasma cutters work by accelerating a jet of plasma through electrically conductive materials. 
                                <br />
                        <br /> 
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={T34} alt="Plasma Cutter" width="100%"/>
                                </div>
                                <br /> 
                        <span style={{ fontWeight: 'bold' }}>Feed Rate:</span> The max travel speed of the machine element being used.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Resolution:</span> Resolution is the smallest change that the machine is capable of measuring.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Accuracy:</span> Accuracy is how close the machine element manages to be from the desired location.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Travel Distance:</span> The max distance which the plasma cutting machine is capable of traveling.
    
                        </p>
            </div>
    )
}