import React from "react";
import {
//   BrowserRouter as Router,
  useParams,
} from "react-router-dom";


import Overview from "./Overview";
import ShopPress from "./ShopPress";
import DrillPress from "./DrillPress";
import Grinder from "./Grinder";
import MiterSaw from "./MiterSaw"
import BandSaw from "./BandSaw"
import LaserCutter from "./LaserCutter"
import AirCompressor from "./AirCompressor"
import DustCollector from "./DustCollector";

const ChangeComponents = {
    overview: Overview,
    shoppress: ShopPress,
    drillpress: DrillPress,
    grinder: Grinder,
    mitersaw: MiterSaw,
    bandsaw: BandSaw,
    lasercutter: LaserCutter,
    aircompressor: AirCompressor,
    dustcollector: DustCollector,

  };

export default function Subtopics(props) {
    // The <Route> that rendered this component has a
    // path of `/topics/:topicId`. The `:topicId` portion
    // of the URL indicates a placeholder that we can
    // get from `useParams()`.
    let { topicId } = useParams();
    
    let MyComponent = ChangeComponents[topicId];
    return (
        <div>
            {/* {topicId} is for testing route names */}
            {/* <h3>{topicId}</h3> */}
            <MyComponent topicId={topicId}/>
      </div>
    );
  }