import React from 'react';
import Subtopics from './Subtopics';
import { Container } from "react-bootstrap";
import {
    Switch,
    Route,
    Link,
    useRouteMatch
  } from "react-router-dom";

function Materials(props) {
    let { path, url } = useRouteMatch();
    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', maxWidth: "100vw"}}>
                {/* <SectionTitle title={props.topicId}/> */}
            <div style={{display: 'flex', flexDirection: 'row'}}> 
            <div style={{marginTop: '10px', marginRight: '0px', marginLeft: '5px',border: '3px', padding:'40px', paddingRight: '50px', borderStyle:'solid', borderColor:'grey', paddingLeft: '20px', whiteSpace:'nowrap'}}>
                <h2>Intro to Materials</h2>
                <li>
                    <Link to={`${url}/nfmetals`}>Non-Ferrous Metals</Link>
                </li>
                <li>
                    <Link to={`${url}/fmetals`}>Ferrous Metals</Link>
                </li>
                <li>
                    <Link to={`${url}/commonmetals`}>Common Metals</Link>
                </li> 
                <li>
                    <Link to={`${url}/gauge`}>Gauge Tables</Link>
                </li> 
                <li>
                    <Link to={`${url}/castiron`}>Cast Iron</Link>
                </li>     
                <li>
                    <Link to={`${url}/steel`}>Steel</Link>
                </li>  
                <li>
                    <Link to={`${url}/stainlesssteel`}>Stainless Steel</Link>
                </li>  
                <li>
                    <Link to={`${url}/aluminum`}>Aluminum</Link>
                </li> 
                <li>
                    <Link to={`${url}/ceramics`}>Ceramics</Link>
                </li>
                <li>
                    <Link to={`${url}/composites`}>Composites</Link>
                </li>
                <li>
                    <Link to={`${url}/semiconductors`}>Semiconductors</Link>
                </li> 
                <li>
                    <Link to={`${url}/polymers`}>Polymers</Link>
                </li>
                
                <li>
                    <Link to={`${url}/peek`}>PEEK</Link>
                </li>   
                <li>
                    <Link to={`${url}/gamma`}>Gamma Irradiation Polymers</Link>
                </li>     
                <li>
                    <Link to={`${url}/wood`}>Wood</Link>
                </li>    
                <li>
                    <Link to={`${url}/epoxy`}>Epoxy Resin</Link>
                </li>      
            
                </div>
                
            <Container>
                <Switch>
                    <Route exact path={path}>
                    <h3>Please select a sub-topic.</h3>
                    </Route>
                    <Route path={`${path}/:topicId`}>
                        <Subtopics />
                    </Route>
                </Switch>
            </Container>
         
                    </div>
            </div>
        </div>
    );
};

export default Materials; 