import React, { useState, useEffect } from 'react'
import { useAuth } from '../../contexts/AuthContext';
 
function NewProject(props) {

    const { currentUser } = useAuth()
    
    const initialFieldValues = {
        projectName: '',
        description: '',
        gitHub: '',
        image: null,
        userName: currentUser.email,

    }

    const [values, setValues] = useState(initialFieldValues);

    useEffect(() => {
        if (props.currentId === '')
            setValues({
                ...initialFieldValues
            })
        else
        setValues({
            ...props.projectObjects[props.currentId]
        })
    }, [props.currentId, props.projectObjects])

    const handleInputChange = e => {
        var { name, value } = e.target
        // name.value = name.value.replace("C:\\fakepath\\", "");
        setValues({
            ...values,
            [name]: value
        })
    }
    // const togglePrivate = e => {
    //     var {name, value} = e.target
    //     setValues({
    //         ...values,
    //         [name]: !value
    //     })
        
    // }

    const handleFormSubmit = e => {
        e.preventDefault();
        props.addOrEdit(values);
    }

    return (

        <form autoComplete="off" onSubmit={handleFormSubmit}>
            <div className="form-row m-2">
                <div>
                    <input className="form-control"
                        placeholder="Project Name"
                        name="projectName"
                        value={values.projectName}
                        onChange={handleInputChange}
                    />
                </div>
            </div>
            <div className="form-row m-2">
                <div>
                    <input className="form-control"
                        placeholder="Description"
                        name="description"
                        value={values.description}
                        onChange={handleInputChange}
                    />
                </div>
            </div>
            <div className="form-row m-2">
                <div>
                    <div>GitHub Link:</div>
                    <input
                        className="form-control"
                        placeholder="GitHub"
                        name="gitHub"
                        value={values.gitHub}
                        onChange={handleInputChange}
                    />
                </div>
            </div>
            <div className="form-row m-2">
                <div>
                    <div>Image:</div>
                    <input
                        type="file"
                        className="form-control"
                        placeholder="Image" name="image"
                        value={values.image}
                        onChange={handleInputChange}
                    />
                </div>
            </div>
      
            <div className="form-group m-2">
                <input
                    type="submit"
                    value={props.currentId ===''?"Save":"Update"}
                    className="btn btn-primary"
                />
            </div>
        </form>

    )
}

export default NewProject