import SheetMetalImg1 from '../../Images/SheetMetalImg1.jpg'
import SheetMetalImg2 from '../../Images/SheetMetalImg2.jpg'
import SheetMetalImg3 from '../../Images/SheetMetalImg3.jpg'
import SheetMetalImg4 from '../../Images/SheetMetalImg4.jpg'
import SheetMetalImg5 from '../../Images/SheetMetalImg5.jpg'
import SheetMetalImg6 from '../../Images/SheetMetalImg6.jpg'

export default function SheetMetal(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Sheet Metal Manufacturing</h1>
               <p>
                                
                                
           
                       
                         <h2>Intro to Sheet Metal Design;</h2>
Sheet metal fabrication is the process of using relatively thin sheets of precisely cut metal to create strong and durable machine parts. Note that the term sheet implies a thickness of less than 3mm while a plate refers to materials with a thickness greater than 3mm. Because parts are formed from a single metal sheet, it is beneficial to design each piece with uniform thickness. 
<br />
                        <br /> 
Once cut, sheet features can be bent to a desired angle using a press brake machine. The press brake uses a set of tools called a punch and die which sits above and below the sheet and forms the bend angles once compressive force is applied. Parts which need bending are sent over as flat sheets with bending information. The bend angle is determined by the depth at which the punch forces the sheet into the die.
<br />
                        <br /> 
When selecting the right sheet metal it is important to consider the daily expected wear on your part, the corrosion protection necessary, mechanical properties and manufacturability. Secondary considerations include cosmetic appearance which you can alter using surface finishing and conductivity if electronics are present. 
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={SheetMetalImg5} alt="Sheet Metal" width="65%"/>
                                </div>
<h2>Terminology;</h2>
Bend Line: The straight lines on the outside of a bend which identify where the curvature of the angle ends.
<br />
Bend Radius: The distance from the bend axis to the inside surface of the sheet between the bend lines. The starting point is formed from the inside intersection of the bend lines and goes out until it reaches the surface. 0.030 inches (.762mm) is the industry standard bend radius.
<br />
Bend Angle: The inside bend angle is the angle between the two surfaces on the side of a bend while the outside bend angle is that plus the thickness of the sheet. 
<br />
Neutral Axis: The location in the sheet which is in neither tension or compression. This is determined by factors such as the material, the bend angle and the bending operation.
<br />
Bend Allowance: The length of the arc between the bend lines that when added to the flange lengths will equal to the total sheet length. 
<br />
K-Factor: The K-factor is the ratio between the thickness of the sheet and the distance to the neutral axis. K=T/t. 
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={SheetMetalImg1} alt="Sheet Metal" width="35%"/>
                                </div>
<h2>Springback;</h2>
When bending a piece of material, it is necessary to overbend the material to a precise degree in order to compensate for the residual stresses which will attempt to slightly return it to its starting shape. The final bend angle will be smaller than the initial bend angle while the final bend radius will be greater than the initial bend radius. The ratio of the final and initial bend angles is known as the springback factor (KS) which is determined from material selection, bending operation and initial bend angle and radius.
<br />
                        <br /> 
<h2>Design Guidelines;</h2>
To prevent fracturing and distortions, keep the inside radius at least equal to the material thickness.
A +/- 1 degree tolerance in bend angle is commonplace in industry.
Flange lengths must be at least four times the thickness of the material.
The bending force of a press brake is related to the square of the thickness, doubling thickness means a four-times increase in the tonnage used for the punch and die.
Thick parts have a tendency to be inaccurate so they should be avoided if possible.
Corner fillets are generally designed to be one half of the material thickness.
The 4T general rule states that you should always keep features four times the material thickness away from bend lines.
Using the same radii for all bends drastically saves on manufacturing cost.
<br />
                        <br /> 
<h2>Bend Relief;</h2>
When bends are made near a material’s edge, the material is susceptible to tears unless bend relief is provided. Small rectangular relief cuts allow the bends to ignore surrounding material. Their depth should be greater than the radius of the bend while their width should be greater than or equal to the material thickness. When corners are bent to form three planes, a bend relief notch should be used to prevent tearing. 
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={SheetMetalImg3} alt="Sheet Metal" width="45%"/>
                                </div>
<h2>Sheet Metal Holes;</h2>
When a bend is designed too close to a hole, the hole may deform into a teardrop shape. Although secondary processes such as drilling and punching can be used, it is often cost effective to design the holes as part of the initial cut. It is also common in manufacturing that the minimum hole diameter be at least the thickness of the material. Special tooling may be required otherwise.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={SheetMetalImg4} alt="Sheet Metal" width="35%"/>
                                </div>
                                <h2>Countersink Holes;</h2>
Countersink holes can be created with a drill press or formed using punch-press tooling and should be no more than .6 x the material thickness. These conical holes allow screws, nails or bolts to sit flush with the surface of the material. The distance between countersink centers should be 8 times the thickness of the material. The distance from the center of a countersunk hole to the edge of a bend should be 3 times the material thickness and 4 times from the distance to the part edge. 
<br />
                        <br /> 
<h2>Curls;</h2>
Forming a hollow, circular roll at the edge of a sheet is the process known as curling. This curl provides strength to the edge and a safer area to grip a metal sheet whose sharp edges often require deburring or post-processing. It is often recommended that the outside radius of the curl be at least twice the thickness of the material. 
<br />
                        <br /> 
<h2>Flange Bending Directions;</h2>
When a sheet metal part is bent, the side of the bend stores compressive forces while the backside stresses in tension. Sheet metal parts with same-side bending directions (shaped like a U) benefit from the internal forces by having additional part stiffness. When a part is bent in opposite directions, the tensile and compressive stresses that are being oppositely generated can cause warping in the piece and should be avoided.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={SheetMetalImg6} alt="Sheet Metal" width="55%"/>
                                </div>

<h2>Hems;</h2>
Hems are folds that can be made at the edge of a part which creates a flat round edge. This process is often done in two parts with the acute angle bent first and then a high compressive force used to flatten out the material. Much like curls, hems can be used to stiffen edges and provide a safe area to grab the material. Combinations of hems can also be used to create strong, tight joints with little or minimal fastening. Hems can also be used to create additional thickness in places which require it. 
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={SheetMetalImg2} alt="Sheet Metal" width="55%"/>
                                </div>
<h2>Holes, Slots, Tabs and Notches;</h2>
Holes and slots placed near bends can become deformed and should be placed a distance from each other at least equal to the material thickness.

                        </p>
            </div>
    )
}