// import GT from '../../Images/GT.jpg'

export default function Overview(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Arduino C++ Libraries</h1>
               <p>
                                {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GT} alt="linkages" width="75%"/>
                                </div> */}
           
        <h1>Servo Library;</h1> 

           
                   
        <br /> 
                       <span style={{ fontWeight: 'bold' }}>Servo Library:</span>
                       <br /> 
<code>{`#include <Servo.h>`}</code>
<br /> 
<code>{`Servo varName;`}</code>
<br /> 
<br /> 
<span style={{ fontWeight: 'bold' }}>Servo Assignment (Servo Library):</span>
<br /> 
This is for assigning the variable to a pin, this is done in the void setup section.
<br /> 
<code>{`varName.attach(#);`}</code>
<br /> 
<br /> 
<span style={{ fontWeight: 'bold' }}>Servo Write Function:</span>
<br /> 
This function commands the servo to move the rotor to the angle you specify.
<br /> 
Don't forget to add a delay after to give the motor time to move to the new destination.
<br /> 
<code>{`myServo.write(varName);`}</code>
<br /> 
<br /> 
<h1>Liquid Crystal Library;</h1>
<br />
<span style={{ fontWeight: 'bold' }}>Import Liquid Crystal Library:</span>
<br /> 
The first line imports the library, the second line tells it what pins you will be using to communicate.
<br /> 
<code>{`#include <LiquidCrystal.h>`}</code>
<br /> 
<code>{`LiquidCrystal lcd(#, #, #, #, #);`}</code>
<br /> 
<br /> 
<span style={{ fontWeight: 'bold' }}>Setup() Function Begin:</span>
<br /> 
The begin function starts the lcd library and tells it how large the screen is. 16 x 2 is for example.
<br /> 
<code>{`lcd.begin(16, 2);`}</code>
<br /> 
<br /> 
<span style={{ fontWeight: 'bold' }}>LCD Print:</span>
<br /> 
The print function writes to the lcd screen. 
<br /> 
<code>{`lcd.print(“text”);`}</code>
<br /> 
<br /> 
<span style={{ fontWeight: 'bold' }}>SetCursor Function:</span>
<br /> 
This function sets the coordinates for the cursor. Remember that the cursor begins zero-indexed at 0,0 the first column on the first row. If you want to write to the next line, select (0, 1) as the new starting location.
<br /> 
<code>{`lcd.setCursor(#, #);`}</code>
<br /> 
<br /> 
<span style={{ fontWeight: 'bold' }}>Loop Function Random:</span>
<br /> 
This function will give a number between 0 and the number you put into it.
<br /> 
<code>{`varName = random(#);`}</code>
<br /> 
<br /> 
<span style={{ fontWeight: 'bold' }}>LCD Clear Screen:</span>
<br /> 
Clears the screen and moves the cursor back to 0,0.
<br /> 
<code>{`Lcd clear();`}</code>

                        </p>
            </div>
    )
}