import GeometryImg3 from '../../Images/GeometryImg3.PNG'
import GeometryImg4 from '../../Images/GeometryImg4.PNG'
import CirclesImg from '../../Images/CirclesImg.jpg'

export default function Circles(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Circles and Ellipses</h1>
               <p>
                                
           
                       
                        <h2>Intro to Circles and Ellipses;</h2>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GeometryImg3} alt="circles" width="55%"/>
                                </div>
                        <h2>Degree to Radian Table;</h2>                  
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GeometryImg4} alt="circles" width="75%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={CirclesImg} alt="circles" width="75%"/>
                                </div>
                        </p>
            </div>
    )
}