import ResinPrinterImg from '../../Images/ResinPrinterImg.jpg'
import T22 from '../../Images/T22.JPG'

export default function ResinPrinter(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Resin Printer</h1>
               <p>

     <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ResinPrinterImg} alt="Resin Printer" width="45%"/>
                                </div>
                                <span style={{ fontWeight: 'bold' }}>Description:</span> Resin printers can rapidly produce precise parts by utilizing a laser or light to selectively cure a layer of resin which is then extracted and reset for the next layer. The layers are gradually pulled upwards out of the resin as they are built and fused into a solid object.
                                <br />
                        <br /> 
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={T22} alt="Resin Printer" width="100%"/>
                                </div>
                                <br /> 
                        <span style={{ fontWeight: 'bold' }}>Type:</span> The type of resin curing process performed by the printer. The most common is SLA (stereolithography) and DLP (direct light processing).
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Layer Thickness:</span> The minimum height of a layer of filament deposited by the machine.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Resolution:</span> Resolution is the accuracy which the machine operates within in comparison to the commands it receives from the computer’s instructions. 
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Build Volume:</span> The total size of the area which the laser table is able to accommodate.

                        </p>
            </div>
    )
}