import ForceAnalysis from '../../Images/ForceAnalysisImg.jpg'


export default function Forceflow(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Force Flow and Critical Sections Analysis</h1>
               <p>
<h2>Intro to Force Flow;</h2>
                            An extremely powerful design tool is learning how to draw approximate force lines through a system and seeing how the force transfers as it moves through the different parts of your system. This analysis is slightly different from the free body diagram as it takes into account the entire system and not just individual components. 
                            <br />
                            <br /> 
                            With this analysis, it is helpful to draw a simplified version of your system and trace the lines of forces as it moves between components. Every time the force transfers to another component, note the way it transfers and what surfaces/materials will be subject to the internal forces.  
                            <br />
                            <br /> 
                            Having a list of force transfers will allow you to determine which transfer sections might be subject to material failure, these are your <span style={{ fontWeight: 'bold' }}>critical sections</span>. Any section deemed critical should be analyzed further and possibly reinforced by selecting a stronger material or by redesigning it with thicker characteristics.
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={ForceAnalysis} alt="Force Analysis" width="55%"/>
                            </div>                     
                            </p>
            </div>
    )
}