import HeatExchangersImg from '../../Images/HeatExchangersImg.jpg'

export default function HeatEx(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Heat Exchangers</h1>
               <p>
                                
 <h2>Intro to Heat Exchangers;</h2>                               
Heat exchangers are devices which transfer thermal energy between two fluids and are typically classified according to flow arrangement
 and type of construction. Typical considerations include size, design, economics and temperature differences. Turbulence within heat exchangers
  is often desirable since it mixes up the fluid and thus causes the most heat exchange.            
                        <br />
                        <br /> 
                        <h2>Types of Heat Exchangers;</h2>
Counter Flow Exchanger: best performer but difficult to manufacture.
<br /> 
Cross Flow Exchanger: not as good as counter flow but easier to manufacture.
<br /> 
Cross Counter Flow Exchanger: good compromise with moderate performance and manufacturing.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={HeatExchangersImg} alt="Heat Exchangers" width="55%"/>
                                </div>
                
                        </p>
            </div>
    )
}