import HardDriveImg from '../../Images/HardDriveImg.jpg'

export default function Drives(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Hard Drives</h1>
               <p>
                                
           
                     
                        <h2>Intro to Drives;</h2>
The hard drive serves as the main storage center for the computing device. Unlike RAM, the hard drive saves data even when the machine is
 powered off. Saved documents and applications are stored on the hard drive using magnetism to “write” onto the drive. 
 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={HardDriveImg} alt="Hard Drive" width="45%"/>
                                </div>
                        </p>
            </div>
    )
}