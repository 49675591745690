import InductorImg from '../../Images/InductorImg.jpg'

export default function Inductors(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Inductors</h1>
               <p>
                                
      
                        <h2>Intro to Inductors;</h2>
The basic role of an inductor in electronics is to prevent surges in current from flowing through it. Under AC conditions, inductors can
 also be used to block high frequency signals while allowing low frequency signals through. This can be useful as selecting the proper
  inductance value can allow you to block radio frequency and electromagnetic interference.
  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={InductorImg} alt="inductor" width="55%"/>
                                </div>
                        </p>
            </div>
    )
}