import Fatigue from '../../Images/FatigueImg.jpg';


export default function Impact(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
                     {/* <SectionTitle title={props.topicId}/> */}
                     <h1>Impact and Fatigue</h1>
                     <p>
                            
<h2>Intro to Impact and Fatigue;</h2>
                            The other sections have so far dealt with static loading, <span style={{ fontWeight: 'bold' }}>impact</span> also called shock, is a case of dynamic loading, which means it happens very rapidly. Hitting a nail with a hammer, cars colliding, or stubbing your toe are all examples of impact loading. Different materials can behave in different ways given the case of impact. Very ductile materials may bend or absorb the energy while more brittle materials might instantaneously shatter or small pieces may flake. Proper assemblies should consider the effects of a sudden impact and look to mitigate potential damage if necessary.
                            <br />
                            <br />
                            With all forms of object stresses, materials often undergo a range of forces and with each fluctuation, microscopic changes can and will propagate inside of the material. If you think of bending a paperclip over and over, eventually it breaks. That’s <span style={{ fontWeight: 'bold' }}>fatigue</span> in action. As the word fatigue suggests, you can think about this phenomenon as if the material is getting tired and after too many cycles, it fails. 
                            <br />
                            <br />
                            Every object and material has something known as a <span style={{ fontWeight: 'bold' }}>fatigue curve</span> and as long as you design your system such that the stresses stay underneath the curve, then the material will be able to undergo an infinite amount of cycles. If your stresses climb above the curve, inevitably it will reach its limit and break in accordance to the microscopic fracturing which formed.  	
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={Fatigue} alt="Fatigue" width="55%"/>
                            </div>                           
                            </p>
            </div>
    )
}