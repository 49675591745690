import React from 'react';
import Subtopics from './Subtopics';
import { Container } from "react-bootstrap";
import {
    Switch,
    Route,
    Link,
    useRouteMatch
  } from "react-router-dom";

function Programming(props) {
    let { path, url } = useRouteMatch();
    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', maxWidth: "100vw"}}>
                {/* <SectionTitle title={props.topicId}/> */}
            <div style={{display: 'flex', flexDirection: 'row'}}> 
            <div style={{marginTop: '10px', marginRight: '0px', marginLeft: '5px',border: '3px', padding:'40px', paddingRight: '0px', borderStyle:'solid', borderColor:'grey', paddingLeft: '20px', whiteSpace:'nowrap'}}>
                <h2 style={{whiteSpace:'normal', paddingRight:'10px'}}>Programming</h2>
   
                <li>
                    <Link to={`${url}/html`}>HTML</Link>
                </li>    
                <li>
                    <Link to={`${url}/css`}>CSS</Link>
                </li>     
                {/* <li>
                    <Link to={`${url}/cplusplus`}>C++</Link>
                </li>     
                <li>
                    <Link to={`${url}/javascript`}>Javascript</Link>
                </li>  
                <li>
                    <Link to={`${url}/reactjs`}>React.js</Link>
                </li>   
                <li>
                    <Link to={`${url}/ros`}>ROS</Link>
                </li>              */}
                </div>
                
            <Container>
                <Switch>
                    <Route exact path={path}>
                    <h3>Please select a sub-topic.</h3>
                    </Route>
                    <Route path={`${path}/:topicId`}>
                        <Subtopics />
                    </Route>
                </Switch>
            </Container>
         
                    </div>
            </div>
        </div>
    );
};

export default Programming; 