import SilkScreenPrintingImg from '../../Images/SilkScreenPrintingImg.jpg'

export default function ScreenPrinting(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Silk Screen Printing (Serigraphy)</h1>
               <p>
                                
           
                   <div div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                   <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={SilkScreenPrintingImg} alt="Silk Screen Printing" width="100%"/>
                                </div>
                                <div style={{width:'40%'}}>
                                <h2>Intro to Screen Printing;</h2>

Silkscreen printing, screen printing or serigraphy is a technique used to transfer ink, paint or dye onto a surface. The technique generally occurs in two stages. First a mesh or screen is placed on the desired surface. The mesh will have certain areas impenetrable to the ink such that when a blade is run across it, the ink fills only in the desired locations (the empty cavities). On the reverse sweep, the blade will cause the screen and surface to touch to transfer the ink and imprint the design. A single color can be printed at a time but multiple colors can be used to produce colored images.
<br />
                        <br /> 
Although silk was the common material of use for the mesh, synthetic threads have replaced it in the modern world. Polyester, nylon and stainless steel are the most common mesh materials used today.

                                </div>
                   </div>
                       <br />
                        <br /> 
<h2>Advantages of Screen Printing;</h2>
Screen printing can scale for large runs with huge amounts of prints such as t-shirt printing.
<br />
The cost vs quality ratio is also very good and can radically lower as the number of prints increases.
<br />
Screen printing allows you to perfectly replicate a design.
<br />
The printing method allows for long lasting effects with minimal fading and wear.
<br />
The printed surface can be washed many times without damaging the image.
<br />
                        <br /> 
<h2>Surfaces for Screen Printing;</h2>
Thermoplastic coatings are often dried by air which can cause them to be softer and more accepting of ink deposits. Thermosets on the other hand are much more durable when bonded to metal through curing.
<br />
                        <br /> 
<h2>Ink Types for Screen Printing;</h2>
<span style={{ fontWeight: 'bold' }}>Air-Dried Solvent Ink:</span> This type of ink does not require air curing which makes it perfect for metal surfaces that are flexible and will have to withstand the outdoors.
<br />
<span style={{ fontWeight: 'bold' }}>Heat-Cured Solvent Ink:</span> These inks need to be baked for a long time to form a secure bond. These include epoxies, polyester, enamels and acrylics.
<br />
<span style={{ fontWeight: 'bold' }}>UV Inks:</span> This type of ink undergoes chemical bonding which makes them extra resistant to scuffing and scratches.


                        </p>
            </div>
    )
}