import React from "react";
import {
//   BrowserRouter as Router,
  useParams,
} from "react-router-dom";
import Belts from './Belts';
import Pulleys from './Pulleys';
import Geartrains from './Geartrains';
import Linearmotion from "./Linearmotion";
import Linkages from "./Linkages";
import Clutches from "./Clutches";
import Cams from "./Cams";
import Leveling from "./Leveling";
import HeatEx from "./HeatEx";
import RobotArms from "./RobotArms";
import Flexure from "./Flexure";

const ChangeComponents = {
    belts: Belts,
    pulleys: Pulleys,
    geartrains: Geartrains,
    linearmotion: Linearmotion,
    linkages: Linkages,
    clutches: Clutches,
    cams: Cams,
    leveling: Leveling,
    heatex: HeatEx,
    robotarms: RobotArms,
    flexure: Flexure,
  };

export default function Subtopics(props) {
    // The <Route> that rendered this component has a
    // path of `/topics/:topicId`. The `:topicId` portion
    // of the URL indicates a placeholder that we can
    // get from `useParams()`.
    let { topicId } = useParams();
    
    let MyComponent = ChangeComponents[topicId];
    return (
        <div>
            {/* {topicId} is for testing route names */}
            {/* <h3>{topicId}</h3> */}
            <MyComponent topicId={topicId}/>
      </div>
    );
  }