import ElementsImg from '../../Images/ElementsImg.jpg'

export default function HTML(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>HTML</h1>
               <p>
                                
           
                        
                        <h2>Intro to HTML;</h2>
                        HyperText Markup Language (HTML) provides structure to the content (text, pictures, links and videos) of a website. An element in HTML defines the type of content and almost always is composed of an opening and closing tag. Elements can be nested within other elements which creates a hierarchy within the code.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ElementsImg} alt="Variables" width="35%"/>
                                </div>
            
                        </p>
            </div>
    )
}