import EpoxyStickingImg from '../../Images/EpoxyStickingImg.png'


export default function EpoxyResin(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Epoxy Resin</h1>
               <p>

<h2>Overview;</h2>
Epoxy resin is a mixture of a resin and a hardener that forms a chemical reaction when combined which hardens the liquid resin into a clear, high gloss solid. Color pigments and other additives can also be added to produce brilliance and depth into your material. It is important to use a high grade epoxy which contains a UV filter to protect against yellowing from sunlight exposure. It is important to not mix water with your epoxy as it will create a milky coloration and prevent the epoxy from properly curing.
<br /> 
<br /> 
<h2>Color Pigments;</h2>
Mica is a natural stone mineral which is often found in makeup and artwork. The fine powder is available in a range of colors and can be added to bring a variety of color to the resin. Ink, liquid acrylic paints and other color powder pigments can also be used.
<br /> 
<br /> 
<h2>Air Bubbles;</h2>
Air will naturally begin to rise from epoxy as it cures which can reduce the quality of your part. Mixing regularly and applying heat is a great way of removing these air bubbles. Hold a butane torch or electric air gun four inches above the workpiece and move rapidly across the surface for best results.
<br /> 
<br /> 
<h2>Vacuum Chamber;</h2>
When using a vacuum chamber, close the outlet port of the main chamber and open the inlet port which connects to the vacuum pump. Allow the pump to run until you reach a pressure of roughly 28 bar. Keep the epoxy within this environment for at least ten minutes so that maximum air removal occurs. Once you turn off the machine and open the main outlet port, the epoxy may have froth or bubbles at the top surface. Use a heat gun to dissipate the bubbles and raise the temperature of the epoxy. Note that adding heat into the system will make it cure faster.
<br /> 
<br /> 
<h2>Directions for Creating Epoxy;</h2>
Prepare your materials in a clean, dust-free and well-ventilated room. The temperature of this room should remain constant.
<br /> 
Build the enclosure for the epoxy to cure in a way that the shape remains constant as the epoxy pushes against the walls of the enclosure. 
<br /> 
Place this enclosure on a horizontal surface.
<br /> 
Equip disposable nitrile gloves and long sleeve apparel. Safety goggles and mask advised.
<br /> 
Mix the correct ratio of epoxy and hardener for three minutes. Failure to properly mix will result in the epoxy never fully curing and remaining sticky.
<br /> 
Color your resin in disposable cups. Note that a little pigment is often sufficient for color.
There are a variety of pouring techniques such as Puddle Pour, Dirty Pour, Flip Cup, Swirl, Air Swipe etc, use these to create the design you desire within your enclosure.
<br /> 
Cover (not direct contact) your piece and allow it to cure for the time your specific epoxy calls for.
<br /> 
If your design includes multiple layers of depth, wait until the resin is hardened enough (time required depends on resin used) and roughen the top layer with sandpaper before applying your next coat.
<br /> 
<br /> 
<h2>Epoxy Mold Design;</h2>
When designing a mold for epoxy, the number one consideration is to ensure that the epoxy comes in contact with a material that it will not stick to to ensure easy mold and part disassembly. Ensure that the walls of your mold has proper reinforcement to prevent it from warping under the pressure of the curing epoxy.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={EpoxyStickingImg} alt="Epoxy Contact Materials" width="40%"/>
                                </div> 
<h2>Epoxy Mixing and Pouring Advice;</h2>
Use a lint free cloth to make sure your container has been swept over
<br /> 
Be precise with the mixing ratio, epoxy is often highly sensitive
<br /> 
Cloudy white area or little white lines are signs that those areas have not been properly combined
<br /> 
Whipping when mixing can create air bubbles which is bad
<br /> 
Don’t mix more than 4 liters at once until you have run through the process
<br /> 
Always scrape the sides and mix in when mixing the two substances
<br /> 
When pouring the mix into the mold, do not scrape in every drop, there is bound to be an unmixed drop somewhere
<br /> 
Curing epoxy creates heat, fans blowing across the surface is highly advised
<br /> 
Refer to the datasheet for amount of time to wait between pours and how deep a pour you can do at once
<br /> 
When doing multiple pour, if the previous layer is no longer tacky, sand down with 220 grit sandpaper and wipe down
<br /> 
Epoxy may shrink as it cures, be prepared to add more if needed
<br /> 
Look at the data sheet to see at what temperature range the supplier recommends
<br /> 
<br /> 
<h2>Sanding;</h2>
120: 5 passes
<br /> 
180: 5 passes
<br /> 
240: 5 passes
<br /> 
400: 7 passes
<br /> 
800: 7 passes
<br /> 
1200: 4 passes with mist spray bottle of water between each pass
<br /> 
Polish with hard pad and polish cream: 3 passes
<br /> 
Polish with soft pad and polish cream: 2 passes
<br /> 
<br /> 
5 passes going right to left followed up to down, overlapping about 50% each pass with each pass taking 10 minutes
<br /> 
<br /> 
Wipe away the dust from the surface between each 10 minute pass with a lint free cloth or shop towel
<br /> 
<br /> 
Note that wood will sand faster than epoxy and you can go faster over those areas
<br /> 
<br /> 
You can use a marker to draw on the surface between each pass to see if you are removing enough material, if you are not removing enough you may be going too fast or are not applying enough downward pressure
<br /> 
<br /> 
If your sanding disk is clogged it can damage the surface
<br /> 
<br /> 
You can use one sanding pad of each grit for all the passes of that grit
                     
                        </p>
            </div>
    )
}