import FerrousImg from '../../Images/FerrousImg.jpg'

export default function FMetals(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Ferrous Metals</h1>
               <p>
                               <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                               <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={FerrousImg} alt="Ferrous Table" width="100%"/>
                                </div>
                                <div style={{width:'80%'}}>
                                <h2>Intro to Ferrous Metals</h2>           
                  
                  Iron is created by removing oxygen and other impurities from iron ore. By adding carbon and small amounts of other elements it can become steel. The ratios and elements used in the process can have enormous impacts on the material characteristics resulting in more than 3500 types of steel today. 
                  <br />
                  <br />
Many elements have natural chemical processes caused by their internal physics and steel is no exception. The contact between steel, oxygen and water causes the metal to begin to revert back to its natural form iron oxide. In the modern era we know this process as rusting. Thankfully coats or nickel or chrome can be plated atop of steel to create stainless steel and prevent this issue. Alternative methods include coating steel in paint, such as with automobiles or enamel like we see with refrigerators.
<br />
<br />
When a medical part is needed, the most common material used is the 300 series of stainless steels. These chrome-steel alloys are non-corrosive, can have a range of surface finishes and have exceptional mechanical properties. It also helps that they are relatively easy to machine and manufacture. 

                                </div>
                               </div>

<h2>Stainless Steel</h2>
The choice between the grades of steel are often based on corrosion requirements, formability and availability in the necessary thickness or temper. Stainless steels also have the ability to be coldworked to high tensile and yield strengths while preserving their ductility and toughness.
<br />
<br />
At room temperature, you can expect yield strengths between 30 to 200 ksi depending on the amount of cold work the material experienced and the compositions. 
<br />
<br />
Many people suffer from nickel allergies so low-nickel varieties may be needed for parts that come into contact with humans. Most surgical tools are made from the 400 series which are not as corrosion resistant but can be heat treated to have very sharp edges. 
<br />
<br />
<h2>Carbon Steels</h2>
This group is categorized by having only trace amounts of other elements besides carbon and iron. These account for roughly 90% of the steel production worldwide. Carbon steels are further divided by their carbon content in the following three groups.
<br />
Low Carbon/Mild Steel: up to .3% carbon
<br />
Medium Carbon Steel: .3 to .6% carbon
<br />
High Carbon Steel: more than .6% carbon
<br />
<br />
<h2>Alloy Steels</h2>
This family of steels may contain elements such as aluminum, nickel, copper, chromium within. These additions are used to modify the materials strength, ductility, corrosion resistance and machinability.
<br />
<br />
<h2>Tool Steels</h2>
These steels are combined with elements such as tungsten, molybdenum, cobalt or valadium to increase heat resistance and durability.                      

                        </p>
            </div>
    )
}