import React from 'react';
import Subtopics from './Subtopics';
import { Container } from "react-bootstrap";
import {
    Switch,
    Route,
    Link,
    useRouteMatch
  } from "react-router-dom";

function Hardware(props) {
    let { path, url } = useRouteMatch();
    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', maxWidth: "100vw"}}>
                {/* <SectionTitle title={props.topicId}/> */}
            <div style={{display: 'flex', flexDirection: 'row'}}> 
            <div style={{marginTop: '10px', marginRight: '0px', marginLeft: '5px',border: '3px', padding:'40px', paddingRight: '0px', borderStyle:'solid', borderColor:'grey', paddingLeft: '20px', whiteSpace:'nowrap'}}>
                <h2 style={{whiteSpace:'normal'}}>Computer Hardware</h2>
                <li>
                    <Link to={`${url}/systems`}>Computer Systems</Link>
                </li>
                <li>
                    <Link to={`${url}/cpu`}>CPU</Link>
                </li>
                <li>
                    <Link to={`${url}/gpu`}>GPU</Link>
                </li>
                <li>
                    <Link to={`${url}/drives`}>Drives</Link>
                </li>  
                <li>
                    <Link to={`${url}/motherboards`}>Motherboards</Link>
                </li>   
                <li>
                    <Link to={`${url}/power`}>Power Supplies</Link>
                </li>  
                <li>
                    <Link to={`${url}/expansion`}>Expansion Devices</Link>
                </li>              
                </div>
                
            <Container>
                <Switch>
                    <Route exact path={path}>
                    <h3>Please select a sub-topic.</h3>
                    </Route>
                    <Route path={`${path}/:topicId`}>
                        <Subtopics />
                    </Route>
                </Switch>
            </Container>
         
                    </div>
            </div>
        </div>
    );
};

export default Hardware; 