import BasicGatesImg from '../../Images/BasicGatesImg.jpg'

export default function Logic(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Logic Operations</h1>
               <p>
                                
           
           <h2>Intro to Logic States and Binary;</h2>
In Boolean logic, the symbols of 1 and 0 are used to denote a high or low voltage input which then forms the foundation of computer engineering. The binary system uses the electronic 0s and 1s which are called bits to form the bases for number tracking. By implementing these 1s and 0s into combinations, various gate formations can be created which decide the output based on input requirements.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={BasicGatesImg} alt="Basic Gates" width="75%"/>
                                </div>
                        </p>
            </div>
    )
}