import { Container } from "react-bootstrap";
import styles from './Lesson.module.css';
// import Video from '../../components/Video';
// import Module from '../../components/Module';
// import Collapsable from '../../components/Collapsable';
import SectionTitle from "../../components/SectionTitle";

function Lesson({ lessonTitle, links, content}) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', maxWidth: "100vw"}}>
    <SectionTitle title={lessonTitle}/>
    <div style={{display: 'flex', flexDirection: 'row'}}> 
    <div className={styles.quicklinks}>
          <h4>Quick Links</h4>
          {links}

    </div>
        <Container >
   
      <div className={styles.content}>
        <div>
            {/* <Video title="Video"/> */}
            </div>
            {content}
          </div>
        </Container>
    </div> 
</div>

  );
}

export default Lesson;