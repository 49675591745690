import UltrasonicImg from '../../Images/UltrasonicImg.jpg'

export default function UltrasonicSensors(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Ultrasonic Range Sensors</h1>
               <p>

                        <h2>Intro to Ultrasonic Range Sensors;</h2>
                        An ultrasonic range finder consists of a transmitter, receiver and control circuits. When activated, the transmitter
                         will send out a series of pulses using the transmitter which can then bounce off objects and return to the receiver. 
                         The distance between the object and the sensor is determined by relating the traveling time of the sound as the width
                          of the pulse.
  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={UltrasonicImg} alt="ultrasonic range sensor" width="40%"/>
                                </div>
                        </p>
            </div>
    )
}