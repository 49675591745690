import FreeBodyDiagram from '../../Images/FreeBodyDiagramImg.jpg'


export default function Freebody(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Free Body Diagrams</h1>
               <p>
<h2>Intro to Free Body Diagrams;</h2>
                        Perhaps the most widely used analysis method within mechanical engineering is the <span style={{ fontWeight: 'bold' }}>free body diagram</span>. The FBD serves as a graphical representation to visualize every force acting on a specific object. These forces include gravity, linear forces, moments, torques, normal forces, friction and anything else that’s relevant. 
                        <br />
                        <br />    
                        When constructing a free body diagram, it is crucial to remember that this is meant as a tool to identify forces and not as a method for doing stress or force calculations. Calculating actual forces is widely done using force simulation software but to use these programs you have to know what forces are acting on your object. 
                        <br />
                        <br />
                        The following is a guideline for constructing free body diagrams, 
                        <br />
                        <br />
                        1. Draw the simplest shape possible that represents the overall structure of an individual component within your system.
                        <br />
                        2. Draw straight lines for linear forces and curved lines around dots for moments and torques.
                        <br />
                        3. Draw a reference coordinate system so you can relate the forces to an independent x,y,z axis.
                        <br />
                        4. If your component is moving, draw the direction using a dashed arrow.
                        <br />
                        <br />
                        Note that when drawing force vectors, it is helpful to include the direction of application, the point of application, the magnitude and the reaction. Every object on our planet is subject to gravity, so be sure to include a downwards gravitational force acting on the center mass of your object. Unless the object is in free-fall, you should also add the reaction force (called the normal force) at the location where the object is pushing back. The gravitational acceleration at the surface of the earth is roughly 9.81 meters/ second^2 (metric) or 32.17 feet/second^2 and if you multiply either by the mass of the object, you will get the gravitational force, since F=ma.
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={FreeBodyDiagram} alt="Free Body Diagrams" width="55%"/>
                                </div>
                        </p>
            </div>
    )
}