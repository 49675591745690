import LinearFunctionsImg from '../../Images/LinearFunctionsImg.jpg'

export default function Linear(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Linear Functions</h1>
               <p>
                              
           <h2>Intro to Linear Functions;</h2>
           A linear function is one which can be organized into the following form; y=mx+b. The slope of the line, represented by the variable (m) , is equal to the change in (y) over the change in (x); (Δy/Δx). The vertical shift, shown where the line crosses the y axis is equal to the variable (b).                  
           <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={LinearFunctionsImg} alt="Linear Functions" width="65%"/>
                                </div>
                        </p>
            </div>
    )
}