import CollisionsImg from '../../Images/CollisionsImg.jpg'

export default function Collisions(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Collisions</h1>
               <p>
                               
           
    <h2>Intro to Collisions</h2>
                        The term collision is used in physics to describe two objects whose motion interacts during a short period in time. All
                         collisions in a closed system conserve momentum, the changes in velocity, direction and momentum are determined by several 
                         factors including mass. These factors will ultimately determine if the kinetic energy of the system is conserved as well. 
                         <br />
                        <br /> 
Collisions are known as elastic if they conserve both momentum and kinetic energy and inelastic if they conserve only momentum. Inelastic collisions
 are often called plastic collisions. The three types of collisions are,
 <br /> 
1. Perfectly Elastic
<br /> 
2. Inelastic
<br /> 
3. Perfectly Inelastic
<br />
                        <br /> 
The coefficient of restitution (e) is a ratio which measures the initial to the final velocities of the objects and ranges from zero to one. A
 perfectly elastic collision has a ratio of 1, since all kinetic energy is conserved and a perfectly inelastic collision has a ratio of 0, since
  all is lost. In the case of a perfectly inelastic collision, the two objects undertake identical paths after the moment of impact. With a 
  perfectly elastic collision though, both objects are repelled without a loss to their velocities and thus kinetic energy.

  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={CollisionsImg} alt="collisions" width="65%"/>
                                </div>
                        </p>
            </div>
    )
}