
import Bolts from '../../Images/BoltsImg.jpg'
import Washers from '../../Images/WashersImg.jpg'
import VSA from '../../Images/VSAImg.jpg'

export default function Fasteners(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Fasteners</h1>
               <p>
                            <h2>Intro to Fasteners;</h2>
                            When designing an assembly, one of the earliest decisions a person will have to make is whether you want to use permanent or impermanent joining methods. Screws, pins, washers, nuts and bolts can easily be undone while adhesives, welding, brazing, soldering and rivets are meant to hold your system together forever. If you are prototyping on a budget, impermanent assemblies are most likely your best bet.
                            <br />
                            <br /> 
                            When browsing a catalogue for such things like screws and bolts it is easy to become overwhelmed with the vastness of the choices available. The most important characterization of these fasteners is what tool is used to tighten them and what application they are being used for. Some people prefer to use hex keys for turning while others prefer a screwdriver. What you need to consider is that repeated use of a fastener often causes damage and “strips” the material which allows turning. In a worse case scenario, a stripped screw can become stuck in an object with no way to remove it.
                
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={Bolts} alt="Types of Common Screws and Bolts" width="55%"/>
                            </div>
                      
                            If you know that you will be putting the fastener under repeated or high stress, then consider using something like a hex head screw whose entire exterior is what is grabbed on to. <span style={{ fontWeight: 'bold' }}>Socket head screws</span> are also commonly reliable. Mainly, it is important to observe how much material will be put under pressure and then choose the right fastener for the job.
                            <br />
                            <br /> 
                            <span style={{ fontWeight: 'bold' }}>Set screws</span> (also sometimes called grub screws) are an extremely useful type of screw which borrows into a material and allows you to secure an object against another object. 
                            <br />
                            <br /> 
                            Unless the material is high grade steel, we highly advise against using <span style={{ fontWeight: 'bold' }}>flat head screws</span> as they are one of the most common screws to strip and their flatness does not allow many ways to remove the screw if that happens.
                            <br />
                            <br /> 
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={Washers} alt="Types of Common Washers" width="55%"/>
                            </div>
                    
                                A <span style={{ fontWeight: 'bold' }}>washer</span> is a thin, often disk-shaped plate with a central hole used to evenly distribute force between a fastener and a material’s contact area. <span style={{ fontWeight: 'bold' }}>Sealing washers</span> can be used in applications that involve fluids to form a seal between the fastener and the contact area, preventing fluids from leaking around your fastener.
                            <br />
                            <br /> 
                            <span style={{ fontWeight: 'bold' }}>Lock washers</span> on the other hand, have a natural locking feature to resist loosening from vibrations. When using motors or dynamic systems, always consider that most screws and bolts will loosen over time and by placing a lock washer between a standard washer and your fastener, your assembly will remain tight indefinitely.
                 
                         
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={VSA} alt="Vibration Safe Assortment" width="40%"/>
                            </div>
                            </p>
            </div>
    )
}