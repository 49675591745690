import ImpactTestingImg from '../../Images/ImpactTestingImg.jpg'
import FractureEquationImg from '../../Images/FractureEquationImg.PNG'

export default function Failure(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Material Failure</h1>
               <p>
       
                        <h2>Intro to Material Failure;</h2>
A material fracture is defined as the fragmentation of separation of an object while under stress resulting in the formation of two or more new surfaces. Cracks initiation and propagation is the process which leads to this fracture. Materials can differ between brittle and fast fractures where objects fail with little to no plastic deformation and ductile fractures where plastic deformation occurs before and during the fracture process.
<br />
                        <br /> 
Typical loading conditions do not often provide enough energy to break interatomic bonds. The main reason for material failure is the hidden defects and flaws which concentrate stresses at certain locations and make them high enough to rupture. 
<br />
                        <br /> 
The common steps in tensile stress fractures is as follows:
<br /> 
1. Necking
<br /> 
2. Formation of microvoids
<br /> 
3. Coalescence of microvoids to form a crack
<br /> 
4. Formation of elliptical crack
<br /> 
5. Shearing and separation into fracture
<br />
                        <br /> 
Cleavage: Cleavage is the breaking of atomic bonds along a specific crystallographic plane. Work is required to expand the amount of internal cracks. 
<br />
                        <br /> 
Transgranular Fractures: These are fracture cracks which pass through the grains. The fracture surface will have multiple grain surfaces because of the different orientations of granular planes which have been split.
<br />
                        <br /> 
Intergranular Fracture: This type of fracture occurs along a grain boundary and often has a single granular plane as its new surface after breaking.
<br />
                        <br /> 
<h2>Fracture Toughness;</h2>
Fracture toughness is the resistance of a material to brittle fracture when a crack is present. The fracture toughness equation provides a method of evaluating the max allowable stress for a given flaw size. The variable Y in the equation is a dimensionless factor dependent on the geometry of the crack, the type of material, the loading profile and the ratio of the crack length to the specimen width. 
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={FractureEquationImg} alt="Fracture Equation" width="25%"/>
                                </div>   
<h2>Impact Testing;</h2>
Impact energy is the energy required to fracture a test specimen under impact loading conditions (often called notch toughness). Some materials fluctuate between ductile and brittle as you increase temperature. The reason for this behavior is due to some materials undergoing an increase in shear strength from changes in temperature which in turn makes it too difficult for dislocations to move. Peierls Stress is the stress required to move a dislocation from one point to another. 
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ImpactTestingImg} alt="Impact Testing" width="45%"/>
                                </div>                      
                        </p>
            </div>
    )
}