import Planes from '../../Images/PlanesGcode.jpg'

export default function Additional(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Additional Commands</h1>
               <h2>G17/ G18/ G19: Set the Working Plane</h2>
               <p>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={Planes} alt="linkages" width="30%"/>
                                </div>
           
                       
                       
                        G17: XY Plane
                        <br /> 
G18: XZ Plane
<br />
G19: YZ Plane                   
<br />
G17 is the default setting in most machines, but the other two planes can be used to accomplish specific tasks.

                        </p>
                <h2>Return Home</h2>
                <p>
                This command instructs the machine to return home from its current position using a straight line. To avoid tool collision, intermediate points can be added to the command line. The machine in that case take a direct path between the series of input locations.
                <br />
                <br />
                  G28.1 X## Y## Z##: Define Home Position  
                    </p>
              
                <h2>G90/G91: Set Mode between Absolute and Relative Mode</h2>
                <p>
                Absolute mode sets the basic home coordinates to always be (0,0,0). In this configuration, the machine will
                 interpret new points such as (10,5) as 10 units right and 5 units up from the origin (0,0). Relative mode on the 
                 other hand uses your current location as the origin. This would mean that a command (10,5) would go 10 units right 
                 and 5 units up from its last coordinate point.    
                    </p>
                      
            </div>
    )
}