// import Module from '../../components/Module';
import NewProject from '../../components/NewProject';
// import styles from './Workbench.module.css';
// import Workbenchpage from '../../images/Workbenchpage2.jpg';
import app from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import React, {useState, useEffect} from 'react'

function Workbench(props) {
    const { currentUser } = useAuth()
    var [projectObjects, setProjectObjects] = useState([])
    var [currentId, setCurrentId] = useState('')

    useEffect(() => {
        app.child('projects').on('value', snapshot => {
            if (snapshot.val() != null)
                setProjectObjects({
                    ...snapshot.val()
                })
            else
                setProjectObjects({})
        })
    }, []);

    const addOrEdit = obj => {
        if(currentId === '')
        app.child('projects').push(
            obj,
            err => {
                if (err)
                    console.log(err)
                else
                    setCurrentId('')
            }
        )
        else
        app.child(`projects/${currentId}`).set(
            obj,
            err => {
                if (err)
                    console.log(err)
                else
                    setCurrentId('')
            }
        )
    }


    const onDelete = id => {
        if (window.confirm('Are you sure you want to delete?')) {
            app.child(`projects/${id}`).remove(
                err => {
                    if (err)
                        console.log(err)
                    else
                        setCurrentId('')
                }
            )
        }
    }



  
    return (
        <div >
            {/* <div className={styles.boldheader}>
                <img src={Workbenchpage} alt="Workbenchpage" width="80%" />
            </div> */}

            <div className="jumbotron jumbotron-fluid" >
                <div className="container">
                    <h1 className="display-4 text-center">Create a Project</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-md-5">
                    <NewProject {...({addOrEdit, currentId, projectObjects})}/>
                </div>
      
            <div className="col-md-7">
                <table className="table table-borderless table-stripped">
                    <thead className="bg-secondary text-white">
                            <tr>
                            <th>Image</th>
                            <th>Project</th>
                            <th>Description</th>
                            <th>GitHub Link</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                        <tbody>
                            {/* if(projectObjects[id].userName == currentUser.email) */}
                        {
                                Object.keys(projectObjects).map(id => {
                          
                                    if (projectObjects[id].userName === currentUser.email) {
                                        
                                        return <tr key={id}>
                                    <td>{projectObjects[id].image}<img src={projectObjects[id].image || "https://via.placeholder.com/40x30"} alt="image tag" /></td>
                                    <td>{projectObjects[id].projectName}</td>
                                    <td>{projectObjects[id].description}</td>     
                                    <td><a target="_blank" rel="noreferrer" href={projectObjects[id].gitHub}>{projectObjects[id].gitHub}</a></td>
                                    <td>
                                      
                                        <button className="btn btn-primary m-1" onClick={() => {setCurrentId(id)}}>Edit</button>
                                      
                                        <button className="btn btn-danger " onClick={() => { onDelete(id) }}>Delete</button>
                     
                                
                                    </td>
                                </tr>
                                }
                
                            
                            })
                        }
                    </tbody>
                    </table>
                    
                </div>
                <div className="jumbotron jumbotron-fluid" >
                    <h1 className="display-2 text-center">Open Projects</h1>
                </div>
                <table className="table table-borderless table-stripped m-4">
                    <thead className="bg-secondary text-white m-4">
                        <tr>
                            <th>Image</th>
                            <th>All Open Projects</th>
                            <th>Description</th>
                            <th>File</th>
                           
                        </tr>
                    </thead>
                        <tbody>
        
                        {
                                Object.keys(projectObjects).map(id => {
                                    if (projectObjects[id].private !== true) {
                                        return <tr key={id}>
                                              <td>{projectObjects[id].image}<img src={projectObjects[id].image || "https://via.placeholder.com/40x30"} alt="image tag" /></td>
                                            <td>{projectObjects[id].projectName}</td>
                                            <td>{projectObjects[id].description}</td>
                                            <td><a target="_blank" rel="noreferrer" href={projectObjects[id].gitHub}>{projectObjects[id].gitHub}</a></td>
                                
                                        </tr>
                                    }
                
                            
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>

        

    )
}
export default Workbench; 