import HydroConnectorsImg from '../../MEComponents/HydroConnectorsImg.jpg'

export default function Tubing(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Intro to Tubing and Hoses;</h1>
               <p>
                            
The basic function of hoses and tubing is to convey fluid pressure between components in an assembly. Tubing is typically understood to consist
 of a single cylinder of material while hoses often have fiber reinforcement and an outer protective layer. Tubing will often be specified by
  outer diameter and wall thickness while hoses are specified by their inside diameter. 
<br />
<br /> 
The supply, fluid, required performance and application will go into determining the component needed. Choosing a component with too small of a
 diameter can result in pressure loss, inefficiency and excessive fluid velocity which can shorten the service life of the part. A diameter too 
 large however results in higher than necessary weight, size and cost. Two important physical characteristics to consider are flexibility and kink
  resistance.
<br />
<br /> 
<h2>Design Considerations;</h2>
Always ensure that the hose line has sufficient dip to compensate for movement during operation. Make sure the hose is not twisted and that
 the pathway has plenty of rounded corners for the fluid to flow. Avoid sharp turns and corners.                       
<br />
<br /> 
<h2>Polyurethane Tubing;</h2>
Strong
<br />
Flexible
<br />
kink/abrasion resistant
<br />
Withstands contact with fuels and oils
<br />
<br />
<h2>Nylon Tubing;</h2>
Tough
<br />
Light
<br />
Dimensionally stable
<br />
Can be made for higher pressure
<br />
Can be rigid or flexible
<br />
High flexural fatigue resistance
<br />
Low water absorption
<br />
<br />
<h2>Polyethylene Tubing;</h2>
For low pressure
<br />
High resistance to chemicals and solvents
<br />
Good flexibility
<br />
Low cost
<br />
<br />
<h2>PVC Tubing;</h2>
Light
<br />
More flexible than nylon and polyethylene
<br />
Can be repeatedly sterilized
<br />
Good chemical resistance
<br />
Low pressure medical applications FDA
<br />
Typically clear
<br />
<br />
<h2>Polypropylene Tubing;</h2>
FDA food approved
<br />
Resistant to chemical attack
<br />
Resistant to UV
<br />
<br />
<h2>Connectors;</h2>
Pneumatic and hydraulic fittings are distinguished from the connection type and function it performs. Especially in the case with fluids, 
straight threads should be improved by adding a coat of seal tape (teflon). In some cases where contamination or corrosion may occur, use
 tapered threads.
<br />
<br />
Ball and Sleeve Fitting: Easy to connect and disconnect.
<br />
Compression Fitting: Uses gaskets, rings, bite-type, grip and coned-end methods.
<br />
Crimp Fittings: Requires crimping tools to adhere together.
<br />
End Fittings: can use clamps or specific surfacing to adhere.
<br />
Push-to-Connect: Easy to connect and disconnect.
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={HydroConnectorsImg} alt="Hydraulic Connectors" width="75%"/>
                            </div>
                           </p>
            </div>
    )
}