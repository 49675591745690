import AtomicModelImg from '../../Images/AtomicModelImg.jpg'
import BondingImg from '../../Images/BondingImg.jpg'

export default function Structures(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Structures of Matter</h1>
               <p>
                                
           
                 
<h2>Intro to Structures of Matter;</h2>
Electrons = 9.11 x 10^-31 kg
<br />
Atomic Number (Z) = # of protons in nucleus of atom = # of electrons in neutral species
<br />
Atomic Mass Number (A) = 1/12 mass of 12C
<br />
Atomic Weight = 6.022 x 10^23 molecules or atoms
<br />
                        <br /> 
<h2>Bohr’s Atomic Model;</h2>
Atoms lie in discrete, spherical orbitals (n level) around the central nucleus. Each orbital exists at a discrete energy level which is determined by quantum numbers.
<br />
n: Designates electron shells and provides information about the energy and size of shells.
<br />
l: Signifies the subshells in which electrons are present and determines the electron subshell.
<br />
ml: Determines the orientation of electrons in 3D space and provides the number of electron states in each subshell and orbital.
<br />
ms: Provides the spin moment on each electron present in the orbitals (either CW or CCW).
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={AtomicModelImg} alt="AtomicModelImg" width="65%"/>
                                </div>
<h2>Aufbau Principle;</h2>
This principle states that electrons will fill the lowest energy atomic orbitals before occupying the higher energy levels.  
<br />
<br /> 
<h2>Pauli Exclusion Principle;</h2>
This principle states that each individual orbital can only take two electrons. If you think of electrons akin to spinning magnets, they pair together and align opposite to each other. 
<br />
There is one s orbital = 2 electrons.
<br />
There are three p orbitals = 6 electrons.
<br />
There are five d orbitals = 10 electrons.
<br />
There are seven f orbitals = 14 electrons.
<br />
<br /> 
<h2>Hund’s Rule;</h2>
This observation states that if there are multiple orbitals at the same energy, electrons will fill individually first before electrons begin to pair.
<br />
<br /> 
<h2>Valence Electrons;</h2>
Valence electrons are those with unfilled shells which are much less stable than those with filled shells. Since they are unfilled, they are the most available for bonding and have a tendency to control chemical properties. The left side of the periodic table are electropositive elements which eagerly give up electrons to become positive ions while the right of the periodic table has a larger concentration of electronegative elements which acquire electrons to become electronegative ions.
<br />
<br /> 
<h2>Ionic Bonding;</h2>
When a metal and a nonmetal bond, the metal gives an electron to the nonmetal which results in a large bonding energy. This bonding occurs between positive and negative ions with a large difference in electronegativity required. The result is the most stable given the lowest amount of unpaired energy remaining after bonding. 
<br />
<br /> 
<h2>Covalent Bonding;</h2>
Nonmetals have the capacity to bind in impermanence ways with other nonmetals which results in a shared electron bonding structure. This occurs between elements with similar electronegativity and variable energy levels. 
<br />
<br /> 
<h2>Metallic Bonding;</h2>
In metals, a term has risen known as a ‘sea of electrons’ where electrons float within the material, not specifically belonging to any specific atom. 
<br />
<br /> 
<h2>Secondary Bonding;</h2>
Secondary bonding occurs from interactions between oppositely charged dipoles.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={BondingImg} alt="Bonding" width="65%"/>
                                </div>
                        </p>
            </div>
    )
}