import SpringTable from '../../MEComponents/SpringTableImg.jpg'
import Equivalent from '../../MEComponents/EquivalentImg.jpg';
import SpringsImg from '../../MEComponents/SpringsImg.jpg'

export default function Springs(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Springs</h1>
               <p>
                                
           
                      <h2>Intro to Springs;</h2>
                        The invention of coil springs in 1857 revolutionized mechanical engineering with their unique ability to absorb, store and then later release energy. Spring types include <span style={{ fontWeight: 'bold' }}>tension springs, compression springs, torsional springs</span> and <span style={{ fontWeight: 'bold' }}>spiral springs</span>. Tension and compression springs absorb energy linearly while torsional and spiral springs absorb rotational energy. If you have ever used a retractable dog leash, a seatbelt or most retractable mechanisms then you have witnessed the incredible power of the spiral spring in action.
                        <br />
                        <br />    
                        Most importantly when talking about springs is to first establish that the traditional metal coil spring is not your only option if you want to store and release energy. Blocks of rubber, compressed air springs or hydraulic springs can all be used for the same purposes. To some extent, every material in the universe has the ability to store and release energy, although for most materials the amount is extremely low. 
                        <br />
                        <br />
                        Often when designing a system it is advantageous to design components out of more elastic materials instead of designing a rigid system which diverts energy into a physical spring. 
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={SpringsImg} alt="springs" width="65%"/>
                                </div>
                        The most standard spring equation uses three variables, F for force, x for displacement and k for the <span style={{ fontWeight: 'bold' }}>spring constant</span>, which is the measure of how stiff the spring will be. The standard equation and units are defined in the table below. Note that the negative symbol in front of the spring constant is there because a compressive force is considered negative.
                        <br />
                        <br />
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={SpringTable} alt="springs" width="55%"/>
                                    </div>
                    
                        <br />
                                    The second most important concept when using springs is their arrangement, which directly affects the total spring constant. Springs can be arranged as a single spring, as a <span style={{ fontWeight: 'bold' }}>series</span>, or as a <span style={{ fontWeight: 'bold' }}>parallel</span> system.
                                    <br />
                        <br />
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={Equivalent} alt="springs" width="55%"/>
                                    </div>
                   
                        <br />
                                    Aligning a system of parallel springs will result in a much stiffer equivalent spring than by aligning your springs one after another as a series. If you can determine the amount of force which will act on your system, then you can utilize the above equation to choose the spring with the right spring constant to ensure your application will compress or stretch to meet your needs.
                                    
                        </p>
            </div>
    )
}