import GeometryImg6 from '../../Images/GeometryImg6.PNG'
import VectorsImg from '../../Images/VectorsImg.jpg'
import GeometryImg7 from '../../Images/GeometryImg7.PNG'
import GeometryImg8 from '../../Images/GeometryImg8.PNG'
import GeometryImg9 from '../../Images/GeometryImg9.PNG'

export default function Vectors(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Vectors</h1>
               <p>
                                
           
                       
                        <h2>Intro to Vectors;</h2>
Vectors are used to represent quantities that have both a direction and a magnitude. Given two points,each with their own three dimensional coordinates, you can generate a vector using the base vector equation. The magnitude or length of a vector can be determined from the magnitude equation. A vector with a magnitude of 1 is known as a unit vector. Vectors can be added using the vector addition equation. The sum of the vectors is called the resultant. Vectors can also be subtracted using the vector subtraction equation. 
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GeometryImg6} alt="vectors" width="55%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={VectorsImg} alt="vectors" width="55%"/>
                                </div>
<h2>Dot Product;</h2>
The dot product is a scalar (just magnitude, no direction) that represents the projection of vector B onto vector A multiplied by the magnitude of A.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GeometryImg7} alt="vectors" width="55%"/>
                                </div>
<h2>Cross Product;</h2>
Cross product requires two, three-dimensional vectors and generates a vector product that is perpendicular to the plane created by vectors A and B.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GeometryImg8} alt="vectors" width="55%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GeometryImg9} alt="vectors" width="55%"/>
                                </div>
                        </p>
            </div>
    )
}