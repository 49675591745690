import React from "react";
import {
//   BrowserRouter as Router,
  useParams,
} from "react-router-dom";
import Plating from './Plating';
import Polishing from './Polishing';
import AbrasiveBlasting from "./AbrasiveBlasting"
import Corrosion from "./Corrosion";
import Coldforming from './Coldforming';
import Vibratory from "./Vibratory";
import Lubricants from "./Lubricants";
import WorkHardening from "./WorkHardening";
import ScreenPrinting from "./ScreenPrinting";
import WoodFinishes from "./WoodFinishes";

const ChangeComponents = {
    plating: Plating,
    polishing: Polishing,
    abrasiveblasting: AbrasiveBlasting,
    corrosion: Corrosion,
    coldforming: Coldforming,
    vibratory: Vibratory,
    lubricants: Lubricants,
    workhardening: WorkHardening,
    screenprinting: ScreenPrinting,
    woodfinishes: WoodFinishes,
  };

export default function Subtopics(props) {
    // The <Route> that rendered this component has a
    // path of `/topics/:topicId`. The `:topicId` portion
    // of the URL indicates a placeholder that we can
    // get from `useParams()`.
    let { topicId } = useParams();
    
    let MyComponent = ChangeComponents[topicId];
    return (
        <div>
            {/* {topicId} is for testing route names */}
            {/* <h3>{topicId}</h3> */}
            <MyComponent topicId={topicId}/>
      </div>
    );
  }