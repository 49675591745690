import React from "react";
import {
//   BrowserRouter as Router,
  useParams,
} from "react-router-dom";


import Overview from "./Overview";
import Planar from "./Planar";
import Router from "./Router";
import TableSaw from "./TableSaw";
import CircularSaw from "./CircularSaw"
import Jointer from "./Jointer"


const ChangeComponents = {
    overview: Overview,
    planar: Planar,
    router: Router,
    tablesaw: TableSaw,
    circularsaw: CircularSaw,
    jointer: Jointer,
   

  };

export default function Subtopics(props) {
    // The <Route> that rendered this component has a
    // path of `/topics/:topicId`. The `:topicId` portion
    // of the URL indicates a placeholder that we can
    // get from `useParams()`.
    let { topicId } = useParams();
    
    let MyComponent = ChangeComponents[topicId];
    return (
        <div>
            {/* {topicId} is for testing route names */}
            {/* <h3>{topicId}</h3> */}
            <MyComponent topicId={topicId}/>
      </div>
    );
  }