import PlasticMoldingImg1 from '../../Images/PlasticMoldingImg1.jpg'
import PlasticMoldingImg2 from '../../Images/PlasticMoldingImg2.jpg'
import PlasticMoldingImg3 from '../../Images/PlasticMoldingImg3.jpg'
import InjectionMoldingImg from '../../Images/InjectionMoldingImg.jpg'

export default function Plastic(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Plastic Manufacturing</h1>
               <p>
                                
                                
                                
           
                      
                        <h2>Injection Molding;</h2>
Injection molding is the process of heating a polymer to a highly plastic state and forcing it into a mold cavity under high pressure. Once the polymer solidifies, the molding is removed and the parts are pushed out. Typical cycle times are between 10 to 30 seconds though 60 seconds is not uncommon. 
<br />
                        <br />
Injection molding parts are often susceptible to shrinkages and a number of factors can be controlled to reduce this effect. Material fillers can often be used along with higher injection pressure to reduce shrinkage by forcing more material into the mold cavity. Higher molding temperatures produce lower plastic viscosity which can also help pack more material into the mold.

                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={PlasticMoldingImg1} alt="Plastic Molding" width="75%"/>
                                </div>
<h2>Draft, Radii and Thickness for Injection Molding;</h2>
The draft of an injection molded design is an incline which helps the part release from a mold by reducing the drag on the material’s surface. Generally, adding a 1 degree of draft per inch of cavity depth will allow a smooth ejection. Adding a radii to sharp corners will eliminate sharp corners and improve material flow as well as part integrity. It is also beneficial for part ejection. 
<br />
                        <br />
Walls that have been designed as too thick can warp and create internal pockets of air, along with using unnecessary material. A common assumption is that the thicker the part, the stronger but that is actually a misconception. A part that is being designed for structural loads should be accompanied with ribs and supporting gussets.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={InjectionMoldingImg} alt="Injection Molding" width="75%"/>
                                </div>
<h2>Compression Molding;</h2>
For parts with simpler geometries, compression molding can be used to press plastic compounds into a desired shape. The mold itself must be heated, usually by electrical resistance, steam or hot oil circulation. This process is commonly used for thermosetting plastics, rubber and polymer matrix composite parts. The material formed inside traditionally comes as powders, pellets, liquids or preform. 
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={PlasticMoldingImg2} alt="Plastic Molding" width="55%"/>
                                </div>
<h2>Blow Molding;</h2>
For plastic parts with large internal cavities, blow molding can radibly produce a variety of shapes by using air pressure to inflate soft plastic within a mold cavity. This process is ideal for making things with thin walls such as bottles.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={PlasticMoldingImg3} alt="Plastic Molding" width="45%"/>
                                </div>
                        </p>
            </div>
    )
}