import T20 from '../../Images/T20.JPG'

export default function Overview(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Overview of Additive Machines</h1>
               <p>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={T20} alt="overview template" width="100%"/>
                                </div>
           
                                <br />
           <span style={{ fontWeight: 'bold' }}>FDM Extrusion Printer:</span> A 3D printer works by extruding spools of 3D printer material (usually thermoplastics) through a heated nozzle which traces the cross section of the part, layer by layer onto a heated bed.
                        <br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Resin Printer:</span> Resin printers can rapidly produce precise parts by utilizing a laser or light to selectively cure a layer of resin which is then extracted and reset for the next layer. The layers are gradually pulled upwards out of the resin as they are built and fused into a solid object.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Laser Sintering Machine:</span> A laser fusion printer uses high powered lasers to fuse powdered material together in a series of layers.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Filament Dryer:</span> A filament drier is a critical machine for many 3d printed materials as many polymers naturally absorb moisture after being exposed to ambient air. The dryer removes the trapped water molecules from inside the material.
                        </p>
            </div>
    )
}