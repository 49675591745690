import ElectroplatingImg from '../../Images/ElectroplatingImg.jpg'

export default function Plating(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Plating</h1>
               <p>
                                
               <h2>Intro to Plating;</h2>
Plating the process of depositing a surface covering unto a material with a conductive surface. Some of the benefits of plating can include improving aesthetics, corrosion inhibition, improved solderability, hardening, improved wearability, reduction of friction, improved paint adhesion, to alter conductivity, improved infrared reflectivity and radiation shielding. There are several methods to plate an object including the Sheffield plate which covers a surface with a metal sheet and then applying heat/pressure to fuse the objects together. Other plating methods include electroplating, vapor deposition, vacuum deposition and sputter deposition.

                       
                        <br />
                        <br /> <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ElectroplatingImg} alt="Electroplating" width="40%"/>
                                    <div style={{width:'50%'}}>
                                    <h2>Electroplating;</h2>
Using a direct electric current, electroplating is the process of producing a metal coating on a surface through the reduction of cations. The object to be coated acts as a cathode of an electrolyte cell where the electrolyte is a solution of salt and the anode is either a block of that metal or an inert conductive material. Electroplating is used for increased abrasion and corrosion resistance, lubricity, reflectivity and electrical conductivity.

                                    </div>
                                </div>         
                        </div>
                        <br />
                                <h2>Electroless Plating;</h2>
Also known as autocatalytic plating, electroless plating is a simple and cost effective alternative to electroplating. The process involves depositing the material into a liquid bath filled with nickel ions which bind to the surface of the material purely through chemical reaction. This process is well suited for parts which require very hard surfaces and particularly high corrosion resistance. 
<br />
                        <br /> 
<h2>Immersion Plating;</h2>
Also known as dip plating, immersion plating is the process of dipping a material into a solution of noble metal ions. There is no electric current passing through the solution, the binding occurs through chemical processes. This process alters the metal’s surface and improves wear, corrosion resistance and electrical conductivity. It can also alter appearance, reflectivity and the bonding capability of the coated part.
<br />
                        <br /> 
<h2>Carburizing;</h2>
Also known as case hardening, carburizing uses heat treatment to produce a wear-resistance surface while maintaining the strength of the base material. The process involves heating the material in a furnace and then introducing a carburizing gas such as carbon monoxide. 
<br />
                        <br /> 
<h2>Physical Vapor Deposition;</h2>
The physical vapor deposition process involves evaporating a solid coating material such as titanium, chromium or aluminum and binding the gaseous metal with a reactive gas such as nitrogen. The compound is deposited as a thin coating on a material which produces an extremely strong bond. The coating is used for material which require corrosion resistance, high hardness, high surface temperature resistance and good impact resistance.
<br />
                        <br /> 
<h2>Plasma Spray Coating;</h2>
Also known as thermal spraying, this process involves spraying molten material onto a surface to provide a coating. The coating material is injected through a very high temperature plasma flame which then cools as it forms on the part’s surface. This process is mainly used when a material requires very high temperature resistance. 
<br />
                        <br /> 
<h2>Common Plating Materials;</h2>

<span style={{ fontWeight: 'bold' }}>Gold:</span> wear resistance, corrosion resistance, low porosity, decorative aesthetics, excellent conductivity.
<br /> 
<span style={{ fontWeight: 'bold' }}>Silver:</span> excellent conductivity, decorative aesthetics.
<br /> 
<span style={{ fontWeight: 'bold' }}>Copper:</span> improved adhesion between base material and coating, will oxidize.
<br /> 
<span style={{ fontWeight: 'bold' }}>Chrome:</span> prevents surface color change, decorative aesthetics, extends material lifetime, low friction coefficient, wear proof, high temperature resistance, reduces oxidation.
<br /> 
<span style={{ fontWeight: 'bold' }}>Zinc:</span> low cost, prevents oxidation. 
<br /> 
<span style={{ fontWeight: 'bold' }}>Tin:</span> non-toxic, good welding capacity, soft and ductile, may crack and oxidize.
<br /> 
<span style={{ fontWeight: 'bold' }}>Nickel:</span> decorative aesthetics, good corrosion resistance.
<br /> 
<span style={{ fontWeight: 'bold' }}>Brass:</span> strong corrosion resistance, good for outdoor elements.


                        </p>
            </div>
    )
}