import PolishingImg from '../../Images/PolishingImg.jpg'

export default function Polishing(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Polishing</h1>
               <p>
                                
           
                     
                        <h2>Intro to Polishing;</h2>
Metal polishing is a finishing method used for both aesthetics and smoothing abrasive material surfaces. The polish can free metallic objects from defects while making the surface more reflective and shiny. Polishing can also be accompanied by buffing, a less harsh method which can result in an even brighter finish. The polish is also helpful as it removes oxidation from the material surface which prevents corrosion and significantly can prolong the working life.
<br />
                        <br /> 
Metal polishing can be performed with a range of abrasives which should be matched to the condition and type of material. If the original object is unfinished, polishing should be done in a set of stages. The first stage primarily removes imperfections using a rougher abrasive which can then transition to a finer abrasive for the aesthetic appeal. To ensure a mirror-like finish, polishing wheels, buffing and high-speed polishers can be used.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={PolishingImg} alt="Polishing" width="45%"/>
                                </div>
                        </p>
            </div>
    )
}