import LaserSintererImg from '../../Images/LaserSintererImg.jpg'
import T23 from '../../Images/T23.JPG'

export default function LaserSintering(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Laser Sintering Machine</h1>
               <p>
                                
           
         
           <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={LaserSintererImg} alt="Laser Sinterer" width="45%"/>
                                </div>
                                <span style={{ fontWeight: 'bold' }}>Description:</span> A laser fusion printer uses high powered lasers to fuse powdered material together in a series of layers.
                                <br />
                        <br /> 
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={T23} alt="Laser Sinterer" width="100%"/>
                                </div>
                                <br /> 
                        <span style={{ fontWeight: 'bold' }}>Type:</span> The variety of laser sintering machines which use plastics are known as selective laser sintering (SLS) while the metal processing units are called selective laser melting/ direct metal laser sintering (SLM/DMLS).
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Layer Thickness:</span> The minimum height of a layer of filament deposited by the machine.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Resolution:</span> Resolution is the accuracy which the machine operates within in comparison to the commands it receives from the computer’s instructions. 
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Build Volume:</span> The total size of the area which the laser table is able to accommodate.
                        </p>
            </div>
    )
}