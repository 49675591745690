import SingleDegreeImg from '../../Images/SingleDegreeImg.jpg'
import FreeVibrationEquationsImg from '../../Images/FreeVibrationEquationsImg.jpg'

export default function SingleDOF(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Single Degree of Freedom Systems</h1>
               <p>
                                

                        
                        <h2>Introduction to Single Degree Freedom Systems;</h2>
The simplest system in vibrations is the mass-spring system which consists of a mass attached to a spring which is itself attached to an immovable surface. The mass is constrained to a single dimension of motion and its movement can be fully described by the displacement quantity x.
<br />
                        <br />
If the mass is displaced from its equilibrium position and then released, it will vibrate indefinitely if no damping is present. This form of continuous vibration is known as free vibration. If the system is being continuously affected by an external force, that system is known as forced vibration.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={SingleDegreeImg} alt="Single Degree System" width="45%"/>
                                </div>
<h2>Free Vibration Without Damping;</h2>
An undamped system composed of a mass and spring will have an equal but opposite force between the force exerted on the mass and the force applied by the spring (2.1). In this case, 0 represents the equilibrium position of the mass. The solution of equation (2.1) is shown in equation (2.2).
<br />
                        <br />
<h2>Period and Natural Frequency;</h2>
A period is defined as the time interval that it takes for the sinusoidal oscillation of the system to complete one cycle. In the case of free vibration without damping, there will be infinitely many periods all of which have an equal magnitude (2.3). The reciprocal of the period is the natural frequency which is the rate at which an object naturally vibrates when disturbed from rest (2.5). In equation (2.5), g and W and variable for the gravitational constant and the weight of an object.
<br />
                        <br />
<h2>Angular Natural Frequency;</h2>
The variable which uses the omega symbol (w) is known as the angular natural frequency which is a measure of the rate of change of a waveform over a period of time (2.4).
<br />
                        <br />
<h2>Initial Conditions;</h2>
In equation (2.2) the variable B is the value of x at time t=0 and the value of A is equal to ẋ/⍵_n at time t=0. These are critical because the conditions at t=0 determine the subsequent oscillations.
<br />
                        <br />
<h2>Phase Angle;</h2>
You can rewrite equation (2.2) using angular natural frequency into equation (2.6). The variable theta is known as the phase angle.
<br />
                        <br />
<h2>Static Deflection;</h2>
The static deflection of a mass-spring system with no damping is the deflection of spring k as a result of gravitational forces from the mass. This deflection is modeled in equation (2.7).
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={FreeVibrationEquationsImg} alt="Free Vibration Equations" width="75%"/>
                                </div>
                        </p>
            </div>
    )
}