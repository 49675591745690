import SingleDegreeDampingImg from '../../Images/SingleDegreeDampingImg.jpg'
import DecrementImg from '../../Images/DecrementImg.jpg'
import FV1Img from '../../Images/FV1Img.jpg'
import FV2Img from '../../Images/FV2Img.jpg'
import FV3Img from '../../Images/FV3Img.jpg'
import FV4Img from '../../Images/FV4Img.jpg'
import FV5Img from '../../Images/FV5Img.jpg'

export default function FreeVibrations(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Free Vibrations with Viscous Damping</h1>
               <p>
                               
           
                      
                        <h2>Introduction to Free Vibration With Viscous Damping;</h2>
The single degree of freedom system with viscous damping consists of a single mass, capable of moving along a single axis, a spring and a damper element. Its differential equation is detailed as equation 3.1. It’s solution is determined by the relationship between the damping coefficient (c) and the critical damping coefficient c_c (3.2). The ratio 𝛇 is equal to the damping coefficient (c) divided b the critical damping coefficient c_c and is called the fraction of critical damping (3.3).               
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={FV1Img} alt="Single Degree Damping" width="45%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={SingleDegreeDampingImg} alt="Single Degree Damping" width="45%"/>
                                </div>
                                <h2>Less-Than-Critical Damping;</h2>
If the damping coefficient of the system is less than the critical damping coefficient then the ratio 𝛇 will be less than 1. In this case, the solution to the differential equation of the system can be determined from equation (3.4). The damped natural frequency (ω_d) is related to the angular natural frequency (ω_n) in equation (3.5)
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={FV2Img} alt="Single Degree Damping" width="65%"/>
                                </div>
<h2>Critical Damping;</h2>
When the damping coefficient c is equal to the critical damping coefficient then there will be oscillations in the system and the solution to the differential equation can be found through equation (3.6).
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={FV3Img} alt="Single Degree Damping" width="45%"/>
                                </div>
<h2>Greater-Than-Critical Damping;</h2>
If the ratio 𝛇 is greater than 1 from the damping coefficient being greater than the critical damping coefficient the system will display non oscillatory motion. If the mass is displaced from its equilibrium position it will return gradually to equilibrium. The solution to the differential equation can be seen in equation (3.7).
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={FV4Img} alt="Single Degree Damping" width="45%"/>
                                </div>
<h2>Logarithmic Decrement;</h2>
The degree of damping in a system with a ratio 𝛇 value of less than 1 can be defined in terms of the difference in peak values between oscillations. By taking the natural logarithm of any two successive cycles of damped free vibration, the decrease in amplitude can be measured (3.8)
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={FV5Img} alt="Single Degree Damping" width="45%"/>
                                </div>
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={DecrementImg} alt="Decrement" width="45%"/>
                                </div>

                        </p>
            </div>
    )
}