import React from 'react';
import Subtopics from './Subtopics';
import { Container } from "react-bootstrap";
import {
    Switch,
    Route,
    Link,
    useRouteMatch
  } from "react-router-dom";

function GeneralMachines(props) {
    let { path, url } = useRouteMatch();
    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', maxWidth: "100vw"}}>
                {/* <SectionTitle title={props.topicId}/> */}
            <div style={{display: 'flex', flexDirection: 'row'}}> 
            <div style={{marginTop: '10px', marginRight: '0px', marginLeft: '5px',border: '3px', padding:'40px', paddingRight: '50px', borderStyle:'solid', borderColor:'grey', paddingLeft: '20px', whiteSpace:'nowrap'}}>
                <h2>General Machines</h2>
                <li>
                    <Link to={`${url}/overview`}>Overview</Link>
                </li>
                <li>
                    <Link to={`${url}/shoppress`}>Shop Press</Link>
                </li>
                <li>
                    <Link to={`${url}/drillpress`}>Drill Press</Link>
                </li>
                <li>
                    <Link to={`${url}/grinder`}>Grinder</Link>
                </li>
                <li>
                    <Link to={`${url}/mitersaw`}>Miter Saw</Link>
                </li>
                <li>
                    <Link to={`${url}/bandsaw`}>Band Saw</Link>
                </li>
                <li>
                    <Link to={`${url}/lasercutter`}>Laser Cutter</Link>
                </li>
                <li>
                    <Link to={`${url}/aircompressor`}>Air Compressor</Link>
                </li>
                <li>
                    <Link to={`${url}/dustcollector`}>Dust Collector</Link>
                </li>
                </div>
                
            <Container>
                <Switch>
                    <Route exact path={path}>
                    <h3>Please select a sub-topic.</h3>
                    </Route>
                    <Route path={`${path}/:topicId`}>
                        <Subtopics />
                    </Route>
                </Switch>
            </Container>
         
                    </div>
            </div>
        </div>
    );
};

export default GeneralMachines; 