import DrillPressImg from '../../Images/DrillPressImg.jpg'
import T12 from '../../Images/T12.JPG'

export default function DrillPress(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Drill Press</h1>
               <p>
                                
           
          
           <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={DrillPressImg} alt="Drill Press" width="45%"/>
                                </div>
                                <span style={{ fontWeight: 'bold' }}>Description:</span> A drill press is a machine tool used to drill round holes into material. 
                                <br />
                        <br /> 
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={T12} alt="Drill Press" width="50%"/>
                                </div>
                                <br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Speed:</span> The range at which the spindle is capable of rotating. Different materials require different drilling speeds so it is important to set your machine at the correct units.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Max Spindle Travel:</span> When rotating the lever arm known as the spindle handle, the spindle assembly will lower towards the bed plate. The max travel is the total distance which the spindle assembly is capable of descending to.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Chuck Max Diameter:</span> The drill chuck can be outfitted with a variety of drill bits which allows different diameter holes to be drilled. The max diameter is the largest bit size which the chuck is capable of fitting inside its opening.
                        </p>
            </div>
    )
}