// import GT from '../../Images/GT.jpg'

export default function Integrals(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Integrals</h1>
               <p>
                                {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GT} alt="linkages" width="75%"/>
                                </div> */}
           
                        <br />
                        <br /> 
                        Cooling systems use either air or liquid to remove or absorb heat out of a system. An easy way to keep a
                         mechanism cool is by rapidly blowing air into it using an electric fan. For heavier duty applications,
                          liquid coolant can be circulated electronically around components that need thermal protection.                    
                
                        </p>
            </div>
    )
}