import GyroAccImg from '../../Images/GyroAccImg.jpg'

export default function Gyroscopes(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Gyroscopes and Accelerometers</h1>
               <p>

<h2>Intro to Gyroscopes;</h2>
Gyroscopes or gyros are electrical devices which measure the three dimensional tilt of the component by using earth’s gravity to help determine
 orientation. Microelectromechanical (MEMS) gyros are small, inexpensive sensors that measure angular velocity in degrees per second (°/s) or
  revolutions per second (RPS). These consist of a freely rotating disk called a rotor which is mounted on a larger, more stable wheel. As the
   gyro turns, the rotor maintains a directly downwards due to the pull of gravity which in turn can be used to have a constant “downwards” 
   direction.
<br />
<br /> 
<h2>Intro to Accelerometers;</h2>
Accelerometers are similar in structure to gyroscopes but with the difference that they measure linear acceleration instead of rotation. 
Internally the accelerometer is composed of microscopic crystals that respond to vibrations caused from changes in velocity. These crystals
 undergo stress which then creates a voltage which is read as a signal. 

  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GyroAccImg} alt="gyroscope and accelerometer" width="40%"/>
                                </div>
                        </p>
            </div>
    )
}