import LaserSinteringImg from '../../Images/LaserSinteringImg.jpg'

export default function Sintering(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Laser Sintering</h1>
               <p>
                                
           
                   <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                   <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={LaserSinteringImg} alt="Laser Sintering" width="100%"/>
                                </div>
                                <div style={{width:'75%'}}>
                                <h2>Intro to Laser Sintering;</h2>
A laser fusion printer uses high powered lasers to fuse powdered material together in a series of layers. The variety of laser sintering machines which use plastics are known as selective laser sintering (SLS) while the metal processing units are called selective laser melting/ direct metal laser sintering (SLM/DMLS). These machines are traditionally larger and used in more industrial settings as the high powered lasers and powders can be difficult to handle. These machines can make extremely precise and strong parts using a variety of materials.
<br />
                        <br /> 
<h2>Selective Laser Sintering (SLS);</h2>
The selective laser sintering manufacturing method is often capable of creating incredibly precise parts by incrementally layering powder which is then precisely sintered together. This process is done using various polymers.
<br />
                        <br /> 
<h2>Selective Laser Melting/ Direct Metal Laser Sintering (SLM/DMLS);</h2>
Using an almost identical manufacturing process as SLS printing, the SLM process utilises metal powder for their object fabrication. The metal’s higher metal point along with the additional risks of melting metal requires the machines to have higher power lasers and better enclosures.

                                </div>
                   </div>
               
                        </p>
            </div>
    )
}