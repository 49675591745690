import React from "react";
import {
//   BrowserRouter as Router,
  useParams,
} from "react-router-dom";


import ActionReaction from '../../toolbox/Idea/ActionReaction';

import ForcesMotion from '../../lessons/Physics/ForcesMotion';
import Overview from "../../toolbox/Idea/Cycle/Subtopics/Overview";


import ComponentDetermination from '../../toolbox/Design/ComponentDetermination';





import WorkshopBuilder from '../../toolbox/Prototyping/WorkshopBuilder';
import GeneralMachines from '../../toolbox/Prototyping/GeneralMachines';
import AdditiveMachines from '../../toolbox/Prototyping/AdditiveMachines';
import MetalShopMachines from '../../toolbox/Prototyping/MetalShopMachines';
import WoodShopMachines from '../../toolbox/Prototyping/WoodShopMachines';
import ElectricalMachines from '../../toolbox/Prototyping/ElectricalMachines';
import HandTools from '../../toolbox/Prototyping/HandTools';
import Consumables from '../../toolbox/Prototyping/Consumables';
import Apparel from '../../toolbox/Prototyping/Apparel';

import Arduino from '../../toolbox/Programming/Arduino';
import ReactCode from '../../toolbox/Programming/React';
import HTML from '../../toolbox/Programming/HTML';
import CSS from '../../toolbox/Programming/CSS';
import Javascript from '../../toolbox/Programming/Javascript';


const ChangeComponents = {
    
    overview: Overview,
    actionreaction: ActionReaction,

    forcesmotion: ForcesMotion,
 

    componentdetermination: ComponentDetermination,
 

    workshopbuilder: WorkshopBuilder,
    generalmachines: GeneralMachines,
    additivemachines: AdditiveMachines,
    metalshopmachines: MetalShopMachines,
    woodshopmachines: WoodShopMachines,
    electricalmachines: ElectricalMachines,
    handtools: HandTools,
    consumables: Consumables,
    apparel: Apparel,

 
    arduino: Arduino,
    reactcode: ReactCode,
    html: HTML,
    css: CSS,
    javascript: Javascript,
   
  };

export default function Tools(props) {
    // The <Route> that rendered this component has a
    // path of `/topics/:topicId`. The `:topicId` portion
    // of the URL indicates a placeholder that we can
    // get from `useParams()`.
    let { topicId } = useParams();
    
    let MyComponent = ChangeComponents[topicId];
    return (
        <div>
            {/* {topicId} is for testing route names */}
            {/* <h3>{topicId}</h3> */}
            <MyComponent topicId={topicId}/>
      </div>
    );
  }