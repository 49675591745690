import DatumImg from '../../Images/DatumImg.jpg';
import ControlFrameImg from '../../Images/ControlFrameImg.jpg';
import AllowanceImg from '../../Images/AllowanceImg.jpg';
import SizesImg from '../../Images/SizesImg.jpg';


export default function GDNT(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>                   
                     <h1>Intro to GD&T</h1>
                      <p>

<h2>Intro to GD&T:</h2>

Geometric Dimensioning and Tolerancing (GD&T) is a set of tools which a designer can use to ensure their drawings can be correctly interpreted. This tool consists of symbols and rules from ASME Y14.5M to describe the dimensions and tolerances of the workpiece. 
<br />
<br />
The addition of GD&T to a drawing communicates part intent and function from the designer to the manufacturing and inspection. GD&T also adds an additional layer of precision to part features and highlights feature to feature relationships.
<br />
<br />
<h2>Datum Reference Frame (DRF);</h2>

DRF is the most important component in assuring a part created from a drawing is compliant to manufacturing inspection. This consists of having three perpendicular planes for the dimensions to have a reference to. In mathematics, these planes are known as the Cartesian coordinate system which establish the X,Y,Z coordinates.
<br />
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={DatumImg} alt="Datums" width="45%"/>
                            </div>   
                               <h2>Feature Control Frame;</h2>
                               <br />
The geometric tolerance for an individual feature is listed in a block known as the feature control frame. The first compartment in the block contains the geometric characteristics such as true position, profile, orientation, ect. The second is the tolerance in relation to the point of reference. When applicable, the diameter symbol precedes the tolerance and is followed by the material condition symbol. The last set of comparents contains the datum planes or axis organized in proper sequence.
<br />
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={ControlFrameImg} alt="Control Frames" width="45%"/>
                            </div>   
                            <h2>Allowance;</h2>
                         
The allowance is defined as the maximum material difference between mating parts. Allowance is the minimum clearance or maximum interference (negative allowance)  between the two parts
Allowance = MMC Hole - MMC Shaft
<br />
<br />
<h2>Clearance;</h2>

Clearance is defined as the loosest fit between two mating parts.
<br />
Clearance = LMC Hole - LMC Shaft
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={AllowanceImg} alt="Allowances" width="45%"/>
                            </div>   
                            <h2>Nominal Size;</h2>
                    
This dimension is the target length for a feature on a workpiece.
<br />
<br />
<h2>Maximum Material Condition (MMC);</h2>
The MMC is the maximum size allowance for a dimension and is equal to the nominal size plus the tolerance.
<br />
<br />
<h2>Least Material Condition (LMC);</h2>
The LMC is the minimum size allowance for a dimension and is equal to the nominal size minus the tolerance.
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={SizesImg} alt="Sizes" width="45%"/>
                            </div>                           
                            </p>
            </div>
    )
}