import GammaTable1Img from '../../Images/GammaTable1Img.jpg'
import GammaTable2Img from '../../Images/GammaTable2Img.jpg'
import GammaTable3Img from '../../Images/GammaTable3Img.jpg'

export default function Gamma(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Gamma Resistant Polymers</h1>
               <p>
                                
           <h2>Intro to Gamma Resistant Polymers</h2>
           The unit Gy refers to “gray” which is the derived unit of ionizing radiation doses in the international system of units (SI). It
            is defined as the absorption amount of one joule of radioactive energy per kilogram of matter. 
           <br />
<br /> 
<h2>Radiation Stability of Medical Grade Polymers;</h2>
 
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GammaTable1Img} alt="Gamma Table" width="65%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GammaTable2Img} alt="Gamma Table" width="65%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GammaTable3Img} alt="Gamma Table" width="65%"/>
                                </div>
                        </p>
            </div>
    )
}