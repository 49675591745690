import GlassManufacturingImg from '../../Images/GlassManufacturingImg.jpg'

export default function Glass(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Glass Manufacturing</h1>
               <p>
                                
           
                     
                        <h2>Intro to Glass Manufacturing;</h2>
Often distinguished by its non-crystalline structure, glass is one of the three basic types of ceramics and can be poured into a mold if the glass is sufficiently fluid. Applications which require transparency, light transmittance or magnification often use glass for its unique optical properties. Glass is much stronger in compression than tension and should be designed to be subject to compressive forces, not tensile stresses. 
<br />
                        <br /> 
<h2>Spinning;</h2>
Spinning is the process of inserting a gob of hot glass into a funnel shaped mold and then rotating the material until it spreads evenly against the walls of the mold. 
<br />
                        <br /> 
<h2>Pressing;</h2>
Hot glass can also be inserted into a mold and pressed into shape using a plunger acting under higher compressive force.
<br />
                        <br /> 
<h2>Rolling;</h2>
Large quantities of molten glass can be forced through a pair of forming rolls which creates a thin sheet out of the material squeezed through the rolls. The material can be then grinded down and polished for parallelism and smoothness. 
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GlassManufacturingImg} alt="Glass Manufacturing" width="75%"/>
                                </div>
                        </p>
            </div>
    )
}