import React from "react";
import {
//   BrowserRouter as Router,
  useParams,
} from "react-router-dom";
import Wires from './Wires';
import Cables from './Cables';
import Connectors from './Connectors';
import Batteries from './Batteries';
import Switches from './Switches';
import Relays from "./Relays";
import Resistors from './Resistors';
import Capacitors from './Capacitors';
import Inductors from './Inductors';
import Solenoids from "./Solenoids";
import Fans from "./Fans";
import Transistors from "./Transistors";
import Fuses from "./Fuses";
import Diodes from "./Diodes";
import Grommets from "./Grommets";


const ChangeComponents = {
    wires: Wires,
    cables: Cables,
    connectors: Connectors,
    batteries: Batteries,
    switches: Switches,
    relays: Relays,
    resistors: Resistors,
    capacitors: Capacitors,
    inductors: Inductors,
    solenoids: Solenoids,
    fans: Fans,
    transistors: Transistors,
    fuses: Fuses,
    diodes: Diodes,
    grommets: Grommets,
  };

export default function Subtopics(props) {
    // The <Route> that rendered this component has a
    // path of `/topics/:topicId`. The `:topicId` portion
    // of the URL indicates a placeholder that we can
    // get from `useParams()`.
    let { topicId } = useParams();
    
    let MyComponent = ChangeComponents[topicId];
    return (
        <div>
            {/* {topicId} is for testing route names */}
            {/* <h3>{topicId}</h3> */}
            <MyComponent topicId={topicId}/>
      </div>
    );
  }