import CameraImg from '../../Images/CameraImg.jpg'

export default function Cameras(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Cameras</h1>
               <p>
                              
           
                        
                        <h2>Intro to Cameras;</h2>
Cameras work by converting a three dimensional image into a two dimensional image by sending and receiving a light source through a focused lens
 and back into an image sensor. Generally, the steps for capturing an image include light entering the lens where an aperture determines the 
 amount of light that reaches the sensor. The shutter determines how long the sensor is exposed to light while the camera's hardware processes 
 the image.
<br />
                        <br /> 
The image quality is dependent on sensor size, aperture size and the quality of the lens. 
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={CameraImg} alt="camera layout" width="55%"/>
                                </div>
                        </p>
            </div>
    )
}