import ConvectionImg from '../../Images/ConvectionImg.jpg'
import ForcedConvectionImg from '../../Images/ForcedConvectionImg.png'
import NaturalConvectionTableImg from '../../Images/NaturalConvectionTableImg.png'

export default function Convection(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Convection</h1>
               <p>

               <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                              
               <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ConvectionImg} alt="Convection" width="100%"/>
                                </div>
                                <div style={{width:'60%'}}>
                                <h2>Intro to Convection;</h2>
                                Convection consists of two mechanisms, energy transfer due to random molecular motion (diffusion) and energy
                         transferred by the motion of the fluid itself. When the flow is caused by an external means such as a fan or 
                         pump, it is known as forced convection. When flow is induced by differences in density or buoyancy, it is known 
                         as natural convection.   
                                <br />
                        <br /> 
                        <h2>Thermal Diffusivity;</h2>
Thermal diffusivity is the measure of the ability of a material to conduct thermal energy relative to its ability to store energy. Materials with large values will rapidly change to their environments while materials with low numbers will take much longer to reach a new equilibrium state. 
                                </div>
           </div>
           <br />
                        <br /> 
           <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                              
                              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                   <img src={ForcedConvectionImg} alt="Convection" width="90%"/>
                                               </div>
                                               <div style={{width:'60%'}}>
                                               <h2>Natural Convection;</h2>
                                               When flow is induced by differences in density or buoyancy, it is known as natural convection. The rate at which an object cools can be determined using Newton’s Law of Cooling. This states that the rate will equal to the temperature difference between the solid object’s surface and the nearby fluid multiplied by a constant called the convection heat transfer coefficient. This coefficient is dependent on the properties of the fluid which the energy is transferring into.
                                    
                       </div>
                          </div>                       
                          <br />
                        <br /> 
                          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                   <img src={NaturalConvectionTableImg} alt="Convection" width="70%"/>
                                               </div>
         
                      
                      
                      
                        </p>
            </div>
    )
}