import EndMillTypesImg from '../../Images/EndMillTypesImg.jpg'
import EndMillProcessImg from '../../Images/EndMillProcessImg.jpg'
import EndMillOverviewImg from '../../Images/EndMillOverviewImg.jpg'

export default function EndMills(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>End Mills</h1>
               <p>
                                
           
               <h2>Intro to End Mills;</h2>
              
For milling, profiling, contouring, slotting, counterboring, drilling and reaming applications, end mills are used for cutting shapes and holes in a workpiece. Shorter end mills with large diameters are better for fast cuts with great rigidity. Coatings can also be used to improve tool life and allow for greater feed and speeds.
                   
                         <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={EndMillOverviewImg} alt="End Mill Overview" width="65%"/>
                                </div>
                                <h2>Types of End Mills;</h2>
                                <span style={{ fontWeight: 'bold' }}>Square End Mills:</span> Used for general milling applications such as slotting, profiling and plunge cutting.
<br />
<span style={{ fontWeight: 'bold' }}>Ball (Ball Nose) End Mills:</span> Used for milling contoured surfaces, slotting and pocketing.
<br />
<span style={{ fontWeight: 'bold' }}>Roughing End Mills:</span> Used for rapidly removing large amounts of material. The teeth design allows for little to no vibrations but leaves a rougher finish.
<br />
<span style={{ fontWeight: 'bold' }}>Finishing End Mills:</span> Used to provide a smooth finish in a single pass.
<br />
<span style={{ fontWeight: 'bold' }}>Drill Mills:</span> Used for drilling, spotting, countersinking or chamfering.  
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={EndMillTypesImg} alt="End Mill Types" width="65%"/>
                                </div>
                                  <h2>Flute Types;</h2>
Flutes are the grooves cut into the body of the end mill. The higher the number of flutes, the greater the strength of the tool and the less space for chip flow. End mills with more flutes can generally be used for cutting harder materials.
<br />
<br />
<span style={{ fontWeight: 'bold' }}>Single Flute Mills:</span> Used for high volume material removal.
<br />
<span style={{ fontWeight: 'bold' }}>Two Flute Mills:</span> Used primarily in slotting and pocketing nonferrous materials.
<br />
<span style={{ fontWeight: 'bold' }}>Three Flute Mills:</span> Used primarily in slotting and pocketing nonferrous materials but have additional strength compared to two flute mills.
<br />
<span style={{ fontWeight: 'bold' }}>Four/Multiple Flute Mills:</span> Used for faster feed rates but the lower chip space can cause chip removal problems. Produces a much finer finish than two and three flute mills.
<br />
<br />
  <h2>Cutting Tool Materials;</h2>
  <span style={{ fontWeight: 'bold' }}>High Speed Steel (HSS):</span> Provides good wear resistance and are cheaper than other end mills. Can be used for non ferrous and ferrous metals.
<br />
<span style={{ fontWeight: 'bold' }}>Vanadium High Speed Steel (HSSE):</span> These have increased wear resistance and toughness. Commonly used for general applications on stainless steels and high silicon aluminums.
<br />
<span style={{ fontWeight: 'bold' }}>Cobalt:</span> Provides better wear resistance, high heat resistance and toughness. Results in excellent material removal and finishing. Can usually machine titanium.
<br />
<span style={{ fontWeight: 'bold' }}>Powdered Metal:</span> Used in high volume production and roughing.
<br />
<span style={{ fontWeight: 'bold' }}>Solid Carbide:</span> Has excellent heat resistance and can be used for high volume applications. Can usually be run two to three times faster than HSS.
<br />
<span style={{ fontWeight: 'bold' }}>Carbide Tips:</span> Cost effective alternative to HSS. Good performance.
<br />
<span style={{ fontWeight: 'bold' }}>Polycrystalline Diamond:</span> Used for extremely high strength materials.
<br />
<br />
  <h2>Standard Coatings and Finishes;</h2>
  <span style={{ fontWeight: 'bold' }}>Titanium Nitride (TiN):</span> General purpose coating which provides high lubricity and increased chip flow with soft metals.
<br />
<span style={{ fontWeight: 'bold' }}>Titanium Carbonitride (TiCN):</span> Harder and more wear resistant than TiN. 
<br />
<span style={{ fontWeight: 'bold' }}>Titanium Aluminum Nitride (TiAIN):</span> Higher hardness and wear resistance than TiN and TiCN. Ideal for stainless steel and titanium.
<br />
<span style={{ fontWeight: 'bold' }}>Aluminum Titanium Nitride (AITiN):</span> Highest hardness and abrasion resistance. Primarily used in machining aerospace materials.
<br />
<span style={{ fontWeight: 'bold' }}>Zirconium Nitride (ZrN):</span> Similar to TiN but has a higher oxidation temperature and resists sticking. Good for nonferrous materials.
<br />
<br />
  <h2>Common Milling Problems and Solutions;</h2>
  <span style={{ fontWeight: 'bold' }}>Excessive Chatter:</span> Vibrations and noise is excessive when milling a part.
<br />
Possible Solutions: 
<br />
Increase feed rate
<br />
Reduce radial depth of cut
<br />
Improve workpiece fixturing
<br />
<br />
<span style={{ fontWeight: 'bold' }}>Rough Surface Finish:</span> Work surface looks uneven and rough.
<br />
Possible Solutions:
<br />
Increase system rigidity
<br />
Increase speed
<br />
Decrease feed
<br />
Change to end mill with more flutes
<br />
End mill may need changing
<br />
<br />
<span style={{ fontWeight: 'bold' }}>Excess Wear of End Mill:</span> Tool is wearing out faster than life cycle.
<br />
Possible Solutions:
<br />
Reduce speed
<br />
Increase or decrease feed
<br />
Change to mill with coating
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={EndMillProcessImg} alt="End Mill Process" width="65%"/>
                                </div>                                                        
                        </p>
            </div>
    )
}