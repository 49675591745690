import DustCollectorImg from '../../Images/DustCollectorImg.jpg'
import T18 from '../../Images/T18.JPG'

export default function ShopPress(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Shop Press</h1>
               <p>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={DustCollectorImg} alt="Dust Collector" width="45%"/>
                                </div>
           
                       
                                <span style={{ fontWeight: 'bold' }}>Description:</span> A dust collector is a mechanical system which uses air flow to capture particles and clean air or other gases from an ambient environment.
                        <br />
                        <br /> 
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={T18} alt="Dust Collector" width="100%"/>
                                </div>
                                <br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Motor Horsepower (HP):</span> The unit of measurement for how much mechanical work can be done by the motor within this assembly.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Airflow (CFM):</span> CFM or cubic feet per minute is a measure of the volume of gas which the machine is capable of moving.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Impeller Size:</span> An impeller is a rotating blade element whose geometry generates currents of airflow.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Sound Rating:</span> The maximum amount of noise which the machine can generate during operation. The most common unit for measuring this is the decibel system.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Filter Size:</span> The size of the filter which should be periodically changed in the machine.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Filtration Limit:</span> The lowest size particle which the machine is capable of filtering from the air.
                        </p>
            </div>
    )
}