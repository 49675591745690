import LatheImg from '../../Images/LatheImg.jpg'
import T36 from '../../Images/T36.JPG'

export default function Lathe(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Lathe</h1>
               <p>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={LatheImg} alt="Lathe" width="45%"/>
                                </div>
           
                       
                                <span style={{ fontWeight: 'bold' }}>Description:</span> A lathe operates by rapidly rotating a piece of material and performing reductive manufacturing operations to that piece, usually by the use of a cutting element.
                        <br />
                        <br /> 
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={T36} alt="Lathe" width="100%"/>
                                </div>
                                <br /> 
                        <span style={{ fontWeight: 'bold' }}>Spindle Power:</span> The maximum output of mechanical work performed by the spindle onto a workpiece.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Spindle Speed:</span> The max rotating speed of the spindle element.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Spindle Bore:</span> The max diameter which the lathe can carve a horizontal hole into a workpiece.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Max Workpiece Length:</span> The max horizontal length of a workpiece inserted into the machine.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Swing:</span> The maximum diameter of a workpiece that can be inserted into the lathe’s chuck.                
                
                        </p>
            </div>
    )
}