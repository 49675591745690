import React from "react";
import {
//   BrowserRouter as Router,
  useParams,
} from "react-router-dom";
import Properties from './Properties';
import StaticFluids from './StaticFluids';
import Considerations from './Considerations';
import Impulse from "./Impulse";
import Buoyancy from './Buoyancy';
import Hydro from "./Hydro";
import Boyles from "./Boyles";

const ChangeComponents = {
    properties: Properties,
    staticfluids: StaticFluids,
    considerations: Considerations,
    impulse: Impulse,
    buoyancy: Buoyancy,
    hydro: Hydro,
    boyles: Boyles,
  };

export default function Subtopics(props) {
    // The <Route> that rendered this component has a
    // path of `/topics/:topicId`. The `:topicId` portion
    // of the URL indicates a placeholder that we can
    // get from `useParams()`.
    let { topicId } = useParams();
    
    let MyComponent = ChangeComponents[topicId];
    return (
        <div>
            {/* {topicId} is for testing route names */}
            {/* <h3>{topicId}</h3> */}
            <MyComponent topicId={topicId}/>
      </div>
    );
  }