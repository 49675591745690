import GasSensorImg from '../../Images/GasSensorImg.jpg'

export default function GasSensors(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Gas Sensors</h1>
               <p>
                    

<h2>Intro to Gas Sensors;</h2>
Gas sensors detect the presence and often concentration of gases in the atmosphere. Given the concentration of the gass, the sensor produces 
a potential difference by changing the resistance of the material inside the sensor. Based on the interpretation of the output voltage signal, 
the type of gas and its concentration can be estimated. The type of gas the sensor can detect is based on the material within the sensor. 
<br /> 
<br /> 
<h2>Electrochemical Sensors;</h2>
This type of sensor is used for detecting oxygen and toxic gases by oxidizing the target gas and measuring the electrical current output.
<br /> 
<br /> 
<h2>Catalytic Bead Sensors;</h2>
This is the most common type of gas sensor and uses catalytic beads to detect and measure combustible gases such as methane, propane and
 hydrogen. The catalytic beads act as oxidizers the combustible gases and measure the temperature change as an output electrical signal.
<br /> 
<br /> 
<h2>Low-Powered Infrared;</h2>
Low-powered IR sensors measure trace gases by measuring the absorption of emitted infrared light through the air sample. These are used
 for measuring carbon dioxide or combustible gases when no oxygen is present.
<br /> 
<br /> 
<h2>Photoionization Detector (PID);</h2>
PID sensors separate molecules into positive and negatively charged ions and measure the charge of these ions which is then used to
 determine the presence of gases. These are used for measuring and detecting volatile organic compounds and toxic gases.

  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GasSensorImg} alt="uv sensor" width="40%"/>
                                </div>
                        </p>
            </div>
    )
}