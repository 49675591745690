import PhotoresistorImg from '../../Images/PhotoResistorImg.jpg'

export default function Photoresistor(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>UV Sensors</h1>
               <p>


<h2>Intro to Photoresistors;</h2>
Photoresistors work by having electrically connecting cells which are capable of detecting visible light. These photocells operate by having the
 resistor change its resistance values based on the concentration of light. 
<br />
<br />  
The lux is used for measuring light intensity and is equal to one lumen per square meter. It is analogous to the radiometric unit watt per square
 metre with the distinction that the power of each wavelength is weighed by the luminosity function. 
<br />
<br />  
<h2>Intro to Photodiodes;</h2>
The photodiode is a semiconductor electrical device which converts light energy in electrical current.  

  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={PhotoresistorImg} alt="uv sensor" width="70%"/>
                                </div>
                        </p>
            </div>
    )
}