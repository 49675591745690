import React from 'react';
import Subtopics from './Subtopics';
import { Container } from "react-bootstrap";
import {
    Switch,
    Route,
    Link,
    useRouteMatch
  } from "react-router-dom";

function Algorithms(props) {
    let { path, url } = useRouteMatch();
    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', maxWidth: "100vw"}}>
                {/* <SectionTitle title={props.topicId}/> */}
            <div style={{display: 'flex', flexDirection: 'row'}}> 
            <div style={{marginTop: '10px', marginRight: '0px', marginLeft: '5px',border: '3px', padding:'40px', paddingRight: '50px', borderStyle:'solid', borderColor:'grey', paddingLeft: '20px', whiteSpace:'nowrap'}}>
                <h2>Algorithms</h2>
                <li>
                    <Link to={`${url}/binarysearch`}>Binary Search</Link>
                </li>
                <li>
                    <Link to={`${url}/sorting`}>Sorting</Link>
                </li>
               
                <li>
                    <Link to={`${url}/recursion`}>Recursion</Link>
                </li>
                {/* <li>
                    <Link to={`${url}/arrays`}>Arrays, Stacks and Queues</Link>
                </li>
                <li>
                    <Link to={`${url}/bigo`}>Big O</Link>
                </li> 
                <li>
                    <Link to={`${url}/structures`}>Data Structures</Link>
                </li>            */}
                </div>
                
            <Container>
                <Switch>
                    <Route exact path={path}>
                    <h3>Please select a sub-topic.</h3>
                    </Route>
                    <Route path={`${path}/:topicId`}>
                        <Subtopics />
                    </Route>
                </Switch>
            </Container>
         
                    </div>
            </div>
        </div>
    );
};

export default Algorithms; 