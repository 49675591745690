import Beams from '../../Images/BeamsImg.jpg'

export default function Supports(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Supports, Trusses and Beams</h1>
               <p>
<h2>Intro to Beam Loading;</h2>
                            It is common in the world of engineering to design structural supports which are rigid in all three lateral directions. However, it may occur that a support which can move in one or two lateral directions is preferable. These supports, known as single, double or three directional <span style={{ fontWeight: 'bold' }}>force transmitting supports</span> can be used to allow unique degrees of freedom. 
                            <br />
                            <br /> 
                            A truss or support is called <span style={{ fontWeight: 'bold' }}>kinematically determinate</span> if all the unknown forces going through the system can be solved using the equilibrium equation which entails the system to add up to zero if it is not moving. In essence, the number of degrees of freedom for a beam or truss at each point will determine if a system is over or under-constrained. 
                            <br />
                            <br /> 
                            This was important in the days before 3D force analysis, called <span style={{ fontWeight: 'bold' }}>finite element analysis (FEA)</span> because engineers would rely on the equilibrium equations to solve for the forces. Arches, trusses and beam systems would have to be designed in this way or the calculations would be unsolvable.
                            <br />
                            <br />
                            Nowadays, this condition is not a requirement and systems can be designed based on design characteristics that dont take into consideration an engineer's ability to solve calculations on paper, thankfully. However, systems being designed as kinematically determinate is still good practice as an over-constrained system might have unnecessary structural add-ons and an under constrained system will be able to move in ways that are not intended.
                            <br />
                            <br />
                            Another good engineering concept to understand are <span style={{ fontWeight: 'bold' }}>shear force and bending moment diagrams</span> on beams. When beams are attached on each side and force is transferred into the beam, it inevitably causes shear force to push downwards at a location while the support maintains equilibrium by pushing upwards in the opposite direction. The further that force has to travel across the beam, the larger the force of the moment. When multiple forces and moments act together onto a beam, it is useful to form these diagrams to determine which parts of your system are experiencing the highest loads thus making it the most vulnerable area.
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={Beams} alt="Supports, Trusses and Beams" width="55%"/>
                            </div>   
                            </p>
            </div>
    )
}