import PowerSupplyImg from '../../Images/PowerSupplyImg.jpg'

export default function PowerSupply(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Power Supply</h1>
               <p>
               <h2>Intro to Power Supply Units;</h2>
The power supply unit (PSU) is an electrical component which converts power from an external source into the required specifications for the
 local electronics. Most power supply units are designed to convert alternating current (AC) from a wall plug into direct current (DC). Their
  secondary function is to regulate the output DC voltage to the limited tolerances needed for today’s technology. 
  <br />
<br /> 
Most power supply units are switched-mode (SMPS) which is known for high efficiency and capability to configure multiple voltage inputs. This
 configuration allows them to operate in different countries where the input voltage might change. For example, the AC voltage and frequency 
 in the US is 120V at 60Hz, in the UK it is 240V at 50Hz and in Australia it is 230V at 50Hz.
 <br />
<br /> 
Some computing systems prefer to forego using standard power supply units and instead utilize Power over Ethernet (PoE) cables which carry power
 over network cables. This is ideal for systems which need to remain untethered to an electrical outlet or more flexibility.
 <br />
<br /> 
<h2>Considerations;</h2>
The most basic aspect of a PSU is its general dimensions and its ability to fit within the form factor of its case or holder. Wattage is the next
 most important aspect to consider with the general rule that PSUs have the highest efficiency when operating at 50% of max load. If the
  components in your system require a combined 600V, it would be ideal to buy a 1200V PSU. You should also always aim to have double the 
  wattage required available in your machine.
  <br />
<br /> 
Some systems consist of more than one PSU where the secondary PSU is kept powered off unless a break in the first switches it on for emergencies.
 This is often the case with servers. It is also common to have the second power supply running and sharing the workload. 
 <br />
<br /> 
<h2>Uninterrupted Power Supply;</h2>
For maximum redundancy, it is sometimes beneficial to have an uninterrupted power supply (UPS) which enables your device to run for a limited
 amount of time if power is lost. These come in three types; online, offline and line-interactive. Online uninterrupted power supplies ensure 
 the quality of power remains constant in the case of a break in power while offline UPS will have a slight delay before taking over. 
 Line-interactive UPS is a combination of the two and provides more protection due to its line conditioning. 
<br />
<br /> 
The image quality is dependent on sensor size, aperture size and the quality of the lens. 
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={PowerSupplyImg} alt="power supply" width="55%"/>
                                </div>
                        </p>
            </div>
    )
}