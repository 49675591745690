import FanImg from '../../Images/ElectricFans.jpg'

export default function Fans(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Fans</h1>
               <p>
                                
      
                        <h2>Intro to Fans;</h2>
                        Fans are a mechanical component which create flow within a fluid, the fluid usually being a gas such as air. The rotating
                         assembly of blades and a hub is known as an impeller, rotor, or runner and are usually contained within a structural 
                         housing such as a case. This housing is used for providing safety from the moving blades while also directing airflow
                          from its general shape. Although most fans are powered by electric motors, some can be powered from hydraulic motors,
                           handcranks, internal combustion engines, or solar power.
                           <br />
<br /> 
Fans produce air flows with high volume and low pressure (slightly higher than ambient pressure), as opposed to compressors which produce high 
pressures at a comparatively low volume. Fans are most often used with electronics for providing convection and thus cooling for other components. 
<br />
<br /> 
<h2>Axial Flow Fans;</h2>
Axial flow fans have blades which move air parallel to the rotating shaft. Some of their advantages include low power consumption, low
 noise and low maintenance. Some of their main disadvantages are that they cannot vary air flow, they cannot work in high temperature 
 applications and since the motor is in direct contact with the airstream, they cannot work in very corrosive conditions.
 <br />
<br /> 
<h2>Cross-Flow Fans;</h2>
There are three flow regions within a cross-flow fan: a vortex region near the fan discharge called the eccentric vortex, a through-
flow region, and a paddling region directly opposite. The advantages of the cross-flow fan includes its compactness due to its shape,
 quiet operation and its ability to provide a high pressure coefficient. Some of the disadvantages include a rather complex manufacturing
  process and higher motor load than traditional axial flow fans.
  <br />
<br /> 
<h2>Centrifugal Fans;</h2>
Centrifugal fans have the distinct method of increasing airstream speed by rotating an impeller which increases the air pressure as the
 air reaches the end of the blades. These fans are often able to produce high pressures which makes them ideal for systems with high 
 temperatures, moist or dirty air streams, and material handling. Some of their other advantages include high durability, decent efficiency
  (around 75%), can operate with minimal vibrations and having large running clearances. The subtypes of centrifugal fans are categorized 
  by their blade shape.
  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={FanImg} alt="fan types" width="75%"/>
                                </div>
                        </p>
            </div>
    )
}