import SemiconductorMatImg from '../../Images/SemiconductorMatImg.jpg'

export default function Semiconductors(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Semiconductor Materials</h1>
               <p>
                                
                                <h2>Intro to Semiconductors;</h2>
Between the two standard groups of conductors and insulators is a small collection of materials with special properties known as semiconductors.
 Specifically, semiconductors are materials whose conductivity is between 10^-7 to 10^3 mho/cm. Some semiconductors are pure elements such as 
 silicon and germanium while others are alloys such as nichrome or brass. 
 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={SemiconductorMatImg} alt="semiconductors" width="55%"/>
                                </div>          
                        </p>
            </div>
    )
}