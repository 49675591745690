import React from "react";
import {
//   BrowserRouter as Router,
  useParams,
} from "react-router-dom";
import Friction from './Friction';
import Efficiency from './Efficiency';
import Buckling from './Buckling';
import Thermal from "./Thermal";
import Stress from "./Stress";
import Strain from "./Strain";
import Impact from "./Impact";
import Creep from "./Creep";

const ChangeComponents = {
    friction: Friction,
    efficiency: Efficiency,
    buckling: Buckling,
    thermal: Thermal,
    stress: Stress,
    strain: Strain,
    impact: Impact,
    creep: Creep,
  };

export default function Subtopics(props) {
    // The <Route> that rendered this component has a
    // path of `/topics/:topicId`. The `:topicId` portion
    // of the URL indicates a placeholder that we can
    // get from `useParams()`.
    let { topicId } = useParams();
    
    let MyComponent = ChangeComponents[topicId];
    return (
        <div>
            {/* {topicId} is for testing route names */}
            {/* <h3>{topicId}</h3> */}
            <MyComponent topicId={topicId}/>
      </div>
    );
  }