import IRSensorImg from '../../Images/IRSensorImg.jpg'

export default function IRSensor(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>IR Sensors</h1>
               <p>

               <h2>IR Sensors;</h2>
               Infrared sensors are electric devices which are capable of measuring the infrared radiation and are often capable of 
               detecting heat energy and detecting motion. Infrared waves typically have wavelengths between 0.75 and 1000µm and are 
               not detectable to the human eye. The infrared spectrum can be further separated into near IR (0.75 to 3µm), mid IR (3 and 6µm)
                and far IR(x is greater than 6µm).  

  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={IRSensorImg} alt="uv sensor" width="60%"/>
                                </div>
                        </p>
            </div>
    )
}