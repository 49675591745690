import BoilingImg from '../../Images/BoilingImg.jpg'
import StatesImg from '../../Images/StatesImg.png'

export default function Boiling(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Boiling</h1>
               <p>
                                
 <h2>Intro to Boiling;</h2>    
 Boiling is the process of a liquid turning into a vapor due to sustained heat transfer. Since there is a phase change, heat transfer 
 to or from the fluid can occur without influencing the fluid temperature. Three terms play a large role in boiling, the latent heat of 
 the system, the surface tension of the liquid and the density difference between the liquid and vapor. The process of boiling occurs at 
 the fluid interface when the temperature of the surface exceeds the saturation temperature corresponding to the fluid pressure.       
                        <br />
                        <br /> 
 <h2>Types of Boiling;</h2>                       
 Pool Boiling: The liquid is inactive and its motion at or near the surface is due to free convection and general mixing caused by bubble 
 growth and detachment.
 <br /> 
Forced Convective Boiling: Fluid motion is caused by external means as well as bubble mixing. 
<br />
                        <br />               
    <h2>Subclasses of Boiling</h2> 
    Subcooled: The temperature of the liquid is below the saturation temperature and thus bubbles formed at the surface may condense back into
     the fluid.
     <br />   
Saturated: The liquid is above the saturation temperature which causes bubbles to propel through the liquid due to buoyancy forces and eventually
 escape from a free surface.

                                <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={StatesImg} alt="Fins" width="100%"/>
                                </div>
                                <div style={{width:'60%'}}>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={BoilingImg} alt="RadiationImg" width="100%"/>
                                </div>
           </div> 
           </div>
                        </p>
            </div>
    )
}