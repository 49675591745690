// import About from '../../templates/About';
import styles from './Home.module.css';
// import gif from '../../images/PTop.png';
// import Everything from '../../images/Everything37Img.jpg';
// import tools from '../../images/PageTools.jpg';
// import library from '../../images/PageLibrary.jpg';
// import technology from '../../images/PageTech.jpg';
// import Workshop from '../../images/WorkshopA3.jpg';
// import TechMatrix from '../../images/TechMatrix7.jpg';
// import Individual from '../../images/Individual7.jpg';
// import Economic from '../../images/Economic5.jpg';
// import Physical from '../../images/Physical1.jpg';
import Poster from '../../images/PosterEverythingFinal14.jpg';

function Home() {
  return (
    <div>
      {/* <About /> */}
      {/* <div className={styles.banner2}>
      <h1 className={styles.boldheader}>Master the Fundamentals and Become an Exceptional Maker!</h1>
      <div style={{display: 'flex', justifyContent: 'center', paddingTop: '60px'}}>
        
          <img src={gif} alt="gif" width="60%" className={styles.border} />
          </div>
      </div> */}
        
          
        <div style={{display: 'flex', justifyContent: 'center', paddingTop: '0px'}}>
          <img src={Poster} alt="everything" width="100%" /> 
          </div>
          {/* <div style={{display: 'flex', justifyContent: 'center', paddingTop: '0px'}}>
          <img src={Physical} alt="time and space" width="100%" /> 
          </div> */}
        
          {/* <div style={{display: 'flex', justifyContent: 'center', paddingTop: '0px'}}>
          <img src={Individual} alt="the individual" width="100%" /> 
          </div> */}
        {/* <div style={{display: 'flex', justifyContent: 'center', paddingTop: '0px'}}>
          <img src={Everything} alt="intro" width="100%" /> 
          </div> */}
        
          {/* <div style={{display: 'flex', justifyContent: 'center', paddingTop: '0px'}}>
          <img src={Economic} alt="economic matrix" width="100%" /> 
          </div> */}
          {/* <div style={{display: 'flex', justifyContent: 'center', paddingTop: '0px'}}>
          <img src={TechMatrix} alt="tech matrix" width="100%" /> 
          </div> */}
          {/* <div style={{display: 'flex', justifyContent: 'center', paddingTop: '0px'}}>
          <img src={Workshop} alt="workshop" width="100%" /> 
          </div> */}
       
      {/* <div className={styles.banner3}>
        <h1 className={styles.boldheader}>Master Design and Engineering, Learn the Tools of the Trade, Discover the Frontier of Technology</h1>
        <div style={{display: 'flex', justifyContent: 'center', paddingTop: '0px'}}>
          <img src={intro} alt="intro" width="60%" /> 
          </div>
      </div>
      <div className={styles.banner3}>
        <h1 className={styles.boldheader}>Overview of Library Module</h1>
        <div style={{display: 'flex', justifyContent: 'center', paddingTop: '10px'}}>
          <img src={library} alt="library" width="60%" /> 
          </div>
      </div>
      <div className={styles.banner3} >
        <h1 className={styles.boldheader} style={{'margin-top': '-50px'}}>Overview of Tools Module</h1>
        <div style={{display: 'flex', justifyContent: 'center', paddingTop: '10px'}}>
        <img src={tools} alt="topic" width="60%"  />
        </div>
      </div>
      <div className={styles.banner3} >
        <h1 className={styles.boldheader} style={{'margin-top': '-50px'}}>Overview of Technology Module</h1>
        <div style={{display: 'flex', justifyContent: 'center', paddingTop: '10px'}}>
        <img src={technology} alt="topic" width="60%"  />
        </div>
      </div> */}
    </div>
  );
}

export default Home;