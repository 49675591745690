import React from 'react';
import Subtopics from './Subtopics';
import { Container } from "react-bootstrap";
import {
    Switch,
    Route,
    Link,
    useRouteMatch
  } from "react-router-dom";

function FluidDynamics(props) {
    let { path, url } = useRouteMatch();
    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', maxWidth: "100vw"}}>
                {/* <SectionTitle title={props.topicId}/> */}
            <div style={{display: 'flex', flexDirection: 'row'}}> 
            <div style={{marginTop: '10px', marginRight: '0px', marginLeft: '5px',border: '3px', padding:'40px', paddingRight: '50px', borderStyle:'solid', borderColor:'grey', paddingLeft: '20px', whiteSpace:'nowrap'}}>
                <h2>Fluid Dynamics</h2>
                <li>
                    <Link to={`${url}/properties`}>Fluid Properties</Link>
                </li>
                <li>
                    <Link to={`${url}/boyles`}>Boyle's Law</Link>
                </li>          
                <li>
                    <Link to={`${url}/staticfluids`}>Static Fluids</Link>
                </li>
                <li>
                    <Link to={`${url}/buoyancy`}>Buoyancy</Link>
                </li>
                <li>
                    <Link to={`${url}/considerations`}>Flow Considerations</Link>
                </li>
                <li>
                    <Link to={`${url}/impulse`}>Impulse Momentum</Link>
                </li>
                <li>
                    <Link to={`${url}/hydro`}>Hydrophilicity</Link>
                </li>
            
                </div>
                
            <Container>
                <Switch>
                    <Route exact path={path}>
                    <h3>Please select a sub-topic.</h3>
                    </Route>
                    <Route path={`${path}/:topicId`}>
                        <Subtopics />
                    </Route>
                </Switch>
            </Container>
         
                    </div>
            </div>
        </div>
    );
};

export default FluidDynamics; 