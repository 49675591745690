import LevelingMountsImg from '../../Images/LevelingMountsImg.jpg'

export default function Leveling(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Leveling Mounts</h1>
               <p>
                                
           
                       
                        <h2>Intro to Leveling Mounts;</h2>
Leveling mounts come in two main varieties; “male” mounts have the studs in place and “female” mounts have the tapped inlets. Female mounts
 have the bolt head that is part of the design and the adjusting shaft is an external piece. These typically have a fixed position and a lower 
 range of adjustments. This type of mount is better suited for machines that have low to no movement and need to remain at a low profile. 
 Male (or stud) mounts have a threaded stud that is permanently attached to the component. Turning the leveling foot clockwise shortens it
  while turning it counter clockwise lengthed it. These offer a great range of adjustments and can typically be adjusted in place with the
   right tool.
<br />
                        <br /> 
Mounts come in a variety of materials including rubber, plastic and metal and vary widely in their total weight capacity. Some mounts are 
designed to allow swivel up to 10 to 15 degrees while others can include non-swivel bases. Mounts can also include vibrational resistant 
pads for machinery which produces strong vibrations.
<br />
                        <br /> 
<h2>Adjustable Glides;</h2>
When the machinery requires both support and to be moved around then the adjustable leveling glide may be the right decision. These are 
commonly designed to take between 250-500lbs (114-227kg) and can be manufactured to include pads for extreme durability.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={LevelingMountsImg} alt="Leveling Mounts" width="55%"/>
                                </div>
                        </p>
            </div>
    )
}