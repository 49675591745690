import ShopPressImg from '../../Images/ShopPressImg.jpg'
import T11 from '../../Images/T11.JPG'

export default function ShopPress(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Shop Press</h1>
               <p>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ShopPressImg} alt="Shop Press" width="45%"/>
                                </div>
                                
           
                               
                                <span style={{ fontWeight: 'bold' }}>Description:</span> A shop press is a machine which uses a lever and hydraulic system to generate compressive force.
                      
                                <br />
                        <br /> 
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={T11} alt="Shop Press" width="100%"/>
                                </div>
                                <br /> 
                        <span style={{ fontWeight: 'bold' }}>Force Capacity:</span> The total amount of force, often described with units of weight, which the press is capable of generating.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Max Distance to Table:</span> The total distance between the ram and the table when placed on the lowest rungs of the machine. Note that this is not the same as the maximum distance that the ram can travel, this is the maximum space ram in resting position and the top of the table.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Table Height Increments: </span>The distance between each set of vertical rungs which the table can be set to.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Stroke: </span>The maximum distance which the ram can travel.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Ram Diameter: </span>The diameter of the ram, which is the machine element which comes into contact with the object under compression.
                        </p>
            </div>
    )
}