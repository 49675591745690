import SortingImg from '../../Images/SortingImg.jpg'

export default function Sorting(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Sorting</h1>
               <p>
                                
           
                      
                        <h2>Intro to Sorting;</h2>
The sorting algorithm is capable of listing an array of items in ascending or descending order which can help the computer more rapidly attain its goal. 
<br />
                        <br /> 
<h2>Selection Sort;</h2>
The selection sort algorithm repeatedly selects the next smallest element and swaps it in place with the current element being looked at.
<br />
                        <br /> 
<h2>Insertion Sort;</h2>
Insertion sort takes a look at an item and inserts it numerically where it belongs in an array of pre-sorted items.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={SortingImg} alt="Sorting" width="55%"/>
                                </div>
                        </p>
            </div>
    )
}