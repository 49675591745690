import RadiationImg from '../../Images/RadiationImg.jpg'
import EmittanceImg from '../../Images/EmittanceImg.png'
import RadiationTableImg from '../../Images/RadiationTableImg.png'

export default function Radiation(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Radiation</h1>
               <p>
               <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
               <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={RadiationImg} alt="RadiationImg" width="100%"/>
                                </div>
                                <div style={{width:'60%'}}>
                                <h2>Intro to Radiation;</h2>
                                Radiation is the process of surfaces with finite temperatures emitting energy in the form of electromagnetic
                         waves. 
                         <br />
                        <br /> 
The term emissivity is a dimensionless quantity between zero and one which measures how well a surface retains or emits energy. Objects 
with an emissivity rating of zero are known as perfectly reflective, meaning 100% of energy is reflected from their surface. Objects with 
an emissivity of 1 are known as “black bodies” and absorb 100% of the energy directed towards them.
<br />
                        <br /> 
The radiation emitting from a body can be measured using the emissivity of an object, its body surface area, the absolute temperature of the object and the Stefan-Boltzmann constant = 5.67 x 10^-8 W/(m^2*K).
    </div>
           </div>
           <br />
                     
           <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
               <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={EmittanceImg} alt="RadiationImg" width="100%"/>
                                </div>
                                <div style={{width:'60%'}}>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={RadiationTableImg} alt="RadiationImg" width="100%"/>
                                </div>
           </div>
           </div>
        
                  

                
                        </p>
            </div>
    )
}