import ProbabilityImg6 from '../../Images/ProbabilityImg6.PNG'
import ProbabilityImg7 from '../../Images/ProbabilityImg7.PNG'
import ProbabilityImg8 from '../../Images/ProbabilityImg8.PNG'

export default function Probability(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Laws of Probability</h1>
               <p>
                                
           
                      
                        <h2>Intro to Probability;</h2>
The probability of an event is denoted P(E) where E is for event and the range of probability ranges from 0 to 1. The probability of an event that is impossible is thus 0 and the probability of an event that is certain to happen is 1.
<br />
                        <br /> 
<h2>Law of Total Probability;</h2>
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ProbabilityImg6} alt="Probability" width="55%"/>
                                </div>
<h2>Law of Compound or Joint Probability;</h2>
If neither P(A) nor P(B) is zero then these equations apply. If either P(A) or P(B) is zero, then P(A,B) = 0.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ProbabilityImg7} alt="Probability" width="55%"/>
                                </div>
<h2>Bayes’ Theorem;</h2>
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ProbabilityImg8} alt="Probability" width="55%"/>
                                </div>
                        </p>
            </div>
    )
}