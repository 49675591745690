import VibratoryFinishImg from '../../Images/VibratoryFinishImg.jpg'

export default function Vibratory(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Metal Vibratory Finish</h1>
               <p>
                                

              
                        <h2>Intro to Metal Vibratory Finish;</h2>
Vibratory finishing is a manufacturing process used to deburr, clean, and burnish (plastically deform surfaces) of small workpieces. The process involves submerging the metal object into a bath filled with specifically shaped granules which are then vibrated using a tumbler system. The vibrations cause the media to rub against the metal objects until the achieved surfacing is accomplished. This process can be done in both dry and wet conditions.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={VibratoryFinishImg} alt="Vibratory Finish" width="40%"/>
                                </div>
                        </p>
            </div>
    )
}