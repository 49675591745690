// import GT from '../../Images/GT.jpg'

export default function Matrixes(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Matrixes</h1>
               <p>
                                {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GT} alt="linkages" width="75%"/>
                                </div> */}
           
                        <br />
                        <br /> 
                        Pneumatic systems use compressed air to transmit energy. In most pneumatic systems, an air compressor generates
                         a continuous input of compressed gas which serves as the lifeblood of the system. By pulling in air from the
                          ambient atmosphere, the compressor can store the air in a high-pressure tank called a receiver and then channel 
                          it using a series of valves or pipes.                    
                
                        </p>
            </div>
    )
}