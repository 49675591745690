import HighpassFilterImg from '../../Images/HighpassFilterImg.jpg'

export default function HighpassFilters(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>High-pass Filters</h1>
               <p>
               <h2>Intro to High-pass Filters;</h2>
High-pass filters only allow high frequency signals through.
                  
                          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={HighpassFilterImg} alt="Highpass Filters" width="55%"/>
                                </div>
                        </p>
            </div>
    )
}