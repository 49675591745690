import InfillBasicImg from '../../Images/InfillBasicImg.jpg'
import InfillGeometryImg from '../../Images/InfillGeometryImg.jpg'
import SkirtBrimRaftImg from '../../Images/SkirtBrimRaftImg.jpg'
import WarpingImg from '../../Images/WarpingImg.jpg'


export default function KeyParameters(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>FDM Printing Key Parameters</h1>
               <p>
                                
           
                    
                      
                            
                                <h2>Intro to Key Parameters;</h2>

                        Fused Deposition Modeling in 3D printing is heavily reliant on optimizing certain parameters via software to customize parts for strength, stiffness, weight, print time, ect. 
                        <br />
                        <br /> 
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={InfillBasicImg} alt="Infill Basic" width="100%"/>
                                </div>
                                <div style={{width:'60%'}}>
                                <span style={{ fontWeight: 'bold' }}>Infill:</span> Infill is the internal structure of a 3D print. In most cases 3D printed parts are not printed solid due to the diminishing returns of part strength versus material consumed and print time. To optimize for this, parts are commonly surrounded by a solid shell and only partially filled.
<br />
                        <br /> 
Since the strength of a design is directly proportional to infill, higher infill percentages are often desirable. A part with an infill of 50% is roughly 25% stronger than a part with an infill of 25%. However, there are diminishing returns as a part with 75% infill is only roughly 10% stronger than a part with 50% infill.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Shells:</span> Shells are the part’s exterior surface. This specification will determine the thickness of the walls between the part and the outside world. Certain printer softwares can specify different thicknesses for the bottom layer, top layer and outer shell. To avoid voids from forming it is advisable to design shells with a thickness that is a multiplier of your 3D printer nozzle’s diameter.
<br />
                        <br /> 
Generally increasing the thickness of the shell will increase the strength of the part. Parts that require finishes such as smoothing, sanding or curing can also benefit from increased shell thickness as these processes often reduce thickness.
<br />
                        <br /> 

                                    </div>   
                        </div>
               
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Layer Height:</span> the height of each deposited layer plays a key role in determining the precision of a part. The smaller the height, the higher the precision though at the expense of longer print times.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Print Speed:</span> The faster the extruder moves, the faster the total print time will be. However, if the extruder moves too fast, the plastic may not have time to properly settle and imperfections may begin to appear in your print. 50 mm/s is a common print speed.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Supports:</span> Supports are a form of scaffolding which are built under part features where there is empty space. These are specifically designed to break away from the part after printing. Since large archways in parts or unsupported features can sag, it is advisable to use supports whenever your geometry requires it.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Extruder Temperature:</span> The temperature of your extruder should be first set within the boundaries of the designed printing temperatures of the spool of filament as specified by the manufacturer. If thin strings of material are clinging to your pieces, then lowering the temperature incrementally may solve your issue.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Bed Temperature:</span> The bed temperature will determine how well the plastic sticks to the printing surface. If your material is not sticking, try increasing the temperature. If the material seems to be flattening into a puddle more than it should, try decreasing the temperature.
 
                       <br />
                        <br /> 
 <h2>General Concepts;</h2>
 <span style={{ fontWeight: 'bold' }}>Layer Adhesions:</span> Fused deposition printing utilizes layers of material stacked on top of each other to form your desired parts. For the heated thermoplastics to bind to one another, high temperatures and pressure causes the previous layers to melt and bond to the newly deposited layer. Since the new material presses into the previous layer, the layers naturally form wave patterns no matter the layer height used. Small features such as holes and threads may require post processing due to this issue.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Retraction:</span> Parts designed to have large gaps of space between points can have excess filament leave faint strings between features. This occurrence is called stringing and although it will not affect the structural integrity of the part, it can cause unnecessary cleanup after a print. Retraction is a software setting which pulls back filament into the printer head when the extruder moves to a new location over empty space. This feature often comes pre-tuned for your specific printer but in the case that stringing still occurs (which may also be caused by having your extruder at too high a temperature) increasing the retraction speed may remedy this situation. Be advised that increasing the retraction speed too much may cause the extruder nozzle to clog.
<br />
                        <br /> 
 <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={SkirtBrimRaftImg} alt="Skirt Brim Raft" width="100%"/>
                                </div>
                                <div style={{width:'60%'}}>
                                <h2>Platform Additions;</h2>
 <span style={{ fontWeight: 'bold' }}>Skirt:</span> A skirt will print a thin layer of material around your piece without touching it. It is sometimes  useful if this is the first part printed from a new spool of filament to ensure that the bed temperature is correct for the material.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Brim:</span> A brim is a single layer of extra material printed below your part which can be peeled off after printing. This feature can be useful as plastic sticks better to plastic than the bed.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Raft:</span> A raft is a multilayer surface printed below your part which extends slightly beyond the outer limits of your part and can also be peeled away after printing. This can be useful as it ensures that the part is printed on an event surface if your printer bed has notches or grooves.

                                </div>
 </div>
 <br />
                     
 <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={InfillGeometryImg} alt="Infill Geometry" width="100%"/>
                                </div>
                                <div style={{width:'60%'}}>
                                <h2>Common Infill Geometry;</h2>
 <span style={{ fontWeight: 'bold' }}>Rectangular:</span> The most basic infill pattern due to fastest printing time and general strength in all directions.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Triangular & Diagonal:</span> The triangular patterns provide high strength in the direction of the walls though the triangles take more time to print than the basic rectangular pattern.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Wiggle:</span> This method allows the model to twist or compress making it very helpful when designing compliant mechanisms or when using soft rubbery materials.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Honeycomb:</span> This method combines fast printing with high strength in all directions.
 
                                    </div>    
 </div>
 <br />
                        <br /> 
<div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={WarpingImg} alt="Warping" width="100%"/>
                                </div>
                                <div style={{width:'60%'}}>
                                <h2>Warping;</h2>
When material is extruded and begins to solidify, its dimensions naturally decrease. This is further worsened from the fact that different sections of the part will cool at different rates based on its geometry and the material conditions of the neighboring material. These differences in cooling creates internal stresses which pull the underlying layers upwards, causing warping.
<br />
                        <br /> 
Designing your parts in accordance with basic guidelines can greatly decrease the amount of warping.
Avoid large flat areas
Reinforce thin flat areas with ridges for strengthening
Sharp corners warp far more than rounded corners, add fillets wherever possible
Select materials less susceptible to warping such as PLA instead of ABS
       
                                    </div>   
</div>
<br />
                        <br /> 
 <h2>FDM Printing General Tips;</h2>
 <span style={{ fontWeight: 'bold' }}>Move Printing Location:</span> The 3D printing process naturally wears down the surface which the extruder deposits material unto over time. To prolong the life of your printer bed, continuously move the location of your prints.

                            
                
                
                        
                        </p>
            </div>
    )
}