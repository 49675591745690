import SoundImg from '../../Images/SoundImg.jpg'

export default function Sound(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Sound</h1>
               <p>
                               
           
                    
                        <h2>Intro to Sound;</h2>
                        
Sound is an audible vibration through a medium which is usually characterized by its amplitude and frequency. The amplitude which is the height
 of the wave on a graph determines how loud the sound. It is important to note that sound does not add to itself linearly, meaning adding more
  sound to an environment does not necessarily mean it will be louder. The sound power, is the total emitted acoustic power of the sound and is
   measured in watts. 
<br />
<br /> 
One of the ways to quantify sound is to compare the amount of pressure variation between the sound relative to the atmospheric pressure. In 
this case, sound is a property of location relative to the source. As sound propagates, sound pressure levels reduce due to absorption in ground
 cover and terrain as well as through molecular energy loss. 
 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={SoundImg} alt="Sound" width="65%"/>
                                </div>
                        </p>
            </div>
    )
}