import UVSensorImg from '../../Images/UVSensorImg.jpg'

export default function UVSensors(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>UV Sensors</h1>
               <p>

                        <h2>Intro to UV Sensors;</h2>
Ultraviolet sensors are components which measure the power and/or intensity of incident ultraviolet radiation. UV light is the portion of 
the electromagnetic spectrum from 100 to 400 nm and is further divided into three wavelength ranges.
<br /> 
UV-A (315-400nm)
<br /> 
UV-B (280-315nm)
<br /> 
UV-C (100-280nm)
<br /> 
Most of the UV-B and all of the UV-C being emitted by the sun is absorbed into our atmosphere. Typical applications of these sensors are
 outdoor uses for measuring incoming UV radiation or in laboratory pharmaceuticals. These sensors generally output an electrical signal
  and require an electrical power source. 
  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={UVSensorImg} alt="uv sensor" width="40%"/>
                                </div>
                        </p>
            </div>
    )
}