import RegulatorsImg from '../../Images/RegulatorsImg.jpg'

export default function Regulators(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Voltage Regulators</h1>
               <p>
                                
           
                      
                        <h2>Intro to Voltage Regulators;</h2>
Voltage regulators (VRs) are electrical components which maintain a fixed output voltage regardless of changes in input voltage and/or load 
conditions. While most voltage regulators operate for DC to DC devices, some are designed for AC to AC and even AC to DC applications. 
<br />
                        <br /> 
There are two main types of voltage regulators, linear and switching. Linear and switching voltage regulators both work by maintaining a steady
 output voltage though the switching regulator is often more efficient. This increase in efficiency allows most of the input voltage to be 
 converted to output voltage without much loss through heat dissipation. 
 <br />
                        <br /> 
<h2>Linear Regulators;</h2>
A linear regulator uses an active pass device such as a MOSFET which is controlled by a high gain operational amplifier. To maintain a steady
 output voltage, linear regulators adjust the resistance to drive the difference between the input voltage and output voltage to zero. This 
 component family consists of step-down devices, meaning the input voltage is always higher than the output voltage. Some of the advantages 
 of using this type of regulator are that they are easy to design, robust, cost efficient and offer low electrical noise.
 <br />
                        <br /> 
<h2>Switching Regulators;</h2>
The switching regulator is a much more complex component and requires careful layout, selecting external component values and tuning control
 loops for stability. This type of regular can be step-up, step-down or both which allows for much greater versatility. Advantages of switching
  regulators is that they can have efficiencies up to 95% and have better thermal performance. 
  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={RegulatorsImg} alt="voltage regulators" width="55%"/>
                                </div>
                        </p>
            </div>
    )
}