import GPUImg from '../../Images/GPUImg.jpg'

export default function GPU(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>GPU</h1>
               <p>
                                
           
                      
                        <h2>Intro to GPU;</h2>
The graphics processing unit (GPU), designed for parallel processing is used for a range of applications including graphics and video rendering.
 Working together closely with the CPU, GPUs are designed to accelerate computer graphic workloads. GPUs come in two basic types; integrated
  and discrete. The integrated version is embedded directly with the CPU while the discrete GPU comes as its own card which is then mounted
   onto a PCI express slot.
   <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GPUImg} alt="GPU" width="35%"/>
                                </div>
                        </p>
            </div>
    )
}