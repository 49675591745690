import PekedImg from '../../Images/PekedImg.jpg'
import PotentialImg from '../../Images/PotentialImg.jpg'

export default function PEKE(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
              
               <h1>Potential & Kinetic Energy</h1>
               <p>
                          
  <h2>Kinetic Energy;</h2>
                        Kinetic energy is the amount of energy an object has due to its motion. Mathematically, kinetic energy is equal to one 
                        half the mass times velocity squared. As we know, if you want to accelerate any object, you have to apply a force to it.
                         Applying a force requires work to be done which then transfers energy into the object and can result in acceleration. 
                         <br />
                        <br /> 
Kinetic energy is related to the square of the velocity which means the energy that an object has increases exponentially. For example, a car 
traveling at 60 mph has four times the amount of energy than a car traveling at 30mph. With each additional mph increase that the car takes, the
 larger and larger the potential for destruction increases.
 <br />
                        <br /> 
                        <h2>Potential Energy;</h2>   
                        Gravitational potential energy is the amount of energy which could be made to manifest from gravitational acceleration. 
                        The reference distance can be arbitrarily chosen and is often referred to as the datum. The mathematical equation shows
                         that potential energy is equal to the height above the reference multiplied by the force of acceleration which itself 
                         is equal to mass times acceleration. 
                         <br />
                        <br /> 
                        <h2>Energy in a Closed System;</h2>
                        If you isolate a system and categorize the types of energy present, you can expand the conservation of energy into a
                         balanced equation. The forces present within this equation are potential and kinetic energy, elastic potential energy
                          and thermal energy. Solving these problems can allow an engineer to see where the energy in a system transfers to and
                           how much energy is lost into heat.
                           <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={PekedImg} alt="potential and kinetic equations" width="75%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={PotentialImg} alt="potential and kinetic example" width="95%"/>
                                </div>
                        </p>
            </div>
    )
}