import HydraulicCylinderImg from '../../MEComponents/HydraulicCylinderImg.jpg'

export default function Cylinders(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Cylinders</h1>
               <p>
                            
<h2>Overview of Hydraulic Cylinders;</h2>
Hydraulic cylinders work by converting hydraulic fluid pressure into mechanical work. The piston rod moves linearly as the pressure fluctuates 
and can be used in a variety of high strength applications such as robots, machinery and construction equipment. These components often rely on
 mineral oil, synthetic oils and occasionally water as their internal fluid.
 <br />
                            <br /> 
Cylinders are composed of two main components, the barrel and the piston and an array of smaller supporting elements such as flanges, bearings,
 fasteners, sliding rings and seals. The piston divides the cylinder into two sections, the upper and lower chambers. By increasing or decreasing 
 the amount of fluid in each of these chambers, and thus their pressures is the mechanism which controls the location of the end rod.
 <br />
                            <br /> 
<h2>Types of Hydraulic Cylinders;</h2> 
Single-Acting Cylinder
<br /> 
Double-Acting Cylinder
<br /> 
Non-differential Cylinder
<br /> 
Telescoping Cylinder
<br /> 
Balanced Piston-Type Cylinder
<br /> 
Cushioned Cylinder
<br /> 
Spring Loaded Cylinder
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={HydraulicCylinderImg} alt="Common Stock Materials" width="55%"/>
                            </div>
                           </p>
            </div>
    )
}