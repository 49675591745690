import CrystalStructuresImg from '../../Images/CrystalStructuresImg.jpg'

export default function Crystalline(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Structure of Crystalline Solids</h1>
               <p>
                                
           
                       
                        <h2>Intro to Crystalline Solids;</h2>
Material densities are highly affected by the packing style of atoms. Crystalline materials such as metals, many ceramics and some polymers have periodic atomic packing which causes their high densities. Non-dense materials have the tendency to have more chaotic, un-ordered atomic packing. The atomic packing factor (APF) is the volume of atoms in a unit cell per the volume of the unit cell. 
<br />
                        <br /> 
Common atomic packing methods include simple cubic structure (SC) which are rare due to its low packing density, body-centered cubic structure (BCC) where atoms touch each other long cube diagonals and face-centered cubic structure (FCC) where atoms touch each other along face diagonals. The face-centered cubic structure has the highest density packing factor of the three. 
<br />
                        <br /> 
Polymorphism is the condition in which a single material contains two or more distinct crystal structures, often determined from temperature changes during melting or manufacturing.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={CrystalStructuresImg} alt="Crystal Structures" width="65%"/>
                                </div>
<h2>X-ray Diffraction and Bragg’s Law;</h2>
X-ray diffraction is the process of sending x-rays into a crystalline solid and recording how the x-rays scatter against the regularly spaced atoms of the crystal. Bragg’s law is a set of observations for how rays should defract against crystal structures and interplanar spacing.

                        </p>
            </div>
    )
}