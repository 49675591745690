import LaserCuttingImg from '../../Images/LaserCuttingImg.jpg'

export default function LaserCutting(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Laser Cutting Technology</h1>
               <p>
                              
           
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={LaserCuttingImg} alt="Laser Cutting" width="100%"/>
                                </div>
                                <div style={{width:'70%'}}>
                                <h2>Intro to Laser Cutting;</h2>
Laser cutting is a manufacturing technique which uses high energy beams to cut through materials up to 20mm in thickness. CO2 Laser cutters are the most common and can cut thicker materials though the newer fiber laser cutter is more accurate and has faster operation. Since the working area of the laser is so small, laser cutting generally produces less warping than mechanical cutting operations. Another advantage of laser cutting is the edges which have been cut undergo localized hardening and produce a durable and smooth edge without the need for finishing.
<br />
                        <br /> 
Most metals can be laser cut although some varieties of aluminum have difficulty being cut and may altogether not work or produce inconsistent results. Mirrored or reflective materials also cannot be laser cut along with masonite boards and composites containing PVC. 
<br />
                        <br /> 
During the laser cutting operation, a portion of the material is burnt away leaving a small gap. The gap is known as a kerf and ranges from 0.08 to 0.45 mm depending on the material used, the material thickness and other factors. It is often advised to keep 2 to 5 mm away from the edge when cutting materials as edges are prone to warping from the excess heat. 

                                </div>
                        </div>
                   
                        </p>
            </div>
    )
}