import React from "react";
import {
//   BrowserRouter as Router,
  useParams,
} from "react-router-dom";
import Hydraulic from './Hydraulic';
import Pneumatic from './Pneumatic';
import Cooling from './Cooling';
import Combustion from "./Combustion";
import Chromatography from "./Chromatography";
import HVAC from "./HVAC";


const ChangeComponents = {
    hydraulic: Hydraulic,
    pneumatic: Pneumatic,
    cooling: Cooling,
    combustion: Combustion,
    chromatography: Chromatography,
    hvac: HVAC,
  };

export default function Subtopics(props) {
    // The <Route> that rendered this component has a
    // path of `/topics/:topicId`. The `:topicId` portion
    // of the URL indicates a placeholder that we can
    // get from `useParams()`.
    let { topicId } = useParams();
    
    let MyComponent = ChangeComponents[topicId];
    return (
        <div>
            {/* {topicId} is for testing route names */}
            {/* <h3>{topicId}</h3> */}
            <MyComponent topicId={topicId}/>
      </div>
    );
  }