import AirCompressorImg from '../../Images/AirCompressorImg.jpg'
import T17 from '../../Images/T17.JPG'

export default function AirCompressor(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Air Compressor</h1>
               <p>
  
     <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={AirCompressorImg} alt="Air Compressor" width="45%"/>
                                </div>
                                <span style={{ fontWeight: 'bold' }}>Description:</span> An air compressor is an electromechanical device which converts electrical power into stored potential energy in the form of pressurized gas or air. 
                                <br />
                        <br /> 
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={T17} alt="Air Compressor" width="45%"/>
                                </div>
                                <br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Motor Horsepower (HP):</span> The unit of measurement for how much mechanical work can be done by the motor within this assembly.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Motor Voltage:</span> The voltage rating that the machine has been constructed to operate within.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Airflow (CFM):</span> CFM or cubic feet per minute is a measure of the volume of gas which the machine is capable of moving.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Max Air Pressure:</span> The maximum amount of pressure generated by this machine.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Sound Rating:</span> The maximum amount of noise which the machine can generate during operation. The most common unit for measuring this is the decibel system.
                        </p>
            </div>
    )
}