import ThermalImg from '../../Images/ExpansionImg.jpg';


export default function Expansion(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
                     {/* <SectionTitle title={props.topicId}/> */}
                     <h1>Thermal Expansion</h1>
                     <p>
                            
<h2>Intro to Thermal Expansion;</h2>
                            <span style={{ fontWeight: 'bold' }}>Thermal expansion</span> is the natural inclination for an object to change shape, area and volume in response to a temperature change. When energy in the form of heat is absorbed into an object, molecules begin to vibrate and become excited. This excitement causes them to increase the distance between neighboring molecules which on a large scale leads to materials expanding. The same is true for the opposite, when an object loses heat energy, the internal molecules begin to slow and condense. 
                            <br />
                            <br /> 
                            Every material has a <span style={{ fontWeight: 'bold' }}>coefficient of thermal expansion</span> which is a numerical constant found through experiment which shows how much a material will grow or shrink based on the temperature. Given any type of system, it is crucial to determine how these changes in shape and volume might affect the components and their ability to function. 
                            <br />
                            <br /> 
                            Not only does the material of a component change its expansion characteristics but its shape as well. Depending on the shape of an object, it will expand and contract differently. When using systems made of various materials it is likely that given a surge of heat, some materials will try to expand much more than others around it. This can cause some materials to emit forces against their neighbors and potentially offset their position. Thermal expansion is one of the main reasons why designing an efficient system is so important. 
                            <br />
                            <br /> 
                            The equation for <span style={{ fontWeight: 'bold' }}>linear expansion</span> and the example above is helpful for visualizing this concept. The variables L and delta L represent the initial length and the change in length of the object. ɑ_L is the variable for our coefficient of thermal expansion and delta T is our change in temperature.  
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={ThermalImg} alt="Expansion" width="65%"/>
                                </div>
                            </p>
            </div>
    )
}