import OpticsImg from '../../Images/OpticsImg.jpg'

export default function Optics(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Optics</h1>
               <p>
                                
           
                  
                        <h2>Intro to Geometric Optics;</h2>
                        
Geometric optics or ray optics is a modeling system that describes light propagation in terms of rays which approximate the paths taken by
 light. These optics do not account for effects such as diffraction and interference and are used with a number of simplifying assumptions.
  These assumptions include that light is traveling in a straight line through a homogenous medium and that bends may split in two if the light
   encounters a certain media. 
   <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={OpticsImg} alt="Optics" width="45%"/>
                                </div>
                        </p>
            </div>
    )
}