import DigitalMicroscopeImg from '../../Images/DigitalMicroscopeImg.jpg'
import T52 from '../../Images/T52.JPG'

export default function DigitalMicroscope(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Digital Microscope</h1>
               <p>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={DigitalMicroscopeImg} alt="Digital Microscope" width="45%"/>
                                </div>
           
                       
           <span style={{ fontWeight: 'bold' }}>Description:</span> Handheld electric microscopes can magnify images, snap high resolution images and record videos and pass all the information through a usb cable.
                        <br />
                        <br /> 
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={T52} alt="Digital Microscope" width="50%"/>
                                </div>
                                <br />        
                        <span style={{ fontWeight: 'bold' }}>Magnification:</span> The amount of times the camera is capable of enlarging an image in relation to the actual size of the material or surface.

                        </p>
            </div>
    )
}