// import GT from '../../Images/GT.jpg'
import styles from './Forum.module.css';
import { Card } from 'react-bootstrap';


function Cards({title, ...rest}) {

    return (

        <Card {...rest} >
        <Card.Body>{title}</Card.Body>
        </Card>

    )
}
export default function Overview(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Overview of the Product Development Cycle</h1>
               <br />
               <br />
               <div>
               <div  className={styles.Table}>
            <div className={styles.Cell}>
                <Cards title="Idea" className={styles.Title} style={{backgroundColor: 'blue'}}/>             
              
                <div className={styles.TopBlock}>
                <Cards title="Define Concept" className={styles.TopTitle} style={{backgroundColor: 'white'}}/>
                </div>
                <div className={styles.TopBlock}>
                <Cards title="Impact Analysis" className={styles.TopTitle} style={{backgroundColor: 'white'}}/>
                </div>
            </div>
            <div className={styles.Cell}>
                <Cards title="Research" className={styles.Title} style={{backgroundColor: 'blue'}}/>
                <div className={styles.TopBlock}>
                <Cards title="Feasibility Analysis" className={styles.TopTitle} style={{backgroundColor: 'white'}}/>
                </div>
                <div className={styles.TopBlock}>
                <Cards title="Product Comparison" className={styles.TopTitle} style={{backgroundColor: 'white'}}/>
                </div>
                <div className={styles.TopBlock}>
                <Cards title="Market Analysis" className={styles.TopTitle} style={{backgroundColor: 'white'}}/>
                </div>
                <div className={styles.TopBlock}>
                <Cards title="Cost Analysis" className={styles.TopTitle} style={{backgroundColor: 'white'}}/>
                </div>
                <div className={styles.TopBlock}>
                <Cards title="Metrics & Constraints" className={styles.TopTitle} style={{backgroundColor: 'white'}}/>
                </div>
              
            </div>
            <div className={styles.Cell}>
                <Cards title="Setup" className={styles.Title} style={{backgroundColor: 'blue'}}/>
              
                <div className={styles.TopBlock}>
                <Cards title="System Layout" className={styles.TopTitle} style={{backgroundColor: 'white'}}/>
                </div>
                <div className={styles.TopBlock}>
                <Cards title="Block Diagrams" className={styles.TopTitle} style={{backgroundColor: 'white'}}/>
                </div>
                <div className={styles.TopBlock}>
                <Cards title="Task Layout" className={styles.TopTitle} style={{backgroundColor: 'white'}}/>
                </div>
              
            </div>
              
            <div className={styles.Cell}>
                <Cards title="Design" className={styles.Title} style={{backgroundColor: 'blue'}}/>
                
                <div className={styles.TopBlock}>
                <Cards title="Component Determination" className={styles.TopTitle} style={{backgroundColor: 'white'}}/>
                </div>
                <div className={styles.TopBlock}>
                <Cards title="3D Modeling (CAD)" className={styles.TopTitle} style={{backgroundColor: 'white'}}/>
                </div>
                <div className={styles.TopBlock}>
                <Cards title="Circuit & PCB Design" className={styles.TopTitle} style={{backgroundColor: 'white'}}/>
                </div>
                <div className={styles.TopBlock}>
                <Cards title="Code Architecture Design" className={styles.TopTitle} style={{backgroundColor: 'white'}}/>
                </div>
                
             
            </div>
                 
            <div className={styles.Cell}>
                <Cards title="Prototyping" className={styles.Title} style={{backgroundColor: 'blue'}}/>
                <div className={styles.TopBlock}>
                <Cards title="Ordering & Fabrication" className={styles.TopTitle} style={{backgroundColor: 'white'}}/>
                </div>
                <div className={styles.TopBlock}>
                <Cards title="Machine Assembly" className={styles.TopTitle} style={{backgroundColor: 'white'}}/>
                </div>
                <div className={styles.TopBlock}>
                <Cards title="Programming" className={styles.TopTitle} style={{backgroundColor: 'white'}}/>
                </div>
                <div className={styles.TopBlock}>
                <Cards title="Testing" className={styles.TopTitle} style={{backgroundColor: 'white'}}/>
                </div>
                <div className={styles.TopBlock}>
                <Cards title="Validation & Compliance" className={styles.TopTitle} style={{backgroundColor: 'white'}}/>
                </div>
          
            </div>
            <div className={styles.Cell}>
                <Cards title="Documentation" className={styles.Title} style={{backgroundColor: 'blue'}}/>
                <div className={styles.TopBlock}>
                <Cards title="Mechanical Drawings" className={styles.TopTitle} style={{backgroundColor: 'white'}}/>
                </div>
                <div className={styles.TopBlock}>
                <Cards title="Electrical Drawings" className={styles.TopTitle} style={{backgroundColor: 'white'}}/>
                </div>
                <div className={styles.TopBlock}>
                <Cards title="Bill of Materials" className={styles.TopTitle} style={{backgroundColor: 'white'}}/>
                </div>
                <div className={styles.TopBlock}>
                <Cards title="Assembly Instructions" className={styles.TopTitle} style={{backgroundColor: 'white'}}/>
                </div>
                <div className={styles.TopBlock}>
                <Cards title="Patenting" className={styles.TopTitle} style={{backgroundColor: 'white'}}/>
                </div>
              
                </div>
         </div>  
</div>
            </div>
    )
}