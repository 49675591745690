import PlateBenderImg from '../../Images/PlateBenderImg.jpg'
import T33 from '../../Images/T33.JPG'

export default function PlateBender(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Plate Bender</h1>
               <p>
                                
           
         
           <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={PlateBenderImg} alt="Plate Bender" width="45%"/>
                                </div>
                                <span style={{ fontWeight: 'bold' }}>Description:</span> A box and pan plate bender is a machine unit used to bend sheet steel to various angles.
                  <br />
                        <br /> 
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={T33} alt="Plate Bender" width="100%"/>
                                </div>
                                <br />     
                        <span style={{ fontWeight: 'bold' }}>Max Thickness:</span> The highest gauge thickness of metal sheets which the machine is able to bend. Note that different metals have different gauge thickness tables.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Max Width:</span> The largest width of sheet metal which the unit is capable of accommodating.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Max Height on Box Sides:</span> The maximum height of material that can be present to allow a bend to be made.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Max Bend Angle:</span> The largest angle size which the material can be bent to.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Minimum Reverse Bend:</span> Reverse bending is when a sheet metal part is bent in one direction, flipped and then pent in the opposite direction. This is usually difficult for machines to accommodate.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Finger Sizes:</span> The length of the individual fingers of the machine. 
    
                        </p>
            </div>
    )
}