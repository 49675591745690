import R1 from '../../Images/R1.PNG'
import R2 from '../../Images/R2.PNG'
import R3 from '../../Images/R3.PNG'
import Resistance2Img from '../../Images/Resistance2Img.jpg'

export default function Resistance(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Resistance</h1>
               <p>
                                 
           
 <h2>Resistance, Resistivity and Conductance;</h2>
                        
Resistance is the ratio being the applied voltage and the resulting current. Ohm’s Law demonstrates that V=IR and R=V/I where R is resistance
 which uses units of Ohms which is equal to a volt/ampere. Ohm’s law can  only be applied to ohmic materials; materials whose resistance is 
 constant over a range of voltages. 
<br />
<br />
Resistance is impacted by a large variety of factors including the shape, length, cross-sectional area of a material. Resistivity on the other
 hand is independent of all factors. It is the chemical measure of how good a material is at blocking the flow of electricity. Conductivity on 
 the other hand, is the inverse of resistivity and is the measure of how good a material is at passing current. An important characteristic of
  resistivity and conductivity is the temperature of the conductor. In most metals, the higher the temperature the higher the resistivity. This
   is caused due to the increased atomic vibrations colliding with the drifting electrons and thus slowing them down.
   <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={Resistance2Img} alt="resistors" width="45%"/>
                                </div> 
<h2>Resistors;</h2>
Resistors are used to limit current flow and set voltage levels within electronics but they work by dissipating power into heat. Determining
 how much power is being dissipated is extremely important when designing circuitry, as going over the maximum power rating of a resistor could 
 fry it. You can determine how much power is being dissipated by using Ohm’s power law.
 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={R1} alt="resistors" width="45%"/>
                                </div> 
 <h2>Resistors in Parallel;</h2>
When two or more resistors are placed in parallel the voltage across each resistor is the same but the current through each of the resistors
 varies with resistance. The total resistance of the combination will always be lower than the lowest resistance value of resistors. 
 <br />
 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={R2} alt="resistors in parallel" width="35%"/>
                                </div> 
<h2>Resistors in Series;</h2>
When resistors in a circuit are connected as a series, then the total resistance will equal to the sum of the resistance of every resistor in
 the series. The amount of current flowing through these resistors are the same while the voltage across each resistor will vary with the 
 resistance. Any number of resistors can be added in series to achieve the desired resistance.
 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={R3} alt="resistors in series" width="45%"/>
                                </div> 
                        </p>
            </div>
    )
}