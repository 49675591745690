import KinematicsImg from '../../Images/KinematicsImg.png'

export default function SystemKinematics(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>System Kinematics</h1>
               <p>
                        

   <h2>Introduction to System Kinematics;</h2>
   There are primarily two ways of controlling robots in terms of positioning and orientation. The first method, called forward kinematics, uses known values of joint angles to determine position and orientation. The second method, called inverse kinematics, uses position and orientation such as x,y coordinates to determine joint angles. Inverse kinematics is more commonly used because the goal of these control methods often revolves around getting the robot to a location in 3D space and having the computer determine which joint angles are needed to get there.

<br />
                        <br />                 
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={KinematicsImg} alt="Kinematics" width="100%"/>
                                </div>
                        </p>
            </div>
    )
}