import LaserCutterImg from '../../Images/LaserCutterImg.jpg'
import T16 from '../../Images/T16.JPG'

export default function LaserCutter(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Laser Cutter</h1>
               <p>
               <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={LaserCutterImg} alt="Laser Cutter" width="45%"/>
                                </div>        
                                <span style={{ fontWeight: 'bold' }}>Description:</span> Laser cutting is a manufacturing technique which uses high energy beams to cut through materials
               <br />
                        <br />
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={T16} alt="Laser Cutter" width="100%"/>
                                </div>   
                                <br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Laser Type:</span> CO2 Laser cutters are the most common and can cut thick materials. Newer fiber laser cutters are more accurate and have faster operation. 
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Cut Speed:</span> This is the distance over time which the platform assembly can move which is directly responsible for the maximum cutting speed which the laser will cut material with.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Laser Strength:</span> The power of a laser, described from wattage, will determine the range of materials it can cut as well as the maximum thickness of the materials.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Accuracy:</span> The accuracy of a laser cutter is the resolution it has in comparison to the commands it receives from the computer’s instructions. 
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Build Volume:</span> The total size of the area which the laser table is able to accommodate.      
             
                        </p>
            </div>
    )
}