import G00 from '../../Images/G00RP.jpg'
import G01 from '../../Images/G01LI.jpg'
import G02 from '../../Images/G02CCI.jpg'
import G03 from '../../Images/G03CCI.jpg'

export default function Common(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Common G-code Commands</h1>
               <h2>G00: Rapid Positioning</h2>
               <p>

The rapid positioning command moves the machine at its maximum travel speed along a straight line from its current position,
to the coordinates specified in the command. The machine will move each of the motors at once to accomplish this command which results
 in a straight line movement. Note that this command is not for cutting or printing, and is used when you simply want to move the machine
  rapidly from one point to another.
  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={G00} alt="linkages" width="40%"/>
                                </div>
                        </p>
                <h2>G01: Linear Interpolation</h2> 
                <p>
                The G01 command also moves the machine in a straight line but sets a feed rate or cutting speed. The desired location is
                 set using the x,y,z coordinates while the spindle speed is set using the F-value. Similarly to the G00 command, G01 interpolates
                  thousands of calculations per second to ensure each motor moves just the right amount to maintain a straight line as it moves
                   between the two points. Unlike the G00 command, this operation is used when the machine is doing its main job such as cutting 
                   away material in a mill or extruding molten plastic in a 3D printer.
                   <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={G01} alt="linkages" width="40%"/>
                                </div>                 
                    </p> 
                <h2>G02: Circular Interpolation Clockwise</h2> 
                <p>
                The G02 command is used to move the machine clockwise in a rotational manner. This is also a working machine command similar
                 to G01. This command also requires the input of the desired center of rotation so that the computer knows how to draw the arc.
                  Since the machine knows its current location, desired endpoint and center of rotation, it will figure out on its own the arc 
                  to make to get to the next location.   
                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={G02} alt="linkages" width="40%"/>
                                </div>            
                    </p> 
                <h2>G03: Circular Interpolation Counterclockwise</h2>
                <p>
                Just like the G02 command, G03 defines a circular rotation around a specific centerpoint although with G03, the rotation is counterclockwise. 
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={G03} alt="linkages" width="40%"/>
                                </div> 
                </p>
                        
            </div>
    )
}