// import GT from '../../Images/GT.jpg'

export default function SetupFunctions(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Arduino C++ Setup Functions</h1>
               <p>
                                {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GT} alt="linkages" width="75%"/>
                                </div> */}
           
        
             
                    
                        <span style={{ fontWeight: 'bold' }}>PinMode:</span>     
                        <br />               
Configures the digital pins to be either input or output.
<br /> 
 <code>{`pinMode(#, INPUT);`}</code>
<br /> 
<code>{`pinMode(#, OUTPUT);`}</code>
<br /> 
<br /> 
<span style={{ fontWeight: 'bold' }}>Serial Begin:</span>
<br /> 
The serial begin function creates a connection between the arduino and your computer and sets the specific baud rate which is the amount of bits per second transferred.
<br /> 
<code>{`Serial.begin(9600);`}</code>
               
                        </p>
            </div>
    )
}