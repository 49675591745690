import React from "react";
import {
//   BrowserRouter as Router,
  useParams,
} from "react-router-dom";
import NumberSystems from './NumberSystems';
import Logic from './Logic';
import Networking from './Networking';
import Internet from "./Internet";
import Security from "./Security";
import Variables from "./Variables";
import Loops from "./Loops";
import Conditionals from "./Conditionals";
import Functions from "./Functions";

const ChangeComponents = {
    numbersystems: NumberSystems,
    logic: Logic,
    networking: Networking,
    internet: Internet,
    security: Security,
    variables: Variables,
    loops: Loops,
    conditionals: Conditionals,
    functions: Functions,
  };

export default function Subtopics(props) {
    // The <Route> that rendered this component has a
    // path of `/topics/:topicId`. The `:topicId` portion
    // of the URL indicates a placeholder that we can
    // get from `useParams()`.
    let { topicId } = useParams();
    
    let MyComponent = ChangeComponents[topicId];
    return (
        <div>
            {/* {topicId} is for testing route names */}
            {/* <h3>{topicId}</h3> */}
            <MyComponent topicId={topicId}/>
      </div>
    );
  }