import FunctionsImg from '../../Images/FunctionsImg.jpg'

export default function Functions(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Functions</h1>
               <p>
                               
           
           <h2>Intro to Functions;</h2>
Functions are small programs that can be executed throughout a code without having to rewrite the block of code entirely. The function, denoted by a function name, creates a group from the lines within its structure that can be called later and numerous times each time the function is called.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={FunctionsImg} alt="Functions" width="25%"/>
                                </div>
                
                        </p>
            </div>
    )
}