// import GT from '../../Images/GT.jpg'

export default function Overview(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Arduino C++ Examples</h1>
               <p>
                                {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GT} alt="linkages" width="75%"/>
                                </div> */}
           
         
           <span style={{ fontWeight: 'bold' }}>Lab Coat:</span> A protective layer when dealing with fluids.
           <br /> 
           <code>{`break;`}</code>
                        </p>
            </div>
    )
}