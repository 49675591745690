import MagnetismImg from '../../Images/MagnetismImg.jpg'

export default function Magnetism(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Magnetism</h1>
               <p>
                                
           
          <h2>Intro to Magnetism;</h2>
                        Magnetism is a force of attraction or repulsion caused by the motion of electric charges. The electrons which spin 
                        around atoms generate an electric current which in turn causes the electrons to behave like microscopic magnets. Most
                         substances have an equal number of electrons spinning in opposite directions which causes them to have weak magnetic
                          fields. Some metals such as iron, cobalt and nickel have electrons spinning in the same direction which makes the 
                          atoms of the substances magnetic. To become magnetized, another strong magnetic force has to enter the magnetic field
                           with another existing magnet. All magnets have both a north and south pole with opposite poles being attracted to
                            each other and like poles repelling. Earth itself is a magnet where molten metal in the earth’s outer core causes
                             the magnetic field.                  
                             <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={MagnetismImg} alt="Magnetism" width="35%"/>
                                </div>
                        </p>
            </div>
    )
}