
import GDSymbolsImg from '../../Images/GDSymbolsImg.jpg';



export default function Symbols(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>GD&T Symbols</h1>
            <p>
                        
           
                       <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <img src={GDSymbolsImg} alt="GD&T Symbols" width="75%"/>
                        </div>
                  
            </p>
            </div>
    )
}