import BandpassFilterImg from '../../Images/BandpassFilterImg.jpg'

export default function Bandpass(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Bandpass Filters</h1>
               <p>
                                
<h2>Intro to Bandpass Filters;</h2>
The bandpass filter passes a short range of frequencies centered around the filter’s resonant frequency.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={BandpassFilterImg} alt="BandpassFilterImg" width="55%"/>
                                </div>
                        </p>
            </div>
    )
}