import ThermocoupleImg from '../../Images/ThermocoupleImg.jpg'
import TempSensors from '../../Images/TempSensors.jpg'

export default function TemperatureSensors(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Temperature Sensors</h1>
               <p>
                                
 
<h2>Intro to Temperature Sensors;</h2>
Temperature is one of the most measured quantities in science and engineering due to the fact that mechanical, electrical, chemical and
 biological systems are all affected by temperature. Temperature sensing can be done through direct contact with the heat source or 
 remotely using thermally radiated energy. 
<br /> 
<br /> 
<h2>Thermocouples;</h2>
Thermocouples are made from joining two dissimilar metals at one end called the “hot junction”. The other ends of the two metals are referred
 to as the “cold junctions”. Due to the two metals having different properties, changes in temperature can generate a slight voltage, known 
 as electromotive force EMF. This small voltage can then be converted into an appropriate temperature signal.
 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ThermocoupleImg} alt="thermocouple layout" width="40%"/>
                                </div>
<h2>Resistance Temperature Detector;</h2>
The resistance temperature detector (RTD) is a type of temperature sensor whose resistance changes with temperature. Platinum is often used 
for RTDs as it is the most sensitive and has the highest range of the different conductor alloys that can be used. Platinum is also used 
because it provides a nearly linear temperature response, is stable and accurate and provides repeatable responses.
<br /> 
<br /> 
<h2>Thermistors;</h2>
Thermistors work similarly to RTDs by having changes in temperature cause measurable changes in resistance. Unlike RTDs which are often
 made of platinum, thermistors are usually made from polymer or a ceramic material. They are also often cheaper but less reliable than 
 RTDs. Thermistors are available in two wire configurations.
<br /> 
<br /> 
Negative Temperature Coefficient (NTC) thermistors are the most commonly used thermistor and have their temperature decrease as the 
temperature increases. They also have a nonlinear temperature relationship which requires significant correction to interpret the data correctly. 
<br /> 
<br /> 
<h2>Semiconductor ICs;</h2>
Semiconductor based ICs come in two types, local temperature sensors and remote digital temperature sensors. Local temperature sensors
 measure their own die temperature while remote digital sensors measure an external transistor. 
 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={TempSensors} alt="types of temperature sensors" width="75%"/>
                                </div>
 
                        </p>
            </div>
    )
}