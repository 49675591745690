import Conduction2Img from '../../Images/Conduction2Img.jpg'
import ConductionTableImg from '../../Images/ConductionTableImg.jpg'
import TwoDimensionalImg from '../../Images/TwoDimensionalImg.jpg'
import SteadyTransientImg from '../../Images/SteadyTransientImg.jpg'

export default function Conduction(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Conduction</h1>
               <p>
                              
           <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
           <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={Conduction2Img} alt="Conduction Equations" width="100%"/>
                                </div> 
                                <div style={{width:'60%'}}>
                                <h2>Intro to Conduction;</h2> 
                                Conduction is the process of high energy particles transferring heat energy to lower energy particles through a solid. One of the ways 
                                this energy transfer occurs is by atoms or molecules vibrating their energy away to adjacent atoms. The other transfer mechanism occurs when 
                                free electrons shift within a material which is more prevalent in materials such as metals where electrons can freely move.
                                <br />
                        <br /> 
                        <h2>Thermal Diffusivity;</h2>
Thermal diffusivity is the measure of the ability of a material to conduct thermal energy relative to its ability to store energy. Materials with large values will rapidly change to their environments while materials with low numbers will take much longer to reach a new equilibrium state. 
                                </div>
           </div>
          
                        <br /> 
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
           <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={TwoDimensionalImg} alt="Conduction" width="90%"/>
                                </div>  
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={SteadyTransientImg} alt="Conduction" width="90%"/>
                                </div>  
           </div>                             
           <br />
                       
<div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ConductionTableImg} alt="Conduction" width="90%"/>
                                </div>  
                                <div style={{width:'60%'}}>
                                <h2>Fourier’s Law For Heat Conduction;</h2>
Since heat transfer is a vector quantity, it can be written as an expanded version of Fourier’s Law for a three dimensional object or can be broken down as individual heat flux rates for each direction. Note that there is a minus sign in front of the thermal conductivity constant (k) because temperature always travels in the direction of decreasing temperature.

                                </div>
</div>
<br />
                       

                        </p>
            </div>
    )
}