import MassSpringImg from '../../Images/MassSpringImg.jpg'
import RotaryTermsTableImg from '../../Images/RotaryTermsTableImg.jpg'

export default function ElementaryParts(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Elementary Parts of Vibratory Systems</h1>
               <p>
                               
           
                       
                        <h2>Introduction to Vibratory Systems;</h2>
                       
The word spring may conjure images of a spiraling length of metal, but in engineering physics the term spring is used more generally to describe the potential energy storing effects of any object. When an object stores kinetic energy it is referred to as mass or inertia and the means in which energy is gradually lost is known as a damper. All systems in engineering can thus be modeled as a system of mass, springs and dampers. 
<br />
                        <br /> 
The field of vibrations focuses on the alternating transfer of energy between potential and kinetic forms. In a damped system, energy is lost with every cycle of vibration and that system will eventually return to rest if no outside energy is added into the system. Within real world applications most objects act as a spring, mass and damper with various characteristics depending on the materials but for this introduction consider elements to have only a single function. This is called lumped parameter systems. With regards to movement, linear motion is referred to as displacement and rotational movement is referred to as angular motion.
<br />
                        <br /> 
<h2>Mass;</h2>

A rigid mass (non deforming) is defined by the amount of force it takes to accelerate by a certain amount. The defining equation which models this relationship is Newton’s 2nd Law F=ma.
<br />
                        <br /> 
<h2>Spring;</h2>

In a linear spring, the change in its length is directly proportional to the force acting along its central axis. The spring stiffness, denoted as the k-value is a constant unique to any spring.
<br />
                        <br /> 
<h2>Damper;</h2>

Similarly to the spring constant, the damping coefficient is a characteristic parameter of a damper system and is representative of the magnitude of the damping which the object will perform onto the system.
<br />
                  
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={MassSpringImg} alt="Mass Spring Dampers" width="75%"/>
                                </div>
<h2>Rotational Motion;</h2>

The elements of a mechanical system are the same as those involved in rotational motion though the variables and terminology is slightly different. The table below highlights these differences. 
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={RotaryTermsTableImg} alt="Rotary Terms" width="75%"/>
                                </div>     
                
                        </p>
            </div>
    )
}