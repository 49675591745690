import CamsImg from '../../Images/CamsImg.jpg'
import CamFollowersImg from '../../Images/CamFollowersImg.jpg'
import CamTerminologyImg from '../../Images/CamTerminologyImg.jpg'

export default function Cams(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Cams</h1>
               <p>
<h2>Intro to Cam and Followers;</h2>
                        A cam is an asymmetric machine element which rotates to move another machine element known as a follower. 
                        The follower is often designed to maintain contact with the rotating cam and move in a linear motion in accordance
                         to the length of the cam at a particular angle. Traditionally springs are mounted between the back plate and the
                          follower to maintain a certain pressure at all times.                    
                        <br />
                        <br />
                        The cam and follower is widely used in exhaust systems as their unique relationship is excellent for opening
                         and closing valves using the rotational motion of a motor. These mechanisms are also commonly found in periodic
                          machines which require specific machine parts to be a certain height with high reliability. 
                        <br />
                        <br />
                        Cams can be classified in a variety of ways but the most important characteristic is the motion of the follower
                         in regards to the motion of the cam. The design style of the cam and follower can often be used independently 
                         depending on the needs of the application.
                        <br />
                        <br />
                        <h1>Types of Cams</h1>
                        <br />
                        <br />
                        1. Disk or plate cam: an oval cam shape transmits motion and sets the follower to a specific height.
                        <br />
                        2. Cylindrical cam: follower motion is achieved using a groove in the cam’s cylindrical surface.
                        <br />
                        3. Translating cam: uses linear cam motion and a humped-shaped cam.
                        <br />
                        4. Wedge cam: uses linear cam motion and a diagonal-shaped cam.
                        <br />
                        5. Spiral Cam: moves the follower in a reciprocating motion along determined by a length of spiral grooves in the cam.
                        <br />
                        6. Heart-shaped cam: uses a heart-shaped cam to allow the follower a stable rest position.
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={CamsImg} alt="cams" width="55%"/>
                                </div>
                        <h1>Types of Followers</h1>
                        <br />
                        <br />
                        1. Knife-edge follower: has a short, sharp area of contact for the mating surface of the follower.
                        <br />
                        2. Roller follower: uses a rolling cylinder face for the follower.
                        <br />
                        3. Flat-faced follower: used for limited space applications, the flat follower face mates with a rolling cam.
                        <br />
                        4. Spherical follower: similar to flat-faced cam, utilizes a curved mating surface.
                        <br />
                        5. Radial follower: when the position of the follower is along the rotating axis of a cam.
                        <br />
                        6. Offset follower: when the position of the follower is off-center to the rotating axis of the cam.
                        <br />
                        Oscillating/ Flat Follower: the follower in contact is a flat surface which oscillates when the cam rotates.
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={CamFollowersImg} alt="cam followers" width="65%"/>
                                </div>
                        <h1>Terminology of Cams</h1>
                        <br />
                        <br />
                        Cam Profile: The surface area which touches between the cam and follower.
                        <br />
                        Base Circle: The smallest circle that can be drawn from the cam profile.
                        <br />
                        Trace Point: A point used to trace the cam profile.
                        <br />
                        Pitch Curve: A curve generated from assuming the cam is fixed and tracing the follower around the cam.
                        <br />
                        Pressure Angle: The angle formed between the normal angle to the pitch curve and the line of motion.
                        <br />
                        Pitch Point: The point where the pressure angle is at a maximum.
                        <br />
                        Pitch Circle: The circle which passes through the pitch point.
                        <br />
                        Prime Circle: A circle that is drawn tangentially to the pitch curve and is concentric to the base circle.
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={CamTerminologyImg} alt="Cam Terminology" width="35%"/>
                                </div>
                        </p>
            </div>
    )
}