import FitsImg from '../../Images/FitsImg.jpg';


export default function Fits(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
                     {/* <SectionTitle title={props.topicId}/> */}
                     <h1>Types of Fits</h1>
                      <p>
                            
                            
                           <h2> Clearance Fit;</h2>
A clearance fit is a tolerance designed so that the largest shaft is smaller than the smallest hole. This allowance is always positive and greater than zero.
<br />
<br />
<h2>Interference Fit;</h2>
An interference fit is when the maximum clearance is negative and the parts must always be forced together.
<br />
<br />
<h2>Transition Fit;</h2>
A transition fit is when the tolerance allowance is negative and the max clearance is positive. In this case, the parts may be loose or forced together.
<br />
<br />
 <h2>Running and Sliding Fits (RC);</h2>
These are intended to provide a fit so that moving parts have a suitable allowance for lubrication.
<br />
<br />
<h2>Force Fit (FN);</h2>
Also known as shrink fits, this type of fit typically involves forcing a cooling or heating of a part, assembling it and then having the material expansion act as a force to keep parts together.
<br />
<br />
The smallest force fit interference for a force fit is 
Min Interference + LMC Shaft - LMC Hole
<br />
<br />
The greatest amount of interference for a force fit is 
Max Interference = MMC Shaft - MMC Hole
<br />
<br />
<h2>Location Fits;</h2>
These are intended only to determine the location of a mating part. 
                         
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={FitsImg} alt="Fits" width="55%"/>
                            </div>
                            </p>
            </div>
    )
}