import PositionImg from '../../Images/PositionImg.jpg'
import DisplacementImg from '../../Images/DisplacementImg.jpg'
import VelocityImg from '../../Images/VelocityImg.jpg'
import AccelerationImg from '../../Images/AccelerationImg.jpg'

export default function ParticalKinematics(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Particle Kinematics</h1>
               <p>

              <h2>Intro to Particle Kinematics;</h2>
              
Particle kinematics is the study of motion with a particle which has a theoretical mass of zero.
<br />
                        <br /> 
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={PositionImg} alt="Position" width="100%"/>
                                </div>
                                <div style={{width:'60%'}}>
                                <span style={{ fontWeight: 'bold' }}>Position:</span> position is the three dimensional coordinates of where an object is in relation to a defined origin, denoted O.
                                </div>
                        </div>
<div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={DisplacementImg} alt="Displacement" width="100%"/>
                                </div>
                                <div style={{width:'60%'}}>
                                <span style={{ fontWeight: 'bold' }}>Displacement:</span> the concept of displacement of an object over time is defined as the change in position from where the object was at time zero to the location of the object at time i. 

                                </div>
</div>
<div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={VelocityImg} alt="Velocity" width="100%"/>
                                </div> 
                                <div style={{width:'60%'}}>
                                <span style={{ fontWeight: 'bold' }}>Velocity:</span> velocity is the resultant of an object's change in displacement over the change in time. Velocity is a vector quantity which means it must be defined with both a magnitude and a direction.
                                <br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Speed:</span> unlike velocity, speed is a scalar quantity which means it is only defined by a magnitude equal to the change of position over the time elapsed.

                                </div>
</div>
<div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={AccelerationImg} alt="Acceleration" width="100%"/>
                                </div>  
                                <div style={{width:'60%'}}>
                                <span style={{ fontWeight: 'bold' }}>Acceleration:</span> acceleration is the measurement of the change in velocity over the change in time. On a plot, the tangent of a velocity curve at any point is the instantaneous acceleration of that particle at that exact instance.

                                </div>
</div>
                
                        </p>
            </div>
    )
}