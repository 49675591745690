import EndMillTableImg from '../../Images/EndMillTableImg.jpg'
import MillingCutsImg from '../../Images/MillingCutsImg.jpg'
import FeedSpeedsImg from '../../Images/FeedSpeedsImg.jpg'
import SpeedEquationsImg from '../../Images/SpeedEquationsImg.jpg'

export default function Speeds(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Speeds & Feeds</h1>
               <p>
               <h2>Intro to Feed and Speeds;</h2>
Every milling operation requires a unique cutting speed and feed rate which will vary based on the cutting tool diameter, the specific model of the end mill bit, the operation and the material being worked on. Most CNC mills have built-in programs which calculate the optimal cutting speed and feed rate for you but it is common for manual mills and lathes to require an input of spindle speed and feed rate by the user.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={EndMillTableImg} alt="End Mill Table" width="55%"/>
                                </div>
                                When referring to speed, it is almost always talking about either the cutting speed (V_c) or the spindle speed (n). The cutting speed, also known as the surface speed, uses units of surface feed per minute (SFM) and is a measure of the number of feet per minute that a given point on the circumference of the cutter travels per minute. This is usually found using a table that is specific to the end mill bit. 
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={MillingCutsImg} alt="Milling Cuts" width="45%"/>
                                </div>
                                The spindle speed (n), measured as revolutions per minute (RPM) is determined using the equation below for imperial units. The inches per minute (IPM) is the number of inches which the cutter passes through in one minute. The chip load is the amount that each flute cuts in a single revolution.
<br />
                        <br /> 
The feed rate (V_F) measures how far the cutting tool will travel per minute from the tool’s center line. The feed rate can be calculated using the question below. The feed per tooth variable is also found through tables specific to the end mill.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={FeedSpeedsImg} alt="Feed Speeds" width="65%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={SpeedEquationsImg} alt="Feed Speeds" width="65%"/>
                                </div>
                        </p>
            </div>
    )
}