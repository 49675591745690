import PowderAdhesionImg from '../../Images/PowderAdhesionImg.jpg'

export default function Printers(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Intro to 3D Printers</h1>
               <p>
                               
           
                       <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                       <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={PowderAdhesionImg} alt="Powder Adhesion" width="100%"/>
                                </div>
                                <div style={{width:'60%'}}>
                                <h2>Intro to Binder Jetting;</h2>
Using powder adhesion, both plastic and metal parts can be constructed in rapid batches which have made this process the preferred mass production method. Binder jetting is a low-cost, low-energy fabrication process which uses the same powder as laser jetting technologies but instead of sintering them using a high powered laser, this utilises a liquid binding agent. After printing, the parts are then either cured if using plastic or sintered if the parts are metal. 

                                </div>
                       </div>
                        
                        </p>
            </div>
    )
}