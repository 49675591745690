import CylinderSizesImg from '../../Images/CylinderSizesImg.jpg'
import ShieldingGasesTable from '../../Images/ShieldingGasesTable.PNG'

export default function WeldingGases(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Welding Gases</h1>
               <p>
                                
           
                       
                        <h2>Selecting MIG and TIG Welding Gas;</h2>
                        Welding gases are used in a variety of different ways in both MIG and TIG welding. The most common use is for shielding of the arc generated between the tip of the welding gun and your workpiece. The shielding provides protection against impurities in the air, dust and other gases. Secondary functions include keeping welds clean on the underside of the seam, heating the metal workpiece and protecting the metal after the welding process.
                                            
<br />
                        <br /> 

Gases generally can be categorized into two groups, inert and reactive. Inert gases do not react with other gases and temperatures while reactive gases do. Inert gases are used in welding for shielding and purity while reactive gases are used to enhance the way the material is fused.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={CylinderSizesImg} alt="Cylinder Sizes" width="65%"/>
                                </div>
<h2>Shielding Gas;</h2>

Air has a tendency to create bubbles within a weld which can cause porosity (holes within the weld bead) and excessive splatter. Unless the welding filler is either flux-cored or flux-coated, both MIG and TIG require a shielding gas. Most shielding gases are inert, which makes them perfect for the localized extreme temperatures in the welding process.
<br />
                        <br /> 
                        Shielding gases can also be used to improve material penetration, improve fluidity of molten pool and can add a smoother finish to the final cooled weld.
                        <br />
                        <br /> 
                        MIG welding gas flow of shielding gases is measured in cubic feet of gas per hour (CFH) which is different from pounds per square inch (psi). These flows are typically very slow and equate to 6-8 psi in-line pressure.
                        <br />
                        <br /> 
                        The four most common shielding gases for MIG welding are Argon, Carbon Dioxide, Helium and Oxygen, or a mixture.
                        <br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Carbon Dioxide (CO2)</span>   is the most common shielding gas due to lowest cost and is the only one that can be used without the addition of an inert gas for welding mild steel. 
                        <br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Argon gas</span>    can produce the highest quality welds but requires a mixture with CO2 (75% Argon and 25% C02) for welding mild steel. Aluminum and titanium welding requires 100% Argon gas. Argon gas is excellent at providing constant arc stability, puddle control, reduced splatter and narrower penetration profiles.
            

<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ShieldingGasesTable} alt="Shielding Gases" width="60%"/>
                                </div>
                        </p>
            </div>
    )
}