import AlgebraTableImg2 from '../../Images/AlgebraTableImg2.PNG'
import AlgebraTableImg3 from '../../Images/AlgebraTableImg3.PNG'

export default function VariableFunctions(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Variable Functions</h1>
               <p>
                                
     <h2>Basic Variable Functions;</h2>
     <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={AlgebraTableImg2} alt="Algebra Table" width="65%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={AlgebraTableImg3} alt="Algebra Table" width="65%"/>
                                </div>
                        </p>
            </div>
    )
}