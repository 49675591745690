import BatteriesEqImg from '../../Images/BatteriesEqImg.jpg'

export default function Batteries(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Batteries</h1>
               <p>
                                
           
                      
                        <h2>Intro to Batteries;</h2>
Batteries are devices that are capable of storing electrical energy as chemical energy to be used for later. There are three main components of
 a battery, two terminals known as the anode (positive) and the cathode (negative) and an electrolyte which separates the terminals. When a 
 device is connected to a battery, a circuit is formed which causes a chemical reaction within the battery to occur which then leads electrons
  out of the battery. 
<br />
                        <br /> 
Some batteries are disposable and will function until they run out of reactants and can only work in one direction. Rechargeable batteries on
 the other hand are bi-directional and are designed so that an electrical energy from an outside source can reverse the operation and restore
  the charge.
<br />
                        <br /> 
<h2>C Ratings;</h2>
The discharge current of a battery is often expressed in terms of C rating which is a measure of the rate at which a battery is discharged
 relative to its maximum capacity. A 1C rate would mean a battery discharges over the span of 1 hour. A battery with 100 Amp-hours and 1C 
 would have a 100 Amp current as it discharged.
<br />
<br /> 
<h2>Types of Batteries;</h2>

Nickel Cadmium Batteries
<br /> 
Alkaline (non-rechargeable)
<br /> 
Nickel Metal Hydride (rechargable)
<br /> 
Lithium Ion
<br /> 
Sealed Lead Acid
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={BatteriesEqImg} alt="battery equivalents" width="80%"/>
                                </div>
                        </p>
            </div>
    )
}