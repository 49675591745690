import HeatTreatmentImg from '../../Images/HeatTreatmentImg.jpg'

export default function Strengthening(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Material Strengthening</h1>
               <p>
        
                        <h2>Intro to Material Strengthening;</h2>
Since slip occurs due to dislocation motion, it is possible to strengthen a material by slowing or stopping the dislocations.
<br />
                        <br /> 
<h2>Reduction in Grain Size;</h2>
Smaller, finer grain materials are harder and stronger than coarse materials since a decrease in grain diameter is an increase in total system grain area. As the grain size decreases, yield strength increases. Increasing the degree of misorientation can impede motion since a grain attempting to travel past another may have to change orientation to continue travel. This disorder between grains can change the continuity from one slip plane to another causing a higher degree of angle strain boundaries.
<br />
                        <br /> 
<h2>Impurity Atoms;</h2>
Impurity atoms generate stress within a lattice and can produce a barrier to dislocation motion. Both attractive and repulsive forces between solutes and dislocations can also prevent motion and increase strength. Generally, solute atoms can increase strength in crystals but under some conditions they may decrease it. 
<br />
                        <br /> 
<h2>Precipitate Hardening;</h2>
Because hard precipitates are difficult to shear, particles are forced to bypass precipitates by looping past them. Stress increases after this looping because subsequent dislocations will have to squeeze through an even smaller section which results in an increase in system strength.
<br />
                        <br /> 
<h2>Work Hardening;</h2>
Cold work is a common forming operation which reduces the cross-sectional area of a material while under room temperature. As cold work is done, yield and tensile strength increases while ductility decreases. Dislocations also entangle during this process which further makes dislocation motion difficult. 
<br />
                        <br /> 
<h2>Heat Treating (Annealing);</h2>
Heat treatment is the restorative process of a material to a state before cold work by recrystallization followed by grain growth. Plastic deformation increases the density of material dislocations and changes the grain size distribution which increases the stored strain energy. When the external stress is removed, most dislocations, grain distortions and relevant strain energy is retained. Stages of annealing include recovery, recrystallization and finally grain growth.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={HeatTreatmentImg} alt="Heat Treatment" width="65%"/>
                                </div>
                        </p>
            </div>
    )
}