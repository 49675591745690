import FD10 from '../../Images/FD10.PNG'
import Fittings from '../../Images/Fittings.jpg'

export default function Considertations(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Flow Considerations</h1>
               <p>
                              
           
                <h2>Head Loss Due to Flow</h2>
                        A “Head” in regards to a hydraulic system is the measurement of fluid pressure above a vertical datum. Head loss is
                         the process of potential energy within a fluid being converted to kinetic due to frictional resistances found throughout
                          a pipe/tube system. This system includes the pipe, valves, fittings, entrance and exit. Head loss is often high enough
                           that it cannot be ignored in flow calculations. Its values largely depend on the square of the flow rate. 
                           <br />
                        <br /> 
The head loss due to friction (H_f) is usually determined by the Darcy-Weisbach equation. This equation uses the dimensionless factor f which 
is a function of the Reynold’s number. L is the length over which the pressure drop occurs, D is the diameter of the pipe and v is the velocity
 of the fluid flow. Losses also occur as the fluid flows through pipe fittings, elbows, valves, couplings and sudden pipe contractions and 
 expansions.
 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={FD10} alt="linkages" width="35%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={Fittings} alt="linkages" width="75%"/>
                                </div>
                
                        </p>
            </div>
    )
}