import QuadraticEquationsImg from '../../Images/QuadraticEquationImg.jpg'

export default function Quadratic(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Quadratic Equations</h1>
               <p>
                              
           <h2>Intro to Quadratic Equations;</h2>
           A quadratic equation can be modeled as y= ax^2 + bx + c and traces out to form a parabola. The narrowness of the parabola is influenced by the variable (a) while the horizontal shift is given by (-b/2a). The vertical shift is given by (-b^2 /a +c). The roots of the equation, which are the points where the parabola crosses the x axis can be determined by using the quadratic function.           <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={QuadraticEquationsImg} alt="Quadratic" width="55%"/>
                                </div>
                        </p>
            </div>
    )
}