import ODEImg1 from '../../Images/ODEImg1.PNG'
import ODEImg2 from '../../Images/ODEImg2.PNG'

export default function ODEs(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Ordinary Differential Equations</h1>
               <p>
                                
           
                       
                        <h2>Intro to ODEs;</h2>
A differential equation is any equation which contains derivatives both ordinary or partial. The order of the equation is the largest amount of differentials present in the equation. For example, if a variable such as acceleration is being derived twice, then the order of the equation is two. An ordinary differential equation only has standard derivatives within it while partial differential equations have partial derivatives. An initial condition is a set of conditions or additional information which allows the equation to be solved.
<br />
                        <br /> 
<h2>First Order Homogeneous Differential Equation;</h2>
Any equation that can be written in the following form. Note that [a] is a real number constant and C is a constant which satisfies the initial conditions.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ODEImg2} alt="ODE equation" width="35%"/>
                                </div>

<h2>Second Order Homogeneous Differential Equation;</h2>
Any equation that can be written in the following form.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ODEImg1} alt="ODE equation" width="35%"/>
                                </div>
                        </p>
            </div>
    )
}