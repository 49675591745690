import NFPAImg from '../../Images/NFPAimg.jpg'

export default function Hazard(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>NFPA Hazard Rating</h1>
               <p>
              
                                <h2>Intro to NFPA Hazard Rating System;</h2>                 
The NFPA (national fire protection association) standard is an organizational system which outlines the hazard level for emergency personnel. The standard also displays the types of hazardous materials that are present. 
<br />
                        <br /> 
The rating includes three color codes (blue, red and yellow) which each have five intensity levels which range from 0 to 4. The rating of 4 is the most dangerous. The rating also has a section in white is often left blank. This section is used when a material or chemical has a special hazard, such as reacting explosively from water. 
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Blue:</span> Health Hazard
<br /> 
<span style={{ fontWeight: 'bold' }}>Red:</span> Fire Hazard
<br /> 
<span style={{ fontWeight: 'bold' }}>Yellow:</span> Chemical Instability
<br /> 
<span style={{ fontWeight: 'bold' }}>White:</span> Specific Hazard       
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={NFPAImg} alt="NFPA Rating" width="55%"/>
                                </div>                        
                        </p>
            </div>
    )
}