import ValvesImg from '../../MEComponents/ValvesImg.jpg'

export default function Valves(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Valves</h1>
               <p>
                            
                            <h2>Overview of Hydraulic Valves</h2>
                            In hydraulic systems, the component which controls and adjusts the hydraulic system pressure, fluid flow amount 
                            and direction is the control valve. There are three main functions within valves: pressure valves, which maintain 
                            the fluid pressure below a certain limit, directional control valves which regulate the rate of fluid flow and flow 
                            control valves which direct fluid flow in a particular direction.                            
                            <br />
                            <br /> 
<h2>Types of Directional Control Valves by Control Method;</h2>
On-Off Control Valves
<br /> 
Proportional Control
<br /> 
Servo Control Valves
<br /> 
Digital Control Valves
<br /> 
<br /> 
<h2>Types of Control Valves Structures;</h2>
Sliding Valve
<br />
Cone Valve
<br />
Ball Valve
<br />
Spool Valve
<br />
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={ValvesImg} alt="types of valves" width="75%"/>
                            </div>
                           </p>
            </div>
    )
}