import BucklingImg from '../../Images/BucklingImg.jpg';


export default function Buckling(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>                   
                     <h1>Buckling</h1>
                      <p>
<h2>Intro to Buckling;</h2>
                            <span style={{ fontWeight: 'bold' }}>Buckling</span> is a term used to describe the very specific 
                            instance where a system collapses onto itself on one of its sides. This often occurs when a system has very thin 
                            structural components and a force of a high enough magnitude is added. This rarely happens if designers know 
                            the concept and how to avoid it. When selecting structural materials, always consider whether the loads are being applied on thin structural components and if so, consider thickening the walls or adding additional supports. 
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={BucklingImg} alt="Buckling" width="45%"/>
                            </div>                       
                            </p>
            </div>
    )
}