

export default function WoodFinishes(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Wood Finishes</h1>
               <p>
          
 <h2>Overview;</h2>
As a material, unfinished wood can have a variety of difficulties if not properly covered with a surface finish because wood is porous. Some of these issues include scratching, expansion and contraction from moisture and oils, temperature, liquids and insect damage. A surface finish can both protect the wood from these issues while also improving the aesthetics by forming a protective barrier either on the surface or by penetrating the surface and hardening there.
<br />
<br />
Oil-based finishes will penetrate the wood and are easier to apply. Paint, polyurethane and shellac are each examples of common wood finishes. In almost all cases, covering the wood with multiple layers of the thinnest coating possible will bring out the best results. 
<br />
<br />
 <h2>Coating Processes;</h2>
Evaporative
<br />
Reactive
<br />
Catalyzing
<br />
Coalescing
<br />
Sitting
<br />
<br />
 <h2>Application Methods;</h2>
Wipe on: Some finishes can be wiped-on and worked into the wood using a soft cloth and go on thinner than the brushed on method. Although this method usually requires more layers, they often dry faster.
Brush on: The majority of finishes are designed to be brushed on which requires an even distribution across the surface. Brushed on finishes typically require less coats than other methods. A general tip to avoid sagging and drip marks is to keep the part horizontal when working on a specific section and rotate as needed.
Spray on: Spray on finishes often require a more complex spray gun setup and spray booth and are more commonly found in industrial and commercial applications. This type of finish allows for fast, thin coats to be applied.
<br />
<br />
 <h2>Stains and Dyes;</h2>
Staining wood is the process of adding ground pigments into the surface of the wood, generally to darken or change the color. It is important to note that most stains will lose their color over time if exposed to repeated cycles of sunlight. A stain or dye is used for color and not protection which means a proper surface finish will need to be applied on top to protect the material.
<br />
<br />
 <h2>Common Finishes;</h2>
Shellac: Shellac has been used for decades and is a resin dissolved in alcohol that is food safe. It has excellent moisture protection but has become less common over time.
Wax: beeswax or paste wax does not provide much protection but can be used as a polishing layer over other types of finishes.
Paint: Dry wood generally takes in paint well although priming may be useful for certain oily woods.
Varnish: Varnish is made from dissolving natural resins and provides a tough clear topcoat. 
Oils: Linseed, tung and mineral oils are common options for wood finishing. Oils will not provide a shiny result and may feel sticky for some time.
Polyurethane: Polyurethane is a liquid plastic that provides a durable finish once it cures. A solvent is typically needed to clean brushes or spray guns, so throwaway cloths or paper towels can be used for the wipe-on process.
<br />
<br />
 <h2>Tips for Surface Finishing;</h2>
It is instinctual to want to start from an edge and work your way around the piece, instead, start in the center and slowly move towards the edge, otherwise dripping is likely to occur.
Once you have the finish over the material, leave it alone, it is counterproductive to overwork the finish by stroking back and forth continuously.
Use a reflective light while finishing which will create a glare on the surfaces and allows you to see where the drips are and the locations of missed areas.
Once you open a can of finish, move the substance into a glass jar to ensure the substance is properly sealed.
Wear a mask while applying a finish, just because the finish is food-safe once it cures does not mean it doesn’t create harmful fumes while it dries.
Be sure to prepare the surface by sanding it in incremental grit values until you are satisfied with the appearance and feel.
Suggested formula: two coats tung oil finish followed by two coats of tung oil finish mixed with spar varnish. Mixture is 1 cup of tung oil with 1 tablespoon (tbsp) of varnish.

                        </p>
            </div>
    )
}