import ShaftsImg from '../../MEComponents/ShaftsImg.jpg'

export default function Shafts(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Shafts, Keys and Accessories</h1>
               <p>
 <h2>Intro to Motors;</h2>
                            <span style={{ fontWeight: 'bold' }}>Keys</span> are a small piece of metal used to connect a shaft to a rotating machine element. The <span style={{ fontWeight: 'bold' }}>keyway</span> is a necessary groove cut into the shaft that allows the key to sit nicely within. When the shaft turns, the key turns with it and transfers the power accordingly. These are often used to transfer power between a motor shaft and a gear.
                            <br />
                            <br />
                            Often it is the case that you need a machine component to sit a certain distance away from another object. <span style={{ fontWeight: 'bold' }}>Spacers</span>, which are essentially tubes, can slide onto a shaft and provide that necessary distance. 
                            <br />
                            <br />
                            Sometimes it is easier to use a retaining ring or <span style={{ fontWeight: 'bold' }}>shaft collar</span> which does the same thing but grips onto the shaft for support. Unlike shaft collars, <span style={{ fontWeight: 'bold' }}>retaining rings</span> require a groove cut into the shaft to keep it from sliding around. It is important to note that shaft collars often grip using a small grub screw and are not capable of withstanding high axial forces while a spacer will resist movement to the extent of the material’s properties. 
                            <br />
                            <br />
                            If you want to have a mechanism slide up and down a shaft, then a <span style={{ fontWeight: 'bold' }}>shaft slider</span> is perfect for you. Internally, dozens of metal beads roll against the beam and provide a smooth and nearly frictionless linear motion.	
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={ShaftsImg} alt="Shafts, Keys and Accessories" width="55%"/>
                            </div>
                            </p>
            </div>
    )
}