import React from "react";
import {
//   BrowserRouter as Router,
  useParams,
} from "react-router-dom";
import Conduction from './Conduction';
import Convection from './Convection';
import Radiation from './Radiation';
import Fins from "./Fins";

import Boiling from './Boiling';


const ChangeComponents = {
    conduction: Conduction,
    convection: Convection,
    radiation: Radiation,
    fins: Fins,
   
    boiling: Boiling,
  
  };

export default function Subtopics(props) {
    // The <Route> that rendered this component has a
    // path of `/topics/:topicId`. The `:topicId` portion
    // of the URL indicates a placeholder that we can
    // get from `useParams()`.
    let { topicId } = useParams();
    
    let MyComponent = ChangeComponents[topicId];
    return (
        <div>
            {/* {topicId} is for testing route names */}
            {/* <h3>{topicId}</h3> */}
            <MyComponent topicId={topicId}/>
      </div>
    );
  }