import React from "react";
import {
//   BrowserRouter as Router,
  useParams,
} from "react-router-dom";
import Particles from './Particles';
import Spectrum from './Spectrum';
import Magnetism from './Magnetism';
import Waves from "./Waves";
import Sound from './Sound';
import Optics from "./Optics";
import Light from "./Light";
import Universe from "./Universe";

const ChangeComponents = {
    particles: Particles,
    spectrum: Spectrum,
    magnetism: Magnetism,
    waves: Waves,
    sound: Sound,
    optics: Optics,
    light: Light,
    universe: Universe,
  };

export default function Subtopics(props) {
    // The <Route> that rendered this component has a
    // path of `/topics/:topicId`. The `:topicId` portion
    // of the URL indicates a placeholder that we can
    // get from `useParams()`.
    let { topicId } = useParams();
    
    let MyComponent = ChangeComponents[topicId];
    return (
        <div>
            {/* {topicId} is for testing route names */}
            {/* <h3>{topicId}</h3> */}
            <MyComponent topicId={topicId}/>
      </div>
    );
  }