import EESymbolsImg1 from '../../Images/EESymbolsImg1.jpg'
import EESymbolsImg2 from '../../Images/EESymbolsImg2.jpg'
import EESymbolsImg3 from '../../Images/EESymbolsImg3.jpg'
import EESymbolsImg4 from '../../Images/EESymbolsImg4.jpg'
import EESymbolsImg5 from '../../Images/EESymbolsImg5.jpg'
import EESymbolsImg6 from '../../Images/EESymbolsImg6.jpg'
import EESymbolsImg7 from '../../Images/EESymbolsImg7.jpg'
import EESymbolsImg8 from '../../Images/EESymbolsImg8.jpg'
import EESymbolsImg9 from '../../Images/EESymbolsImg9.jpg'
import EESymbolsImg10 from '../../Images/EESymbolsImg10.jpg'
import EESymbolsImg11 from '../../Images/EESymbolsImg11.jpg'
import EESymbolsImg12 from '../../Images/EESymbolsImg12.jpg'
import EESymbolsImg13 from '../../Images/EESymbolsImg13.jpg'
import EESymbolsImg14 from '../../Images/EESymbolsImg14.jpg'
import EESymbolsImg15 from '../../Images/EESymbolsImg15.jpg'

export default function Symbols(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Electrical Symbols</h1>
               <p>
                                
           
                
                        <h2>Intro to Electrical Symbols;</h2>
                
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={EESymbolsImg1} alt="EE Symbols" width="55%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={EESymbolsImg2} alt="EE Symbols" width="55%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={EESymbolsImg3} alt="EE Symbols" width="55%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={EESymbolsImg4} alt="EE Symbols" width="55%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={EESymbolsImg5} alt="EE Symbols" width="55%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={EESymbolsImg6} alt="EE Symbols" width="55%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={EESymbolsImg7} alt="EE Symbols" width="55%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={EESymbolsImg8} alt="EE Symbols" width="55%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={EESymbolsImg9} alt="EE Symbols" width="55%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={EESymbolsImg10} alt="EE Symbols" width="55%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={EESymbolsImg11} alt="EE Symbols" width="55%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={EESymbolsImg12} alt="EE Symbols" width="55%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={EESymbolsImg13} alt="EE Symbols" width="55%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={EESymbolsImg14} alt="EE Symbols" width="55%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={EESymbolsImg15} alt="EE Symbols" width="55%"/>
                                </div>
                        </p>
            </div>
    )
}