// import VariablesImg from '../../Images/VariablesImg.jpg'

export default function CPlusPlus(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Arduino C++</h1>
               <p>
                                
           
                        
                        <h2>Intro to Arduino C++;</h2>
                        Arduino interface is separated into three sections,
                        <br />
Definitions and imports in the topmost area of the code,
<br />
<code>{`void setup(){`}</code>
<br />
<code>{`//The setup runs once when the arduino is first turned on`}</code>
<br />
<code>{`}`}</code>
<br />
<code>{`void loop(){`}</code>
<br />
<code>{`//The loop runs continuously once the setup has completed`}</code>
<br />
<code>{`}`}</code>








{/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={VariablesImg} alt="Variables" width="65%"/>
                                </div> */}
                
                        </p>
            </div>
    )
}