import PulleysImg from '../../Images/PulleysImg.jpg'

export default function Pulleys(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Pulleys</h1>
               <p>
<h2>Intro to Pulley Assemblies;</h2>
                        A pulley system is an assembly between a spinning wheel and rope that is designed to transfer power and/or gather 
                        mechanical advantage through this exchange. Mechanical advantage describes a process of trading one or more 
                        characteristics for another deemed more valuable for the situation. In the case of pulleys, by adding additional pulleys 
                        to the exchange, a designer can trade additional distance rotated for increases in torsion. Unlike belt and chain drives,
                         the pulley itself acts more as a guide for the rope and provides support for the mechanism.                     
                         <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={PulleysImg} alt="pulley" width="75%"/>
                                </div>
                        </p>
            </div>
    )
}