import React from "react";
import {
//   BrowserRouter as Router,
  useParams,
} from "react-router-dom";
import Basics from './Basics';
import Resistance from './Resistance';
import Grounds from './Grounds';
import Capacitance from "./Capacitance";
import Inductance from "./Inductance";
import AC from "./AC";
import ThreePhase from "./ThreePhase";

const ChangeComponents = {
    basics: Basics,
    resistance: Resistance,
    grounds: Grounds,
    capacitance: Capacitance,
    inductance: Inductance,
    ac: AC,
    threephase: ThreePhase,
 
  };

export default function Subtopics(props) {
    // The <Route> that rendered this component has a
    // path of `/topics/:topicId`. The `:topicId` portion
    // of the URL indicates a placeholder that we can
    // get from `useParams()`.
    let { topicId } = useParams();
    
    let MyComponent = ChangeComponents[topicId];
    return (
        <div>
            {/* {topicId} is for testing route names */}
            {/* <h3>{topicId}</h3> */}
            <MyComponent topicId={topicId}/>
      </div>
    );
  }