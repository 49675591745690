import DiffusionImg from '../../Images/DiffusionImg.jpg'

export default function Diffusion(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Diffusion in Materials</h1>
               <p>
                    
                        <h2>Intro to Diffusion;</h2>
Diffusion is a gradient process of atoms or molecules going from an area of high concentration to areas of lower concentration. Inhomogeneous materials and solutions can become homogenous from diffusion so long as the temperature is enough to overcome energy barriers to atomic motion. Gases and liquids naturally undergo random (Brownian) motion while solids undergo vacancy diffusion or interstitial diffusion. 
<br />
                        <br /> 
Within solid alloys, atoms have a tendency to migrate from regions of high concentrations to those of lower concentrations which can result in boundary layers of separate materials to slowly connect as the atoms from each boundary begin to “jump” to the other side.
<br />
                        <br /> 
<h2>Vacancy Diffusion;</h2>
 In order to move from lattice site to lattice site atoms need a certain amount of energy to break their bonds with neighboring atoms and to cause the necessary lattice distortions during motion from one site to another. This energy largely comes from atomic vibrations. The amount of vacancy diffusion is proportional to the amount of vacancies in the atom and the activation energy of the exchange. As temperature within a material increases, so does the number of vacancies thus leading to increases in diffusion.
 <br />
                        <br /> 
<h2>Interstitial Diffusion;</h2>
Interstitial diffusion requires smaller impurity atoms to fit into the interstices with a host’s structure. This generally occurs much faster than vacancy diffusion because there are far more interstitial sites than vacancies.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={DiffusionImg} alt="Diffusion" width="65%"/>
                                </div>
                        </p>
            </div>
    )
}