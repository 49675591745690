import PlanarImg from '../../Images/PlanarImg.jpg'
import T41 from '../../Images/T41.JPG'

export default function Planar(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Planar</h1>
               <p>
    
     <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={PlanarImg} alt="Planar" width="45%"/>
                                </div>
                                <span style={{ fontWeight: 'bold' }}>Description:</span> A wood planar is a mechanical machine which serves to smooth rough wood stock surfaces, make opposite material sides parallel and set thickness to a set dimension.
                                <br />
                        <br /> 
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={T41} alt="Planar" width="100%"/>
                                </div>
                                <br /> 
                        <span style={{ fontWeight: 'bold' }}>Intake Width:</span> The maximum width of material that will fit horizontally into the planar.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Max Intake Height:</span> The maximum height of material that will fit vertically into the planar.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Speed (Feed Rate):</span> The rotating speed at which the internal cutting blades will spin.

                        </p>
            </div>
    )
}