import CombustionImg from '../../Images/CombustionImg.jpg'

export default function Combustion(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Combustion Systems</h1>
               <p>
 <h2>Intro to the Combustion Cycle;</h2>
                        The process of combustion utilizes the release of energy from a fuel and air mixture. In an internal combustion
                         engine, this process occurs inside of the engine itself and through a series of pistons, crankshafts and 
                         valves, will travel through the engine and convert mechanical power.                    
                         <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={CombustionImg} alt="combustion cycle" width="65%"/>
                                </div>
                        </p>
            </div>
    )
}