import AbrasiveBlastingImg from '../../Images/AbrasiveBlastingImg.jpg'

export default function AbrasiveBlasting(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Abrasive Blasting</h1>
               <p>
                                


                        <h2>Intro to Shot Peening;</h2>
Shot peening is a post processing technique which works by striking a surface with round ball shots made from metals, glass or ceramics with enough force to generate plastic deformation. The multiple indentation leaves a residual compressed layer of stress within the material surface. The main advantage of this technique is to extend the surface life of a component by using the compressed layer of stress to combat fatigue while helping resist the propagation of cracks.
<br />
                        <br />
The shot peening process is commonly performed by an air blasting system or centrifugal blast wheel. Air blast systems use a high pressure air nozzle to shoot at a workpiece.
<br />
                        <br />
<h2>Intro to Sand Blasting;</h2>
Also known as abrasive blasting, the sandblasting manufacturing technique operates by propelling a stream of abrasive material such as sand against a surface under high pressure. This can be used to create uniformity in coloration of a material surface while also smoothing rough surfaces. Although sand blasters can come in handheld versions, traditionally machines come as a box with an inlet to allow the operator to fit their hands inside the machine while being protected. The sand can be recycled back into a storage chamber which is then used again for a number of cycles.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={AbrasiveBlastingImg} alt="Abrasive Blasting" width="55%"/>
                                </div>
                        </p>
            </div>
    )
}