import React from "react";
import {
//   BrowserRouter as Router,
  useParams,
} from "react-router-dom";
import SheetMetal from "./SheetMetal";
import PressBrake from "./PressBrake";
import LaserCutting from "./LaserCutting";
import Glass from "./Glass";
import Casting from './Casting';
import Plastic from './Plastic';
import PMC from './PMC';
import Powder from "./Powder";
import Speeds from "./Speeds";
import EndMills from "./EndMills";
import Taper from "./Taper";
import PlasmaCutting from "./PlasmaCutting";
import Turning from "./Turning";
import TubeBending from "./TubeBending";

const ChangeComponents = {
    sheetmetal: SheetMetal,
    pressbrake: PressBrake,
    lasercutting: LaserCutting,
    glass: Glass,
    casting: Casting,
    plastic: Plastic,
    pmc: PMC,
    powder: Powder,
    speeds: Speeds,
    endmills: EndMills,
    taper: Taper,
    plasmacutting: PlasmaCutting,
    turning: Turning,
    tubebending: TubeBending,
  };

export default function Subtopics(props) {
    // The <Route> that rendered this component has a
    // path of `/topics/:topicId`. The `:topicId` portion
    // of the URL indicates a placeholder that we can
    // get from `useParams()`.
    let { topicId } = useParams();
    
    let MyComponent = ChangeComponents[topicId];
    return (
        <div>
            {/* {topicId} is for testing route names */}
            {/* <h3>{topicId}</h3> */}
            <MyComponent topicId={topicId}/>
      </div>
    );
  }