import LinearMotionImg from '../../Images/LinearMotionImg.jpg'
import OscillatingMotionImg from '../../Images/OscillatingMotionImg.jpg'
import ReciprocatingMotionImg from '../../Images/ReciprocatingMotionImg.jpg'
import RotaryMotionImg from '../../Images/RotaryMotionImg.jpg'
import StaticForceImg from '../../Images/StaticForceImg.jpg'
import DynamicForceImg from '../../Images/DynamicForceImg.jpg'
import TensionImg from '../../Images/TensionImg.jpg'
import CompressionImg from '../../Images/CompressionImg.jpg'
import ShearForceImg from '../../Images/ShearForceImg.jpg'
import TorsionImg from '../../Images/TorsionImg.jpg'

export default function ForcesMotion(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Forces and Motion</h1>
               <p>
  <h2>Intro to Motion;</h2>
                
There are several types of movement, linear motion, angular/rotary motion, oscillating motion and reciprocating motion. <span style={{ fontWeight: 'bold' }}>Displacement</span> is defined as the change in position of an object which can be a result of either type of motion.
<br />
                        <br /> 
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={LinearMotionImg} alt="Linear Motion" width="100%"/>
                                </div>
<div style={{width:'60%'}}>
<span style={{ fontWeight: 'bold' }}>Linear</span> motion is movement which travels in one direction along a straight line. This motion can take place in both two and three dimension spaces. Linear motion is measured in terms of distance with units such as meters (metric) or feet, inches (imperial/US). 

</div>
                        </div>
<br />
                        <br /> 
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={OscillatingMotionImg} alt="Oscillating Motion" width="100%"/>
                                </div>
                                <div style={{width:'60%'}}>
                                <span style={{ fontWeight: 'bold' }}>Rotary/ angular</span> motion is movement which rotates around a fixed point. Rotational/angular motion is measured in terms of angle with such units as degree or radians.

                                </div>
                        </div>
<br />
<div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ReciprocatingMotionImg} alt="Reciprocating Motion" width="100%"/>
                                </div>
                                <div style={{width:'60%'}}>
                                <span style={{ fontWeight: 'bold' }}>Oscillatory or oscillating</span> motion is a type of periodic motion which occurs when an object perpetually travels back and forth from a fixed point. A great example of this is the swinging arm of a pendulum clock.

                                </div>
</div>
<div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={RotaryMotionImg} alt="Rotary Motion" width="100%"/>
                                </div>
                                <div style={{width:'60%'}}>
                                <span style={{ fontWeight: 'bold' }}>Reciprocating motion (reciprocation)</span> is a repetitive linear movement where an object moves back and forth along a straight path. A great example of this is a piston sliding back and forth within a gas engine.

                                </div>
</div>
                        <br /> 
<br />
                        <br /> 
<h2>Intro to Force;</h2>
In engineering, we often refer to the general term of <span style={{ fontWeight: 'bold' }}>force</span> as exclusively that which moves things in a linear motion while <span style={{ fontWeight: 'bold' }}>torque</span> is that which moves things in a rotational manner. Force is a <span style={{ fontWeight: 'bold' }}>vector</span> quantity, which means it has a magnitude, a direction and a point of application. When forces only have a magnitude and no fixed direction it is referred to as a <span style={{ fontWeight: 'bold' }}>scalar</span> force.
<br />
                        <br /> 
<div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={StaticForceImg} alt="Static Force" width="100%"/>
                                </div>
                                <div style={{width:'60%'}}>
                                <span style={{ fontWeight: 'bold' }}>Static Force:</span> The word static describes that there is a force acting upon an unmoving environment. An example of this is a person standing still while holding a basketball in their outstretched hand.
                                </div>
</div>
<div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={DynamicForceImg} alt="Dynamic Force" width="100%"/>
                                </div>
                                <div style={{width:'60%'}}>
                                <span style={{ fontWeight: 'bold' }}>Dynamic Force:</span> Unlike static force, dynamic force is termed for when a force is acting upon a system which is moving. If a person is running while holding a basketball, then the downwards force of the ball is acting upon a dynamic system.    </div>
</div>
<div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={TensionImg} alt="Tension" width="100%"/>
                                </div>
                                <div style={{width:'60%'}}>
                                <span style={{ fontWeight: 'bold' }}>Tension:</span> Tension is the occurrence of two forces acting upon the same object in opposing directions such as a rope when two people pull on each side.</div>
</div>
<div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={CompressionImg} alt="Compression" width="100%"/>
                                </div>
                                <div style={{width:'60%'}}>
                                <span style={{ fontWeight: 'bold' }}>Compression:</span> Compression is when two forces are pushing towards each other. If a person holds an object above their head while standing, a reactive force is moving up from the ground and down from the heavy object.</div>
</div>
<div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ShearForceImg} alt="Shear Force" width="100%"/>
                                </div>
                                <div style={{width:'60%'}}>
                                <span style={{ fontWeight: 'bold' }}>Shear Force:</span> Forces which intersect along a line and move past each other are known to cause shearing. Scissors use this principle to apply two opposite forces along a thin cutting line which separates as the blades move closer together.</div>
</div>
<div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={TorsionImg} alt="Torsion" width="100%"/>
                                </div>
                                <div style={{width:'60%'}}>
                                <span style={{ fontWeight: 'bold' }}>Torsion:</span> Torsion or torque is the rotational application of force. Turning a bolt with a wrench requires a certain amount of torque to overcome the frictional forces and move the fastener.</div>
</div>
                        </p>
            </div>
    )
}