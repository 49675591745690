import PressureTransducer from '../../Images/PressureTransducer.jpg'

export default function PressureSensors(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Pressure Sensors</h1>
               <p>

<h2>Intro to Pressure Transducers;</h2>
Pressure transducers are components which convert fluid pressure into an analog electrical signal. Pressure transducers often utilize strain
 gauges which measure the force against them by deforming under pressure and altering their voltage produced accordingly. There are also
  pressure transducers which use capacitors or piezoelectric sensors instead of strain gauges. In all cases, the voltage, current or frequency
   is converted into a signal which is then relayed back to the computer or PLC. 
   <br />
<br />  
<h2>Overview of Types of Pressure;</h2>
Absolute Pressure: Measures the measure in relation to a perfect vacuum pressure using absolute zero as the reference point.
<br />  
Gauge Pressure: Measures pressure in relation to atmospheric pressure.
<br />  
Differential Pressure: Compares the pressure from each side of the sensor.
<br />
<br />  
<h2>Types of Pressure Transducers;</h2>
Strain Gauge Pressure Transducer
<br />  
Capacitance Pressure Transducer
<br />  
Potentiometric Pressure Transducer
<br />  
Resonant Wire Pressure Transducer
<br />
<br />  
<h2>Output Signal Overview;</h2>
As stated above, when a pressure transducer is connected to a power and pressure source it will generate an electrical signal proportional to
 the pressure. This output signal can come in four main varieties, each with their own advantages and disadvantages.
 <br />
<br />  
Digital Pressure Transducer: The digital signal provides more versatility than the analog. Depending on the protocol, these transmission lines
 can be longer than a mile and are often equipped with “smart sensing” capabilities which include alarms, location, calibration sensing and log
  data.
  <br />
<br />  
Millivolt Output Pressure Sensor (ratiometric): With this sensor, the actual output is directly proportional to the input power or excitation.
 Due to the output signal being so low, these should not be used in environments with alot of electrical noise. However, due to the sensor’s
  lack of signal conditioning, they can operate in harsh environments.  
  <br />
<br />  
Voltage Pressure Transmitter: Using an input between 0-5dc or 0-10 Vdc, this type of transducer provides a higher output voltage due to its 
signal conditioning. These outputs are not a direct function of excitation which allows them to work with unregulated power supplies so long
 as they fall within an adequate power range. These are ideal for environments with high electrical noise.
 <br />
<br />  
mA Output Pressure Transducer: Usually operating from 0-20mA, this transducer is often a two wire configuration where the input wire provides 
power and the output wire sends out the current output signal. This allows it to be more immune to electrical interference while allowing long
 transmission line distances.

  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={PressureTransducer} alt="pressure transducer" width="70%"/>
                                </div>
                        </p>
            </div>
    )
}