import React from 'react';
import Subtopics from './Subtopics';
import { Container } from "react-bootstrap";
import {
    Switch,
    Route,
    Link,
    useRouteMatch
  } from "react-router-dom";

function Tools(props) {
    let { path, url } = useRouteMatch();
    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', maxWidth: "100vw"}}>
                {/* <SectionTitle title={props.topicId}/> */}
            <div style={{display: 'flex', flexDirection: 'row'}}> 
            <div style={{marginTop: '10px', marginRight: '0px', marginLeft: '5px',border: '3px', padding:'40px', paddingRight: '0px', borderStyle:'solid', borderColor:'grey', paddingLeft: '20px', whiteSpace:'nowrap'}}>
                <h2 style={{whiteSpace:'normal'}}>Tools & Machines</h2>
                   
                <li>
                    <Link to={`${url}/shoppress`}>Shop Press</Link>
                </li>
                <li>
                    <Link to={`${url}/drillpress`}>Drill Press</Link>
                </li>
                <li>
                    <Link to={`${url}/grinder`}>Grinder</Link>
                </li>
                <li>
                    <Link to={`${url}/mitersaw`}>Miter Saw</Link>
                </li>
                <li>
                    <Link to={`${url}/bandsaw`}>Band Saw</Link>
                </li>
                <li>
                    <Link to={`${url}/lasercutter`}>Laser Cutter</Link>
                </li>
                <li>
                    <Link to={`${url}/aircompressor`}>Air Compressor</Link>
                </li>
                <li>
                    <Link to={`${url}/dustcollector`}>Dust Collector</Link>
                </li> 
                <li>
                    <Link to={`${url}/cnc`}>CNC</Link>
                </li>
                <li>
                    <Link to={`${url}/mill`}>Mill</Link>
                </li>
                <li>
                    <Link to={`${url}/platebender`}>Plate Bender</Link>
                </li>
                <li>
                    <Link to={`${url}/plasmacutter`}>Plasma Cutter</Link>
                </li>
                <li>
                    <Link to={`${url}/waterjetcutter`}>Waterjet Cutter</Link>
                </li>
                <li>
                    <Link to={`${url}/lathe`}>Lathe</Link>
                </li>  
                <li>
                    <Link to={`${url}/extrusionprinter`}>Extrusion Printers</Link>
                </li>             
                <li>
                    <Link to={`${url}/resinprinter`}>Resin Printing</Link>
                </li>
                <li>
                    <Link to={`${url}/lasersinterer`}>Laser Sintering</Link>
                </li>    
          
                <li>
                    <Link to={`${url}/handtools`}>Hand Tools</Link>
                </li>
                <li>
                    <Link to={`${url}/woodworkingtools`}>Woodworking Tools</Link>
                </li>
                </div>
                
            <Container>
                <Switch>
                    <Route exact path={path}>
                    <h3>Please select a sub-topic.</h3>
                    </Route>
                    <Route path={`${path}/:topicId`}>
                        <Subtopics />
                    </Route>
                </Switch>
            </Container>
         
                    </div>
            </div>
        </div>
    );
};

export default Tools; 