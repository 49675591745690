import InductanceImg from '../../Images/InductanceImg.jpg'
import InductorTableImg from '../../Images/InductorTableImg.png'

export default function Inductance(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Inductance</h1>
               <p>
                                
           
                      
                        <h2>Intro to Inductance;</h2>
                        While capacitors trap electric charges using electric fields, inductors store charge using magnetic fields. 
Simply by running a current through a wire, circular magnetic fields can be generated where increasing or decreasing the current directly
 affects the magnetic field strength. Inductance is the study of how free electrons behave as the magnetic fields are then increased or 
 decreased. Any device capable of generating a magnetic field has inductance.
 
 <br />
                           <br />
<h2>Electromagnetism;</h2>
According to the laws of electromagnetism, an electric field at rest can be represented by a uniform set of electric field lines or lines
 of force. For an electric field at rest, these lines are uniform and radially distributed yet the charges are moving in a constant direction, 
 the field loses its uniformity. If the charges begin to accelerate, electromagnetic waves begin to propagate. Although in theory, each electron
  traveling across a wire should generate an electric field perpendicular to its motion, thermal effects, collisions and randomness have magnetic
   fields pointing in every direction.
   <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={InductanceImg} alt="inductance" width="45%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={InductorTableImg} alt="inductance" width="45%"/>
                                </div>
                        </p>
            </div>
    )
}