import RouterImg from '../../Images/RouterImg.jpg'
import T42 from '../../Images/T42.JPG'

export default function Router(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Router</h1>
               <p>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={RouterImg} alt="Router" width="45%"/>
                                </div>
           
                      
           <span style={{ fontWeight: 'bold' }}>Description:</span> A tabletop router is used to smooth or shape the edge of wood pieces and often come with a variety of routing bits for different edge styles.
                        <br />
                        <br /> 
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={T42} alt="Router" width="70%"/>
                                </div>
                                <br />         
                        <span style={{ fontWeight: 'bold' }}>Table Top Length:</span> The horizontal dimension of the table work surface.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Table Top Depth:</span> The depth of the table work surface.
                        </p>
            </div>
    )
}