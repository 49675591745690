import JointsImg from '../../Images/JointsImg.jpg'
import ACHFImg from '../../Images/ACHFImg.jpg'
import DCRPImg from '../../Images/DCRPImg.jpg'
import DCSPImg from '../../Images/DCSPImg.jpg'
import FluxCoreWeldingImg from '../../Images/FluxCoreWeldingImg.jpg'
import MIGWeldingImg from '../../Images/MIGWeldingImg.jpg'
import OxyAceWeldingImg from '../../Images/OxyAceWeldingImg.jpg'
import TIGWeldingImg from '../../Images/TIGWeldingImg.jpg'
import ShieldedMetalArcWeldingImg from '../../Images/ShieldedMetalArcWeldingImg.jpg'
import WeldingConnectionsImg from '../../Images/WeldingConnectionsImg.jpg'

export default function Welding(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Welding</h1>
               <p>
                                
           
<div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={WeldingConnectionsImg} alt="Welding Connections" width="100%"/>
                                </div> 
                                <div style={{width:'60%'}}><h2>Intro to Welding;</h2>Welding is a permanent joining method which uses an electrical arc and a filler metal electrode or stick to bond with the working piece. During the process, the arc melts the filler metal with the material of the workpiece to combine it into a single protected metal. The two most common methods of melting the metals is by using an electrically generated arc or igniting a gas to generate heat. 
                                <br />
                        <br /> 
The depth of the molten metal is referred to as penetration. The amount of penetration will determine the functionality of the joined metals after the cool. Welds without penetration will inevitably fail over time. The deposited weld is known as the bead which generally will build up on top of the workpiece.

</div>                       
</div>
                      
<br />
                        <br /> 
 <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={OxyAceWeldingImg} alt="Oxy Ace Welding" width="100%"/>
                                </div>
                                <div style={{width:'40%'}}>
                                <h2>Oxy-Acetylene Welding;</h2>
A very common gas used in gas welding is a mixture of oxygen and acetylene. The addition of the oxygen to the acetylene produces a flame nearly 5500 ‘F (3000’C) and is useful since it does not require an electrical power supply.

                                </div>
    </div>                       
<div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={MIGWeldingImg} alt="MIG Welding" width="100%"/>
                                </div>
                                <div style={{width:'40%'}}>
                                <h2>Gas Metal Arc Welding (MIG/ GMAW);</h2>
MIG welding is an arc welding process that uses a continuous, consumable wire called an electrode. A shielding gas can also be used which travels through the welding gun and protects against contaminants. The gas flows directly from the welding gun onto the workpiece and is typically argon or another noble gas since they do not react during combustion. A great benefit to MIG welding is its ease of application and that the metal does not need to be as clean as with TIG welding. However, this process can be quite dirty as it produces sparks, fumes and smoke.
    
                                </div>

</div>

<br />
                        <br /> 
 <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={TIGWeldingImg} alt="TIG Welding" width="100%"/>
                                </div>
                                <div style={{width:'50%'}}>
                                <h2>Tungsten Arc Welding (TIG/ GTAW);</h2>
TIG welding also uses an electric arc and a non-consumable electrode made from tungsten. The process can be supplemented using a filler metal. Argon or helium are the most common external gases used in this process. It is critical in this process to ensure the work area has been cleaned.

                                </div>
    </div>                       

<br />
                        <br /> 
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ShieldedMetalArcWeldingImg} alt="Shielded Metal Arc Welding" width="100%"/>
                                </div>
                                <div style={{width:'50%'}}>
                                <h2>Shielded Metal Arc Welding (Stick/ SMAW);</h2>
Stick welding uses a shielded metal arc welder and a consumable electrode stick. During the process, the stick softens and combines with metals between the workpiece and the electrode. As the stick melts, its protective cover also melts which shields the welded area from oxygen and other gases. The metal electrode is coated with a flux which releases carbon dioxide while melting which acts as a shielding gas to prevent contamination. This process requires a certain degree of practice as the process of puddling the bead to a proper amount can be difficult to master.

                                </div>
                        </div>

<br />
                        <br /> 
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={FluxCoreWeldingImg} alt="Flux Core Welding" width="100%"/>
                                </div>
                                <div style={{width:'50%'}}>
                                <h2>Flux-Cored Arc Welding (FCAW);</h2>
Similar to MIG welding, flux welding uses a continuous wire and a power supply to generate an arc. In this case, the electrode is a hollow metal tube which combines with the continuous electrode and the base metal.

                                </div>
                        </div>

<h2>Welding Current and Polarity;</h2> 
MIG and TIG welding offers three choices of welding current: Direct Current Straight Polarity (DCSP), Direct Current Reverse Polarity (DCRP) and Alternating Current with High Frequency stabilization (ACHF). These options will greatly affect the penetration pattern and bead configuration of your welds. 
<br />
                        <br /> 
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={DCSPImg} alt="DCSP" width="100%"/>
                                </div>
                                <div style={{width:'60%'}}>
                                TIG welding with <span style={{ fontWeight: 'bold' }}>DCSP</span> results in deep penetration due to the high concentration of heat in the joint area. There is no cleaning action when using this polarity. This method is the best option for joining metals with high melting points such as titanium and stainless steel and can easily join thicker plates.

                                </div>
                        </div>

<br />
                        <br /> 
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={DCRPImg} alt="DCRP" width="100%"/>
                                </div>
                                <div style={{width:'60%'}}>
                                TIG welding with <span style={{ fontWeight: 'bold' }}>DCRP</span> results in wider and shallower penetration of the work material than DCSP. However, this method produces an oxide cleaning action as the argon ions flowing towards the work material strike the surface and break up oxides on the surface. Another advantage is high volume deposition rate which allows faster welding. DCRP is the better option for welding thin sheets as it reduces distortion and residual stress or for joining metals with low melting points such as copper and aluminum. Some disadvantages for this method include shorter life of non-consumable electrodes and difficulty welding together thick plates.
                    
                                </div>
                        </div>
                      
                        <br />
                        <br /> 
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ACHFImg} alt="DCRP" width="100%"/>
                                </div>
                                <div style={{width:'60%'}}>
                                <span style={{ fontWeight: 'bold' }}>ACHF</span> provides a balanced approach for both penetration and cleaning action. Every half cycle the ion flow will break up oxides while the alternating current provides medium penetration. This method is suitable for a wide range of plate thickness and is compatible with most electrode types.
                       
                                </div>
                        </div>
                    
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={JointsImg} alt="Joints" width="100%"/>
                                </div>
                        </p>
            </div>
    )
}