import ForLoopImg from '../../Images/ForLoopImg.jpg'
import WhileLoopsImg from '../../Images/WhileLoopsImg.jpg'

export default function Loops(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Loops</h1>
               <p>
                                
           
                       
                        <h2>Intro to While Loops;</h2>
A while loop is a way to repeat a block of code until a predetermined condition stops being true. It is important that these conditions become false at some point or the program will operate as an infinite loop and never stop iterating through the executable.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ForLoopImg} alt="For Loop" width="45%"/>
                                </div>
<h2>For Loops;</h2>
The for loop is a slightly more specialized loop which can take in a variable counter and repeat a loop ‘for’ a certain amount of iterations. 
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={WhileLoopsImg} alt="While Loop" width="45%"/>
                                </div>                
                
                        </p>
            </div>
    )
}