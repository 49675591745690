import ForcedVibrationImg from '../../Images/ForcedVibrationImg.jpg'

export default function ForcedVibrations(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Forced Vibrations without Damping</h1>
               <p>
                                
           
                        
                        <h2>Force Applied to Mass;</h2>
A single degree-of-freedom system which has a force applied to the mass will have a differential equation of motion in the form of equation (4.1). The solution for the displacement of the mass (x) can be found using equation (4.2)
<br />
                        <br />                 
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ForcedVibrationImg} alt="ForcedVibrationImg" width="45%"/>
                                </div>
                        </p>
            </div>
    )
}