import ComputerSystemsImg from '../../Images/ComputerSystemsImg.jpg'

export default function Systems(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Computer Systems</h1>
               <p>
                               
           
                      
                        <h2>Intro to Computer Systems;</h2>
The computer system consists of both physical components known as hardware and digital programs known as software. A computer itself is defined
 as a digital electronic device capable of receiving input data and outputting program data. The computer system can be separated into four major
  categories; input devices, the central processing unit (CPU), system memory and output devices. Input devices are components which give 
  instructions to the computer such as the keyboard and mouse while output devices are components which the computer sends commands to such 
  as external speakers, printers or monitors.
<br />
                        <br /> 
The computer hardware can also be broken into two categories; the components which exist within the computer case and those which exist out
 of it. The computer case, often called the cabinet, is the protective and structural housing which stores the most critical parts of the 
 computer hardware. Inside fans and shelves keeps certain components cool and further provides organizational structure.
 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ComputerSystemsImg} alt="ComputerSystems" width="65%"/>
                                </div>
                        </p>
            </div>
    )
}