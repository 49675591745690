import SteelsTableImg1 from '../../Images/SteelsTableImg1.jpg'
import SteelsTableImg2 from '../../Images/SteelsTableImg2.jpg'
import SteelsTableImg3 from '../../Images/SteelsTableImg3.jpg'

export default function Steel(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Steel</h1>
               <p>
               <h2>Steel Properties Table;</h2>

 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={SteelsTableImg1} alt="Steels Table" width="100%"/>
                                </div> 
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={SteelsTableImg2} alt="Steels Table" width="100%"/>
                                </div>  
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={SteelsTableImg3} alt="Steels Table" width="100%"/>
                                </div>           
                        </p>
            </div>
    )
}