import React from "react";
import {
//   BrowserRouter as Router,
  useParams,
} from "react-router-dom";
import Extrusion from './Extrusion';
import Powder from './Powder';
import Resin from './Resin';
import Sintering from "./Sintering";
import KeyParameters from "./KeyParameters";

const ChangeComponents = {
    extrusion: Extrusion,
    powder: Powder,
    resin: Resin,
    sintering: Sintering,
    keyparameters: KeyParameters,
  };

export default function Subtopics(props) {
    // The <Route> that rendered this component has a
    // path of `/topics/:topicId`. The `:topicId` portion
    // of the URL indicates a placeholder that we can
    // get from `useParams()`.
    let { topicId } = useParams();
    
    let MyComponent = ChangeComponents[topicId];
    return (
        <div>
            {/* {topicId} is for testing route names */}
            {/* <h3>{topicId}</h3> */}
            <MyComponent topicId={topicId}/>
      </div>
    );
  }