import StainlessSteelTableImg from '../../Images/StainlessSteelTableImg.jpg'
import StainlessSteelsImg from '../../Images/StainlessSteelsImg.jpg'

export default function StainlessSteel(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Stainless Steel</h1>
               <p>
               <h2>Intro to Stainless Steel;</h2>
      
There are thousands of variations of stainless steels, many of which are achieved by including amounts of carbon, chromium and/or nickel. A high content of carbon creates steel that is much more durable and longer lasting than other alloys. Chromium is largely used to increase the corrosion resistance and nickel improves formability, weldability and ductility of the material. Many people suffer from nickel allergies so low-nickel varieties may be needed for parts that come into contact with humans. 
<br />
<br />
The choice between the grades of steel are often based on corrosion requirements, formability and availability in the necessary thickness or temper. Stainless steels also have the ability to be coldworked to high tensile and yield strengths while preserving their ductility and toughness.
<br />
<br />
At room temperature, you can expect yield strengths between 30 to 200 ksi depending on the amount of cold work the material experienced and the compositions. 
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={StainlessSteelsImg} alt="StainlessSteelsImg" width="80%"/>
                                </div> 
<h2>Austenitic Stainless Steel;</h2>
This grouping of stainless steel includes large amounts of chromium which makes the material corrosion resistant. It also has a tendency to make the materials produced non-magnetic, though they can become magnetic with a cold forming process. These are the most common types of stainless.
<br />
<br />
Within the austenitic steel family, there are two main grades, grade 304 and 316. Grade 304 and 316 have high tensile strengths (approximately 621 MPa and 579 MPa) and both have high maximum operating temperatures (870 ‘C and 800 ‘C). The largest difference between the two is resistance to chlorides such as salt. Grade 316 and 316L are more resistant and should be used for parts which come into contact with fluids. 
<br />
<br />
<h2>Ferritic Stainless Steel;</h2>
Ferritic steels are the second most common stainless steel families and are also magnetic. These materials can be hardened with cold forming and tend to be less expensive due to their low nickel content.
<br />
<br />
The two main grades of ferritic stainless steel are grade 430 and 434. Although grade 430 is weaker than the other steels, it offers better resistance to nitric acid. Grade 434 has high tensile strength and maximum operating temperature than 430 as well as better pitting resistance (localized corrosion-based surface damage).
<br />
<br />
<h2>Martensitic Stainless Steel;</h2>
This family of stainless steel is used in applications where a high impact resistance or high tensile strength is needed. It is common for these to be coated with a protective polymer coating to improve corrosion resistance. 
<br />
<br />
Martensitic stainless steel only has one common variety, grade 420. It offers slightly less resistance to chemicals than austenitic and ferritic steels but still has good resistance. Its high tensile strength and impact resistance makes it ideal for items such as cutlery.
<br />
<br />
               <h2>Stainless Steel Properties Table;</h2>

 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={StainlessSteelTableImg} alt="StainlessSteelTableImg" width="100%"/>
                                </div> 
                                          
                        </p>
            </div>
    )
}