import AluminumTableImg from '../../Images/AluminumTableImg.jpg'


export default function Aluminum(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Aluminum</h1>
               <p>
               <h2>Aluminum Properties Table;</h2>

 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={AluminumTableImg} alt="Aluminum Table" width="100%"/>
                                </div> 
                                  
                        </p>
            </div>
    )
}