import OscillatorsImg from '../../Images/OscillatorsImg.jpg'

export default function Oscillators(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Oscillators and Timers</h1>
               <p>
                                


                        <h2>Intro to Oscillators;</h2>
The purpose of the oscillator is to generate a repetitive waveform of a specific shape, frequency and amplitude that can be used to drive other
 circuits. The specific application will dictate if a pulsed, sinusoidal, square, sawtooth or triangular waveform is required.
 <br />
                        <br />
For digital electronics, square oscillators called “clocks” are used to drive bits of information through logic gates at a rate dictated by the
 frequency of the clock.
 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={OscillatorsImg} alt="oscillator signals" width="65%"/>
                                </div>

                        </p>
            </div>
    )
}