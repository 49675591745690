// import GT from '../../Images/GT.jpg'

export default function Overview(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Overview of Consumables</h1>
               <p>
                                {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GT} alt="linkages" width="75%"/>
                                </div> */}
           
           <br /> 
           <span style={{ fontWeight: 'bold' }}>Solder:</span> A spool of metal used for joining wires and electrical connections.
                        <br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Flux:</span> A chemical cleaning agent used to help solder stick to the tip of a soldering iron.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Tap Fluid:</span> Fluid used to absorb excess heat when tapping or machining.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Grease:</span> Fluid used to increase lubrication in dynamic machines.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Adhesive:</span> Chemical methods of joining two surfaces.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Zip Ties:</span> Plastic wrapping devices used for wire management.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Filament:</span> Spools of material for 3d printing.                 
                
                        </p>
            </div>
    )
}