import BowstringTrussImg from '../../Images/BowstringTrussImg.png'
import EnglishHoweTrussImg from '../../Images/EnglishHoweTrussImg.png'
import FinkTrussImg from '../../Images/FinkTrussImg.png'
import HoweTrussImg from '../../Images/HoweTrussImg.png'
import KingPostTrussImg from '../../Images/KingPostTrussImg.png'
import KTrussDiaImg from '../../Images/KTrussDiaImg.png'
import PrattTrussImg from '../../Images/PrattTrussImg.png'
import WarrenTrussImg from '../../Images/WarrenTrussImg.png'

export default function Trusses(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Truss Design</h1>
               <p>
<h2>Overview of Trusses;</h2>
A truss is a structural framework made up of connected joints designed to efficiently support loads, primarily through axial forces. As with most designs, the best truss design depends on the specific characteristics of the application such as span length, load requirements, aesthetic preference and cost. 
<br />
                            <br /> 
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={WarrenTrussImg} alt="Warren Truss" width="100%"/>
                            
                            </div>
                            <div style={{width:'60%'}}>
                        
                            <span style={{ fontWeight: 'bold' }}>1. Warren Truss:</span> This is a simple and efficient design often used in bridges and buildings and provides a good balance of cost and performance. Well suited for medium span bridges and buildings. The equal length members distribute forces evenly.
<br />
                          

                            </div>
                            </div>
                            <br /> 
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={KingPostTrussImg} alt="King Post Truss" width="100%"/>
                            </div>
                            <div style={{width:'60%'}}>
                            <span style={{ fontWeight: 'bold' }}>2. King Post Truss:</span> The King Post is a simple design and has one vertical at the center of two diagonals. This design is used commonly for small bridges and roofs. It is simple and economical.

<br />
                          

                            </div>
                            </div>
                            <br /> 
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={EnglishHoweTrussImg} alt="English Howe Truss" width="100%"/>
                            </div>
                            <div style={{width:'60%'}}>
                            <span style={{ fontWeight: 'bold' }}>3. English Howe Truss:</span> The English Howe Truss features both vertical and a mirrored assortment of diagonal members. This simple design has moderate structural integrity and a nice aesthetic.

<br />
                          

                            </div>
                            </div>
                            <br /> 
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={HoweTrussImg} alt="Howe Truss" width="100%"/>
                            </div>
                            <div style={{width:'60%'}}>
                            <span style={{ fontWeight: 'bold' }}>4. Howe Truss:</span> The Howe Truss is a simple rectangular design with good characteristics well suited for medium to long spans.
<br />
                          

                            </div>
                            </div>
                            <br /> 
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={KTrussDiaImg} alt="K Truss" width="100%"/>
                            </div>
                            <div style={{width:'60%'}}>
                            <span style={{ fontWeight: 'bold' }}>5. K Truss:</span> The K Truss is often used in bridge construction because it offers good strength and stability.

<br />
                          

                            </div>
                            </div>
                            <br /> 
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={PrattTrussImg} alt="Pratt Truss" width="100%"/>
                            </div>
                            <div style={{width:'60%'}}>
                            <span style={{ fontWeight: 'bold' }}>6. Pratt Truss:</span> The Pratt Truss is characterized by its diagonal members slanting towards the center. It is commonly used for longer span bridges because the design offers good compression resistance.

<br />
                          

                            </div>
                            </div>
                            <br /> 
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={FinkTrussImg} alt="Fink Truss" width="100%"/>
                            </div>
                            <div style={{width:'60%'}}>
                            <span style={{ fontWeight: 'bold' }}>7. Fink Truss:</span> The Fink truss uses a W-shaped pattern to provide great strength and rigidity. This design is often used in industrial buildings and roofs and can span long distances without the need for additional support.
<br />
                          

                            </div>
                            </div>
                            <br /> 
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={BowstringTrussImg} alt="BowstringTruss" width="100%"/>
                            </div>
                            <div style={{width:'60%'}}>
                            <span style={{ fontWeight: 'bold' }}>8. Bowstring Truss:</span> The Bowstring Truss follows the same downwards pointing design as the Parker and English Howe Truss but with the difference that the upper chord is an arc. This has a unique aesthetic while maintaining structural integrity.
<br />
                          

                            </div>
                            </div>

 
                           
                            </p>
            </div>
    )
}