import T50 from '../../Images/T50.JPG'

export default function Overview(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Overview of Electrical Machines</h1>
               <p>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={T50} alt="overview" width="100%"/>
                                </div>
           
                                <br />
                                <span style={{ fontWeight: 'bold' }}>Oscilloscope:</span> Oscilloscopes are devices which exclusively measure voltage and have a response rate fast enough to plot real-time voltage data. These machines can also be configured to indirectly measure current, strain, acceleration and pressure.
                        <br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Digital Microscope:</span> Handheld electric microscopes can magnify images, snap high resolution images and record videos and pass all the information through a USB cable.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Soldering Iron:</span> A soldering iron is a handheld tool with a heated tip which is used to melt solder so that it can create an electrical connection between two workpieces.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Fume Suction Fan:</span> A ventilation device used to limit the operator’s exposure to hazardous or toxic fumes.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Light & Magnification Glass:</span> A workbench attachment tool used to magnify electrical components for easier assembly.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Component Holder:</span> An adjustable clamp system to hold workpieces in position during soldering.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Heat Gun:</span> A handheld device used to blow hot air unto shrink fit tubing.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Solder Sucker:</span> A spring loaded hand tool used to absorb material when removing solder from a connection.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Wire Stripper:</span> A hand tool used to separate and remove a wire’s sleeve.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Tweezer Set:</span> A small set of picks used to scrape and probe electrical components.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Crimping Tool:</span> A handheld device used to deform connectors onto a wire and form a connection between the wire and connector.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Non-Contact Voltage Tester:</span> An electronic test equipment used to measure voltage without physically touching any surface.                
                
                        </p>
            </div>
    )
}