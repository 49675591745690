import CreepImg from '../../Images/CreepImg.jpg';


export default function Creep(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>                   
                     <h1>Creep</h1>
                      <p>
<h2>Intro to Creep;</h2>
Creep is a time dependent permanent deformation in materials caused from the material undergoing constant stress throughout time. This typically
 occurs at high temperatures and around 40% of most metal’s melting temperature. The rate of deformation is subject to the materials properties,
  exposure time, temperature and the applied structural load. Polymers specifically are very sensitive to creep deformation.
  <br />
                        <br /> 
<h2>Stages of Creep;</h2>
Creep can be separated into three distinct stages. Primary creep is when the creep rate (slope) begins to decrease with time caused by strain
 hardening. Secondary creep is a steady-state, constant slope balance between strain hardening and recovery and is vital to long-life
  applications. Tertiary creep sees the slope begin to increase until it eventually ruptures due to microstructural defects.
  <br />
                        <br /> 
<h2>Factors Affecting Creep Resistance;</h2>

High melting temperature
<br />
High modulus of elasticity
<br />
Large grain size (the large grain boundaries limit sliding)
<br />
Solid solution alloying (enhances resistance via precipitate hardening)
<br />
Directional solidification (such as elongated, columnar grains and single crystals)

                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={CreepImg} alt="Creep" width="45%"/>
                            </div>                       
                            </p>
            </div>
    )
}