import BoylesLaw from '../../Images/BoylesLawImg.jpg'

export default function Boyles(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Boyle's Law</h1>
               <p>
                             
           
          <h2>Intro to Boyle’s Law;</h2>
          
Gases do not have a fixed volume and will respond to a change in pressure by compressing or expanding. In a closed container, pressure changes if there is a change in temperature, the number of molecules increases/decreases or if the volume changes. Boyle’s law presents the relationship between pressure and volume for a fixed number of molecules of gas (fixed mass) at a constant temperature.
<br />
                        <br /> 
P1V1 = P2V2
<br />
                        <br /> 
P1 and P2 is the initial and final pressure while V1 and V2 is the initial and final volume. The volume of a given mass is inversely proportional to the pressure applied to it. This is a linear relationship, if the volume is increased by two, the pressure is halved. 
             
             <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={BoylesLaw} alt="linkages" width="75%"/>
                                </div>
                        </p>
            </div>
    )
}