// import GT from '../../Images/GT.jpg'

export default function Variables(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Arduino C++ Variables</h1>
               <p>
                                {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GT} alt="linkages" width="75%"/>
                                </div> */}
         
<span style={{ fontWeight: 'bold' }}>Include:</span>
<br />
The include function imports a library from the computer to the arduino, note that it has to be downloaded in the arduino’s library list to be able to be included.
<br />
<code>{`#include <LibraryName.h>`}</code>
<br />
<br />
<span style={{ fontWeight: 'bold' }}>Variable Unsigned Long:</span>
<br />
This variable holds the time an LED was last switched.
<br />
<code>{`unsigned long varName = #;`}</code>
<br />
<br />
<span style={{ fontWeight: 'bold' }}>Long Variable:</span>
<br />
This is used to hold a large number. The six places used are for the example.
<br />
<code>{`long varName = #######;`}</code>
<br />
<br />
<span style={{ fontWeight: 'bold' }}>Boolean:</span>
<br />
A boolean is a variable that can only be either true or false.
<br />
<code>{`boolean varName = true;`}</code>
<br />
<code>{`boolean varName = false;`}</code>
<br />
<br />
<span style={{ fontWeight: 'bold' }}>Int Variable:</span>
<br />
The variable int will hold a whole number
<br />
<code>{`int variableName = #;`}</code>
<br />
<br />
<span style={{ fontWeight: 'bold' }}>Int Array:</span>
<br />
Arrays are ways to store values with a relation between the variables. In this case the values are integers.
<br />
<code>{`int arrayName[#];`}</code>//This creates the array
<br />
<code>{`int arrayName[0] = #;`}</code> //This sets the first element to be equal #
<br />
<code>{`int arrayName1[] = {#1, #2, #3, #4]}; //This creates an array and sets their values all in one line`}</code>
    <br />
    <br />
<span style={{ fontWeight: 'bold' }}>Constants:</span>
<br />
Constants are similar to variables such that they store information but with the distinction that they cannot be changed.
<br />
<code>{`const varName = #;`}</code>
<br />
<br />
<span style={{ fontWeight: 'bold' }}>Constant Float:</span>
<br />
A float is a variable that has a decimal place.
<br />
<code>{`const float varName = ##.#;`}</code>
                
                        </p>
            </div>
    )
}