import CommonMetalsTableImg from '../../Images/CommonMetalsTableImg.jpg'
import CommonTensileTableImg from '../../Images/CommonTensileTableImg.jpg'
import CommonTensileTableImg2 from '../../Images/CommonTensileTableImg2.jpg'

export default function CommonMetals(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Common Metals</h1>
               <p>
               <h2>Common Metal Properties Table;</h2>


 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={CommonMetalsTableImg} alt="Common Metals Table" width="100%"/>
                                </div>  
                                <br />
                        <br />      
<h2>Common Metal Tensile Strength Table;</h2>  
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={CommonTensileTableImg} alt="Common Tensile Table" width="100%"/>
                                </div> 
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={CommonTensileTableImg2} alt="Common Tensile Table" width="100%"/>
                                </div> 
                        </p>
            </div>
    )
}