import CosmosImg from '../../Images/CosmosImg.jpg'

export default function Universe(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>The Universe</h1>
               <p>
                            
           
                       
           <h2>Intro to the Universe;</h2>
                        The universe is made up of force and matter governed by a system of natural laws that emerged as a result of particle
                         properties. On a micro scale, every subatomic particle has a spin, charge and mass, though sometimes that mass is zero.
                          On a macro scale, the limits of the known universe rests within an supposedly infinite void. 
                          <br />
                        <br /> 
From within our reality, three spatial dimensions can be used to represent the directions in space. As matter within this dimension changes due
 to forces and interactions, it is often helpful to add an additional dimension representing time. Time as we know it is not a physical property.
  Humankind long ago discovered that light travels at a constant speed in a vacuum and since then have used that property to describe changes
   of matter which took place for a duration related to this traveling speed. 
   <br />
                        <br /> 
This understanding of time is useful for the day to day yet becomes problematic, as particle decay and the physical existence of matter changes
 based on speed and local gravity. The satellites around earth’s orbit use Einstein’s equations to compensate for this difference in time change
  since the gravity which satellites experience is slightly different than that on Earth.
 
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={CosmosImg} alt="universe" width="75%"/>
                                </div>
                
                        </p>
            </div>
    )
}