import {
    // BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch
  } from "react-router-dom";
import Cards from '../../components/Cards';
import styles from './Navigation.module.css';
import Topic from '../Topic';
function Navigation(props) {
    let { path, url } = useRouteMatch();
    return (
        <div>
        <div  className={styles.Table}>
            <div className={styles.Cell}>
                <Cards title="Mathematics" className={styles.Title} style={{backgroundColor: 'blue'}}/>             
                <div className={styles.Block}>
                <Link to={`${url}/algebra`} className={styles.Brick}>Algebra</Link>
                </div>
                <div className={styles.Block}>
                <Link to={`${url}/geometry`} className={styles.Brick}>Geometry</Link>
                </div>
                <div className={styles.Block}>
                <Link to={`${url}/calculus`} className={styles.Brick}>Calculus</Link>
                </div>
                <div className={styles.Block}>
                <Link to={`${url}/statistics`} className={styles.Brick}>Probability & Statistics</Link>
                </div>
                <div className={styles.Block}>
                <Link to={`${url}/odes`} className={styles.Brick}>ODEs</Link>
                </div>
            </div>
            <div className={styles.Cell}>
                <Cards title="Physics" className={styles.Title} style={{backgroundColor: 'blue'}}/>
                <div className={styles.Block}>
                <Link to={`${url}/datatables`} className={styles.Brick}>Data Tables</Link>
                </div>
                <div className={styles.Block}>
                <Link to={`${url}/forcesmotion`} className={styles.Brick}>Forces and Motion</Link>
                </div>
               
                <div className={styles.Block}>
                <Link to={`${url}/naturalsystems`} className={styles.Brick}>Natural Systems</Link>
                </div>
               
                <div className={styles.Block}>
                <Link to={`${url}/thermodynamics`} className={styles.Brick}>Thermodynamics</Link>
                </div>
                <div className={styles.Block}>
                <Link to={`${url}/fluiddynamics`} className={styles.Brick}>Fluid Dynamics</Link>
                </div>
                <div className={styles.Block}>
                <Link to={`${url}/vibrations`} className={styles.Brick}>System Dynamics</Link>
                </div>
               
            </div>
            <div className={styles.Cell}>
                <Cards title="Mechanical Engineering" className={styles.Title} style={{backgroundColor: 'blue'}}/>
                <div className={styles.Block}>
                <Link to={`${url}/statics`} className={styles.Brick}>Statics</Link>
                </div>
                <div className={styles.Block}>
                <Link to={`${url}/design`} className={styles.Brick}>Design Constraints</Link>
                </div>
               
                <div className={styles.Block}>
                <Link to={`${url}/components`} className={styles.Brick}>Components</Link>
                </div>
                <div className={styles.Block}>
                <Link to={`${url}/assemblies`} className={styles.Brick}>Assemblies</Link>
                </div>
                <div className={styles.Block}>
                <Link to={`${url}/systems`} className={styles.Brick}>Systems</Link>
                </div>
                <div className={styles.Block}>
                <Link to={`${url}/drawings`} className={styles.Brick}>Drawings & GD&T</Link>
                </div>
                {/* <div className={styles.Block}>
                <Link to={`${url}/systemdynamics`} className={styles.Brick}>System Dynamics</Link>
                </div> */}
            </div>
              
            <div className={styles.Cell}>
                <Cards title="Electrical Engineering" className={styles.Title} style={{backgroundColor: 'blue'}}/>
                <div className={styles.Block}>
                <Link to={`${url}/electricity`} className={styles.Brick}>Electricity and Circuitry</Link>
                </div>
                <div className={styles.Block}>
                <Link to={`${url}/handson`} className={styles.Brick}>Hands-on Electronics</Link>
                </div>      
                <div className={styles.Block}>
                <Link to={`${url}/basiccomponents`} className={styles.Brick}>Basic Components</Link>
                </div>
                <div className={styles.Block}>
                <Link to={`${url}/discrete`} className={styles.Brick}>Discrete Components</Link>
                </div>
                <div className={styles.Block}>
                <Link to={`${url}/sensors`} className={styles.Brick}>Sensors</Link>
                </div>
                <div className={styles.Block}>
                <Link to={`${url}/filters`} className={styles.Brick}>Signals & Filters</Link>
                </div>
                
             
            </div>
                 
            <div className={styles.Cell}>
                <Cards title="Industrial Engineering" className={styles.Title} style={{backgroundColor: 'blue'}}/>
                <div className={styles.Block}>
                <Link to={`${url}/materials`} className={styles.Brick}>Material Tables</Link>
                </div>
                <div className={styles.Block}>
                <Link to={`${url}/materialscience`} className={styles.Brick}>Material Science</Link>
                </div>
                <div className={styles.Block}>
                <Link to={`${url}/reductive`} className={styles.Brick}>Reductive Manufacturing</Link>
                </div>
                <div className={styles.Block}>
                <Link to={`${url}/additive`} className={styles.Brick}>Additive Manufacturing</Link>
                </div>
                <div className={styles.Block}>
                <Link to={`${url}/postprocessing`} className={styles.Brick}>Post Processing</Link>
                </div>
                <div className={styles.Block}>
                <Link to={`${url}/machineassembly`} className={styles.Brick}>Machine Assembly</Link>
                </div>
                <div className={styles.Block}>
                <Link to={`${url}/toolsandmachines`} className={styles.Brick}>Tools & Machines</Link>
                </div>
            </div>
            <div className={styles.Cell}>
                <Cards title="Computer Science" className={styles.Title} style={{backgroundColor: 'blue'}}/>
                <div className={styles.Block}>
                <Link to={`${url}/basics`} className={styles.Brick}>Computer Basics</Link>
                </div>
                <div className={styles.Block}>
                <Link to={`${url}/algorithms`} className={styles.Brick}>Algorithms</Link>
                </div>
                <div className={styles.Block}>
                <Link to={`${url}/hardware`} className={styles.Brick}>Computer Hardware</Link>
                </div>
                <div className={styles.Block}>
                <Link to={`${url}/gcode`} className={styles.Brick}>G-code & LL</Link>
                </div>
                <div className={styles.Block}>
                <Link to={`${url}/arduino`} className={styles.Brick}>Arduino C++</Link>
                </div>
                {/* <div className={styles.Block}>
                <Link to={`${url}/programming`} className={styles.Brick}>HTML & CSS</Link>
                </div>
               
                <div className={styles.Block}>
                <Link to={`${url}/javascriptreact`} className={styles.Brick}>Javascript & React</Link>
                </div> */}
            
                </div>
         </div>                
            <div>
            
                    <Switch>
                        <Route exact path={path}>
                        <h3 style={{marginLeft:'10px', marginTop:'5px', marginBot:'5px'}}>Please select a topic.</h3>
                        </Route>
                        <Route path={`${path}/:topicId`}>
                        <Topic />
                        </Route>
                    </Switch>
            </div>
        </div>

    )
}
export default Navigation; 