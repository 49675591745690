import AdhesivesImg from '../../Images/AdhesivesImg.jpg'

export default function Adhesives(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Adhesives</h1>
               <p>
                                
           
                      
                        <h2>Intro to Adhesives;</h2>
Industrial adhesives come in both organic and inorganic variations and can be used to permanently join components together. Secondary applications of adhesives are to fill gaps between seams, contain fluid, prevent leaks and infiltration of unwanted material.
<br />
                        <br /> 
<h2>Categories of Adhesive Properties;</h2>
Hot Melt Adhesives: these can be repeatedly softened or melted using heat and hardened or set by cooling which allows parts to be repositioned during assembly.
<br />
Pressure Sensitive Adhesives: also known as contact adhesives, these adhere to most surfaces with very slight pressure and are thus useful for applications with large surface areas.
<br />
Thermoset Adhesives: using crosslinked polymeric resins and cured using heat or pressure, these adhesives traditionally exhibit good creep resistance and can be used for high load applications.
<br />
UV Curing Adhesives: These adhesive use UV light for curing which allows a permanent bond formed without the use of heat.
<br />
                        <br /> 
<h2>Types of Common Adhesives;</h2>
Acrylic Adhesives: known for their excellent environmental resistance and fast setting time, these often cure instantly on contact with surfaces from their moisture content.
<br />
Epoxy Adhesives: Epoxy is a copolymer created from combining resin and a hardener.
<br />
Phenolic, Melamine and Formaldehyde: these are thermoset adhesives which can form strong bonds and have good thermal resistance.
<br />
Polyurethane Adhesives: Polyurethane has excellent flexibility, impact resistance and durability.
<br />
Rubber Adhesives: often used as sealants, rubber adhesives provide high flexibility.
<br />
Silicone Adhesives: these sealants also have a high degree of flexibility and very high thermal resistance.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={AdhesivesImg} alt="Adhesives" width="65%"/>
                                </div> 
                        </p>
            </div>
    )
}