import EncodersImg from '../../Images/EncodersImg.jpg'

export default function Opto(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Encoders</h1>
               <p>
                                
           
                       
                        <h2>Intro to Encoders;</h2>
The rotary encoder, also called a shaft encoder, is an electro-mechanical component that converts the angular position of a shaft or knob to an
 electrical output signal. The two main types of encoders are the absolute and incremental encoder. The absolute encoder outputs its current
  shaft position while the incremental encoder outputs information regarding the motion of its shaft. 
<br />
                        <br /> 
One of the most important characteristics of the absolute encoder is that it retains its position information even when power has been turned
 off. The incremental encoder cannot “remember” its position after powering down which forces it to return to a fixed reference. 
<br />
                        <br /> 
Some advantages of the rotary encoder is that they are highly reliable, accurate, low cost and offer high resolution. Some of their disadvantages
 include being susceptible to radio and magnetic interference in the case of magnetic encoders, direct sunlight interference for optical
  encoders and dirt, oil and dust contamination in the case of electro-mechanical encoders. 
  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={EncodersImg} alt="encoders" width="45%"/>
                                </div>
                        </p>
            </div>
    )
}