import React from "react";
import {
//   BrowserRouter as Router,
  useParams,
} from "react-router-dom";
import Greek from './Greek';
import Powers from './Powers';
import Conversions from './Conversions';
import Homogeneity from "./Homogeneity";
import Hazard from "./Hazard";


const ChangeComponents = {
    greek: Greek,
    powers: Powers,
    conversions: Conversions,
    homogeneity: Homogeneity,
    hazard: Hazard,
  };

export default function Subtopics(props) {
    // The <Route> that rendered this component has a
    // path of `/topics/:topicId`. The `:topicId` portion
    // of the URL indicates a placeholder that we can
    // get from `useParams()`.
    let { topicId } = useParams();
    
    let MyComponent = ChangeComponents[topicId];
    return (
        <div>
            {/* {topicId} is for testing route names */}
            {/* <h3>{topicId}</h3> */}
            <MyComponent topicId={topicId}/>
      </div>
    );
  }