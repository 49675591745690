import SlipImg from '../../Images/SlipImg.jpg'

export default function Dislocations(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Dislocations</h1>
               <p>
                                
           
                        
                        <h2>Intro to Dislocations and Strengthening;</h2>
Dislocation motion in metals requires the least energy because of the non-directional bonding and close packed directions for slip. With metals dislocations occur because of slip and if the edges are contained from moving, no plastic deformation can occur. With covalent ceramics, dislocation motion is difficult because it has directional angular bonding. Ionic ceramics dislocation motion is also difficult because atoms have to avoid nearby positively or negatively charged atoms which can repulse against them.
<br />
                        <br />
<h2>General Slip Motion;</h2>
Slip is the situation where an extra part of a plane of atoms (called an edge dislocation) slides over to an adjacent plane and creates plastic deformation. The edge dislocation moving is usually caused by a shear stress applied in a direction perpendicular to the atomic plane. A continuous application of shear stress will ultimately lead to a step formed on the surface of the crystal.
<br />
                        <br />
<h2>Twinning;</h2>
A shear force can also cause atomic displacement on one side of a plane to mirror the atoms on the other side of the plane. The displacement magnitude in the twin region is proportional to the atom’s distance from the twin plane. HCP crystal structures are the most likely to twin between the three common crystal structures (BCC, FCC, HCP). Twinning typically occurs at low temperatures and high rates of shear loading or shock loading. Atom movements in twinning are much less than an atomic distance and do not produce as much gross deformation as slip due to small lattice strain.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={SlipImg} alt="Slip" width="75%"/>
                                </div>
                        </p>
            </div>
    )
}