import GCodeImg from '../../Images/GCodeImg.jpg'

export default function Intro(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Introduction to G-code</h1>
               <h2>What is G-code?</h2>
               <p>
                                
           
        
                        Whether you are a maker, artist or engineer, CNC (Computer Numerical Control) machines and 3D printers have enormous potential for creating parts. The list of instructions that these machines use to know what they should be doing and where they should be doing it is known as G-code (Geometric-code). 
                        <br />
                        <br /> 
When using reductive machines like a mill or a lathe, the G-code instructs the machine on how quickly to move, how fast it should spin and how to follow the desired toolpath. With additive manufacturing machines, the g-code similarly controls the extruder along the right paths to deposit stacking layers of material. 
<br />
                        <br /> 
Every machine that uses G-code will ultimately pass these instructions through what is known as a post-processor. This converts the universal code into the specific commands that the machines were built to respond to.                
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GCodeImg} alt="linkages" width="55%"/>
                                </div>
                        </p>
                <h2>Set your Units</h2>
                <p>
                The G-code command line above the rest of the code specifies the units to me used for the entirety of the instructions.
                <br /> 
G20 = millimeters
<br /> 
G21 =inches

If neither of these are specific, then the machine will use whatever units were used in the previous program.
<br />
<br />       
                </p>
                <h2>How to Read G-code Commands</h2>
                <p>
                Lines of G-code are read from left to right. At the top of the page you can set the units you wish to use using a simple G command.
                <br />
<br />  
Lets take a look at a line of G-code from a milling machine.
<br />
G01 X234.3476 Y544.2323 Z-1.0000 F200.00
<br />
<br />  
The code is structured like so,
<br />
G## X## Y## Z## F##
<br />
<br /> 
The G01 command tells the machine to move in a straight line.
<br />
The X,Y,Z are cartesian coordinates which tell our machine what position it needs to move to.
<br />
The F value is the feed rate, which tells the machine how fast it should rotate the cutting tool. In this case, it will be moving at 200mm/min.
<br />
<br /> 
The coordinate system act as a series of desire locations and the computer figures out how to move between these points based on the command in front of the line of code.

                </p>
            </div>
    )
}