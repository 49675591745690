import ConversionTableImg from '../../Images/ConversionTableImg.jpg'

export default function Conversions(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Unit Conversions</h1>
               <p>
                                
           
        <h2>Basic Unit Conversions;</h2>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ConversionTableImg} alt="Conversions Table" width="65%"/>
                                </div>
                        </p>
            </div>
    )
}