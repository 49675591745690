import ShieldingImg from '../../Images/ShieldingImg.jpg'

export default function Shielding(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Electrical Shielding</h1>
               <p>
                                
           <h2>Intro to Electrical Shielding;</h2>
              
                         
                   
                        Modern electrical devices, signal and power wiring have a natural output of electromagnetic interference (EMI) which generates noise in neighboring components and equipment. That noise can disrupt control signals and degrade critical measurements. Most electrical compliance accreditation organizations test equipment not only for their output of electromagnetic interference, but also their vulnerability to input interference. Depending on the application, devices can also be subject to radio frequency interference (RFI) and/or electrostatic interference (ESI).
                        <br />
                        <br /> 
Proper grounding and shielding techniques are necessarily for both commercial and home products, especially when devices and components are in close proximity. The basic elements of an electrical system can be broken into three parts: a source of noise, a receiving device and a channel between the two. The process of shielding attempts to eliminate one of the three elements from emitting inference which then breaks the cycle.
<br />
                        <br /> 
<h2>Sources of Signal Interference;</h2>
<span style={{ fontWeight: 'bold' }}>Static Noise:</span> Static noise occurs when an electrical field distorts a signal. Since current naturally induces an electrical field when traveling through a wire, this is very common. A continuous foil shield is the most common solution as it can provide a shielding efficiency of nearly 100% especially when paired with proper grounding techniques.
<br /> 
<span style={{ fontWeight: 'bold' }}>Magnetic Noise:</span> Large AC motors, transformers and other heavy-duty appliances are the most common sources of magnetic noise due to their tendency to send current flows opposite to the instruments. The most common solution is using a twisted pair of signal wiring which can reduce the noise vulnerability by an order of magnitude.
<br /> 
<span style={{ fontWeight: 'bold' }}>Common Mode Noise:</span> if a system fails to meet proper power and grounding guidelines, the current flow between different potential grounds can generate noise. This can be avoided from proper planning and system layout.
<br /> 
<span style={{ fontWeight: 'bold' }}>Crosstalk:</span> Crosstalk describes the tendency for nearby signal wires to impose the signal they are carrying to other signal wires. The best solution is to have individually shielded twisted pairs.
    
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ShieldingImg} alt="Shielding" width="55%"/>
                                </div>                      
                        </p>
            </div>
    )
}