import LinearMotionImg from '../../Images/LinearmotionImg.jpg'

export default function Linearmotion(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Linear Motion Systems</h1>
               <p>
                                
           <h2>Intro to Linear Motion Assemblies;</h2>
                        Power screws come in two main types, the lead screw and the ball screw. If a person can constrain the slider
                         from rotating and rotate the screws, then the sliders will move linearly up and down the screw assemblies. This 
                         is an incredibly useful way of converting rotational movement into linear movement.                    
                        <br />
                        <br />
                        Both these assemblies work in the same way but the advantages of the ball screw is that the friction between 
                        the slider and the screw is almost zero resulting in almost no power loss during transfer. 
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={LinearMotionImg} alt="linear motion assemblies" width="55%"/>
                                </div>
                        </p>
            </div>
    )
}