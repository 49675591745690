import React from 'react'
import { Card } from 'react-bootstrap';


function Cards({title, ...rest}) {

    return (

        <Card {...rest} >
        <Card.Body>{title}</Card.Body>
        </Card>

    )
}

export default Cards