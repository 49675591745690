// import LubricantsImg from '../../Images/LubricantsImg.jpg'

export default function WorkHardening(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Work Hardening</h1>
               <p>
         

<h2>Intro to Work Hardening;</h2>
As a metal is worked upon from virtually any physical process it undergoes changes in its hardness and strength. Most metals first undergo weakening as their crystalline structures slip but quickly begin to harden as these lattice deformations create blockades preventing further deformations.
<br />
<br />
Heat from power transfer during reductive manufacturing processes can also be a major source of hardening which can lead to broken parts and machines. A major concern for any technician is that the workpiece becomes as hard as the cutting tool. The conditions and thermal conductivity of the metal will ultimately determine the extent of the hardening. 
<br />
<br />
<h2>Avoiding Work Hardening;</h2>
There are several ways to reduce work hardening in metal; correct feed and speeds, special tool coatings and proper coolant use. Tool rubbing which causes localized spikes of heat can cause series work hardening. The ultimate goal in machining is to get the heat transferred to the chips and avoid any deformation in the workpiece caused by the friction. Flooding the workpiece with coolant may be necessary to maintain proper temperature, especially when machining stainless steel and alloys.

{/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={LubricantsImg} alt="Lubricants" width="75%"/>
                                </div> */}

                        </p>
            </div>
    )
}