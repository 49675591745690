import React from 'react';
import Subtopics from './Subtopics';
import { Container } from "react-bootstrap";
import {
    Switch,
    Route,
    Link,
    useRouteMatch
  } from "react-router-dom";

function Sensors(props) {
    let { path, url } = useRouteMatch();
    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', maxWidth: "100vw"}}>
                {/* <SectionTitle title={props.topicId}/> */}
            <div style={{display: 'flex', flexDirection: 'row'}}> 
            <div style={{marginTop: '10px', marginRight: '0px', marginLeft: '5px',border: '3px', padding:'40px', paddingRight: '20px', borderStyle:'solid', borderColor:'grey', paddingLeft: '20px', whiteSpace:'nowrap'}}>
                <h2 style={{whiteSpace:'normal'}}>Sensors</h2>
                <li>
                    <Link to={`${url}/phsensors`}>pH Sensors</Link>
                </li>
                <li>
                    <Link to={`${url}/temperaturesensors`}>Temperature</Link>
                </li>    
                <li>
                    <Link to={`${url}/conductivitysensors`}>Conductivity</Link>
                </li>
                <li>
                    <Link to={`${url}/uvsensors`}>UV Sensors</Link>
                </li>
                <li>
                    <Link to={`${url}/spectrometers`}>Spectrometers</Link>
                </li> 
                <li>
                    <Link to={`${url}/pressuresensors`}>Pressure</Link>
                </li> 
                <li>
                    <Link to={`${url}/ultrasonicsensors`}>Ultrasonic</Link>
                </li>
                <li>
                    <Link to={`${url}/gyroscopes`}>Gyroscopes</Link>
                </li> 
                <li>
                    <Link to={`${url}/currentsensors`}>Current</Link>
                </li>   
                <li>
                    <Link to={`${url}/gassensors`}>Gas Sensors</Link>
                </li>  
                <li>
                    <Link to={`${url}/forcesensors`}>Force Sensors</Link>
                </li> 
                <li>
                    <Link to={`${url}/lidar`}>Lidar</Link>
                </li>  
                <li>
                    <Link to={`${url}/magnetometer`}>Magnetometer</Link>
                </li>  
                <li>
                    <Link to={`${url}/irsensor`}>IR Sensors</Link>
                </li> 
                <li>
                    <Link to={`${url}/humiditysensors`}>Humidity Sensors</Link>
                </li> 
                <li>
                    <Link to={`${url}/photoresistor`}>Photoresistors</Link>
                </li>                       
                </div>
                
            <Container>
                <Switch>
                    <Route exact path={path}>
                    <h3>Please select a sub-topic.</h3>
                    </Route>
                    <Route path={`${path}/:topicId`}>
                        <Subtopics />
                    </Route>
                </Switch>
            </Container>
         
                    </div>
            </div>
        </div>
    );
};

export default Sensors; 