import NetworkDevicesImg from '../../Images/NetworkDevicesImg.jpg'

export default function Network(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Network Communications</h1>
               <p>
                                
           
                 
                        <h2>Intro to Network Communication;</h2>
Wireless communication devices connect transmission between devices and can range from a few centimeters to kilometers in distance. There are a
 variety of other ways to connect a machine to a data transferring network such as infrared communication, radio frequency modules, wifi 802.11 
 networks, bluetooth modules, GPS controlled, cellular, satellite and even voice controlled communication.
 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={NetworkDevicesImg} alt="network devices" width="55%"/>
                                </div>
                        </p>
            </div>
    )
}