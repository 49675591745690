import LidarSensorImg from '../../Images/LidarSensorImg.jpg'

export default function Lidar(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Lidar</h1>
               <p>

               <h2>Intro to Lidar;</h2>
The ability to perceive the world in three dimensions is pivotal to the development of many technologies and light detection and ranging sensors
 (Lidar) provide a solution for this challenge. The lidar device sends pulsed lasers, invisible to the human eye at certain frequencies and then
  measures the return time of the lasers along with their wavelengths. By using a system of mirrors, the lasers are able to generate clouds of
   points which when taken together can provide a three dimensional depth measurement. 

  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={LidarSensorImg} alt="uv sensor" width="60%"/>
                                </div>
                        </p>
            </div>
    )
}