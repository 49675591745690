import ThreePhaseImg from '../../Images/ThreePhaseImg.jpg'

export default function ThreePhase(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>3-Phase Systems</h1>
               <p>
                                
           
                      
                        <h2>Intro to Three Phase Systems;</h2>
With electrical circuits, the phase refers to the distribution of the load. In standard AC circuits you have two phases and thus two wires,
 a phase fire carrying the power and a neutral wire with current flowing between these two wires. In high load situations, a third can be
  added and each phase signal set to 120 electrical degrees apart. Some three phase systems may even have a fourth wire to help with efficiency.
<br />
                        <br /> 
Residential homes typically have two phase systems while industrial buildings use 3-phase. In the US, transformers are often use to achieve
 this third phase while other parts of the world such as Europe do it directly. Due to peaks and dips in voltage, 3-phase systems can also
  offer a more stable and consistent delivery of power.
  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ThreePhaseImg} alt="three phase signals" width="45%"/>
                                </div>
                        </p>
            </div>
    )
}