import IsometricImg from '../../Images/IsometricImg.jpg';
import DimensioningImg from '../../Images/DimensioningImg.jpg';
import SectionViewImg from '../../Images/SectionViewImg.jpg';

export default function Drawings(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>                   
                     <h1>Buckling</h1>
                      <p>
<h2>Isometric Drawings;</h2>

The purpose of an engineering drawing is to convey every piece of information required for an outside person to understand and potentially fabricate a workpiece. An isometric drawing is a 3 dimensional representation of an object which uses a 30 degree tilt to better show depth. In an isometric drawing, vertical lines are kept vertical while the horizontal and depth lines are drawn at a 30 degree angle from the horizontal axis. 
<br />
<br />
<h2>Orthographic Drawings;</h2>

An orthographic view of an object consists of having a compilation of two dimensional views which allows the viewer to see hidden features in an object that would otherwise be missed. Although three views is standard: front, side, top, one should attempt to use the minimum amount of views possible to describe the object fully.
<br />
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={IsometricImg} alt="Isometric" width="45%"/>
                            </div>  
                            <h2>Dimensioning;</h2>
                          
Dimensioning is the art of applying dimensions to parts with ideally no more and no less dimensions than are needed to fully understand the part. Not only does redundant dimensions clutter a drawing but often when tolerances are added, having additional dimensions increases the risk of conflicts. It is also good practice to refrain from chaining dimensions between features and instead measure each dimension from a fixed reference point. Also avoid placing dimensions directly over the objects whenever possible.
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={DimensioningImg} alt="Dimensioning" width="45%"/>
                            </div>  
                            <h2>Sectioning;</h2>
                      
It is common for a part to have interior details which cannot be made visible from the outside. In some cases, dotted lines can be used to indicate internal “hidden” geometry though this only is applicable if the parts are simple. In more complex geometries, a sectional view can be made to “cut” the object in half to display the cross sectional area.
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={SectionViewImg} alt="Section View" width="45%"/>
                            </div>  
                            </p>
            </div>
    )
}