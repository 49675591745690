import HumidityImg from '../../Images/HumidityImg.jpg'

export default function HumiditySensors(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>UV Sensors</h1>
               <p>
               <h2>Intro to Humidity Sensors;</h2>
A humidity sensor, sometimes called a hygrometer, can sense, measure and report both moisture and air temperature. Humidity is the concentration
 of water molecules within air. Relative humidity is a term used to describe the ratio between the moisture of the air to the highest moisture 
 possible at a certain temperature. 

  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={HumidityImg} alt="uv sensor" width="40%"/>
                                </div>
                        </p>
            </div>
    )
}