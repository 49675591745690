import ConnectorsImg from '../../Images/ConnectorsImg.jpg'

export default function Connectors(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Connectors</h1>
               <p>
                                
      
      <h2>Intro to Electrical Terminals;</h2>
Electrical wire connectors are components used to create a secure electrical connection and are used across almost all electrical engineering
 industries. 
 <br />
<br /> 
<h2>Wire Ferrules;</h2>
A wire ferrule is a type of wire terminal which is placed over a stripped wire and then crimped to provide a secure connection. These differ
 from standard terminals as the wire ferrule is crimped directly onto the metal connection while terminals are usually crimped further on the
  insulation material. When crimped over a stranded wire, it will protect the fine strands from fraying.  
  <br />
<br /> 
This component is often used with screw-type terminal blocks and in applications where repeated insertion and removal is necessary. Secondary
 benefits include no strand fraying, better contact and strain relief. 
 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ConnectorsImg} alt="electrical connectors" width="75%"/>
                                </div>
                        </p>
            </div>
    )
}