import BrazingImg from '../../Images/BrazingImg.jpg'

export default function Brazing(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Brazing</h1>
               <p>
                                
           
                        
                        <h2>Intro to Brazing;</h2>
The process of brazing is a permanent joining method which uses a molten filler material to join two pieces of metal. The nuance of this process is the need to attain a temperature that is above the melting point of the filler material but below the joined metal’s melting temperature. The capillary action of the molten metal will have it settle within the groove between the two joining pieces and solidify as it cools. 
<br />
                        <br /> 
Brazing joints often have a stronger tensile strength than the joined metals themselves and will repel gases and liquid along with withstanding shock and vibration. This method is also useful when joining two dissimilar metals which offers greater variability in design. 
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={BrazingImg} alt="BrazingImg" width="55%"/>
                                </div>
                        </p>
            </div>
    )
}