import AudioImg from '../../Images/AudioImg.jpg'

export default function Audio(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Audio Electronics</h1>
               <p>
                                
           
                   
                        <h2>Intro to Audio Electronics;</h2>
Audio electronics are devices which convert sound signals into electrical signals usually by the means of using a microphone. Further
 processing can allow you to amplify certain signals, filter out specific frequencies, combine signals, transform signals, modulate the signal
  and even use signals to trigger a switch. The reverse process of converting an electrical signal into a sound signal can also be achieved,
   usually by means of a speaker.
   <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={AudioImg} alt="microphone layout" width="55%"/>
                                </div>
                
                        </p>
            </div>
    )
}