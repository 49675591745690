import CapacitanceImg from '../../Images/CapacitanceImg.jpg'
import CapacitorTableImg from '../../Images/CapacitorTableImg.png'

export default function Capacitance(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Capacitance</h1>
               <p>
                                
           
            
                        <h2>Intro to Capacitance;</h2>
                        Capacitors are oppositely charged parallel conducting plates separated by a small distance by an insulator, usually air
                         or a dielectric such as ceramic. If you apply a voltage across the plates, electrons are pumped out of the negative
                          battery terminal and collect on the lower plate while electrons begin leaving the upper plate and going into the
                           positive battery terminal. A capacitor can attain a positive and negatively charged plate state very quickly. 
                           Along with this charge is a resultant electric field between the plates and a voltage equal to the battery voltage. 
                           <br />
                           <br />
The important aspect to note is that when you remove the voltage source, the charge, electric field and voltage remain.  A concept known as 
leakage current would eventually cause the capacitor to discharge potentially within seconds or hours depending on the materials used. The
 charge holds temporarily because the electric field binds the electrons like glue to the plate. 
 <br />
                           <br />
These components are widely used in industry for blocking direct current while allowing alternating current to pass. In analog filter networks
 and electric power transmission they are used to smooth out the power flow within the circuit. They can even be used in resonant circuits to
  tune radios to particular frequencies. 
  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={CapacitanceImg} alt="capacitance" width="55%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={CapacitorTableImg} alt="capacitance" width="55%"/>
                                </div>
                        </p>
            </div>
    )
}