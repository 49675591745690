import AlgebraTableImg1 from '../../Images/AlgebraTableImg1.PNG'

export default function IntegerFunctions(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Integer Functions</h1>
               <p>
                                
           
           <h2>Basic Integer Functions;</h2>
           <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={AlgebraTableImg1} alt="Algebra Table" width="65%"/>
                                </div>
                        </p>
            </div>
    )
}