import React from 'react';
import Subtopics from './Subtopics';
import { Container } from "react-bootstrap";
import {
    Switch,
    Route,
    Link,
    useRouteMatch
  } from "react-router-dom";

function Reductive(props) {
    let { path, url } = useRouteMatch();
    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', maxWidth: "100vw"}}>
                {/* <SectionTitle title={props.topicId}/> */}
            <div style={{display: 'flex', flexDirection: 'row'}}> 
            <div style={{marginTop: '10px', marginRight: '0px', marginLeft: '5px',border: '3px', padding:'40px', paddingRight: '0px', borderStyle:'solid', borderColor:'grey', paddingLeft: '20px', whiteSpace:'nowrap'}}>
                <h2 style={{whiteSpace:'normal'}}>Reductive</h2>
                <h2 style={{whiteSpace:'normal', paddingRight:'10px'}}>Manufacturing</h2>
                <li>
                    <Link to={`${url}/sheetmetal`}>Sheet Metal</Link>
                </li>
                <li>
                    <Link to={`${url}/pressbrake`}>Press Brake</Link>
                </li>
                <li>
                    <Link to={`${url}/lasercutting`}>Laser Cutting</Link>
                </li>
                <li>
                    <Link to={`${url}/plasmacutting`}>Plasma Cutting</Link>
                </li>
                <li>
                    <Link to={`${url}/glass`}>Glass Manufacturing</Link>
                </li>
                <li>
                    <Link to={`${url}/casting`}>Metal Casting</Link>
                </li>
                <li>
                    <Link to={`${url}/plastic`}>Plastic Manufacturing</Link>
                </li>
                <li>
                    <Link to={`${url}/speeds`}>Speeds & Feeds</Link>
                </li>
                <li>
                    <Link to={`${url}/endmills`}>End Mills</Link>
                </li>
                <li>
                    <Link to={`${url}/taper`}>Machine Tapers</Link>
                </li>
                <li>
                    <Link to={`${url}/turning`}>Turning Operations</Link>
                </li>
                <li>
                    <Link to={`${url}/tubebending`}>Tube Bending</Link>
                </li>
                {/* <li>
                    <Link to={`${url}/pmc`}>PMC Processing</Link>
                </li>
                <li>
                    <Link to={`${url}/powder`}>Powder Metallurgy</Link>
                </li>                 */}
                </div>
                
            <Container>
                <Switch>
                    <Route exact path={path}>
                    <h3>Please select a sub-topic.</h3>
                    </Route>
                    <Route path={`${path}/:topicId`}>
                        <Subtopics />
                    </Route>
                </Switch>
            </Container>
         
                    </div>
            </div>
        </div>
    );
};

export default Reductive; 