// import GT from '../../Images/GT.jpg'

export default function Derivatives(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Derivatives</h1>
               <p>
                                {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GT} alt="linkages" width="75%"/>
                                </div> */}
           
                        <br />
                        <br /> 
                        Hydraulic systems use pressurized liquids to power their assemblies by manipulating the volume of fluids in different
                         parts of the system. A natural physical property of liquids is that when pressurized, the pressure is transferred 
                         equally into all parts of the fluid. When combined with the fact that most liquids are very hard to compress, engineers 
                         can design hydraulic systems that are extremely powerful.                    
                
                        </p>
            </div>
    )
}