import OPAmpsImg from '../../Images/OPAmpsImg.jpg'

export default function OP(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Operational Amplifiers</h1>
               <p>
                                
           
                        
                        <h2>Intro to Operational Amplifiers;</h2>
Operational amplifiers (op amps) are an integrated device with two inputs (non-inverting and inverting), two DC power supply leads (positive 
and negative), an output lead and a few specialized leads based on the model. If the schematic omits the power supply leads, you can assume 
that a dual supply is being used.
<br />
                        <br /> 
The operation of the op amplifier can create a variety of useful ways. When the voltage applied to the inverting terminal is more positive
 than that of the non-inverting, the output saturates towards the negative supply voltage. Conversely, when the opposite is true, the output
  saturates towards the positive supply voltage. In other words, this device switches between maximum output states when there is a voltage
   difference between its inputs.
<br />
                        <br /> 
By itself this application is limited but becomes monumental when combined with negative feedback. When the voltage is channeled back from
 the output terminal to the inverting terminal the gain of an op amp can be controlled and the op amp’s output is prevented from saturating.
  This basic concept when paired with a resistor allows us to adjust the output voltage to a value determined by the resistance. There can
   also be positive feedback loops where the output in linked through a network to the non-inverting input although this is much less common.
   <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={OPAmpsImg} alt="op amps" width="55%"/>
                                </div>
                        </p>
            </div>
    )
}