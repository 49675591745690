// import GT from '../../Images/GT.jpg'

export default function Overview(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Overview of Clothing & Apparel</h1>
               <p>
                                {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GT} alt="linkages" width="75%"/>
                                </div> */}
           
           <br /> 
           <span style={{ fontWeight: 'bold' }}>Lab Coat:</span> A protective layer when dealing with fluids.
                        <br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Work Gloves:</span> A protective layer for your hands.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Welding Apron:</span> A protective layer when machining, designed to be sleeveless to reduce the risk of injury.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Welding Helmet:</span> A device used to protect the operator from welding glare.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Breathing Mask:</span> A device used to protect the respiratory system of the operator.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Hearing Protection:</span> A device used to protect the hearing of the operator during machining.               
                
                        </p>
            </div>
    )
}