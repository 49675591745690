import ProbabilityImg5 from '../../Images/ProbabilityImg5.PNG'

export default function Permutations(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Permutations and Combinations</h1>
               <p>
                                
           
                     
                        <h2>Intro to Permutations and Combinations;</h2>
A permutation is a specific order of a given set of objects. A combination is simply a set itself with no reference to order. The number of possible different permutations of [n] number of objects taken in an amount of [r] can be determined with the standard permutation equation. The number of distinct combinations can be found with the standard combination equation.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ProbabilityImg5} alt="Probability equations" width="55%"/>
                                </div>
                        </p>
            </div>
    )
}