import EquilibriumImg from '../../Images/EquilibriumImg.jpg'

export default function PhaseChange(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Phase Changes</h1>
               <p>
                           
                        <h2>Intro to Phase Changes;</h2>
Phase: A phase is a homogeneous part of a system which has uniformity both physically and chemically
<br />
Solution: A solid, liquid or gas in a single phase
<br />
Mixture: A grouping of solid, liquid or gas in more than one phase
<br />
Solubility Limit: The maximum concentration where a single-phase solution can exist
<br />
Equilibrium: The state of minimum energy for a given temperature, pressure and composition which will stay constant over time.
<br />
Metastable State: The time before a system reaches equilibrium.
<br />
Isomorphous: The complete liquid or solid solubility.
<br />
                        <br />   
<h2>Binary Systems;</h2>
The melting temperature of a solution which has two components will typically be between the melting temperature of the two. With pure components, complete melting can occur before the temperature increases but for multi-component solutions, a liquid and solid state can occur together at the same time.

<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={EquilibriumImg} alt="Equilibrium" width="45%"/>
                                </div>                      
                        </p>
            </div>
    )
}