
import JointerImg from '../../Images/JointerImg.png'
import PlanarImg from '../../Images/PlanarImg.png'
import RouterImg from '../../Images/RouterImg.png'
import TableSawImg from '../../Images/TableSawImg.png'

export default function WoodworkingTools(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Woodworking Tools</h1>
               <p>
                             
        
<h2>Planar;</h2>
<span style={{ fontWeight: 'bold' }}>Overview:</span> A wood planar is a mechanical machine which serves to smooth rough wood stock surfaces, make opposite material sides parallel and set thickness to a set dimension.
<br />
<span style={{ fontWeight: 'bold' }}>Description:</span> A wood planar is a mechanical machine which serves to smooth rough wood stock surfaces, make opposite material sides parallel and set thickness to a set dimension.
<br />
<span style={{ fontWeight: 'bold' }}>Intake Width:</span> The maximum width of material that will fit horizontally into the planar.
<br />
<span style={{ fontWeight: 'bold' }}>Max Intake Height:</span> The maximum height of material that will fit vertically into the planar.
<br />
<span style={{ fontWeight: 'bold' }}>Speed (Feed Rate):</span> The rotating speed at which the internal cutting blades will spin.
<br />
<br />
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={PlanarImg} alt="Mill" width="45%"/>
                                </div>
                                <br />
<br />                             
<h2>Router;</h2>

<span style={{ fontWeight: 'bold' }}>Description:</span> A tabletop router is used to smooth or shape the edge of wood pieces and often come with a variety of routing bits for different edge styles.
<br />
<span style={{ fontWeight: 'bold' }}>Table Top Length:</span> The horizontal dimension of the table work surface.
<br />
<span style={{ fontWeight: 'bold' }}>Table Top Depth:</span> The depth of the table work surface.
<br />
<br />
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={RouterImg} alt="Mill" width="45%"/>
                                </div>
                                <br />
<br />                             
<h2>Table Saw;</h2>

<span style={{ fontWeight: 'bold' }}>Description:</span> A table saw is a wood cutting machine used to cut wood pieces to a certain width.
<br />
<span style={{ fontWeight: 'bold' }}>Blade Diameter:</span> The diameter of the rotating blade within the table.
<br />
<span style={{ fontWeight: 'bold' }}>Rip Capacity:</span> Rip capacity is the maximum distance between the table saw and the adjustable shoulder when the adjustable shoulder is extended to its furthest position.
<br />
<span style={{ fontWeight: 'bold' }}>Speed (Feed Rate):</span> The rotating speed at which the internal cutting blades will spin.
<br />
<br />
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={TableSawImg} alt="Mill" width="45%"/>
                                </div>
                                <br />
<br />    
<h2>Jointer;</h2>

<span style={{ fontWeight: 'bold' }}>Description:</span> A jointer is a woodworking machine used to produce a flat surface onto a board’s side.
<br />
<span style={{ fontWeight: 'bold' }}>Table Length/Depth:</span> The dimensions of the work surface of the machine.
<br />
<span style={{ fontWeight: 'bold' }}>Cutter Head:</span> The amount of blades which the rotating element contains.
<br />
<span style={{ fontWeight: 'bold' }}>Cuts/Min:</span> The amount of cuts which the rotating blades will make given a unit of time.
<br />
<br />
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={JointerImg} alt="Mill" width="45%"/>
                                </div>
                                <br />
<br />    
<h2>Electric Sander;</h2>
Overview: An electric sander is a handheld power tool used to smooth the surface of wood using fast moving abrasive disks.
                      
                       
                       
                       
                        </p>
            </div>
    )
}