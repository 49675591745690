import PointDefectsImg from '../../Images/PointDefectsImg.jpg'

export default function Imperfections(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Imperfections in Solids</h1>
               <p>
                                
           
                        
                        <h2>Intro to Imperfections in Solids;</h2>
Microscopic defects can have profound impacts on the macroscopic properties of materials. During solidification, which is the process of casting molten material nuclei form which then grow to form crystals. As the crystals grow they begin to reach the borders of other grown nuclei and form grain boundaries. 
<br />
                        <br />
<h2>Point Defects in Metals;</h2>
Vacancies: Vacancies are crystal structures where atoms failed to occupy a space required to maintain the consistency of the crystal structure. This absence causes a distortion in the planes.
Interstitial Atoms: Interstitial atoms are atoms which have become stuck in a position where no atom should have been which also causes distortion of the planes as it “pushes” against other nearby atoms.
Substitutional Atoms: These are entirely different atoms which have found their way into the crystal structure which can affect the material properties. These fall into two subcategories where substitutional atoms are those which have roughly the same dimensions as the crystalline atoms and interstitial atoms which are much smaller and fit within the gaps of the crystal structure.
Frenkel Defect: In this case, a cation is out of place within the crystal structure.
Schottky Defect: In this case, a paired set of cation and anion vacancies within the crystal structure.
<br />
                        <br />
<h2>Line Defects;</h2>
Dislocations: Dislocations are one dimension defects where atoms are misaligned. This slip occurs between entire planes and thus produces permanent plastic deformation.
<br />
                        <br />
<h2>Bulk Defects;</h2>
Pores: These can affect optical, thermal and mechanical properties
<br />
Cracks: Cracks can affect mechanical properties
<br />
Foreign Inclusion: These can greatly influence electrical, mechanical and optical properties of a material.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={PointDefectsImg} alt="Point Defects" width="75%"/>
                                </div>
                        </p>
            </div>
    )
}