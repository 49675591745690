import ACCircuitsImg from '../../Images/ACCircuitsImg.jpg'

export default function AC(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>AC Circuits</h1>
               <p>
                                
           
                      
                        <h2>Intro to Alternating Circuits;</h2>
In a direct circuit, electrons flow in only one direction. Another common type of circuit is the alternating current where not only does
 the current periodically change directions but the voltage periodically changes as well. AC circuits are generated from repeating sine 
 waves which can vary in frequency from a few cycles per second to billions of cycles per second depending on the application. 
<br />
                        <br /> 
The most common way of generating sinusoidal waveforms is by electromagnetic induction using an AC generator. One of the most important 
aspects of these circuits is the ability to increase or decrease voltage using a transformer. With DC circuits, a transformer is useless 
and decreasing or increasing voltage typically involves resistive power loss. 
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ACCircuitsImg} alt="ac circuit" width="45%"/>
                                </div>
                        </p>
            </div>
    )
}