import ODEImg4 from '../../Images/ODEImg4.PNG'
import ODEImg5 from '../../Images/ODEImg5.PNG'
import ODEImg6 from '../../Images/ODEImg6.PNG'
import ODEImg7 from '../../Images/ODEImg7.PNG'
import ODEImg8 from '../../Images/ODEImg8.PNG'

export default function Laplace(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Laplace Transforms</h1>
               <p>
                                
           
                        <h2>Intro to Laplace Transforms;</h2>
The Laplace transform is a mathematical set of conversion functions which can bring equations with real variables into a frequency equation with complex variables. The unilateral transform pair is as follows.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ODEImg4} alt="laplace equations" width="35%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ODEImg5} alt="laplace table" width="65%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ODEImg6} alt="laplace table" width="65%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ODEImg7} alt="laplace table" width="65%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ODEImg8} alt="laplace table" width="65%"/>
                                </div>
                        </p>
            </div>
    )
}