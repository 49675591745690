import MotorsImg from '../../Images/MotorsImg.jpg'

export default function Motors(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Motors and Actuators</h1>
               <p>
<h2>Intro to Motors;</h2>
                            Electro-mechanical motors are the one of the most common methods of actuating a system and traditionally work by converting electrical energy into mechanical energy. <span style={{ fontWeight: 'bold' }}>DC motors</span>, which utilize direct current achieve this energy conversion by coiling wires in a certain configuration which then generate an electro-magnetic field when a current passes through. The rotor, which is the part of the motor which rotates, often has a system of field magnets which spin in accordance to the direction of the flowing power.
                            <br />
                            <br /> 
                            If you aren't familiar with the term <span style={{ fontWeight: 'bold' }}>actuator</span>, know that it is a general term which means an object that allows something to work. A motor is a type of actuator.
                            <br />
                            <br /> 
                            The five most common types of DC motor applications are the brushed DC motor, the brushless DC motor, the stepper motor, the servo motor and the linear actuator. 
                            <br />
                            <br /> 
                            The standard <span style={{ fontWeight: 'bold' }}>DC brushed motor</span> can often be used by simply running a current at the appropriate voltage through the motor’s voltage and ground wires. Most of these motors are bi-directional, meaning reversing the direction of the current will cause the motor to rotate in the opposite direction. Most of the differences between these motors and brushless motors have to do with the internal wire coil configurations. Brushed motors are cheaper and a motor controller is not needed for most applications. RC cars use brushed DC motors to power their wheels. They are great for making things spin at a fixed rotational speed. 
                            <br />
                            <br /> 
                            <span style={{ fontWeight: 'bold' }}>Brushless motors</span> are far more complicated, more expensive but have excellent efficiency, power output and make less noise. Drones often use brushless DC motors to spin their propellers due to these excellent characteristics and reduced size.
                            <br />
                            <br /> 
                            <span style={{ fontWeight: 'bold' }}>Stepper motors</span> are a form of brushless DC motor that have extremely high precision and can “step” in increments less than a degree. Small, hobby stepper motors are excellent for building machines that need high accuracy but are often slow compared to other traditional motors. These motors are often bipolar, which means the current has to alternate direction which requires a motor control board for control.
                            <br />
                            <br /> 
                            <span style={{ fontWeight: 'bold' }}>Servo motors</span> are built using a DC motor and an arrangement of internal gears and can be very easily controlled using a servo control board. Although they are easy to use and have reasonable accuracy, their downside is that these motors will often only be able to rotate 180 degrees or less. These work great for robotic starter projects because most servo control boards can control a dozen or more servos at a time.
                            <br />
                            <br /> 
                            The <span style={{ fontWeight: 'bold' }}>linear actuator</span> is a component which uses an internal DC motor to make a cylinder move in and out of a chamber in a straight line. The term stroke refers to how long is the distance between the cylinder when it is fully retracted to when it is fully extended. In most cases, because they use an internal DC motor, they can be controlled using a standard DC motor controller. Linear actuators are often very strong and can push and pull objects very well making them excellent for walking robots.
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={MotorsImg} alt="Motors" width="55%"/>
                            </div>
                            </p>
            </div>
    )
}