import GaugeImg1 from '../../Images/GaugeImg1.jpg'
import GaugeImg2 from '../../Images/GaugeImg2.jpg'
import GaugeImg3 from '../../Images/GaugeImg3.jpg'
import GaugeImg4 from '../../Images/GaugeImg4.jpg'
import GaugeImg5 from '../../Images/GaugeImg5.jpg'
import GaugeImg6 from '../../Images/GaugeImg6.jpg'


export default function Gauge(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Gauge Tables</h1>
               <p>
               <h2>Sheet Metal Gauge Tables;</h2>

 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GaugeImg1} alt="Gauge Table" width="60%"/>
                                </div> 
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GaugeImg2} alt="Gauge Table" width="60%"/>
                                </div> 
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GaugeImg3} alt="Gauge Table" width="60%"/>
                                </div> 
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GaugeImg4} alt="Gauge Table" width="60%"/>
                                </div> 
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GaugeImg5} alt="Gauge Table" width="60%"/>
                                </div> 
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GaugeImg6} alt="Gauge Table" width="60%"/>
                                </div>                                 
                        </p>
            </div>
    )
}