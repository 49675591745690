import ODEImg3 from '../../Images/ODEImg3.PNG'

export default function Fourier(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Fourier Transforms</h1>
               <p>
                                


                        <h2>Intro to Fourier Transforms;</h2>
The Fourier transforms are mathematical formulas which convert equations from the space and time domain into decomposed functions within the spatial and frequency domain. This is used largely in signal processing.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ODEImg3} alt="fourier table" width="75%"/>
                                </div>
                        </p>
            </div>
    )
}