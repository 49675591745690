import PressBrakeImg from '../../Images/PressBrakeImg.jpg'
import NeutralAxisShiftImg from '../../Images/NeutralAxisShiftImg.png'

export default function PressBrake(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Press Brake</h1>
               <p>
                                
           
               <h2>Intro to Press Brakes;</h2>
         
Sheet features can be bent to a desired angle using a press brake machine. The press brake uses a set of tools called a punch and die which sits above and below the sheet and forms the bend angles once compressive force is applied. The bend angle is determined by the depth at which the punch forces the sheet into the die. As always, it is important to compensate for the springback of the material once the compressive force has been removed.
<br />
                        <br /> 
Air bending is the process in which the material does not reach the bottom of the die. Bottoming is the process when the sheet collides with the bottom of the die. Both of these conditions will have their own set of rules and tables.
<br />
                        <br /> 
Press brake machines are traditionally rated by their pressing capacity (tonnage) and their overall possible bending length. The actuating force of the brake can come from various sources though the most common types of press brakes are mechanical, pneumatic, hydraulic and servo-electric. Mechanical press brakes derive their power from a flywheel connected to ram by a crankshaft. Pneumatic and hydraulic press brakes use compressed air and hydraulic cylinders respectively for their power. Servo-electric press brakes often utilize belt-driven ball screw assemblies to power their vertical ram.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={PressBrakeImg} alt="Press Brake" width="65%"/>
                                </div>
                                <br />
                        <br /> 
<h2>Neutral Axis Movement;</h2>
Bending metal will naturally cause elongation as the top of the bend undergoes compression and the bottom of the bend is expanded from the tensile forces. This interaction causes the neutral axis (the boundary between compression and tension) to shift upwards towards the inside bend radius. This shift is referred to as the k-factor which can be used to deduct from the original dimensions so that the final product has the correct dimensions after elongation.       
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={NeutralAxisShiftImg} alt="Press Brake" width="45%"/>
                                </div>
                        </p>
            </div>
    )
}