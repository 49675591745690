import MotherBoardImg from '../../Images/MotherBoardImg.jpg'

export default function Motherboards(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Motherboards</h1>
               <p>
                                
           
                       
                        <h2>Intro to Motherboards;</h2>
The motherboard is attached to every component in the computer and allows them to freely communicate. The motherboard also contains controllers
 which are circuits that allow the operating system to connect with hardware such as the hard drive.
<br />
                        <br /> 
Read-Only Memory (ROM) is hard-wired permanent memory which is stored even when the system is powered off. This type of memory handles things
 that need minimal modification as it is difficult to change. 
 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={MotherBoardImg} alt="MotherBoard" width="45%"/>
                                </div>
                        </p>
            </div>
    )
}