import ClutchesImg from '../../Images/ClutchesImg.jpg'

export default function Clutches(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Clutches and Brakes</h1>
               <p>
      <h2>Intro to Clutches;</h2>
                        Brakes work by attempting to maximize friction between a rotating component and a static component while simultaneously
                         attempting to minimize wear. A clutch acts similarly but with the difference that it connects two rotating elements, 
                         ideally in a smooth and gradual connection. The ability of a clutch to connect and disconnect components sharing an 
                         axis of rotation allows for conditional assemblies.                    
                        <br />
                        <br />
                        Friction brakes and clutches have three minimum requirements for the assembly to function. 
                        <br />
                        1. The torsional force between the two members caused by friction will require a force of its own to keep the two members pressed together.
                        <br />
                        2. The energy converted into frictional heat has to be low enough not to cause damage to any of the components of the system. 
                        <br />
                        3. The deterioration and wear of the brake pad or clutch surface must have an acceptable life span.
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ClutchesImg} alt="clutches" width="55%"/>
                                </div>
                        </p>
            </div>
    )
}