import LatheImg from '../../Images/LatheImg.jpg'
import T36 from '../../Images/T36.JPG'
import ThreadingImg from '../../Images/ThreadingImg.jpg'
import TappingImg from '../../Images/TappingImg.jpg'
import GroovingImg from '../../Images/GroovingImg.jpg'
import KnurlingImg from '../../Images/KnurlingImg.jpg'
import PartingImg from '../../Images/PartingImg.jpg'
import ReamingImg from '../../Images/ReamingImg.jpg'
import StepTurningImg from '../../Images/StepTurningImg.jpg'
import TaperTurningImg from '../../Images/TaperTurningImg.jpg'
import FormingImg from '../../Images/FormingImg.jpg'
import FacingImg from '../../Images/FacingImg.jpg'
import DrillingImg from '../../Images/DrillingImg.jpg'
import ContourTurningImg from '../../Images/ContourTurningImg.jpg'
import ChamferTurningImg from '../../Images/ChamferTurningImg.jpg'
import BoringImg from '../../Images/BoringImg.jpg'

export default function Lathe(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Lathe</h1>
               <p>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={LatheImg} alt="Lathe" width="100%"/>
                                </div>
                                <div style={{width:'60%'}}>
                                <span style={{ fontWeight: 'bold' }}>Description:</span> A lathe operates by rapidly rotating a piece of material and performing reductive manufacturing operations to that piece, usually by the use of a cutting element.
                       
                                </div>
                </div>
                               
           
          
                        <h2>Lathe Specifications</h2>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={T36} alt="Lathe" width="100%"/>
                                </div>
                                <br /> 
                        <span style={{ fontWeight: 'bold' }}>Spindle Power:</span> The maximum output of mechanical work performed by the spindle onto a workpiece.

                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Spindle Speed:</span> The max rotating speed of the spindle element.

                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Spindle Bore:</span> The max diameter which the lathe can carve a horizontal hole into a workpiece.

                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Max Workpiece Length:</span> The max horizontal length of a workpiece inserted into the machine.
                      <br /> 
                        <span style={{ fontWeight: 'bold' }}>Swing:</span> The maximum diameter of a workpiece that can be inserted into the lathe’s chuck.                
                        <br />
                        <br /> 

<h2>Types of Lathe Tools;</h2> 


<span style={{ fontWeight: 'bold' }}>Facing Tool Post</span>  
<br /> 
<span style={{ fontWeight: 'bold' }}>Right & Left Hand Shank Cutting Tool</span>  
<br /> 
<span style={{ fontWeight: 'bold' }}>Boring Bar</span>  
<br /> 
<span style={{ fontWeight: 'bold' }}>Chamfering Tool</span>  
<br /> 
<span style={{ fontWeight: 'bold' }}>Knurling tool</span>  
<br /> 
<span style={{ fontWeight: 'bold' }}>Parting Tool</span>  


                        </p>
            </div>
    )
}