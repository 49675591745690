// import GT from '../../Images/GT.jpg'

export default function NumberSystems(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Number Systems</h1>
               <p>
                                {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GT} alt="linkages" width="75%"/>
                                </div> */}
           
                        <br />
                        <br /> 
                        The process of combustion utilizes the release of energy from a fuel and air mixture. In an internal combustion
                         engine, this process occurs inside of the engine itself and through a series of pistons, crankshafts and 
                         valves, will travel through the engine and convert mechanical power.                    
                
                        </p>
            </div>
    )
}