import SwitchesImg from '../../Images/SwitchesImg.jpg'

export default function Switches(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Switches</h1>
               <p>
                                
           
                      
           <h2>Intro to Switches;</h2>
Switches are electro-mechanical devices used to connect or disconnect current flow within a circuit or divert the electric flow through another
 path. A switch is characterised by its number of “poles” and number of “throws”. The poles represent the number of contacts that the switch can
  move to while the throws are the amount of possible contact to contact connections. 
  <br />
<br /> 
One of the two main features which need to be observed with switches is to note whether the switch has momentary contact or if it has latched
 contact. Momentary contact switches like pushbuttons are mainly used when you only want a connection for a limited amount of time. Latched
  switches will maintain connection until it is moved to another position. 
  <br />
<br /> 
The second important feature is if the switch has a neutral position such as a center-off position.
<br />
<br /> 
The two types of switches are mechanical switches which require physical contact to operate. Electric switches on the other hand are controlled
 using semiconductors and can be digitally activated and configured. 
 <br />
<br /> 
<h2>Mechanical Switches;</h2>
Mechanical switches can be categorized in a variety of ways including their method of actuation (manuel, limit, process switches), their 
number of contacts, their number of poles and throws, their operation and construction (pushbuttons, toggle, rotary, joystick) or their
 memory type (momentary or latched/locked). 
 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={SwitchesImg} alt="switches" width="75%"/>
                                </div>
                        </p>
            </div>
    )
}