import ParticlesImg from '../../Images/ParticlesImg.jpg'

export default function Particles(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Particle Theory</h1>
               <p>
         
<h2>Intro to Particles;</h2> 
Subatomic particles can exist as both a wave and a particle with their default state being waves. Solid matter as we experience it becomes less
 and less “solid” as you zoom in, until it reaches the point where everything boils down to clouds of waves and particles which rapidly jump
  between locations. 
  <br />
                        <br />   
The standard model of elementary particles is separated into three generations of matter, composed of groups known as quarks and leptons. These 
particles make up the family which builds the structure for solid matter in our universe. The other family of particles are the force carriers, 
known as bosons. The higgs boson is a particular particle which gives matter mass. Light particles, known as photons, have attached bosons, and 
thus have no natural mass, though they do have a charge and spin. 
<br />
                        <br />   
Several proposed particles such as gravitons are theorized to exist but have never been confirmed in a lab. These ultra-small particles could
 be the force particles which give matter their gravitational attractions. Anti-matter is another theorized particle theory that is so far
  unverified, in a nutshell, it states that for most forms of particles, there exists another particle with opposite spin and charge which
   form when together with the standard particles and thus equalize the energy equations upon creation.
   <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ParticlesImg} alt="Particles" width="85%"/>
                                </div>
                        </p>
            </div>
    )
}