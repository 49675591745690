import BasicFiltersImg from '../../Images/BasicFiltersImg.jpg'

export default function Basic(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Basic Filters</h1>
               <p>
                              
           
                        
                        <h2>Intro to Filters;</h2>
Filters are a process you can implement into a circuit to block certain frequencies while allowing others through. Low-pass filters allow low frequency signal components to pass while high-pass filters only allow high frequency signals through. The bandpass filter passes a short range of frequencies centered around the filter’s resonant frequency. The notch filter (often called the band-reject filter) passes all frequencies except those centered around the filter’s natural frequency. 
<br />
                        <br /> 
Filters are useful for eliminating unwanted noise and even flattening out dc voltages. Radios are an example of a filter as the changes to the input filters out all radio broadcasts except those at a very specific frequency. 
<br />
                        <br /> 
Filters come in two general types; active filters and passive filters. Passive filters are designed using passive components such as resistors, capacitors and inductors and are traditionally better suited for frequencies between 100 Hz to 300 MHz. Active filters are constructed from op amps, capacitors and resistors and are capable of handling extremely low frequencies (close to 0 Hz). They can also provide voltage gain and increase the ratio of the output voltage from the input voltage. A drawback of active filters is their low frequency capability (up to 100 kHz) before they become unreliable due to their op amp’s bandwidth. 
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={BasicFiltersImg} alt="Basic Filters" width="65%"/>
                                </div>
<h2>Terminology and Units;</h2>
When describing a filter it is commonplace to use a response curve which presents attenuation (V_out/V_in) vs frequency. Attenuation is often expressed in decibels (dB) while frequency can be expressed using angular rotation ⍵ (rads/sec) or using the conventional notation in Hertz (Hz).
The two forms are related by the following equation.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>-3-dB Frequency (f_3dB):</span> This term represents the input frequency that will cause the output signal to drop to -3dB relative to the input signal. The -3dB frequency is equivalent to the cutoff frequency which is the point in which the input-to-output power is reduced by one half or reduced by 1/sqrt(2). Low-pass and high-pass filters have only one -3dB frequency though the bandpass and notch filters have two (called f1 and f2).
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Center Frequency (f0):</span> On linear-log graphs, bandpass filters are symmetrical around the filters center (resonant) frequency. On linear-log paper, the central frequency is equal to the square root of the f1 and f2 frequency.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Passband:</span> This term represents the frequency signals which reach the output with less than or equal to -3dB of attenuation.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Stop-band Frequency (fs):</span> This specific frequency is where the attenuation reaches a  desired output set by the designer.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Quality Factor (Q):</span> This is the ratio of the center frequency of a bandpass filter to the -3dB bandwidth.

                        </p>
            </div>
    )
}