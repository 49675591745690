import SolenoidImg from '../../Images/SolenoidTypes.jpg'

export default function Solenoids(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Solenoids</h1>
               <p>
                                
      
                        <h2>Intro to Solenoids;</h2>
                        Solenoids are electrical devices composed of electrical windings, a moveable plunger armature and a housing. Once an
                         electrical current is sent through the wires, the magnetic field attracts or repels the solenoid’s movable armature
                          depending on the solenoid. You can find solenoids that extend and retract on the market. The housing which is usually
                           made of iron or steel provides protection but also concentrates the magnetic field. Without power, the armature may 
                           or may not lock into its resting position depending on the solenoid. Although solenoids are often very small, the 
                           force in which they are able to open and close the aperture is often extremely high.
                           <br />
<br /> 
<h2>Types of Solenoids;</h2>
AC Laminated Solenoids: This type of solenoid is known for the amount of force it can generate for its first stroke. The length of the stroke is also often longer than DC solenoids. These produce a steady buzzing sound when in use.
<br /> 
DC C-Frame Solenoid: This type of solenoid uses a C-shaped frame to wrap around the coil of wire. These can also be configured for AC systems, though DC is more common.
<br /> 
DC D-Frame Solenoid: This type of solenoid is very similar to the C-frame but with the exception that it uses two pieces of metal for its protective structure which forms a D-shaped covering.
<br /> 
Linear Solenoid: The linear solenoid is one of the most common on the market as they can be configured to either push or pull when energized. These are often used in locking mechanisms as they can provide an enormous amount of resistance from opening or closing.
<br /> 
Rotary Solenoid: The rotary solenoid uses bearings, disks and grooves to provide a rotational movement of the armature.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={SolenoidImg} alt="solenoid types" width="75%"/>
                                </div>
                        </p>
            </div>
    )
}