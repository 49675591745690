import NonFerrousImg from '../../Images/NonFerrousImg.jpg'

export default function NFMetals(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Non-Ferrous Metals</h1>
               <p>
                                
           
                               <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                               <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={NonFerrousImg} alt="NonFerrous" width="100%"/>
                                </div>
                                <div style={{width:'70%'}}>
                                <h2>Intro to Metals;</h2>
Typically metals, more so than any other material type, are used due to their extremely high degree of strength and durability. When in the 
presence of humans, materials are strictly regulated by the FDA and must meet specific healthcare criteria, such as being non toxic. They often
 require the use of cleaning agents so a surface finish capable of resisting chemicals is often an additional requirement. When dealing with 
 implants, metals also need to be non-magnetic and non-corrosive.

                                </div>
                                </div>         
                      
 <h2>Copper;</h2>
Copper is soft and may cause toxicity if inside a human. However, copper has outstanding antiviral and antibacterial properties. Surfaces that
 are constantly being touched like door handles, handrails or switches are perfect for this metal. 
<br />
                        <br /> 
<h2>Titanium;</h2>
Titanium is often used instead of stainless steel as it is just as strong and durable while being substantially lighter. It has its drawback
 however, that it is very difficult to manufacture. They can however, be 3D printed for a high cost. 
<br />
                        <br /> 
<h2>Aluminum;</h2>
This metal is one of the most common in industry for being light, moderately strong and corrosion resistant. Aluminum is often alloyed, painted
 or anodized for durability and surface considerations.
<br />
                        <br /> 
<h2>Cobalt Chrome;</h2>
Has very high wear resistance and can be electropolished to make the surface very smooth and prevent contamination. It is very hard and can be
 CNC machined and 3D printed.
               
                
                        </p>
            </div>
    )
}