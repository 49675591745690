import FD1 from '../../Images/FD1.PNG'
import FD2 from '../../Images/FD2.PNG'
import FD3 from '../../Images/FD3.PNG'
import FD4 from '../../Images/FD4.PNG'
import FD5 from '../../Images/FD5.PNG'
import FD6 from '../../Images/FD6.PNG'
import SurfaceTensionImg from '../../Images/SurfaceTensionImg.jpg'

export default function Properties(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Fluid Properties</h1>
               <p>
                                
           <h2>What is a Fluid;</h2>
           To provide a definition which can satisfy engineers and scientifically minded individuals, the molecular structure of materials
            forms the basis to what matter can be categorized as a fluid or as a solid. A solid has densely spaced molecules with strong 
            intermolecular bonds which allows the solid to maintain its shape. The fluid, which consists of both liquids and gases have molecules
             that are spaced further apart and whose intermolecular bonds are either very weak or negligible. These differences allow fluids to
              be easily deformed, poured into containers or pressurized through a tube. Specifically, a fluid is defined as a substance that
               deforms continuously when acted upon by a shearing stress of any magnitude.                 
<br />
<br /> 
<h2>Density;</h2>
The density of a fluid, defined by the Greek symbol 𝞺 (rho) is defined as the mass per unit volume of a material. In the SI system, density has
 units of kg/m^3 while in the BG system, it uses slugs/ft^3. The value of density for fluids can vary enormously but liquid density is
  traditionally less impacted by changes in temperature and pressure. Gases on the other hand, are highly affected by these changes.
<br />
<br /> 
Specific volume is the inverse of density (𝙫=1/p) and is known as the volume per unit mass of a material. 
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={FD1} alt="density" width="10%"/>
                                </div> 

<h2>Specific Weight</h2>
The specific weight of a fluid, denoted by the Greek symbol 𝜸 (gamma) is defined as the weight per unit volume of matter. This is thus related
 to density by being equal to the density times the local gravitational acceleration.
<br />
<br />
Similar to how density is used to characterize the mass of a material, specific weight is used to characterize its relative weight. The BG
 units are in lbs/ft^3 while the SI units are in N/m^3 under conditions of standard gravity (g=32.174 ft/s^2 = 9.807 m/s^2). Under these
  conditions of standard gravity, water has a specific weight of 62.4 lb/ft^3 and 9.81 kN/m^3.
  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={FD2} alt="specific weight" width="10%"/>
                                </div> 

<h2>Specific Gravity</h2>
The specific gravity (SG) of a fluid is defined as the ratio of the density of the fluid and the density of water at a specified temperature. 
The usual temperature used is 4 degrees celsius because at this temperature the density of water is 1000kg/m^3 or 1.94 slugs/ft^3. 
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={FD3} alt="specific gravity" width="15%"/>
                                </div> 

<h2>Ideal Gas Law</h2>
Unlike liquids which can often be described as being negligibly compressible, gases can change in gas density enormously from the change in 
pressure and temperature. An ideal gas is a theoretical gas whose molecules occupy zero space, travel in straight (cartesian) lines and who 
have zero attraction to each other. Although this ideal gas does not exist in the real world, its similarity to real gases allows very accurate
 approximations for the relationship between pressure, temperature, and density. That mathematical relationship is known as the ideal gas law. 
 <br />
<br />
It states, the density of a gas will equal to its absolute pressure divided by the absolute temperature times a gas constant. The gas constant 
R, will depend on the specific gas and its molecular weight. 
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={FD4} alt="ideal gas law" width="10%"/>
                                </div> 

<h2>Viscosity</h2>
Properties such as density and specific weight which measures the “heaviness” of a fluid are not sufficient to describe the properties of a 
fluid. Oil and water have very similar densities yet behave in very different ways. Viscosity is the measure of how much a fluid adheres to 
itself and can be thought of as the “stickiness” of a fluid to itself. On a molecular scale, the chains of molecules for these kinds of fluids 
typically range much longer than fluids such as oil, whose chains are almost nonexistent.
<br />
<br />
 Due to viscosity being related to fluid resistance, it follows that as temperature increases, viscosity decreases. The effect of temperature
  on viscosity can be approximated using the Sunderland Equation for gases and Andrede’s Equation for liquids. In the case of gases, C and S
   are empirical constants and T is absolute temperature. In the case of liquids, B and D are the constants and T is absolute temperature. 
   <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={FD5} alt="viscosity" width="10%"/>
                                </div> 

<h2>Reynold's Number</h2>
A dimensionless combination of variables that is often used in the study of viscous flow through pipes is called the Reynold’s Number(Re).
 In regards to its equation, 𝞺 is the density, V is the mean fluid velocity, D is the pipe diameter 𝛍 is the fluid viscosity. What is 
 specifically important is the ratio between viscosity and density which is known as the kinematic viscosity.                                
 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={FD6} alt="viscosity" width="10%"/>
                                </div>    

<h2>Bulk Modulus</h2>
Another important characteristic of fluids is its compressibility and how its volume responds to pressure. The property known as bulk 
modulus E_v is commonly used to describe a fluid's compressibility. In most cases, a decrease in volume will result in a higher density
 which is represented as the negative sign in the equation. As gases are compressed or expanded, the relationship between pressure and 
 density depends on the circumstances of the process. If the process is isothermal (constant temperature) then the pressure over density
  will equal constant.
  <br />
<br />
<h2>Vapor Pressure</h2>
It is commonly observed that liquids left in a container that is open to the atmosphere will evaporate. This is due to the fact that some
 molecules of the liquid at the surface will have sufficiently high energy to overcome the intermolecular cohesive forces. If a container
  of fluid is closed with a small air space above the fluid, pressure will begin to accumulate in the air pocket as a result of the escaping
   molecules. Vapor pressure is the measured pressure state in which the number of molecules entering and leaving between the surface and the 
   air pocket reaches equilibrium.
   <br />
<br />
<h2>Surface Tension</h2>
At the interface between fluids and the atmosphere, liquids and gases naturally form an attractive force between molecules which attempt
 to cling to like molecules. Molecules within a fluid are all attracted to each other equally, yet molecules at the surface are subjected
  to a net force towards the interior. This phenomena can be observed when a relatively lightweight piece of matter, such as a leaf lands 
  and sits on top of water without breaking the surface.   
  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={SurfaceTensionImg} alt="Surface Tension" width="50%"/>
                                </div> 
                        </p>
            </div>
    )
}