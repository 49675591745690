import Gauge1 from '../../Images/Gauge1.jpg'
import Gauge2 from '../../Images/Gauge2.jpg'
import WireTypes from '../../Images/WireTypes.jpg'

export default function Wires(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Wires</h1>
               <p>
                        
           <h2>Intro to Wires</h2>
                 
                        Gauge number is a term used to describe a wire’s diameter. The diameter has an inverse relationship with the gauge number
                         and the larger the diameter, the smaller the gauge number. When the current is expected to be large, it is important to
                          use a wire with a sufficient current rating that has a safety factor to account for fluctuations.
                        <br />
                        <br /> 
Solid core wire is made of a single length of metal and is an excellent choice for wiring up breadboards. The solid core fits easily into the
 breadboard’s sockets and will not fray like a stranded wire. The one characteristic to note is solid core wires commonly have low fatigue
  strengths and will snap after being bent back and forth several times. 
<br />
                        <br /> 
Braided and stranded wires are constructed from intertwined stands of metal, often copper. These types of wires are typically better conductors
 than the solid core wire because the multiple of strands comprising a greater surface area. Braided and stranded wires are also very flexible
  and will not break when flexed. Braided wires are often used when an electromagnetic shield is necessary, typically in noise-reduction cables.
  <br />
                        <br /> 
<h2>Types of Wire;</h2>
Pretined Solid Bus Wire: This type of wire has a tin-lead alloy coating to enhance solderability and is usually coated with polyethylene, 
Teflon or PVC.
<br />
Speaker Wire: This stranded wire has a high copper content to increase conduction and comes as a joined pair.
<br />
Magnet Wire: This wire is used for building anything that requires a high amount of loops such as coils and electromagnets. It is made of 
solid core wire and insulated by a varnish coating.

                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={WireTypes} alt="wire types" width="75%"/>
                                </div>
<h2>Copper Wire Specifications and Gauge Chart</h2>
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={Gauge1} alt="wire gauge chart" width="75%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={Gauge2} alt="wire gauge chart" width="75%"/>
                                </div>
                        </p>
            </div>
    )
}