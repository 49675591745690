import T10 from '../../Images/T10.JPG'


export default function Overview(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Overview of General Machines</h1>
               <p>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={T10} alt="Overview of Template" width="100%"/>
                                </div>
   
                              
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Hydraulic Shop Press:</span> A shop press is a machine which uses a lever and hydraulic system to generate compressive force.
                        <br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Drill Press:</span> A drill press is a machine tool used to drill round holes into material. 
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Grinder & Belt Sander:</span> A grinder/belt sander is a two-in-one mechanism used to refine the surface of a material using rotating abrasive surfaces.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Miter Saw:</span> The miter saw is a rotating cutting saw which can be positioned to produce angle cutting paths. The cutting operation is done by lowering the blade onto the workpiece which has been placed on top of the lower cutting bed.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Band Saw:</span> The band saw is a continuous band of sharp metal which is stretched between two or more wheels that rotate to allow cutting of a material. Because the cutting teeth on the band are small, this machine is often used for cutting metal and other hard materials.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Laser Cutter:</span> Laser cutting is a manufacturing technique which uses high energy beams to cut through materials.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Air Compressor:</span> An air compressor is an electromechanical device which converts electrical power into stored potential energy in the form of pressurized gas or air. 
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Dust Collection System:</span> A dust collector is a mechanical system which uses air flow to capture particles and clean air or other gases from an ambient environment.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Bench Vise:</span> A vice is a mechanical tool used to squeeze components or material under compression to allow them to be worked upon without fear of slipping. It is often helpful to have both a light duty vice with soft jaws and a heavy duty vice with additional features.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Shop Vacuum:</span> A shop vac is a vacuum cleaner system designed for both dry and wet spills. Most models are capable of being used indoors and outdoors and are a necessary tool in keeping any workshop clean.
                        </p>
            </div>
    )
}