import React from "react";
import {
//   BrowserRouter as Router,
  useParams,
} from "react-router-dom";
import Safety from './Safety';
import Circuits from './Circuits';
import Multimeters from './Multimeters';
import Oscilloscopes from "./Oscilloscopes";
import Soldering from "./Soldering";
import Symbols from "./Symbols";
import Shielding from "./Shielding";
import ElectricalTools from "./ElectricalTools";

const ChangeComponents = {
    safety: Safety,
    circuits: Circuits,
    multimeters: Multimeters,
    oscilloscopes: Oscilloscopes,
    soldering: Soldering,
    symbols: Symbols,
    shielding: Shielding,
    electricaltools: ElectricalTools,
  };

export default function Subtopics(props) {
    // The <Route> that rendered this component has a
    // path of `/topics/:topicId`. The `:topicId` portion
    // of the URL indicates a placeholder that we can
    // get from `useParams()`.
    let { topicId } = useParams();
    
    let MyComponent = ChangeComponents[topicId];
    return (
        <div>
            {/* {topicId} is for testing route names */}
            {/* <h3>{topicId}</h3> */}
            <MyComponent topicId={topicId}/>
      </div>
    );
  }