import CPUImg from '../../Images/CPUImg.jpg'

export default function CPU(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>CPU</h1>
               <p>
                                
           
                    
                        <h2>Intro to CPUs;</h2>
The CPU is often called the brains of the computer because it controls so many of the processes involved in computing. A processor's speed is
 measured in megahertz (MHz) which determines the amount of instructions per second calculated and gigahertz (GHz), which is billions of 
 instructions per second. The computer as a whole depends on multiple components to determine speed but the CPU speed determines the amount 
 of instructions per second which has a high impact.
<br />
                        <br /> 
<h2>Memory and RAM</h2>
CPUs act closely with memory which stores and sends data. Random Access Memory (RAM) is impermanent memory which is stored for the duration
 which the computer is on but disappears when the computer is powered off. The more RAM the computer has, the more things it can do at once.
  A lack of RAM may cause a computer to be slow, especially when multiple programs are open at once. Because of the close relationship between 
  the CPU and RAM, the two are often located adjacent to each other if not directly built on top of each other.
  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={CPUImg} alt="cpu" width="45%"/>
                                </div>
                        </p>
            </div>
    )
}