import DevelopmentBoardsImg from '../../Images/DevelopmentBoardsImg.jpg'

export default function Circuits(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Constructing Circuits</h1>
               <p>
                                
           <h2>Circuit Diagrams</h2>
                    
                        The circuit schematic or circuit diagram is the blueprint for a circuit and should be constructed according 
                        to standard practice so that it can easily be understood by other engineers. This document should entail everything 
                        a reader would need to buy required parts, assemble things together and give a basic understanding of the expected 
                        output. The following list goes over common industry practice for creating easy-to-read circuit diagrams.                   
                        <br />
                        <br /> 
                        1. Standard convention places inputs to the left, outputs to the right, positive supply terminals at the top, negative supply terminals to the bottom of the drawing.
                        <br /> 
                        2. Keep functional groups such as amplifiers, input stages filters, ect out of the schematic.
                        <br /> 
                        3. Annotate each of the components such as resistor 1 as R_100 if the resistor is 100 ohm.
                        <br /> 
                        4. Be sure to use abbreviations for large values such as 1 mega ohm = 1,000,000 ohms.
                        <br /> 
                        5. When labeling components with pins, place pins on the outside of the components and the name of the component on the inside. Be sure to label the pins.
                        <br /> 
                        6. If the waveform is important, be sure to include it somewhere on the diagram.
                        <br /> 
                        7. Wires that are joined are indicated by a dot, at their joining location while wires that are not joined either have a semicircle over the crossing area or are simply crossed.
                        <br /> 
                        8. Include an area at the bottom of the diagram that includes the name of the diagram, the designer’s name and the date. 
                        <br /> 
                        <br /> 
                        <h2>Development Boards;</h2>
                     
                        Development boards come in a variety of shapes and sizes but come in two main models, the bread-board and the proto-board. 
                        The bread-board consists of a square rectangle with a grid of input holes that you can stick the end of a wire into. Hidden 
                        from view, are rows of metal connecting the holes together. Most common breadboards will also have a column on either side
                         of the board to connect the positive and negative voltage lines.
                         <br /> 
                        <br /> 
                        A proto-board on the other hand is a flat plastic rectangle used to permanently join electrical components by soldering
                         them onto conductive metal rings. These rings can be connected by applying solder into any two dimensional configuration
                          desired. It is important to heat the metal rings before solder is applied or the connection will be of poor quality.
                          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={DevelopmentBoardsImg} alt="Development Boards" width="45%"/>
                                </div>
                        <h2>PCB Design</h2>
                     
                        Most modern engineers utilize online electrical circuit designers which take an input circuit and generate a PCB board
                         with all the built-in connections from the circuit. In most cases a designer can get away with utilizing both sides of
                          the PCB for wiring but in the event that you need extra space, PCBs can be designed to have an infinite amount of 
                          internal layers.
                          <br /> 
                        <br /> 
                        <h2>Enclosing your Circuit</h2>
                  
                        Aluminum and plastic are the two most common enclosure materials for housing electronics, the former being used for higher
                         power applications. In the case of aluminum, be sure to ground the box to avoid shocking yourself. It is common practice 
                         to have all circuit boards and components lifted off the surfaces using non-conductors like wood and plastic, but one 
                         should be careful, especially with high power electronics.
                         <br /> 
                        <br /> 
                        If you expect your components to generate a lot of heat, consider adding a grill to a side of your enclosure which leads 
                        to a fan. The convection of fresh air added to a system is often more than enough to ensure components stay below their
                         operating temperature limits. 
                         <br /> 
                        <br /> 
                        It is customary to add frequently used components such as switch, gauges and potentiometers to the front of a box, and
                         rarely used components to the back of the enclosure. If you are designing the plastic box from scratch, it is often a
                          good idea to built standoffs right into your design, especially when using 3D printed parts.

                        </p>
            </div>
    )
}