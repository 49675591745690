import OscillationsImg from '../../Images/OscillationsImg.jpg'

export default function Waves(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Oscillations and Waves</h1>
               <p>
                                
           
                       
                        <h2>Intro to Oscillations and Waves;</h2>
                        
Oscillations are a motion which predictively repeats itself while a wave is an oscillation that also has a direction and is moving somewhere. 
Simple harmonic motion (SHM) occurs when the force directed toward the equilibrium point is proportional to the displacement from the 
equilibrium. This motion has symmetry between the maximum displacement and the periods of each cycle is constant.
<br />
<br /> 
<h2>Natural Frequency;</h2>
Every object has something known as a natural frequency which is the frequency or rate at which the object vibrates naturally. Objects can have 
more than one natural frequency which are traditionally found using harmonic oscillators. Using springs or vibrations, engineers can apply a
 forced frequency to a system that equals their natural frequency. In these cases, resonance is created which can result in a chaotic outburst 
 from the system and even materials self-destructing. 
 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={OscillationsImg} alt="Oscillations" width="55%"/>
                                </div>
                
                        </p>
            </div>
    )
}