import TransistorImg from '../../Images/TransistorImg.jpg'

export default function Transistors(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Transistors</h1>
               <p>
                                
      
                        <h2>Intro to Transistors;</h2>
                        The transistor is the foundation of many electrical and computing devices and is a component made from
                         semiconductor material which is able to both conduct and insulate. This property allows it to act as both 
                         a switch and an amplifier. The transistor is a three terminal device with a base, responsible for activating 
                         the transistor, a collector as the positive lead and an emitter as the negative lead. Generally, transistors
                          work by allowing you to control the flow of current through one channel by varying the intensity of another,
                           much smaller current that is flowing through the second channel.
  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={TransistorImg} alt="Transistor" width="35%"/>
                                </div>
                        </p>
            </div>
    )
}