import MultimetersImg from '../../Images/MultimetersImg.jpg'

export default function Multimeters(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Multimeters</h1>
               <p>
                                
           
                  
                        <h2>Intro to Multimeters;</h2>
                        A multimeter or VOM (volt-ohm-milliammeter) is an instrument widely used for measuring voltage, current and resistance.
                         Multimeters come in two main types, digital and analog. The main difference between the two types is the analog uses 
                         a moving dial while the digital has an led screen which simply displays the output. Both models are generally good 
                         although the digital multimeter may be slightly more accurate and the analog model may be better at reading circuits 
                         which have a lot of electrical noise. A digital multimeter will often be incapable of working when electrical noise 
                         is present while the analog model should work just fine.                  
                         <br />
                        <br /> 
                        <h2>Measuring Voltage;</h2>
                 
                        Measuring voltage like all commands requires the user to turn the knob to the appropriate setting. If you are measuring
                         a dc circuit, select the DC voltage setting with the number closest to what voltage the circuit should be running. If 
                         you are measuring an AC circuit, turn the knob to the AC voltage setting. Note that the multimeter will display the RMS
                          voltage setting which is equal to .707 the voltage of Peak to Peak. 
                          <br />
                        <br /> 
                          <h2>Measuring Current;</h2>
                  
                        Measuring a circuit’s current is one small step more complicated than measuring its voltage. After setting your
                         device to the appropriate setting, break the circuit at the location you want to make a test reading and place
                          the two ends of the device as a new connector between the two break areas. 
                          <br />
                        <br /> 
                          <h2>Measuring Resistance;</h2>
                    
                        Measuring resistances is also quite simple. Simply turn the knob to the ohms setting, remove power from the resistive section 
                        and place the multimeter’s probes across the section.
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={MultimetersImg} alt="Multimeters" width="65%"/>
                                </div>    
                        </p>
            </div>
    )
}