import React from 'react';
import Subtopics from './Subtopics';
import { Container } from "react-bootstrap";
import {
    Switch,
    Route,
    Link,
    useRouteMatch
  } from "react-router-dom";
// import styles from './Components.css'; 


function Design(props) {
    let { path, url } = useRouteMatch();
    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', maxWidth: "100vw"}}>
                {/* <SectionTitle title={props.topicId}/> */}
            <div style={{display: 'flex', flexDirection: 'row'}}> 
            <div style={{marginTop: '10px', marginRight: '0px', marginLeft: '5px',border: '3px', padding:'40px', paddingRight: '50px', borderStyle:'solid', borderColor:'grey', paddingLeft: '20px', whiteSpace:'nowrap'}}>
                <h2>Design</h2>
                <li>
                    <Link to={`${url}/friction`}>Friction</Link>
                </li>
                <li>
                    <Link to={`${url}/efficiency`}>Efficiency</Link>
                </li>
                <li>
                    <Link to={`${url}/thermal`}>Thermal Expansion</Link>
                </li>
                <li>
                    <Link to={`${url}/stress`}>Stress</Link>
                </li>
                <li>
                    <Link to={`${url}/strain`}>Strain</Link>
                </li>
                <li>
                    <Link to={`${url}/impact`}>Impact and Fatigue</Link>
                </li>
                <li>
                    <Link to={`${url}/buckling`}>Buckling</Link>
                </li>
                <li>
                    <Link to={`${url}/creep`}>Creep</Link>
                </li>
                </div>
                
            <Container>
                <Switch>
                    <Route exact path={path}>
                    <h3>Please select a sub-topic.</h3>
                    </Route>
                    <Route path={`${path}/:topicId`}>
                        <Subtopics />
                    </Route>
                </Switch>
            </Container>
         
                    </div>
            </div>
        </div>
    );
};

export default Design; 