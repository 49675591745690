import CurrentImg from '../../Images/CurrentImg.jpg'

export default function Basic(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Basic Components</h1>
               <p>
                                
           
                       
                        
                        <h2>Current;</h2>
Electrical current is the amount of charge passing through an area over a period of time and uses units of coulombs per second. Note that
 an ampere (A) is also often used which is equivalent to coulombs/second. Since a coulomb is a large amount of energy, milliamps (1mA=1*10^3 A),
  microamps (E-6) and nanoamps (E-9) are also commonly used.
<br />
<br /> 
When dealing with conductors such as copper, the electrical current is caused by free electrons moving through a circuit of conductive ions.
 In the case of copper, each copper atom has one “free” electron. The charge on a single electron is equal to negative 1.602x10^-19 Coulombs.
  It might sound counterintuitive upon first hearing it, but when we describe a positive flow of current, what is actually happening is 
  negatively charged electrons are flowing counter to the direction of the supposed positive flow. However, it is much more convenient to 
  deal with current as positively charged particles are moving along a circuit which thankfully is equivalent to negatively charged particles
   flowing counter directionally.
<br />
<br />
The average amount of current passing through an area can be determined by measuring by the change in current over an elapsed period of time.
 To find the instantaneous current, the time interval can be shortened until it converges about zero. 
<br />
<br />
Perspective is important when understanding current so the following is a list of general amounts of current used by everyday devices.
<br />
A 100w light bulb uses 1A
<br />
A television uses 1-3A
<br />
A microwave use 8-13A
<br />
A smartphone browsing the internet uses 200mA
<br />
A toaster uses 7-10A
<br />
A laptop computer use 2-3A
<br />	
An LED light uses 20mA
<br />
A sufficiently high amount of current to cause respiratory arrest 100mA to 1A
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={CurrentImg} alt="current" width="45%"/>
                                </div>  
<h2>Voltage;</h2>
The reason a current decides to flow through a current is because a voltage difference exist between the two points. An electromotive force (EMF)
 is created when a voltage across a conductor is formed which in turn provides a push to all free electrons. Note that the term “potential
  difference” of just “potential” is often used to describe voltage, these are the same thing. 
<br />
<br />
A device which maintains a steady voltage across its terminals is known as direct voltage or a DC circuit. The units of voltage are volts which 
equal one joule/coulomb. You can simply think of a joule as an amount of energy which shows that voltage is a measure of energy difference per an
 amount of energy. 
<br />
<br />
<h2>Power;</h2>
Power is the measure of how much energy per second goes into powering a circuit. In an ideal case, the amount of mechanical energy output by a
 motor per say would be equal to the amount of electrical energy input into the motor. In the real world, some inefficiencies would exist and
  some energy would be lost to heat. 
<br />
<br />
Mathematically power can be calculated by being equal to the voltage times the current; P=IV. This is referred to as the generalized power law.
            
                
                        </p>
            </div>
    )
}