import ResinPrintingImg from '../../Images/ResinPrintingImg.jpg'

export default function Resin(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Resin Printing</h1>
               <p>
                                
           
                       <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                       <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ResinPrintingImg} alt="Resin Printing" width="100%"/>
                                </div>
                                <div style={{width:'70%'}}>
                                <h2>Intro to Direct Light Processing;</h2>
Light curing is a process which can create incredibly precise parts for low form-factor objects. The parts themselves must all be created using photopolymers which can come in a variety of material properties. 
<br />
                        <br /> 
<h2>Intro to Stereolithography (SLA);</h2>
SLA printers can rapidly and affordably produce precise parts by utilizing a laser to selectively cure a layer of resin which is then extracted and reset for the next layer. The layers are gradually pulled upwards out of the resin as they are built and fused in a series like all 3D printing technologies.
<br />
                        <br /> 
<h2>Direct Light Processing (DLP);</h2>
DLP printers work in the same way as stereolithography machines with the exception that they replace the high powered laser with a projector capable of curing an entire layer at once. These are also used to fabricate photopolymer parts.

                                </div>
                       </div>
                   
                        </p>
            </div>
    )
}