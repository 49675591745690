import TableSawImg from '../../Images/TableSawImg.jpg'
import T43 from '../../Images/T43.JPG'

export default function TableSaw(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Table Saw</h1>
               <p>
                                
           

           <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={TableSawImg} alt="Table Saw" width="45%"/>
                                </div>
                                <span style={{ fontWeight: 'bold' }}>Description:</span> A table saw is a wood cutting machine used to cut wood pieces to a certain width.
                                <br />
                        <br /> 
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={T43} alt="Table Saw" width="100%"/>
                                </div>
                                <br />       
                        <span style={{ fontWeight: 'bold' }}>Blade Diameter:</span> The diameter of the rotating blade within the table.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Rip Capacity:</span> Rip capacity is the maximum distance between the table saw and the adjustable shoulder when the adjustable shoulder is extended to its furthest position.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Speed (Feed Rate):</span> The rotating speed at which the internal cutting blades will spin.
                        </p>
            </div>
    )
}