import WorkImg from '../../Images/WorkImg.jpg'

export default function Work(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Work and Energy</h1>
               <p>

              <h2>The Work-Kinetic Energy Theorem;</h2>
                        Since all force and matter is energy, one of the best ways to measure energy is to compare how much work could the 
                        energy accomplish. The standard scientific unit of measuring energy is the joule which is equal to the amount of work
                         done by a 1 Newton force on an object that is moved 1 meter. 
                         <br />
                        <br /> 
The work-kinetic energy theorem states that “the work done by the total acting force on an object between two points is equal to the change in
 its kinetic energy”. In other words, the integral of the force between the two points along the path has an equivalent value to the kinetic
  energy change in the system.
  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={WorkImg} alt="Work Equation" width="75%"/>
                                </div>
                
                        </p>
            </div>
    )
}