import React from "react";
import {
//   BrowserRouter as Router,
  useParams,
} from "react-router-dom";
import AirCompressor from './AirCompressor';
import BandSaw from './BandSaw';
import CNC from './CNC';
import DrillPress from "./DrillPress";
import DustCollector from "./DustCollector";
import ExtrusionPrinter from "./ExtrusionPrinter";
import Grinder from "./Grinder";
import LaserCutter from "./LaserCutter";
import LaserSinterer from "./LaserSinterer";
import Lathe from "./Lathe";
import HandTools from "./HandTools";
import Mill from "./Mill";
import MiterSaw from "./MiterSaw";

import PlasmaCutter from "./PlasmaCutter";
import PlateBender from "./PlateBender";
import ResinPrinter from "./ResinPrinter";
import ShopPress from "./ShopPress";
import WaterjetCutter from "./WaterjetCutter";
import WoodworkingTools from "./WoodworkingTools";

const ChangeComponents = {
    aircompressor: AirCompressor,
    bandsaw: BandSaw,
    cnc: CNC,
    drillpress: DrillPress,
    dustcollector: DustCollector,
    extrusionprinter: ExtrusionPrinter,
    grinder: Grinder,
    lasercutter: LaserCutter,
    lasersinterer: LaserSinterer,
    lathe: Lathe,
    handtools: HandTools,
    mill: Mill,
    mitersaw: MiterSaw,
  
    plasmacutter: PlasmaCutter,
    platebender: PlateBender,
    resinprinter: ResinPrinter,
    shoppress: ShopPress,
    waterjetcutter: WaterjetCutter,
    woodworkingtools: WoodworkingTools,
  };

export default function Subtopics(props) {
    // The <Route> that rendered this component has a
    // path of `/topics/:topicId`. The `:topicId` portion
    // of the URL indicates a placeholder that we can
    // get from `useParams()`.
    let { topicId } = useParams();
    
    let MyComponent = ChangeComponents[topicId];
    return (
        <div>
            {/* {topicId} is for testing route names */}
            {/* <h3>{topicId}</h3> */}
            <MyComponent topicId={topicId}/>
      </div>
    );
  }