import IntegralsImg from '../../Images/IntegralsImg.jpg'
import IntegralsTableImg from '../../Images/IntegralsTableImg.PNG'

export default function Integrals(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Integrals</h1>
               <p>
                                
           
                        <h2>Intro to Integral Calculus;</h2>
The integral of a function generates the area under a line or curve to the horizontal axis. If an integral has a set a and b limit values, only the area between those limits are to be evaluated. Otherwise, the integral function will calculate the total area under a curve.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={IntegralsImg} alt="Integrals" width="35%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={IntegralsTableImg} alt="Integrals able" width="65%"/>
                                </div>
                        </p>
            </div>
    )
}