import GearsImg from '../../MEComponents/GearsImg.jpg'


export default function Gears(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Gears and Gearboxes</h1>
               <p>
                           
   <h2>Intro to Gears;</h2>
                            One of the most fundamental components of mechanical engineering is none other than the gear. Gears are extraordinary for transferring power from one place to another without losing too much of your energy to excess friction.
                            <br />
                            <br /> 
                            <span style={{ fontWeight: 'bold' }}>Spur gears</span> are some of the most common gears you will find on the market and can be made from a wide variety of materials. Even 3d printed plastic spur gears work well for lower force applications. 
                            <br />
                            <br /> 
                            As with all gears, misalignment is a very common point of failure. The force exchange works by the teeth of the gears pushing and sliding against each other and by having the teeth too close or too far from each other, you run the risk of grinding, unnecessary friction loss and even breaking.
                            <br />
                            <br /> 
                            A <span style={{ fontWeight: 'bold' }}>gearbox</span> is a power transmission component used primarily to provide speed and torque conversions between rotating power sources. Internally, gearboxes often use combinations of gears to achieve their speed-torque conversion. <span style={{ fontWeight: 'bold' }}>Planetary</span> gearboxes are often more expensive due to their complexity but can have enormous conversion factors such as 50:1 or even 100:1. This means they divide the speed of rotation by 50 but multiply the torsional power by 50! An additional benefit of planetary gears is their extremely high efficiency which in turn leads to minimal wasted energy.
                            <br />
                            <br /> 
                            <span style={{ fontWeight: 'bold' }}>Gear Ratchets</span> are a useful way of preventing gear rotation in a certain direction. When possible, you can design your assembly so that gravity pushes down on the ratchet while dynamic systems will require a spring.
                            <br />
                            <br />
                            <span style={{ fontWeight: 'bold' }}>Rack and pinion</span> gears have the unique ability to convert rotational movement into linear movement while <span style={{ fontWeight: 'bold' }}>bevel gears</span> can change the angle of rotation of an assembly.
                            <br />
                            <br />
                            <span style={{ fontWeight: 'bold' }}>Worm gears</span> have a very special property where the worm(spiral) gear can rotate the cylindrical gear but the cylinder gear can’t turn the worm gear. This is excellent when you don't want reactive forces stressing against your motor but you want your motor to be able to turn an assembly.
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={GearsImg} alt="Types of Common Gears" width="55%"/>
                            </div>
                            </p>
            </div>
    )
}