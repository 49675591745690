import T30 from '../../Images/T30.JPG'

export default function Overview(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Overview of Metalshop Machines</h1>
               <p>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={T30} alt="overview" width="100%"/>
                                </div>
                                <br />
                   
                                <span style={{ fontWeight: 'bold' }}>CNC Machine:</span> A computer numerical control machine is an automated control device which converts computer code to machine instructions. Modern CNC machines can come equipped with tool change wheels to allow a wide range of functionally.
                        <br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Mill:</span> Mills are industrial machines which use rotary cutters to remove material. 
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Plate Bender:</span> A box and pan plate bender is a machine unit used to bend sheet steel to various angles.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Plasma Cutter:</span> Plasma cutters work by accelerating a jet of plasma through electrically conductive materials. 
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Waterjet Cutter:</span> Waterjet cutters work by rapidly discharging water infused with abrasives onto a material surface.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Lathe:</span> A lathe operates by rapidly rotating a piece of material and performing reductive manufacturing operations to that piece, usually by the use of a cutting element.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Welding Unit:</span> Welding is the process of joining two or more materials using heat and pressure. There are a variety of welding varieties such as TIG, MIG, Stick which can all be used with their own advantages and disadvantages. Engineers should determine what kind of process and application they need before choosing a variety.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Spot Welder:</span> Spot welding is a welding variation where two metal pieces are layered on top of each other and a high energy charge flows between two metal tongs and fuses the material at that specific spot.

                        </p>
            </div>
    )
}