import FlextureDesignsImg from '../../Images/FlextureDesignsImg.png';


export default function Compliance(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Flexure and Compliant Mechanisms</h1>
            <p>
               <h2>Overview;</h2>
               Industrial robots often benefit from having flexible mechanisms in both the end effector and the joints. Regarding the end effector, it is much better to have materials or mechanisms which deform to better grip the object it desires to manipulate than having a rigid material attempt to squeeze it hard enough to resist slippage due to gravity. On a system level, having compliant joints allows for your robot arm to better react if an external force presses upon a limb of your machine. This is especially useful with walking robots that need to walk on uneven terrain. Having the ability for an entire joint to compress a few inches allows for a much more fluid gait than a rigid robot having to balance entirely on fewer points of contact if the ground is not perfectly level.<br />
                        <br />
                 
                         <h2>Active vs Passive Compliance;</h2>
The term active compliance refers to features which must be set by the operator prior to the use of the robot. This can consist of pressure sensors, torque sensors, current sensors or vision electronics. Passive compliance are features that have been built into the robot such as spring mechanisms in the limbs which compress and tense as the actuators attempt to move the limbs. 
<br />
<br />
<h2>Compliant Mechanical Systems;</h2>
                        <span style={{ fontWeight: 'bold' }}>1. Flexible Materials:</span> Possibly the easiest to attain some degree of compliance is by using materials which have enough rigidity that they can withstand compression forces while also being flexible enough to deform around an object. Rubber, certain polymers or elastomers are great examples of these.
                        <br />
<span style={{ fontWeight: 'bold' }}>2. Spring Mechanisms:</span> Springs are another great way of achieving compliance in at least one direction of motion. Leaf springs, coil springs or compliant linkages are excellent in this regard.
<br />
<span style={{ fontWeight: 'bold' }}>3. Hinges and Joints:</span> Using hinges or joints which limit motion in certain directions is very useful in reducing the amount of compliant dimensions each joint is responsible for.
<br />
<span style={{ fontWeight: 'bold' }}>4. Flexures:</span> A flexure is a flexible element which has been engineered to plastically deform in specific degrees of freedom.
<br />
<span style={{ fontWeight: 'bold' }}>5. Cam & Follower:</span> A cam system is very useful in generating controlled movement. The compliance can be engineered into the cam’s rotary profile.

<br />
<span style={{ fontWeight: 'bold' }}>6. Slip Systems:</span> A slip system can be used to absorb shock or distribute loads in an assembly.

<br />
<span style={{ fontWeight: 'bold' }}>7. Compliant Linkages:</span> Linkages which can bend or deform to accommodate various forces are also commonly used in heavy machinery.

<br />
<span style={{ fontWeight: 'bold' }}>8. Dampers and Shock Absorbers:</span> Shock absorbers can be used for high weight applications such as automobiles.
<br />
<br />
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>                    
                            <img src={FlextureDesignsImg} alt="Flexture Designs" width="60%"/>
                        </div>   
           
                    


            </p>
            </div>
    )
}