import ScriptsImg from '../../Images/ScriptsImg.jpg'

export default function Scripts(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Writing G-code Scripts</h1>
               <p>
                                
        
                        Many of the following scripts require input parameters along with the commands. For example, M03/M04 need a parameter S to set the spindle turning speed. The line M03 S2000 will turn a spindle at 2000rpm in a clockwise direction.
                        <br />
                        <br /> 
Another important characteristic with G-code is the codes are modal, meaning they remain in effect until canceled or replaced by other code. For example, a series of movements only requires you to set the spindle speed on the first command, the spindle will continue turning in that direction as it moves between locations.

                        </p>
                <h2>Script Codes</h2>
                <p>
                M00: Program Stop
                <br /> 
M02: End of Program
<br /> 
M03: Spindle ON clockwise
<br /> 
M04: Spindle ON counterclockwise
<br /> 
M05: Spindle Stop
<br /> 
M06: Tool Change
<br /> 
M08: Flood coolant On
<br /> 
M09: Flood coolant Off
<br /> 
M30: End of Program
<br /> 
    
                    </p> 
                    <h2>For 3D Printers</h2>
                    <p>
                    M104: Start Extruder Heating
                    <br />
M109: Wait Until Extruder Reaches T0
<br />
M14: Start Bed Heating
<br />
M190: Wait Until Bed Reaches T0
<br />
M106: Set Fan Speed
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ScriptsImg} alt="ScriptsImg" width="75%"/>
                                </div>             
                        </p>       
            </div>
    )
}