import React from "react";
import {
//   BrowserRouter as Router,
  useParams,
} from "react-router-dom";

import Algebra from '../../lessons/Math/Algebra';
import Geometry from '../../lessons/Math/Geometry';
import Calculus from '../../lessons/Math/Calculus';
import Statistics from '../../lessons/Math/Statistics';
import ODEs from '../../lessons/Math/ODEs';

import ForcesMotion from '../../lessons/Physics/ForcesMotion';
import NaturalSystems from '../../lessons/Physics/NaturalSystems';
import Vibrations from '../../lessons/Physics/Vibrations';
import Thermodynamics from '../../lessons/Physics/Thermodynamics';
import FluidDynamics from '../../lessons/Physics/FluidDynamics';
import DataTables from "../../lessons/Physics/DataTables";

import Components from "../../lessons/MechanicalEngineering/Components";
import Design from '../../lessons/MechanicalEngineering/Design';
import Statics from '../../lessons/MechanicalEngineering/Statics';
import Assemblies from '../../lessons/MechanicalEngineering/Assemblies';
import Systems from '../../lessons/MechanicalEngineering/Systems';
import SystemDynamics from "../../lessons/MechanicalEngineering/SystemDynamics";
import Drawings from "../../lessons/MechanicalEngineering/Drawings";

import BasicComponents from '../../lessons/ElectricalEngineering/BasicComponents';
import Electricity from '../../lessons/ElectricalEngineering/Electricity';
import Discrete from '../../lessons/ElectricalEngineering/Discrete';
import Filters from '../../lessons/ElectricalEngineering/Filters';
import Sensors from '../../lessons/ElectricalEngineering/Sensors';
import Handson from '../../lessons/ElectricalEngineering/Handson';

import Additive from '../../lessons/IndustrialEngineering/Additive';
import MaterialScience from '../../lessons/IndustrialEngineering/MaterialScience';
import Postprocessing from '../../lessons/IndustrialEngineering/Postprocessing';
import Materials from '../../lessons/IndustrialEngineering/Materials';
import Reductive from '../../lessons/IndustrialEngineering/Reductive';
import MachineAssembly from "../../lessons/IndustrialEngineering/MachineAssembly";
import ToolsandMachines from '../../lessons/IndustrialEngineering/ToolsandMachines';

import Basics from '../../lessons/ComputerScience/Basics';
import Hardware from '../../lessons/ComputerScience/Hardware';
import Programming from '../../lessons/ComputerScience/Programming';
import Algorithms from '../../lessons/ComputerScience/Algorithms';
import Gcode from '../../lessons/ComputerScience/Gcode';
import JavascriptReact from "../../lessons/ComputerScience/React";
import Arduino from "../../lessons/ComputerScience/Arduino";

const ChangeComponents = {
    
    algebra: Algebra,
    geometry: Geometry,
    calculus: Calculus,
    statistics: Statistics,
    odes: ODEs,

    forcesmotion: ForcesMotion,
    naturalsystems: NaturalSystems,
    vibrations: Vibrations,
    thermodynamics: Thermodynamics,
    fluiddynamics: FluidDynamics,
    datatables: DataTables,

    components: Components,
    design: Design,
    statics: Statics,
    assemblies: Assemblies,
    systems: Systems,
    systemdynamics: SystemDynamics,
    drawings: Drawings,

    electricity: Electricity,
    basiccomponents: BasicComponents,
    discrete: Discrete,
    filters: Filters,
    sensors: Sensors,
    handson: Handson,

    additive: Additive,
    materialscience: MaterialScience,
    postprocessing: Postprocessing,
    materials: Materials,
    reductive: Reductive,
    machineassembly: MachineAssembly,
    toolsandmachines: ToolsandMachines,

    programming: Programming,
    basics: Basics,
    hardware: Hardware,
    algorithms: Algorithms,
    gcode: Gcode,
    javascriptreact: JavascriptReact,
    arduino: Arduino,
  };

export default function Topic(props) {
    // The <Route> that rendered this component has a
    // path of `/topics/:topicId`. The `:topicId` portion
    // of the URL indicates a placeholder that we can
    // get from `useParams()`.
    let { topicId } = useParams();
    
    let MyComponent = ChangeComponents[topicId];
    return (
        <div>
            {/* {topicId} is for testing route names */}
            {/* <h3>{topicId}</h3> */}
            <MyComponent topicId={topicId}/>
      </div>
    );
  }