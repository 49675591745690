import BandSawImg from '../../Images/BandSawImg.jpg'

export default function Bandsaw(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Band Saw</h1>
               <p>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={BandSawImg} alt="Band Saw" width="45%"/>
                                </div>
           
                        
                                <span style={{ fontWeight: 'bold' }}>Description:</span> The band saw is a continuous band of sharp metal which is stretched between two or more wheels that rotate to allow cutting of a material. Because the cutting teeth on the band are small, this machine is often used for cutting metal and other hard materials.
                        <br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Motor Horsepower (HP):</span> The unit of measurement for how much mechanical work can be done by the motor within this assembly.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Cutting Depth Capacity:</span> The maximum distance which the blade can be brought down which determines the maximum cut depth of the machine.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Blade Speed:</span> The speed at which the blade will reciprocate or rotate depending on the style of the machine.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Blade Length:</span> The total length of the band which is rapidly reciprocated or circulated to cut material. This is important to note for when replacing or upgrading the blade will inevitably occur due to wear.
                        </p>
            </div>
    )
}