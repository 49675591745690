import AnodizingImg from '../../Images/AnodizingImg.jpg'

export default function Corrosion(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Corrosion Resistance</h1>
               <p>
                                
           
 
                        <h2>Intro to Anodising</h2>
One of the most popular methods of improving corrosion resistance on aluminum is anodisation which first involves submerging the material in a low pH acid bath and connecting the material to an anode of an electrical circuit. The electric current creates an oxidation reaction which causes the natural oxide on the metal surface to thicken which in turn creates a protective outer layer. The thickness can be further thickened by increasing the coating time. For materials undergoing consistent liquid contact or those set for outdoor conditions, a 20um coating thickness is recommended.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={AnodizingImg} alt="Anodizing" width="55%"/>
                                </div>
<h2>Other Corrosion Resistant Processes;</h2>
Plasma Electrolytic Oxidation (PEO)
<br />
Chromate Conversion Coating
<br />
Paints
<br />
Powder Coats
<br />
Hot Blackening

                        </p>
            </div>
    )
}