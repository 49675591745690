import ComputerPowerSupplyImg from '../../Images/ComputerPowerSupplyImg.jpg'

export default function Power(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Power Supply</h1>
               <p>
                               
           
                    
                        <h2>Intro to Power Supplies;</h2>
The power supply module in a computing system is responsible for converting power from the wall outlet into the type of power needed by the 
computer. This power is then sent throughout the computer and its accessories via wires and cables. Some power supplies have a manual switch
 for selecting input voltage though most can do this function automatically. 
 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={ComputerPowerSupplyImg} alt="ComputerPowerSupply" width="35%"/>
                                </div>
                        </p>
            </div>
    )
}