import CapacitorImg from '../../Images/CapacitorImg.jpg'

export default function Capacitors(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Capacitors</h1>
               <p>
                                
           
                     
                        <h2>Intro to Capacitors;</h2>
Capacitors perform a variety of functions in electronics. The most common function is energy storage with the charge held until the circuit
 requires it. The second most common function is being placed in series with a signal path and blocking DC while allowing AC to flow. Other
  functions include smoothing out power supplies, control timing in ICs and shopping waveforms. The rate of charging and discharging can 
  also be controlled by placing a resistor in series with the capacitor. 
  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={CapacitorImg} alt="capacitors" width="75%"/>
                                </div>
                        </p>
            </div>
    )
}