import T40 from '../../Images/T40.JPG'

export default function Overview(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Overview of Woodshop Machines</h1>
               <p>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={T40} alt="overview" width="100%"/>
                                </div>
                                <br />
                 
                                <span style={{ fontWeight: 'bold' }}>Planar:</span> A wood planar is a mechanical machine which serves to smooth rough wood stock surfaces, make opposite material sides parallel and set thickness to a set dimension.
                        <br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Router:</span> A tabletop router is used to smooth or shape the edge of wood pieces and often come with a variety of routing bits for different edge styles.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Table Saw:</span> A table saw is a wood cutting machine used to cut wood pieces to a certain width.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Circular Saw:</span> A circular saw is a handheld wood cutting machine which can often be arranged at a certain bevel angle for diagonal cuts.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Jointer:</span> A jointer is a woodworking machine used to produce a flat surface onto a board’s side.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Electric Sander:</span> An electric sander is a handheld power tool used to smooth the surface of wood using fast moving abrasive disks.               
                
                        </p>
            </div>
    )
}