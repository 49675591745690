import StressesImg from '../../Images/StressesImg.jpg';
import ShearStressImg from '../../Images/ShearStressImg.png';
import TensileStressImg from '../../Images/TensileStressImg.png';


export default function Stress(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
                     {/* <SectionTitle title={props.topicId}/> */}
                     <h1>Stress</h1>
                     <p>
                            
                          
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>                    
                            <img src={StressesImg} alt="Stresses" width="100%"/>
                        </div>   
                        <div style={{width:'70%'}}>
                        <h2>Intro to Static Body Stresses;</h2>
                            When designing anything, you will need to know what is going inside of the object. Engineering <span style={{ fontWeight: 'bold' }}>stress</span> is a physical quantity that identifies the internal force which a particle has on neighboring particles within an object. There are seven major types of stress, five of which are known as static loading stresses, meaning they are the resultant of a slow or constant force. The other two, impact stress and fatigue stress have their own sections.
                            <br />
                            <br /> 
                            <span style={{ fontWeight: 'bold' }}>Compression</span> stress squeezes an object evenly in both directions while <span style={{ fontWeight: 'bold' }}>tension</span> is the process of being pulled apart in opposite directions. The instance of <span style={{ fontWeight: 'bold' }}>shear stress</span> is how scissors or gardening shears cut materials.
                             It compresses the material resulting in separation as atoms slip past each other. <span style={{ fontWeight: 'bold' }}>Bending stress</span> wants to form a U-shape with the material while <span style={{ fontWeight: 'bold' }}>torsion</span> twists a material in opposite directions. 
                           
                     </div>
                        </div>
                        <br />    
                        <br />
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>                    
                            <img src={TensileStressImg} alt="Tensile Stress" width="100%"/>
                        </div>   
                        <div style={{width:'55%'}}>
                        <h2>Tensile & Compressive Stress - Normal Stress;</h2>
                        Tensile and compressive forces are often called “normal stress” because a force acts perpendicular(normal) to the object plane. The same equation is used for when the force is pushing or pulling on the object. 
                     </div>
                        </div>
                        <br />    
                        <br />
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>                    
                            <img src={ShearStressImg} alt="Tensile Stress" width="100%"/>
                        </div>   
                        <div style={{width:'55%'}}>
                        <h2>Shear Stress;
</h2>
Shear is generated as opposing forces act on a parallel plane. The atoms or molecules of the materials slide past each other until fully separating. 
 </div>
                        </div>
                            </p>
            </div>
    )
}