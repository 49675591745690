import RelayImg from '../../Images/RelayImg.jpg'

export default function Relays(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Relays</h1>
               <p>
                                
           
                     
                        <h2>Intro to Relays;</h2>
Relays are electrically actuated switches and come in three basic types; mechanical relays, reed relays and solid-state relays. With mechanical
 relays, a current is used to generate a small magnetic field which pulls a flexible lever plate into contact with another. Read relays have a
  pair of reeds which spring together when a current is added, forming a circuit connection. Solid state relays work by applying external
   voltages across its n and p-type semiconductor junctions. 
<br />
<br /> 
Typically, mechanical relays are used for mid current (2-15 Amps) and relatively slow switching (10 to 100ms). Reed relays are designed for
 lower currents (.5 to 1 AMps) and fast switching speeds (0.2 to 2ms). Solid state relays come in a variety of ways with their own current 
 settings and have extremely fast switching speeds (up to 1 to 100ns).
                 
 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={RelayImg} alt="relay" width="40%"/>
                                </div>
                        </p>
            </div>
    )
}