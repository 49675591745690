import Inertia from '../../Images/InertiaImg.jpg'
import InertiaEx from '../../Images/InertiaExImg.jpg'
export default function Newton(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Newton's Laws</h1>
               <p>
           
           <h2>Law of Conservation of Inertia;</h2>
  
                        Largely known as Newton’s First Law or the Law of Conservation of Energy, this observation of nature states that an
                         object in motion will stay in motion unless acted upon by an outside force. The same can be said for an object that is
                          not in motion; it will remain motionless unless acted upon by an external force. 
                          <br />
                        <br /> 
Every object, at any time, falls into one of two categories. Objects are either accelerating or not accelerating which is known as equilibrium. 
An object that is moving at a constant speed is also considered in equilibrium. Both of these conditions have their own equations. One of the
 most fundamental equations in physics and engineering is the equation; force is equal to mass multiplied by acceleration. 
 <br />
                        <br /> 
The non-standard form which helps you conceptualize this equation is that an object will accelerate equal to the force applied divided by the
 mass of the object. Heavier objects have more mass and thus need a greater force to get them moving than lighter objects.
 <br />
                        <br /> 
For a non-accelerating object, the sum of all the forces acting on the object have to equal zero, otherwise the object would start to accelerate.
 The same can be said of the sum of the moment forces.
 <br />
                        <br /> 
These equations apply to each of the spatial dimensions which we often refer to as the (X,Y,Z) axis. Identifying if the objects in a system are
 accelerating is the first step to determining how you should approach identifying what forces are acting on your system.
   
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={Inertia} alt="inertia equation" width="55%"/>
                                </div>
          
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={InertiaEx} alt="example of inertia" width="75%"/>
                                </div>
                                <br />
                        <br /> 
                        <h2>Law of Dynamics</h2>
                        The second of Newton’s Laws observed the proportional relationship between acceleration and an object's mass within an 
                        inertial reference frame. It states that the momentum of an object or particle is equal to the object’s mass multiplied
                         by its velocity. 
                         <br />
                        <br /> 
Note that an internal reference frame is a spatial (physical) coordinate system with an additional independent time axis.
<br />
                        <br /> 
                        <h2>Law of Reaction;</h2>
                        The third and final of Newton’s Laws shows that if an object exerts force upon another object along some line connecting 
                        the two objects than at the point of contact, the two objects will experience an equal and opposite force. You can also 
                        think of this phenomenon within the context of any closed system. The sum of all internal forces within that system will 
                        equal to zero.
                        <br />
                        <br /> 
Another useful extraction from this law is that no force can exist in isolation. For an object or particle to “feel” the force acting upon it 
requires the presence of another object. This leads to the statement that no object can exert a force upon itself.

                        </p>
            </div>
    )
}