import React from "react";
import {
//   BrowserRouter as Router,
  useParams,
} from "react-router-dom";

import ElementaryParts from './ElementaryParts';
import SingleDOF from './SingleDOF';
import FreeVibrations from "./FreeVibrations";
import ForcedVibrations from "./ForcedVibrations";
import SystemKinematics from "./SystemKinematics";

const ChangeComponents = {
    
    elementaryparts: ElementaryParts,
    singledof: SingleDOF,
    freevibrations: FreeVibrations,
    forcedvibrations: ForcedVibrations,
    systemkinematics: SystemKinematics,
  };

export default function Subtopics(props) {
    // The <Route> that rendered this component has a
    // path of `/topics/:topicId`. The `:topicId` portion
    // of the URL indicates a placeholder that we can
    // get from `useParams()`.
    let { topicId } = useParams();
    
    let MyComponent = ChangeComponents[topicId];
    return (
        <div>
            {/* {topicId} is for testing route names */}
            {/* <h3>{topicId}</h3> */}
            <MyComponent topicId={topicId}/>
      </div>
    );
  }