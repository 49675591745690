import React from "react";
import {
//   BrowserRouter as Router,
  useParams,
} from "react-router-dom";
import Springs from './Springs';
import Gears from './Gears';
import Bearings from './Bearings';


import Shafts from "./Shafts";
import Stock from "./Stock";
import Tubing from "./Tubing";
import Pumps from "./Pumps";
import Valves from "./Valves";
import Cylinders from "./Cylinders";

const ChangeComponents = {
    springs: Springs,
    gears: Gears,
    bearings: Bearings,
 
  
    shafts: Shafts,
    stock: Stock,
    tubing: Tubing,
    pumps: Pumps,
    valves: Valves,
    cylinders: Cylinders,
  };

export default function Subtopics(props) {
    // The <Route> that rendered this component has a
    // path of `/topics/:topicId`. The `:topicId` portion
    // of the URL indicates a placeholder that we can
    // get from `useParams()`.
    let { topicId } = useParams();
    
    let MyComponent = ChangeComponents[topicId];
    return (
        <div>
            {/* {topicId} is for testing route names */}
            {/* <h3>{topicId}</h3> */}
            <MyComponent topicId={topicId}/>
      </div>
    );
  }