import BuoyancyImg from '../../Images/BuoyancyImg.jpg'

export default function Buoyancy(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Buoyancy</h1>
               <p>
                               
           
          <h2>Archimedes Principle;</h2>     
                        A submerged or floating body has a buoyancy force facing upward equal to the weight of the fluid displaced by the body.
                         Since a floating body is in equilibrium, the floating body displaces a weight of fluid equal to its own weight. 
                         <br />
                        <br /> 
The center of buoyancy is located at the 3 dimensional center (centroid) of the displaced fluid. In the special case of a body existing at the 
interface of two fluids, the buoyant force is equal to the sum of the weights of the displaced fluids.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={BuoyancyImg} alt="BuoyancyImg" width="65%"/>
                                </div>
                
                        </p>
            </div>
    )
}