import React from "react";
import {
//   BrowserRouter as Router,
  useParams,
} from "react-router-dom";
import PHSensors from './PHSensors';
import ConductivitySensors from './ConductivitySensors';
import UVSensors from './UVSensors';
import Spectrometers from "./Spectrometers";
import TemperatureSensors from "./Temperature";
import PressureSensors from "./Pressure";
import UltrasonicSensors from "./Ultrasonic";
import Gyroscopes from "./Gyroscopes";
import CurrentSensors from "./CurrentSensors";
import GasSensors from "./GasSensors";
import ForceSensors from "./ForceSensors";
import Lidar from "./Lidar";
import Magnetometer from "./Magnetometer";
import IRSensor from "./IRSensors";
import HumiditySensors from "./Humidity";
import Photoresistor from "./Photoresistor";

const ChangeComponents = {
    phsensors: PHSensors,
    conductivitysensors: ConductivitySensors,
    uvsensors: UVSensors,
    spectrometers: Spectrometers,
    temperaturesensors: TemperatureSensors,
    pressuresensors: PressureSensors,
    ultrasonicsensors: UltrasonicSensors,
    gyroscopes: Gyroscopes,
    currentsensors: CurrentSensors,
    gassensors: GasSensors,
    forcesensors: ForceSensors,
    lidar: Lidar,
    magnetometer: Magnetometer,
    irsensor: IRSensor,
    humiditysensors: HumiditySensors,
    photoresistor: Photoresistor,
  };

export default function Subtopics(props) {
    // The <Route> that rendered this component has a
    // path of `/topics/:topicId`. The `:topicId` portion
    // of the URL indicates a placeholder that we can
    // get from `useParams()`.
    let { topicId } = useParams();
    
    let MyComponent = ChangeComponents[topicId];
    return (
        <div>
            {/* {topicId} is for testing route names */}
            {/* <h3>{topicId}</h3> */}
            <MyComponent topicId={topicId}/>
      </div>
    );
  }