import React from "react";
import {
//   BrowserRouter as Router,
  useParams,
} from "react-router-dom";

import Welding from "./Welding";
import Brazing from "./Brazing";
import Adhesives from "./Adhesives";
import Fasteners from "./Fasteners";
import Rivets from "./Rivets";
import ShrinkFitting from "./ShrinkFitting";
import WeldingGases from "./WeldingGases";


const ChangeComponents = {

    welding: Welding,
    brazing: Brazing,
    adhesives: Adhesives,
    fasteners: Fasteners,
    rivets: Rivets,
    shrinkfitting: ShrinkFitting,
    weldinggases: WeldingGases,
  
  };

export default function Subtopics(props) {
    // The <Route> that rendered this component has a
    // path of `/topics/:topicId`. The `:topicId` portion
    // of the URL indicates a placeholder that we can
    // get from `useParams()`.
    let { topicId } = useParams();
    
    let MyComponent = ChangeComponents[topicId];
    return (
        <div>
            {/* {topicId} is for testing route names */}
            {/* <h3>{topicId}</h3> */}
            <MyComponent topicId={topicId}/>
      </div>
    );
  }