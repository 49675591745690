import NotchpassFilterImg from '../../Images/NotchpassFilterImg.jpg'

export default function Notchpass(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Notchpass Filters</h1>
               <p>
               <h2>Intro to Notch Filters;</h2>
The notch filter (often called the band-reject filter) passes all frequencies except those centered around the filter’s natural frequency. 
                   
                         <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={NotchpassFilterImg} alt="Notchpass Filter" width="55%"/>
                                </div>
                        </p>
            </div>
    )
}