import BinarySearchImg from '../../Images/BinarySearchImg.jpg'

export default function BinarySearch(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Binary Search</h1>
               <p>
                               
           
                     
                        <h2>Intro to Binary Search;</h2>
The binary search algorithm is used to locate an item from a sorted list of items. Its efficiency comes from dividing the list of items in half and determining which half the item is in. By repeating this process, it is able to locate an item much faster than individually searching each item in the list until it finds what it is looking for. 
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={BinarySearchImg} alt="Binary Search" width="45%"/>
                                </div>
                
                        </p>
            </div>
    )
}