import React, { useState } from 'react';
import styles from './Header.module.css';
import { Button } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';
import { useHistory, Link } from 'react-router-dom';
// import user from '../../images/user3.png';
import globe from '../../images/globe2.png';
import learn from '../../images/learn2.png';
// import dashboard from '../../images/dashboard2.png';
// import info from '../../images/info2.png';
// import logo from '../../images/LogoZ.png';
// import logo4 from '../../images/MECHA2.png';

function Header(props) {
    const [error, setError] = useState("");
    const { logout } = useAuth();
    const history = useHistory();
    const {currentUser} = useAuth()

   async function handleLogout() {
        setError('')

        try {
            await logout()
            history.push('/')
        } catch {
            setError("Failed to logout")
            console.log(error)
        }
    }

    return (
        <header className={styles.Header}>
            {/* <div className={styles.Title}><img src={logo4} alt="logo" width="500px" height="75px"/></div> */}
            
            {/* <h1>MECHANIZE</h1> */}
            
            <nav>
                <ul>
                {
                        currentUser ?
                            <>
                                
                            
                            <li>
                                    
                                    <Link to="/"><img src={globe} alt="info" width="20%" /> Home</Link>
                            </li>                           
                            <li>
                            <Link to="/library"><img src={learn} alt="learn" width="20%" /> Library</Link>
                            </li>                                             
                          
                               
                            {/* <li>                               
                                <div><Link to="/tools"><img src={dashboard} alt="globe" width="32%" /> Tools</Link></div>
                                </li>
                                <li>
                            <Link to="/technology"><img src={info} alt="dashboard" width="15%" /> Technology</Link>
                                </li>  
                            <li>                                
                                <Link to="/profile"><img src={user} alt="user" width="20%" /> Profile</Link>
                                </li> */}
                            <Button variant="link" onClick={handleLogout}>Log out</Button>                              
                            </>
                        :
                        <>
                            <li>
                            
                                <Link to="/login">Login</Link> 
                            </li>
                            <li>:</li>
                            <li>
                                <Link to="/signup">Signup</Link>
                            </li>
   
                        </>
                }
                </ul>
            </nav>
           
        </header>
    );
};

export default Header; 