import CurrentSensorsImg from '../../Images/CurrentSensorsImg.jpg'

export default function CurrentSensors(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Current Sensors</h1>
               <p>

<h2>Intro to Current Sensors;</h2>
Current sensors work by measuring the flow of current passing through a wire and generating a signal proportional to the flow rate. Unlike voltage
 which can be measured between any points without intrusion, current sensing is a more difficult approach as the insertion of a detector within
  the circuit can affect system performance. Due to this reason, many types of current sensors instead measure the magnetic field generated
   around a wire, known as the Hall Effect. 
<br /> 
<br />
<h2>Hall Effect Sensors;</h2>
The Hall effect is a principle which observed that when a current carrying conductor (such as a wire) is introduced to an external perpendicular
 magnetic field then a voltage difference can be measured at the right angle of the current path. Hall effect sensors are composed of a core,
  Hall effect device and relevant signal conditioning circuitry. This type of sensor works by positioning the core around the current conductor
   which then concentrates the conductors’ magnetic field into the Hall effect device which is itself positioned at 90 degrees to the conductor.
    The magnetic field produces a potential difference which is then read as a proportional signal.
<br /> 
<br />
<h2>Inductive Sensors;</h2>
Inductive sensors utilize a coil wrapped around a current carrying wire to sense current in an AC system. The coil is powered from the magnetic
 field generated from the wire and then passes that voltage as a proportional signal. 
<br /> 
<br />
<h2>Magnetoresistive Sensors;</h2>
The magnetoresistive effect is a property of certain metals to change their resistance as a function to the magnetic field applied to them. When
 magnetic flux is not applied, current flows directly between the sensor plates. If a magnetic field is applied, a Lorentz force deflects the
  current, deflects the current between the plates along a longer distance which in turn raises the resistance. The resistance is then measured
   and converted into a signal proportional to the magnetic field strength and thus the current parameters.
 
  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={CurrentSensorsImg} alt="uv sensor" width="60%"/>
                                </div>
                        </p>
            </div>
    )
}