import PEEKImg from '../../Images/PEEKImg.jpg'

export default function PEEK(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>PEEK</h1>
               <p>
               <h2>Polyether ether Ketone (PEEK);</h2>
PEEK is a colorless organic thermoplastic polymer used in a variety of biomedical and engineering applications. This semi-crystalline
 thermoplastic has excellent mechanical and chemical properties which are retained at high temperatures. It also is highly resistant to thermal
  degradation and biodegradation though it has a weakness to halogens and strong Bronsted and Lewis acids.
  <br />
<br />
PEEK polymers are made from step-growth polymerization from the transfer of alkaline groups of phenolate salts. Using recent advances in
 processing, some PEEK is capable of shape-memory behavior where external temperature can return the deformed material back to its original
  shape.
  <br />
<br /> 
Density: 1320 kg/m^3
<br /> 
Young’s Modulus (E): 3.6 GPa
<br /> 
Tensile Strength (𝛉_t): 90-100 MPa
<br /> 
Elongation at Break: 50%
<br /> 
Notch Test: 55 kJ/m^2
<br /> 
Glass Transition Temperature: 143 ‘C
<br /> 
Melting Point: 343 ‘C
<br /> 
Thermal Conductivity: 0.25 W/(m*K)
<br /> 
Water Absorption (24hrs): 0.1%

 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={PEEKImg} alt="PEEK" width="65%"/>
                                </div>          
                        </p>
            </div>
    )
}