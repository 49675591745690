import BearingsImg from '../../MEComponents/BearingsImg.jpg'


export default function Bearings(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Bearings and Mounts</h1>
               <p>
<h2>Intro to Bearings;</h2>
                            <span style={{ fontWeight: 'bold' }}>Bearings</span> are a category of mechanical components which often are used
                             for rotating and shaft applications. Most bearings consist of an outer compartment which houses a rotating inner
                              section that attaches to a rod or cylindrical member. 
                            <br />
                            <br /> 
                            For our rotating bearings, the internal mechanisms which attempt to provide a frictionless rotation internally
                             utilize small metal balls, cylindrical rollers or cone shaped rollers. Ball bearings are often used for lower
                              load applications while the hardier cylindrical bearings will be more expensive but capable of supporting higher
                               load requirements.
                            <br />
                            <br /> 
                            On the lip of most bearings will be two grub screws which when tightened, pin the inserted rods, allowing the
                             transfer of power. Although the frictional force between the rod and the two screws is minimal, because the
                              bearings’ rotary mechanism is nearly frictionless, the rods will rarely slip. Higher load applications can
                               also have stronger ways of pinning the rods to the inside chamber. 
                            <br />
                            <br />
                        <h2>Intro to Mounts;</h2>     
                            <span style={{ fontWeight: 'bold' }}>Mounts</span> on the other hand, do not have a rotating internal assembly
                             and primarily serve to hold a beam in place. As is the case with the clamping mount, the tightening procedure 
                             actually forces the entire mount closer to itself to tighten its hold against the inserted rod. 
                            <br />
                            <br /> 
                            Many bearings such as the rotary bearings which come with or without the flat back plate will not have any 
                            mechanism to grip onto an inserted rod. These are used for very low turning force applications where the 
                            frictional force between the bearings’s inside chamber and the outside of the rod is sufficient to ensure a 
                            smooth rotation.
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={BearingsImg} alt="springs" width="55%"/>
                                </div>
                            </p>
            </div>
    )
}