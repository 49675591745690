// import GT from '../../Images/GT.jpg'

export default function BasicFunctions(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Arduino C++ Basic Functions</h1>
               <p>
                                {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GT} alt="linkages" width="75%"/>
                                </div> */}
           
         
         
           <span style={{ fontWeight: 'bold' }}>DigitalRead Function:</span>
           <br /> 
The digitalRead function checks the voltage level of any digital input.
<br /> 
<code>{`variableName = digitalRead(#);`}</code>
<br /> 
<br /> 
<span style={{ fontWeight: 'bold' }}>DigitalWrite Function:</span>
<br /> 
The digitalWrite function allows you to send 5v or 0v to an output pin.
<br /> 
<code>{`digitalWrite(#, LOW);`}</code>
<br /> 
<code>{`digitalWrite(#, HIGH);`}</code>
<br /> 
<br /> 
<span style={{ fontWeight: 'bold' }}>Analog Read:</span>
<br /> 
The analog read function takes one argument of which pin it should take a voltage reading on and responds with a value between 0 and 1023.
<br /> 
<code>{`analogRead(varName);`}</code>
<br /> 
<br /> 
<span style={{ fontWeight: 'bold' }}>Analog Write:</span>
<br /> 
<code>{`analogWrite(#, #);`}</code>
<br /> 
<br /> 
<span style={{ fontWeight: 'bold' }}>Serial Print:</span>
<br /> 
This function sends information from the arduino to your computer and displays it to the serial monitor. If you give it a variable as an argument, it will print the value of the variable. If the text is in parenthesis, it will print out the letters.
<br /> 
<code>{`Serial.print(varName);`}</code>
<br /> 
<code>{`Serial.print(“text”);`}</code>
<br /> 
<br /> 
<span style={{ fontWeight: 'bold' }}>Serial Print Line:</span>
<br /> 
This function passes information to the serial monitor but first creates a new line.
<br /> 
<code>{`Serial.println(varName);`}</code>
<br /> 
<br /> 
<span style={{ fontWeight: 'bold' }}>Serial Write:</span>
<br /> 
Serial write sends information over the serial connection between the computer and Arduino. It an only send a value between 0 and 255. To ensure you are sending value that can be read, divide an analog pin value by 4. 
<br /> 
<code>{`Serial.write(analogRead(A0/4);`}</code>
<br /> 
<br /> 
<span style={{ fontWeight: 'bold' }}>Delay Functiont:</span>
<br /> 
The delay function tells the Arduino to stop doing anything for a period of time, note that it is in milliseconds (delay(1000) = 1 second).
<br /> 
 <code>{`delay(####);`}</code>
<br /> 
<br /> 
<span style={{ fontWeight: 'bold' }}>Map Function:</span>
<br /> 
This function scales numbers for you. It takes five arguments, the number to be scaled, the minimum value of the input, the max value of input, min value of output and max value of output. 
<br /> 
<code>{`variableName = map(variableName1, #, #, #, #);`}</code>
<br /> 
<br /> 
<span style={{ fontWeight: 'bold' }}>Millis Function:</span>
<br /> 
Millis reports how long has it been since the Arduino has been powered on or reset.
<br /> 
<code>{`millis()`}</code>
<br /> 
<br /> 
<span style={{ fontWeight: 'bold' }}>Tone Function:</span>
<br /> 
The tone function takes three arguments, what pin to play the sound on, what frequency to play (in this case pitch which is a mapped int of the sensorValue), and how long to play the note in milliseconds. 
<br /> 
Be sure to add a delay to give it time to play the sound.
<br /> 
<code>{`tone(#, varName, #;`}</code>
    <br /> 
    <br /> 
    <span style={{ fontWeight: 'bold' }}>No Tone:</span>
    <br /> 
This function tells the pin to stop playing sound. The number inside the parenthesis is the pin to be affected.
<br /> 
<code>{`noTone(#);`}</code>
<br /> 
<br /> 
<span style={{ fontWeight: 'bold' }}>Return:</span>
<br /> 
Return lets the main program know what the outcome of a comparison is. The function terminate the function and returns the value found.
<br /> 
<code>{`return true;`}</code>

                        </p>
            </div>
    )
}