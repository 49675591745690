import FusesImg from '../../Images/FusesImg.jpg'

export default function Inductors(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Fuses & Breakers</h1>
               <p>
                                
      
                        <h2>Intro to Fuses;</h2>
                        Fuses are electrical safety devices which are used to provide overcurrent protection in an electrical system. Most fuses are single-use only and consist of a metal wire or strip which melts when too much current flows through it, thus breaking the circuit. 
                        <br />
                        <br />
                        <h2>Intro to Circuit Breakers;</h2>
                        Circuit breakers are electrical switches which disconnect a circuit in the presence of a short circuit or an overload. Unlike fuses, circuit breakers can be reused and reset either manually or automatically depending on the model.  
  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={FusesImg} alt="Fuses" width="55%"/>
                                </div>
                        </p>
            </div>
    )
}