import React from "react";
import {
//   BrowserRouter as Router,
  useParams,
} from "react-router-dom";
import FMetals from './FMetals';
import NFMetals from './NFMetals';
import Ceramics from './Ceramics';
import Polymers from './Polymers';
import Composites from "./Composites";
import Semiconductors from "./Semiconductors";
import PEEK from "./PEEK";
import Gamma from "./Gamma";
import CastIron from "./CastIron";
import CommonMetals from "./CommonMetals";
import Steel from "./Steel";
import Aluminum from "./Aluminum";
import StainlessSteel from "./StainlessSteel";
import Gauge from "./Gauge";
import Wood from "./Wood";
import Epoxy from "./Epoxy";

const ChangeComponents = {
    fmetals: FMetals,
    nfmetals: NFMetals,
    ceramics: Ceramics,
    polymers: Polymers,
    composites: Composites,
    semiconductors: Semiconductors,
    peek: PEEK,
    gamma: Gamma,
    castiron: CastIron,
    commonmetals: CommonMetals,
    steel: Steel,
    aluminum: Aluminum,
    stainlesssteel: StainlessSteel,
    gauge: Gauge,
    wood: Wood,
    epoxy: Epoxy,
  };

export default function Subtopics(props) {
    // The <Route> that rendered this component has a
    // path of `/topics/:topicId`. The `:topicId` portion
    // of the URL indicates a placeholder that we can
    // get from `useParams()`.
    let { topicId } = useParams();
    
    let MyComponent = ChangeComponents[topicId];
    return (
        <div>
            {/* {topicId} is for testing route names */}
            {/* <h3>{topicId}</h3> */}
            <MyComponent topicId={topicId}/>
      </div>
    );
  }