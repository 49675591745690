import LowpassFilterImg from '../../Images/LowpassFilterImg.jpg'

export default function Lowpass(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Lowpass Filters</h1>
               <p>
                                
           
                   
                        <h2>Intro to Low-pass Filters;</h2>
Low-pass filters allow low frequency signal components to pass.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={LowpassFilterImg} alt="LowPass Filter" width="55%"/>
                                </div> 
                
                        </p>
            </div>
    )
}