import DerivativesImg from '../../Images/DerivativesImg.jpg'
import DerivativesTableImg from '../../Images/DerivativesTableImg.PNG'

export default function Derivatives(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Derivatives</h1>
               <p>
                                
           
                   
                        <h2>Intro to Differential Calculus;</h2>
A derivative is the rate of change of a line of curve taken at an instantaneous moment in time. 
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={DerivativesImg} alt="Derivatives" width="35%"/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={DerivativesTableImg} alt="Derivatives Table" width="65%"/>
                                </div>  
                
                        </p>
            </div>
    )
}