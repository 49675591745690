import styles from './Forum.module.css';
// import Forumpage from '../../images/ToolbarImg11.jpg';
import {
    // BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch
  } from "react-router-dom";
import Cards from '../../components/Cards';
import Tools from '../Tools';

function Forum(props) {
    let { path, url } = useRouteMatch();
    return (
<div>
          
            {/* <div className={styles.boldheader}>
    <img src={Forumpage} alt="Forumpage" width="100%" />
</div> */}

<div>
<div  className={styles.Table}>
            <div className={styles.Cell}>
                <Cards title="Idea" className={styles.Title} style={{backgroundColor: 'blue'}}/>                                        
                <div className={styles.Block}>
                <Link to={`${url}/overview`} className={styles.Brick}>Product Development</Link>
                </div>
                <div className={styles.Block}>
                <Link to={`${url}/actionreaction`} className={styles.Brick}>Action & Reaction</Link>
                </div>
                
            </div>
          
            <div className={styles.Cell}>
                <Cards title="Research" className={styles.Title} style={{backgroundColor: 'blue'}}/>                            
                <div className={styles.Block}>
                <Link to={`${url}/forcesmotion`} className={styles.Brick}>Unit Converter</Link>
                </div>
            </div>
          
            <div className={styles.Cell}>
                <Cards title="Setup" className={styles.Title} style={{backgroundColor: 'blue'}}/>                                       
                <div className={styles.Block}>
                <Link to={`${url}/componentdetermination`} className={styles.Brick}>Project Manager</Link>
                </div>
            </div>              
            
            <div className={styles.Cell}>
                <Cards title="Design" className={styles.Title} style={{backgroundColor: 'blue'}}/>  
                <div className={styles.Block}>
                <Link to={`${url}/componentdetermination`} className={styles.Brick}>Component Determination</Link>
                </div>
            </div>                 
            
            <div className={styles.Cell}>
                <Cards title="Prototyping" className={styles.Title} style={{backgroundColor: 'blue'}}/>
                {/* <div className={styles.Block}>
                <Link to={`${url}/workshopbuilder`} className={styles.Brick}>Workshop Builder</Link>
                </div> */}
                <div className={styles.Block}>
                <Link to={`${url}/generalmachines`} className={styles.Brick}>General Machines</Link>
                </div>
                <div className={styles.Block}>
                <Link to={`${url}/additivemachines`} className={styles.Brick}>Additive Machines</Link>
                </div>
                <div className={styles.Block}>
                <Link to={`${url}/metalshopmachines`} className={styles.Brick}>Metalshop Machines</Link>
                </div>
                <div className={styles.Block}>
                <Link to={`${url}/woodshopmachines`} className={styles.Brick}>Woodshop Machines</Link>
                </div>
                <div className={styles.Block}>
                <Link to={`${url}/electricalmachines`} className={styles.Brick}>Electrical Machines</Link>
                </div>
                <div className={styles.Block}>
                <Link to={`${url}/handtools`} className={styles.Brick}>Hand Tools</Link>
                </div>
                <div className={styles.Block}>
                <Link to={`${url}/consumables`} className={styles.Brick}>Consumables</Link>
                </div>
                <div className={styles.Block}>
                <Link to={`${url}/apparel`} className={styles.Brick}>Clothing & Apparel</Link>
                </div>
            </div>
           
            <div className={styles.Cell}>
                <Cards title="Programming" className={styles.Title} style={{backgroundColor: 'blue'}}/>
                <div className={styles.Block}>
                <Link to={`${url}/arduino`} className={styles.Brick}>Arduino C++</Link>
                </div>  
                <div className={styles.Block}>
                <Link to={`${url}/html`} className={styles.Brick}>HTML</Link>
                </div>  
                <div className={styles.Block}>
                <Link to={`${url}/css`} className={styles.Brick}>CSS</Link>
                </div> 
                <div className={styles.Block}>
                <Link to={`${url}/javascript`} className={styles.Brick}>Javascript</Link>
                </div> 
                <div className={styles.Block}>
                <Link to={`${url}/reactcode`} className={styles.Brick}>React</Link>
                </div> 
                </div>
         </div>  
</div>

              
            <div>
            
                    <Switch>
                        <Route exact path={path}>
                        <h3 style={{marginLeft:'10px', marginTop:'5px', marginBot:'5px'}}>Please select a tool to get started.</h3>
                        </Route>
                        <Route path={`${path}/:topicId`}>
                        <Tools />
                        </Route>
                    </Switch>
            </div>
        </div>

    )
}
export default Forum;  