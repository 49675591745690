import LinkagesImg from '../../Images/LinkagesImg.jpg'
import ToggleJointsImg from '../../Images/ToggleJointsImg.png'
import ConRodImg from '../../Images/ConRodImg.png'

export default function Linkages(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Linkages</h1>
               <p>
<h2>Intro to Linkages;</h2>
                        The mechanical linkage is an assembly of connected (usually metal) components that can be configured in an 
                        endless array of configurations to transfer power from one form to another. Note that the number of linear 
                        and rotational dimensions that it can move in is called the system's degree of freedom.                    
                        <br />
                        <br />
                        The family of linkages includes designs such as the lever, which balances a rigid body against a fulcrum. 
                        Due to natural laws such as those for moment force, designers can select the location of the pivot and generate 
                        massive forces from minor ones. 
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={LinkagesImg} alt="linkages" width="75%"/>
                                </div>
                                <br />    
                        <br />
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>                    
                            <img src={ToggleJointsImg} alt="Toggle Joints" width="100%"/>
                        </div>   
                        <div style={{width:'70%'}}>
                        <h2>Two Bar Linkage (Toggle Joints);</h2>
                    
             
                        
The toggle joint is a mechanism made of two arms linked together. In regards to the diagram, point 1 is fixed in space but free to rotate, point 2 is free to move in space and can rotate and point 3 can only rotate and move in the horizontal direction. This configuration creates a massive force multiplication when a force F is applied vertically downwards at point 2. The horizontal force P can be calculated with the following equations.


                     </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>                    
                            <img src={ConRodImg} alt="Con Rod" width="100%"/>
                        </div>   
                        <div style={{width:'70%'}}>
                        <h2>Connecting Rod Mechanism;</h2>
                    
             
                        
                        The connecting rod mechanism is a single bar connection to a rotating component. The position (s), velocity (v) and acceleration (a) can be found using the corresponding equation.

                     </div>
                        </div>
                        </p>
            </div>
    )
}