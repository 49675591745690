import PolymerChainsImg from '../../Images/PolymerChainsImg.jpg'
import PolymerEquationImg from '../../Images/PolymerEquationImg.JPG'

export default function PolymerStructures(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Polymer Structures</h1>
               <p>
    
                        <h2>Intro to Polymer Structures;</h2>
The majority of polymers are hydrocarbons, meaning they are created from hydrogen and carbon. A saturated hydrocarbon has each carbon atom singularly bonded to four other atoms. Unsaturated hydrocarbons which have a tendency to be unstable, have double and even triple bonds. Isomerism is the condition that two compounds can have the same chemical formula but very different structures. 
<br />
                        <br /> 
<h2>Polymer Definitions;</h2>
Linear Polymer: Spaghetti-like molecular chains.
<br /> 
Branched Polymer: Composed of backbone chains with small secondary bond chains between them.
<br /> 
Elastomers and Rubber: Materials which can sustain deformations greater than 200% and may be thermoplastics or lightly cross-linked thermosets. The chain of coil-like molecules are capable of reverse stretching by applying force.
<br /> 
Thermoplastics: These have long chains produced by joining monomers and typically display plastic, ductile characteristics.
<br /> 
Thermosets: These have long chains of molecules strongly cross-linked together to form a three-dimensional network. These are generally stronger but more brittle than thermoplastics. They also do not melt but instead begin to decompose when subject to heat.,
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={PolymerChainsImg} alt="PolymerChainsImg" width="65%"/>
                                </div> 
<h2>Tacticity;</h2>
The structure of a polymer will strongly affect their properties and their ability for molecules to slide past each other or arrange themselves in regular crystalline patterns. A regular assortment called isotactic permits the greatest regularity of packing and bonding while an alternating pattern called syndiotactic or a random pattern called static produces pooper molecular packing causing lower strength and melting temperature.
<br />
                        <br /> 
<h2>Degree of Polymerization;</h2>
Degree of polymerization is the average number of repeat units per chain. Lowercase m in the equation is the average molecular weight of repeat units.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={PolymerEquationImg} alt="Polymer Equation" width="35%"/>
                                </div>                
                        </p>
            </div>
    )
}