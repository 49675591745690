import EfficiencyImg from '../../Images/EfficiencyImg.jpg';


export default function Efficiency(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
                     {/* <SectionTitle title={props.topicId}/> */}
                     <h1>Efficiency</h1>
                      <p>
                            
                            <h2>Intro to Efficiency;</h2>
                            <span style={{ fontWeight: 'bold' }}>Efficiency</span> is the measure of how well a system performs and what
                             percentage of its power is properly transferred. An ideal system with 100% efficiency is technically impossible 
                             because in all cases, some energy will be lost to friction and heat. However, well designed mechanical systems can 
                             show efficiencies within the high 90%. 
                            <br />
                            <br /> 
                            In the case of motors, reading the motor sheets and finding the motor efficiency will give you the ratio between
                             the electrical power supplied to the motor and the mechanical power the motor is able to put out.
                            <br />
                            <br /> 
                            In the case of mechanical assemblies, designing an efficient system is extremely important. Inefficient systems 
                            which operate for long periods of time will continuously lose power in the form of heat which will in turn result
                             in ever increasing temperatures. Since most assemblies have a predetermined range of operating temperatures, 
                             inefficient systems will eventually cross those limits and cause damage and even failure.
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={EfficiencyImg} alt="Efficiency" width="55%"/>
                            </div>
                            </p>
            </div>
    )
}