import CNCMachineImg from '../../Images/CNCMachineImg.jpg'
import T31 from '../../Images/T31.JPG'

export default function CNCMachine(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>CNC Machine</h1>
               <p>

     <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={CNCMachineImg} alt="CNC Machine" width="45%"/>
                                </div>
                                <span style={{ fontWeight: 'bold' }}>Description:</span> A computer numerical control machine is an automated control device which converts computer code to machine instructions. Modern CNC machines can come equipped with tool change wheels to allow a wide range of functionally.
                                <br />
                        <br /> 
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={T31} alt="CNC Machine" width="100%"/>
                                </div>
                                <br /> 
                                <span style={{ fontWeight: 'bold' }}>Spindle Power:</span>Spindle Power: The maximum output of mechanical work performed by the spindle onto a workpiece.
                                <br />
                        <br /> 
                                <span style={{ fontWeight: 'bold' }}>Max Speed:</span> The max rotating speed of the spindle element.
                                <br />
                        <br /> 
                                <span style={{ fontWeight: 'bold' }}>Feed Rate:</span> The max travel speed of the machine element being used.
                                <br />
                        <br /> 
                                <span style={{ fontWeight: 'bold' }}>Build Volume:</span> The max dimensions of the internal work surface which determines the size of the work pieces which the machine can operate on. 
                        </p>
            </div>
    )
}