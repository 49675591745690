import SpectrometerImg from '../../Images/Spectrometer.jpg'

export default function Spectrometers(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Spectrometers</h1>
               <p>
                                
           
                     
                        <h2>Intro to Spectrometers;</h2>
Optical spectroscopy is the field of sensory components used for measuring light intensity in the ultraviolet (UV), visible (VIS), near-infrared
 (NIR) and infrared (IR) range of the electromagnetic spectrum. These can be used for such applications as color measurement, concentration 
 determination of chemical flows and electromagnetic radiation analysis. 
<br />
                        <br /> 
The instrument typically consists of five main parts, the entrance slit, collimator, a grating or prism for dispersion, a focusing optic and 
a detector. Fibre-optic cables can be used to transport light from the sample to the optical bench of the spectrometer. The choice of these 
subcomponents can have a huge impact on the sensitivity, resolution, bandwidth and stray light within the system. 
<br />
                        <br /> 
A diffraction grating is an optical element that separates incoming radiation into specific wavelengths. These are formed by a series of
 equally spaced parallel grooves formed in a reflective coating on a suitable substrate. The way these grooves are formed separate the gratings 
 into two types; holographic and ruled. The former is made by ruling a diamond across the reflective surface while the ladder is formed by laser
  interference. 
<br />
                        <br /> 
When determining the optimal configuration of a spectrometer, wavelength range is the most important parameter and will define the appropriate
 grating choice. Slit size is also a key factor in determining both resolution and output into the optical bench. The need to balance resolution
  and light output is necessary as an optimized resolution may not provide enough light to get a stable measurement. 
<br />
                        <br /> 
The data capture rate is much faster with linear detector arrays which have no moving parts than with monochromator designs. 
                
                         <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={SpectrometerImg} alt="spectrometer layout" width="40%"/>
                                </div>
<h2>Dark Current;</h2>
Dark current is a relatively low amount of electric current that may flow through photosensitive devices even when no photons are passing through 
the device. These electrons can flow even if there is no ambient or direct light, thus the name ddark. This electric current which flows are the
 charges generated through heat from the detector which have been thermally excited and from leakage within the machine. 

This type of current is one of the main sources of noise within photosensitive devices such as spectrophotometers. With highly sensitive devices
 which have limited photon counts, as you approach higher absorbance unit values, dark current may be the resultant of data flatlining. Due to
  the fact that dark current is thermally generated, lowering the temperature of the device is a physical way of lowering the dark current. 
  Otherwise, stable dark current can be accounted for while unstable dark current will have to be interpolated over. 
                      
                        </p>
            </div>
    )
}