import CentroidsImg from '../../Images/CentroidsImg.jpg'


export default function Centroids(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Centroids</h1>
               <p>
 <h2>Intro to Centroids;</h2>
                            Unless using a 3D modeling software which does the heavy lifting for you, it is extremely helpful to treat objects as if the entirety of their mass is condensed on their centers of gravity. The <span style={{ fontWeight: 'bold' }}>centroid</span> can be understood as the point where an entire object could be balanced on the tip of a pin. 
                            <br />
                            <br /> 
                            It is often the case when dealing with complex shapes that the center of mass isn’t physically on the object but somewhere near it. This occurrence is perfectly acceptable for the purpose of calculations. Knowing the location of the center of rotation can be extremely helpful at understanding how force vectors may rotate your object. The centroid of an object is not only its center of mass and center of rotation but its center of gravity. All these characteristics can be reduced to a single point in space and allows difficult assortments of shapes to be taken as a whole.
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={CentroidsImg} alt="Centroids" width="65%"/>
                                </div>     
                            </p>
            </div>
    )
}