import SignalsImg from '../../Images/SignalsImg.jpg'
import FMoCImg from '../../Images/FMoCImg.jpg'

export default function Signals(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Signals</h1>
               <p>
                              
                      
<h2>Introduction to Signals;</h2>
Since the beginning of the 19th century, electrical signals have been used to represent, manipulate, transmit and receive information. This
 information can be categorized into two different forms: analog and digital.<span style={{ fontWeight: 'bold' }}> Analog</span> information
  is continuous, while <span style={{ fontWeight: 'bold' }}>discrete</span> signals only 
 exist as a fixed value between instances of time. Both digital and analog information is represented by the most fundamental quantity of 
 electrical engineering, the signal.
 <br /> 
               <br />   
The conversion of information from an energy form into another is known as energy conversion or <span style={{ fontWeight: 'bold' }}>transduction</span>. All conversion systems outside
 pure theory are inefficient and lose various amounts of energy as heat. The hardware and medium which the signal travels will determine the
  magnitude of this inefficiency. Mathematically, a signal is merely a function with independent variables such as time, space and value 
  integers holding certain “weights”. 
  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={SignalsImg} alt="Basic Filters" width="65%"/>
                                </div>                      
<h2>Fundamental Model of Communication;</h2>
The Fundamental Model of Communication depicts a signal produced by a <span style={{ fontWeight: 'bold' }}>source</span> with the goal of being ultimately absorbed by the <span style={{ fontWeight: 'bold' }}>sink</span>. With
 physical systems, each signal corresponds to an electrical voltage or current. With time-domain signals, such as speech, music or video 
 signals (television), the signals can be a function of two spatial variables and time.
 <br /> 
               <br />   
Communication signals such as messages must be recast for transmission. This is depicted below as the source signal s(t) passing through the
<span style={{ fontWeight: 'bold' }}> transmitter</span> block and exiting as a modulated x(t) signal. The same block diagram applies for most communication systems although the subsystems
  may be very different. In the case of computers, the transmitter block encapsulates the typed characters into a packet and launches them into
   the internet with their destination address. In the case of radio transmitters, the transmitter block accepts an input audio signal and
   produces an electromagnetic wave that is distributed via an antenna. 
   <br /> 
               <br />   
The transmitted signals next pass through the <span style={{ fontWeight: 'bold' }}>channel</span>. The channel is where the majority of corruption occurs with noise,
 distortions and attenuation (gradual loss of intensity) progressively affecting the signal. Once finally having passed through various 
 mediums, the communication will reach the <span style={{ fontWeight: 'bold' }}>receiver</span> as the signal r(t). For decades engineers have worked to create receivers that can 
 decipher these r(t) signals and reconstruct them back into the closest semblance of the original s(t) signal. These reconstructed s(t) 
 signals have a “hat” to represent their often imperfect resemblance to the original messages. The sink finally receives the message without
  producing an output of its own and can proceed based on the system.
  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={FMoCImg} alt="Basic Filters" width="65%"/>
                                </div>                       
                        </p>
            </div>
    )
}