import Absorbance from '../../Images/LightAbsorbtion.jpg'

export default function Light(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Light Absorbance</h1>
               <p>
                               

                    
                        <h2>Intro to Light Absorbance;</h2>
                        Light absorbance occurs when the energy of photons is transferred into atoms and molecules. This reduction in light
                         energy exponentially diminishes as light travels through clear materials or solutions. This implies that the reduction
                          in light is thus exponentially related to the concentration of the sample and the path which the light takes. 
                          <br />
<br /> 
The Beer-Lambert Law provides a relationship between the transmittance of light to the absorbance by taking the negative log(10) of the 
transmittance. This results in a linear relationship with the intensity of the absorbing medium and the distance the light travels. In this 
equation, T refers to transmittance which is a ratio of the transmitted light over the incident light.
<br />
<br /> 
Absorbance is measured in absorbance units (Au) which is related to transmittance. For reference, 1.0Au is equal to 10% transmittance, 2.0Au
 is equal to 1% transmittance and 3.0Au is equal to .1% transmittance. Since transmittance decreases logarithmically, linearity breaks down on 
 higher concentrations due to sample saturation. Sample saturation referring to the point in which new data does not provide any meaningful
  information. Since the transmitted light at 2.0Au is already at 1%, any changes in light concentration would reflect only minor changes in
   light absorption. Generally would never reach 4.0Au and still maintain a linear relationship between absorbance and transmittance. The same
    is true for lower end measurements.
    <br />
<br /> 
Samples being measured for absorbance are subject to the errors associated with light such as reflection, refraction and scattering. Light 
scattering, which is the event where particles or radiation deviates light from moving in a straight line, can decrease the transmission of 
light even if no light is being absorbed. These kinds of errors can be mitigated by maximizing resolution through particle removal and using 
glass cuvettes which minimizes absorption of light.
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={Absorbance} alt="light absorbance" width="50%"/>
                                </div>
                        </p>
            </div>
    )
}