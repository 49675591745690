import OscilloscopeImg from '../../Images/OscilloscopeImg.jpg'
import T51 from '../../Images/T51.JPG'

export default function Oscilloscope(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Oscilloscope</h1>
               <p>
                                
   
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={OscilloscopeImg} alt="Oscilloscope" width="45%"/>
                                </div>
                                <span style={{ fontWeight: 'bold' }}>Description:</span> Oscilloscopes are devices which exclusively measure voltage and have a response rate fast enough to plot real-time voltage data. These machines can also be configured to indirectly measure current, strain, acceleration and pressure.
                                <br />
                        <br /> 
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={T51} alt="Oscilloscope" width="70%"/>
                                </div>
                                <br />     
                        <span style={{ fontWeight: 'bold' }}>Analog Bandwidth:</span> Generally, bandwidth is the measure of how well an oscilloscope is able to measure a signal. Specifically, bandwidth is the frequency at which the oscilloscope attenuates a sinusoidal input signal to 70.7% of the signal’s true amplitude.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Sample Rate:</span> The rate at which the machine is capable of generating and storing data.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Record Length:</span> Given an amount of data input, record length is the amount of time which the machine will spend recording the incoming data.
<br />
                        <br /> 
                        <span style={{ fontWeight: 'bold' }}>Analog Channels:</span> The amount of input signals the machine can process at once.

                        </p>
            </div>
    )
}