// import VariablesImg from '../../Images/VariablesImg.jpg'

export default function CSS(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>CSS</h1>
               <p>
                                
           
                        
                        <h2>Intro to CSS;</h2>
CSS is a programming used to make a website beautiful and organized.
{/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={VariablesImg} alt="Variables" width="65%"/>
                                </div> */}
                
                        </p>
            </div>
    )
}