import GroundsImg from '../../Images/GroundsImg.jpg'

export default function Grounds(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Grounds</h1>
               <p>
                                
           
                 
                        <h2>Intro to Grounds;</h2>
Understanding voltage allows you to see that it is a measure between the difference between two points which by itself provides little usable
 information. The voltages are much more useful when you can compare them to a zero-reference of zero which is often the purpose of a ground
  in the circuit. 
<br />
                        <br /> 
The definition of an earth ground is a connection terminated using a rod buried in the earth at more than 8 feet. The earth provides an
 electrically neutral body with a comparatively infinite balance of positive and negative charges which dispels attempts at charging.
  Any introduction of new charge is quickly absorbed into the earth with no measurable effect due to the earth’s size. This makes the
   earth a uniquely useful reference point to compare other signals. By connecting a circuit to the earth, it allows sharing of a common
    reference point across any device connected. 
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GroundsImg} alt="grounds" width="35%"/>
                                </div>
                        </p>
            </div>
    )
}