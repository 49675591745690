import PotentiometersImg from '../../Images/PotentiometersImg.jpg'

export default function Potentiometers(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Potentiometers</h1>
               <p>
                                
           
                       
                        <h2>Intro to Potentiometers;</h2>
A potentiometer is a physically adjustable variable resistor with three terminals. Two of the terminals are connected at both ends of the
 resistor while the third connects to a sliding contact called a wiper which moves over the resistive element. The position of the wiper 
 determined the output voltage of the potentiometer which essentially functions as a variable voltage divider. 
 <br />
                        <br /> 
Potentiometers are often referred to as potmeters or pots and the most common type of potentiometer is the single turn rotary potentiometer. 
The potentiometer taper is the relationship between the mechanical potion and the resistance. Linear tapers and logarithmic tapers are the two 
most common types of potentiometers manufactured. 
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={PotentiometersImg} alt="potentiometer types" width="65%"/>
                                </div>
                        </p>
            </div>
    )
}