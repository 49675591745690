// import GT from '../../Images/GT.jpg'

export default function BasicFunctions(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>REACT Basic Functions</h1>
               <p>
                                {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={GT} alt="linkages" width="75%"/>
                                </div> */}
           
       
           
           <span style={{ fontWeight: 'bold' }}>Text in Paragraph:</span>
           <br /> 
<code>{`<p> text </p>`}</code>
<br /> 
<br /> 
<span style={{ fontWeight: 'bold' }}>Header 1:</span>
<br /> 
<code>{`<h1> text </h1>`}</code>
<br /> 
<br /> 
<span style={{ fontWeight: 'bold' }}>Break:</span>
<br /> 
<code>{`<br />`}</code>
<br /> 
<br /> 
<span style={{ fontWeight: 'bold' }}>Import:</span>
<br /> 
<code>{`import fileName from ‘../../Images/fileName.jpg’`}</code>
<br /> 
<br /> 
<span style={{ fontWeight: 'bold' }}>Picture In Div:</span>
<br /> 
<code>{`<div style={{display: ‘flex’, justifyContent: ‘center’, alignItems: ‘center’}}>`}</code>
<br /> 
<code>{`<img src={fileName} alt=“alternative” width=“75%”/>`}</code>
<br /> 
<code>{`</div>`}</code>
<br /> 
<br /> 
<span style={{ fontWeight: 'bold' }}>Code as Text:</span>
<br /> 
<code>{`<code>{add wrapped apostrophes and text here}</code>`}</code>
<br /> 
<br /> 
<span style={{ fontWeight: 'bold' }}>Bold Text with Span:</span>
<br /> 
<code>{`<span style={{ fontWeight: ‘bold’ }}>Text</span>`}</code>

                        </p>
            </div>
    )
}