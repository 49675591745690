import CompositesImg from '../../Images/CompositesImg.jpg'

export default function Composites(props) {
    return (
        <div style={{marginTop: '10px', marginLeft:'0px', border: '3px', padding:'40px', borderStyle:'solid', borderColor:'grey'}}>
               {/* <SectionTitle title={props.topicId}/> */}
               <h1>Composites</h1>
               <p>
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-evenly'}}>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={CompositesImg} alt="Composites" width="100%"/>
                                </div>
                                <div style={{width:'80%'}}>
                                <h2>Intro to Composites</h2>
           A composite material is one made from two or more materials of distinct categories whose combination leads to a new material with different properties than those of the parent elements. One of the strengths of composites is they can be isomorphic, meaning they may have different strength characteristics in different directions.  Composites can also be strong yet stiff with strength/stiffness to weight ratios much higher than steel. They may also have greater toughness and fatigue resistance than many metals.
           <br />
                        <br /> 
Composites themselves are often made from a primary matrix and secondary layer. The matrix serves as the bulk of the material while the secondary layer, sometimes called the reinforcing agent, adds additional properties to the composite. These secondary layers can come in the form of a fiber, particle or special geometry.

                                </div>
                                </div>
          
<h2>Limitations</h2>                   
Composites are often at the mercy of their parent element weaknesses and composites which use polymers are likely subject to chemical attacks as were the parent polymers. The manufacturing is also more complicated which leads to higher consumer cost.               
                        </p>
            </div>
    )
}